import React from "react";
import { PropsOf } from "@ugitgud/legos/ui/shared";
import { twMerge } from "tailwind-merge";
import { SupportedGames } from "@ugitgud/legos/ui/nav/GameSelector";

export type GameSelectorButtonGroupChildProps = { toggled?: boolean; game: SupportedGames };

export type GameNavButtonGroupProps = PropsOf<"div"> & {
  selectedGame: SupportedGames;
  children: React.ReactElement<GameSelectorButtonGroupChildProps>[] | React.ReactElement<GameSelectorButtonGroupChildProps>;
};

export const GameNavButtonGroup = React.forwardRef(
  (
    { className, children, selectedGame, ...rest }: GameNavButtonGroupProps,
    ref: React.RefObject<React.ElementRef<"div">> | React.ForwardedRef<React.ElementRef<"div">>,
  ) => {
    const classNames = twMerge("w-full flex flex-nowrap flex-row justify-start dark:bg-purple-550", className);
    return (
      <div ref={ref} className={classNames} {...rest}>
        {React.Children.map(children, (x) => {
          if (React.isValidElement(x)) {
            return React.cloneElement(x, { toggled: x.props.game === selectedGame });
          }
          return null;
        })}
      </div>
    );
  },
);
