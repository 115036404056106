import { normalizeToBasicPatch } from "@outplayed/riot-assets";

export function normalizePatch(patch: string) {
  const patchSplit = patch.split(/_|\./g);
  if (patchSplit.length >= 2) {
    return `${patchSplit[0]}_${patchSplit[1]}`;
  }

  return patch;
}

export function comparePatch(patch1: string, patch2: string) {
  const normalizedPatch1 = normalizeToBasicPatch(patch1);
  const normalizedPatch2 = normalizeToBasicPatch(patch2);

  if (!normalizedPatch1 && !normalizedPatch2) return 0;
  if (!normalizedPatch1) return 1;
  if (!normalizedPatch2) return -1;

  const season1 = Number(normalizedPatch1.split(".")[0]);
  const season2 = Number(normalizedPatch2.split(".")[0]);
  const week1 = Number(normalizedPatch1.split(".")[1]);
  const week2 = Number(normalizedPatch2.split(".")[1]);

  if (season1 === season2) {
    if (week1 === week2) {
      return 0;
    } else if (week1 < week2) {
      return -1;
    } else {
      return 1;
    }
  } else if (season1 < season2) {
    return -1;
  } else {
    return 1;
  }
}
