import { useState } from "react";
import gql from "graphql-tag";
import { useApolloClient, useQuery, QueryHookOptions, QueryOptions } from "@apollo/client";
import { replacePlusFromURLPath } from "@ugg/shared/utils/url-query-params";
import { INTERNAL_CURRENT_SEASON } from "@ugg/shared/utils/season-helpers";
import { MatchLP } from "@ugg/shared/components/SummonerProfiles/overview/match-history/match-history-helpers";

type MatchSummaryParams = Partial<{
  queueType: number[];
  duoRiotUserName: string;
  duoRiotTagLine: string;
  role: number[];
  season: number;
  championId: number;
  page: number;
}>;

export function getParamsForFetchMatchSummaryQuery(
  region: string,
  riotUserName: string,
  riotTagLine: string,
  queryParams: MatchSummaryParams = {},
) {
  return {
    regionId: region,
    riotUserName: replacePlusFromURLPath(riotUserName.toLowerCase()),
    riotTagLine: replacePlusFromURLPath(riotTagLine.toLowerCase()),
    queueType: queryParams.queueType || [],
    duoRiotUserName: queryParams.duoRiotUserName || "",
    duoRiotTagLine: queryParams.duoRiotTagLine || "",
    role: queryParams.role || [],
    seasonIds: !!queryParams.season ? [queryParams.season] : [INTERNAL_CURRENT_SEASON, INTERNAL_CURRENT_SEASON - 1],
    championId: !queryParams.championId || isNaN(queryParams.championId) ? [] : [queryParams.championId],
    page: queryParams.page ?? 1,
  };
}

export function useFetchSummonerMatchSummaries(
  regionId: string,
  riotUserName: string,
  riotTagLine: string,
  queryParams: MatchSummaryParams,
  queryOptions: QueryHookOptions = {},
) {
  const params = getParamsForFetchMatchSummaryQuery(regionId, riotUserName, riotTagLine, queryParams || {});

  return useQuery<PlayerMatchSummariesData>(FETCH_MATCH_SUMMARIES, {
    ...queryOptions,
    variables: params,
  });
}

export function useFetchSummonerMatchSummariesLazily(
  regionId: string,
  riotUserName: string,
  riotTagLine: string,
  queryParams: MatchSummaryParams,
  queryOptions?: QueryOptions,
) {
  const client = useApolloClient();
  const params = getParamsForFetchMatchSummaryQuery(regionId, riotUserName, riotTagLine, queryParams || {});

  const [fetchState, setFetchState] = useState<{
    loading: boolean;
    data: PlayerMatchSummariesData["fetchPlayerMatchSummaries"] | null;
    error: Error | null;
  }>({ loading: false, data: null, error: null });

  const getSummonerMatchSummaries = (page: number) => {
    setFetchState({ loading: true, data: null, error: null });
    client
      .query<PlayerMatchSummariesData>({
        ...(queryOptions || {}),
        query: FETCH_MATCH_SUMMARIES,
        variables: { ...params, page: page },
      })
      .then((response) => {
        setFetchState({ loading: false, data: response.data.fetchPlayerMatchSummaries, error: null });
      })
      .catch((error) => {
        setFetchState({ loading: false, data: null, error: error });
      });
  };

  return [getSummonerMatchSummaries, fetchState] as const;
}

export interface TeamData {
  championId: number;
  summonerName: string | null;
  riotUserName: string | null;
  riotTagLine: string | null;
  teamId: number;
  role: number;
  hardCarry: number;
  teamplay: number;
  placement: number;
  playerSubteamId: number;
}
export interface MatchSummary {
  assists: number;
  augments: number[];
  championId: number;
  cs: number;
  damage: number;
  damageTaken: number;
  deaths: number;
  gold: number;
  items: number[];
  jungleCs: number;
  killParticipation: number;
  kills: number;
  level: number;
  matchCreationTime: number;
  matchDuration: number;
  matchId: number;
  maximumKillStreak: number;
  primaryStyle: number;
  queueType: string;
  regionId: string;
  role: number;
  runes: number[];
  subStyle: number;
  summonerName: string | null;
  riotUserName: string | null;
  riotTagLine: string | null;
  summonerSpells: number[];
  psHardCarry: number;
  psTeamPlay: number;
  lpInfo: MatchLP | null;
  teamA: TeamData[];
  teamB: TeamData[];
  version: string;
  visionScore: number;
  win: boolean;
}
export interface PlayerMatchSummariesData {
  fetchPlayerMatchSummaries: {
    finishedMatchSummaries: boolean;
    matchSummaries: MatchSummary[];
    totalNumMatches: number;
  };
}

const TeamData = `
  championId
  summonerName
  riotUserName
  riotTagLine
  teamId
  role
  hardCarry
  teamplay
  placement
  playerSubteamId
`;

export const MatchSummaryInfo = `
  assists
  augments
  championId
  cs
  damage
  deaths
  gold
  items
  jungleCs
  killParticipation
  kills
  level
  matchCreationTime
  matchDuration
  matchId
  maximumKillStreak
  primaryStyle
  queueType
  regionId
  role
  runes
  subStyle
  summonerName
  riotUserName
  riotTagLine
  summonerSpells
  psHardCarry
  psTeamPlay
  lpInfo {
    lp
    placement
    promoProgress
    promoTarget
    promotedTo {
      tier
      rank
    }
  }
  teamA {
    ${TeamData}
  }
  teamB {
    ${TeamData}
  }
  version
  visionScore
  win
`;

const FETCH_MATCH_SUMMARIES = gql`
  query FetchMatchSummaries(
    $championId: [Int],
    $page: Int,
    $queueType: [Int],
    $duoRiotUserName: String,
    $duoRiotTagLine: String,
    $regionId: String!,
    $role: [Int],
    $seasonIds: [Int]!,
    $riotUserName: String!
    $riotTagLine: String!
  ) {
    fetchPlayerMatchSummaries(
      championId: $championId,
      page: $page,
      queueType: $queueType,
      duoRiotUserName: $duoRiotUserName,
      duoRiotTagLine: $duoRiotTagLine,
      regionId: $regionId,
      role: $role,
      seasonIds: $seasonIds,
      riotUserName: $riotUserName
      riotTagLine: $riotTagLine
    ) {
      finishedMatchSummaries
      totalNumMatches
      matchSummaries {
        ${MatchSummaryInfo}
      }
    }
  }
`;
