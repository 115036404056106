import React from "react";
import { Tag, TagLabel, TagProps } from "@ugitgud/legos/ui/form/tag/Tag";
import classNames from "classnames";

export type NavTagProps = Omit<TagProps, "children"> & { children: string | React.ReactNode; shrink?: boolean };

export const NavTag = React.forwardRef<HTMLSpanElement, NavTagProps>(
  ({ className, children, size, shrink = true, ...props }, ref) => {
    return (
      <Tag className={classNames(className, "font-bold")} size={size} ref={ref} {...props}>
        <TagLabel className="uppercase inline-flex" size={size}>
          <span
            className={classNames(
              shrink && "xxxs:max-w-[1ch]",
              "xxs:max-w-none overflow-hidden uppercase text-clip whitespace-nowrap",
            )}
          >
            {children}
          </span>
        </TagLabel>
      </Tag>
    );
  },
);
