import { ALL_QUEUE_TYPE_OPTIONS, ALL_ROLE_OPTIONS, SEASON_OPTIONS } from "./common";

export const OVERVIEW_OPTIONS = {
  queueType: ALL_QUEUE_TYPE_OPTIONS,
  role: ALL_ROLE_OPTIONS,
  season: SEASON_OPTIONS,
};

export const OVERVIEW_DEFAULT = {
  queueType: ALL_QUEUE_TYPE_OPTIONS[0].value,
  role: ALL_ROLE_OPTIONS[0].value,
  season: SEASON_OPTIONS[0].value,
};
