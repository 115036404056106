import { createContext } from "react";
import { renderToStaticMarkup } from "react-dom/server";

export const JSONContext = createContext({});
export const JSONContextProvider = JSONContext.Provider;

// Recursively traverse tree and find all requests
export const getSSRData = async (Component, context) => {
  renderToStaticMarkup(Component);
  if (context.requests) {
    // Store request data for further use
    for (const [url, data] of Object.entries(context.requests)) {
      const res = await data.then((res) => res);
      context.data ? (context.data[url] = res) : (context.data = { [url]: res });
    }
    // Clean up finished requests
    delete context.requests;
    const data = await getSSRData(Component, context).then((data) => data);
    return data;
  } else {
    return new Promise((resolve, reject) => {
      resolve(context.data || {});
    });
  }
};
