function sendSearchBarEvent(searchQuery, searchId) {
  let curSearchId = searchId || "default";
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "search",
      category: "search-bar",
      searchQuery,
      searchBarType: curSearchId,
    });
  }
}

export { sendSearchBarEvent };
