import { useMemo } from "react";
import classNames from "classnames";
import { TooltipContainer } from "@outplayed/tooltips";
import { capitalizeFirstLetter } from "@ugg/shared/utils/string";
import { getTierColor, convertRomanNumeral } from "@ugg/shared/utils/rank-helpers";
import { useProfileHistoricRanks } from "@ugg/shared/api/requests/summoner-profiles/historic-ranks";
import { getSeasonLabel } from "@ugg/shared/utils/season-helpers";

interface HistoricRanksProps {
  className?: string;
  riotUserName: string;
  riotTagLine: string;
  regionId: string;
  queueType: number;
}

export function HistoricRanks(props: HistoricRanksProps) {
  const { className, riotUserName, riotTagLine, regionId, queueType } = props;
  const { data, loading, error } = useProfileHistoricRanks(riotUserName, riotTagLine, regionId, queueType);
  const { getHistoricRanks } = data || {};

  const historicData = useMemo(() => {
    return ((Array.isArray(getHistoricRanks) && getHistoricRanks) || [])
      .filter((historicRank) => !!historicRank.season && !!historicRank.tier)
      .sort((a, b) => b.season - a.season);
  }, [getHistoricRanks]);

  if (historicData.length === 0) {
    return null;
  }

  return (
    <div className={classNames("flex flex-wrap gap-[6px]", className)}>
      {historicData.map((el) => {
        const seasonText = getSeasonLabel(el.season);
        return (
          <TooltipContainer
            key={el.season}
            tooltipInfo={`${capitalizeFirstLetter(el.tier)} ${convertRomanNumeral(el.rank)} ${
              el.lp !== null && !isNaN(el.lp) ? `${el.lp}LP` : ""
            }`}
            disableTooltip={!el.rank}
          >
            <div className="flex items-center justify-center px-[5px] h-[18px] rounded-[3px] bg-purple-100 text-[10px] text-lavender-50 font-medium whitespace-nowrap cursor-default">
              <span>
                <strong>S{seasonText}</strong>{" "}
                <span style={{ color: getTierColor(el.tier) }}>{capitalizeFirstLetter(el.tier)}</span>
              </span>
            </div>
          </TooltipContainer>
        );
      })}
    </div>
  );
}
