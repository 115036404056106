let regex_dict = {
  re_en_US: /(joined the lobby)(.*)|(joined the room.)(.*)/,
  re_pt_BR: /(entrou no saguão)(.*)/,
  re_cs_CZ: /(vstoupil do lobby)(.*)/, // todo
  re_hu_HU: /(csatlakozott az előszobához)(.*)/,
  re_pl_PL: /(dołącza do pokoju)(.*)/,
  re_ro_RO: /(s-a alăturat lobby-ului)(.*)/,
  re_el_GR: /(μπήκε στο λόμπι)(.*)/, // todo
  re_de_DE: /(ist der Lobby beigetreten)(.*)/,
  re_es_ES: /(se ha unido a la sala.)(.*)/,
  re_fr_FR: /(a rejoint le salon)(.*)/,
  re_it_IT: /(si è unito alla lobby)(.*)/,
  re_ja_JP: /(がロビーに参加しました)(.*)/,
  re_es_MX: /(se unió a la sala)(.*)/,
  re_ko_KR: /(님이 로비에 참가하셨습니다.)(.*)/,
  re_ru_RU: /(присоединился к лобби)(.*)/,
  re_tr_TR: /(lobiye katıldı)(.*)/,
  re_zh_CN: /(加入了队伍聊天)(.*)/,
  re_zh_TW: /(進入組隊房間)(.*)/,
  re_vn_VN: /(đã tham gia sảnh chờ.)(.*)/,
  re_th_TH: /(เข้าร่วมล็อบบี้)(.*)/,
};

const allRegex =
  /(joined the lobby)|(joined the room.)|(entrou no saguão)|(vstoupil do lobby)|(csatlakozott az előszobához)|(dołącza do pokoju)|(s-a alăturat lobby-ului)|(μπήκε στο λόμπι)|(ist der Lobby beigetreten)|(se ha unido a la sala.)|(a rejoint le salon)|(si è unito alla lobby)|(がロビーに参加しました)|(se unió a la sala)|(님이 로비에 참가하셨습니다.)(присоединился к лобби)|(lobiye katıldı)|(加入了队伍聊天)|(進入組隊房間)|(đã tham gia sảnh chờ.)|(เข้าร่วมล็อบบี้)/;

let regex_st_dict = {
  re_cs_CZ: /(Hráč)/,
  re_el_GR: /(Ο παίκτης)/,
};

const unique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export function stripMultiSearch(textValue) {
  let regex_master = new RegExp(
    Object.values(regex_dict)
      .map((val) => {
        return val.source;
      })
      .join("|"),
    "g",
  );
  let regex_st = new RegExp(
    Object.values(regex_st_dict)
      .map((val) => {
        return val.source;
      })
      .join("|"),
  );
  const retVal = textValue
    .split(/[,\n\r]/g)
    .map((val) => {
      val = val.replace(regex_master, "");
      val = val.replace(regex_st, "");
      return val.trimStart();
    })
    .filter((text) => text.length > 0);
  return [...new Set(retVal)];
}

// search bar has to do it differently because inputs don't detect new lines /n
export const stripMultiSearchBar = (textValue) => {
  // flag to check if this was a multisearch
  const isMultiSearch = textValue.match(allRegex);

  const split = textValue?.split(allRegex);
  const filtered = split?.filter((val) => val && val !== undefined);
  const names = filtered?.filter((val, i) => i % 2 === 0);
  const finalNames = names?.map((val) => val.trim());
  const uniqueNames = finalNames?.filter((v, i, a) => a.indexOf(v) === i);

  return {
    isMultiSearch: isMultiSearch?.length > 0 && finalNames?.length > 1,
    names: uniqueNames,
  };
};
