import classNames from "classnames";

export function SummonerProfileLoader({ className }: { className?: string }) {
  return (
    <div className={classNames("flex items-center justify-center", className)}>
      <div className="spinthatshit-loader">
        <div className="spinner" />
      </div>
    </div>
  );
}
