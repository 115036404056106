import moment from "moment";

moment.relativeTimeThreshold("m", 59);
moment.defineLocale("match", {
  // Update relative time
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    ss: "%d seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
  // Update Calendar
  calendar: {
    lastDay: "MMM D",
    sameDay: "h:mm A",
    nextDay: "MMM D",
    lastWeek: "MMM D",
    nextWeek: "MMM D",
    sameElse: "MMM D",
  },
});

moment.defineLocale("multi-search-match", {
  // Update relative time
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "1m",
    ss: "1m",
    m: "1min",
    mm: "%dmin",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1mo",
    MM: "%dmo",
    y: "1yr",
    yy: "%dyr",
  },
});
