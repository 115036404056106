import { useLocation } from "react-router-dom";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import { pathToRegexp } from "path-to-regexp";

export enum TIER_LIST_PAGES {
  DEFAULT_TIER_LIST = "tier-list",
  TOP_TIER_LIST = "top-lane-tier-list",
  JUNGLE_TIER_LIST = "jungle-tier-list",
  MID_TIER_LIST = "mid-lane-tier-list",
  ADC_TIER_LIST = "adc-tier-list",
  SUPPORT_TIER_LIST = "support-tier-list",
  DUO_TIER_LIST = "duo-tier-list",
  COMBAT_TIER_LIST = "combat",
  OBJECTIVES_TIER_LIST = "objectives",
}

export enum SPECIAL_TIER_LIST_PAGES {
  // URF_TIER_LIST = "urf-tier-list",
  PICK_URF_TIER_LIST = "urf-tier-list",
  ARURF_TIER_LIST = "arurf-tier-list",
  ARAM_TIER_LIST = "aram-tier-list",
  NEXUS_BLITZ_TIER_LIST = "nexus-blitz-tier-list",
  ONE_FOR_ALL_TIER_LIST = "one-for-all-tier-list",
  ARENA_TIER_LIST = "arena-tier-list",
  ARENA_DUO_TIER_LIST = "arena-duo-tier-list",
}

// ------------------------------------------------------------------------------------------------------------
// TIER LIST PAGES BY URL
// ------------------------------------------------------------------------------------------------------------

export const tierListPageUrls = {
  [TIER_LIST_PAGES.DEFAULT_TIER_LIST]: AppRoutes.DEFAULT_TIER_LIST,
  [TIER_LIST_PAGES.TOP_TIER_LIST]: AppRoutes.TOP_TIER_LIST,
  [TIER_LIST_PAGES.JUNGLE_TIER_LIST]: AppRoutes.JUNGLE_TIER_LIST,
  [TIER_LIST_PAGES.MID_TIER_LIST]: AppRoutes.MID_TIER_LIST,
  [TIER_LIST_PAGES.ADC_TIER_LIST]: AppRoutes.ADC_TIER_LIST,
  [TIER_LIST_PAGES.SUPPORT_TIER_LIST]: AppRoutes.SUPPORT_TIER_LIST,
  [SPECIAL_TIER_LIST_PAGES.PICK_URF_TIER_LIST]: AppRoutes.PICK_URF_TIER_LIST,
  [SPECIAL_TIER_LIST_PAGES.ARURF_TIER_LIST]: AppRoutes.ARURF_TIER_LIST,
  [SPECIAL_TIER_LIST_PAGES.ARAM_TIER_LIST]: AppRoutes.ARAM_TIER_LIST,
  [SPECIAL_TIER_LIST_PAGES.NEXUS_BLITZ_TIER_LIST]: AppRoutes.NEXUS_BLITZ_TIER_LIST,
  [SPECIAL_TIER_LIST_PAGES.ONE_FOR_ALL_TIER_LIST]: AppRoutes.ONE_FOR_ALL_TIER_LIST,
  [SPECIAL_TIER_LIST_PAGES.ARENA_TIER_LIST]: AppRoutes.ARENA_TIER_LIST,
  [SPECIAL_TIER_LIST_PAGES.ARENA_DUO_TIER_LIST]: AppRoutes.ARENA_DUO_TIER_LIST,
  [TIER_LIST_PAGES.DUO_TIER_LIST]: AppRoutes.DUO_TIER_LIST,
  [TIER_LIST_PAGES.COMBAT_TIER_LIST]: AppRoutes.COMBAT_TIER_LIST,
  [TIER_LIST_PAGES.OBJECTIVES_TIER_LIST]: AppRoutes.OBJECTIVES_TIER_LIST,
} as const;

export function useTierListPage() {
  const location = useLocation();
  const { pathname } = location;

  const foundPage = Object.entries(tierListPageUrls).find(([page, path]) => {
    if (pathToRegexp(path).exec(pathname)) {
      return page as keyof typeof tierListPageUrls;
    }
  });

  return (foundPage && foundPage[0]) as keyof typeof tierListPageUrls;
}
