import * as React from "react";
import type { SVGProps } from "react";
export const SvgGameLol = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 32 32" {...props}>
    <path fill="url(#game-lol_svg__a)" d="M15.176 3.333H7.155l2.11 2.955v19.419l-2.532 2.955h18.152l2.955-4.221H15.176z" />
    <path
      fill="url(#game-lol_svg__b)"
      d="M7.577 8.57A11.78 11.78 0 0 0 4.2 16.842c0 3.213 1.282 6.127 3.362 8.257l.015-.017v-2.638a10.1 10.1 0 0 1-1.689-5.602c0-2.072.622-3.998 1.69-5.602z"
    />
    <path
      fill="url(#game-lol_svg__c)"
      d="M24.25 22.752a10.1 10.1 0 0 0 1.901-5.91c0-5.311-4.087-9.668-9.287-10.097V5.052c6.134.433 10.976 5.546 10.976 11.79 0 2.153-.576 4.171-1.582 5.91z"
    />
    <path fill="url(#game-lol_svg__d)" d="M22.049 22.752A8.444 8.444 0 0 0 16.864 8.44v14.31z" />
    <defs>
      <linearGradient id="game-lol_svg__a" x1={1.363} x2={24.671} y1={28.662} y2={0.693} gradientUnits="userSpaceOnUse">
        <stop stopColor="#AF893D" />
        <stop offset={0.234} stopColor="#D29D40" />
        <stop offset={0.531} stopColor="#FFCC74" />
        <stop offset={0.823} stopColor="#E8B55D" />
      </linearGradient>
      <linearGradient id="game-lol_svg__b" x1={1.363} x2={24.671} y1={28.662} y2={0.693} gradientUnits="userSpaceOnUse">
        <stop stopColor="#AF893D" />
        <stop offset={0.234} stopColor="#D29D40" />
        <stop offset={0.531} stopColor="#FFCC74" />
        <stop offset={0.823} stopColor="#E8B55D" />
      </linearGradient>
      <linearGradient id="game-lol_svg__c" x1={1.363} x2={24.671} y1={28.662} y2={0.693} gradientUnits="userSpaceOnUse">
        <stop stopColor="#AF893D" />
        <stop offset={0.234} stopColor="#D29D40" />
        <stop offset={0.531} stopColor="#FFCC74" />
        <stop offset={0.823} stopColor="#E8B55D" />
      </linearGradient>
      <radialGradient
        id="game-lol_svg__d"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(-47.911 31.503 -1.902)scale(17.6343)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.119} stopColor="#117D8D" />
        <stop offset={0.642} stopColor="#2FEBEB" />
        <stop offset={0.866} stopColor="#117D8D" />
      </radialGradient>
    </defs>
  </svg>
);
