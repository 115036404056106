import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import MediaQuery from "components/MediaQuery";
import DisabledFeature from "components/DisabledFeature";

const DisabledRoute = (props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    setInit(true);
  }, []);

  if (!init) {
    return null;
  }

  return (
    <React.Fragment>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE" renderNullOnFail>
        <DisabledFeature />
      </MediaQuery>
      <MediaQuery min="TABLET" max="DESKTOP_LARGE" renderNullOnFail>
        <Route {...props} />
      </MediaQuery>
    </React.Fragment>
  );
};

export default DisabledRoute;
