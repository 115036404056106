import { window } from "global";
import React from "react";
export const DDRAGON = "https://ddragon.leagueoflegends.com";

export function alphanumeric(string) {
  return string.replace(/[^A-Za-z0-9]/g, "");
}

export function getImgFromSpritesheet(spritesheetUrl, x, y, newSize, altSrc) {
  // spritesheetUrl: url of spritesheet in question
  // x: sprite position in riot's spritesheet
  // y: sprite position in riots spritesheet
  // newSize: integer that is less than or equal to 48, since most of riots pixels is greater than 48

  if (altSrc) {
    return (
      <div style={{ height: `${newSize}px`, width: `${newSize}px` }}>
        <img width={newSize} src={altSrc} />
      </div>
    );
  }

  const backgroundPosition = `-${x}px -${y}px`;
  const scaleAmt = newSize / 48 || 1;

  return (
    <div style={{ height: `${newSize}px`, width: `${newSize}px` }}>
      <div
        style={{
          height: "48px", // original size of the sprite image
          width: "48px",
          backgroundImage: `url(${spritesheetUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: backgroundPosition,
          // backgroundSize
          transform: `scale(${scaleAmt})`,
          transformOrigin: "0 0",
        }}
      />
    </div>
  );
}

const localJSONCache = {};
export function getJSON(url) {
  if (window && window.XMLHttpRequest) {
    if (localJSONCache[url]) {
      return localJSONCache[url];
    }

    const request = new XMLHttpRequest();
    request.open("GET", url, false);
    request.send(null);

    if (request.status === 200) {
      const json = JSON.parse(request.response);
      localJSONCache[url] = json;
      return json;
    } else {
      return;
    }
  }
}
