/**
 * (c) 2010-2017 Torstein Honsi
 *
 * License: www.highcharts.com/license
 *
 * Dark blue theme for Highcharts JS
 * @author Torstein Honsi
 */
"use strict";

export default {
  colors: [
    "#08a6ff",
    "#d35847",
    "#f7a35c",
    "#7cb5ec",
    "#90ee7e",
    "#7798BF",
    "#aaeeee",
    "#ff0066",
    "#eeaaee",
    "#55BF3B",
    "#DF5353",
    "#7798BF",
    "#aaeeee",
  ],
  chart: {
    backgroundColor: "transparent",
    // backgroundColor: 'rgba(41, 55, 69, 1)',
    className: "dark-container",
    // plotBackgroundColor: 'rgba(255, 255, 255, .1)',
  },
  title: {
    style: {
      fontSize: "10px",
      color: "#C0C0C0",
    },
  },
  subtitle: {
    style: {
      color: "rgba(160, 160, 160, 1)",
      fontSize: "8px",
    },
  },
  xAxis: {
    gridLineColor: "rgba(255, 255, 255, 0.1)",
    gridLineWidth: 1,
    labels: {
      style: {
        color: "#A0A0A0",
      },
    },
    lineColor: "#A0A0A0",
    tickColor: "#A0A0A0",
    title: {
      style: {
        color: "#A0A0A0",
        fontSize: "8px",
        fontWeight: "normal",
      },
    },
  },
  yAxis: {
    gridLineColor: "rgba(255, 255, 255, 0.1)",
    labels: {
      style: {
        color: "#A0A0A0",
        fontSize: "8px",
        fontWeight: "normal",
      },
    },
    lineColor: "#A0A0A0",
    minorTickInterval: null,
    tickColor: "#A0A0A0",
    tickWidth: 1,
    title: {
      style: {
        color: "#A0A0A0",
        fontSize: "8px",
        fontWeight: "normal",
        // fontFamily: 'Trebuchet MS, Verdana, sans-serif'
      },
    },
  },

  toolbar: {
    itemStyle: {
      color: "silver",
    },
  },
  plotOptions: {
    series: {
      animation: false,
    },
    line: {
      dataLabels: {
        color: "#CCC",
      },
      marker: {
        lineColor: "#333",
      },
    },
    bar: {
      borderWidth: 0,
    },
    column: {
      maxPointWidth: 20,
    },
    spline: {
      marker: {
        lineColor: "#333",
      },
    },
    scatter: {
      marker: {
        lineColor: "#333",
      },
    },
    candlestick: {
      lineColor: "white",
    },
  },
  legend: {
    itemStyle: {
      // font: '9pt Trebuchet MS, Verdana, sans-serif',
      color: "#A0A0A0",
      fontSize: "8px",
      fontWeight: "normal",
    },
    itemHoverStyle: {
      color: "#FFF",
    },
    itemHiddenStyle: {
      color: "#444",
    },
  },
  credits: false,
  labels: {
    style: {
      color: "#A0A0A0",
      fontSize: "8px",
      fontWeight: "normal",
    },
  },

  navigation: {
    buttonOptions: {
      symbolStroke: "#DDDDDD",
      hoverSymbolStroke: "#FFFFFF",
      theme: {
        fill: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0.4, "#606060"],
            [0.6, "#333333"],
          ],
        },
        stroke: "#000000",
      },
    },
  },

  // scroll charts
  rangeSelector: {
    buttonTheme: {
      fill: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0.4, "#888"],
          [0.6, "#555"],
        ],
      },
      stroke: "#000000",
      style: {
        color: "#CCC",
        fontWeight: "bold",
      },
      states: {
        hover: {
          fill: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0.4, "#BBB"],
              [0.6, "#888"],
            ],
          },
          stroke: "#000000",
          style: {
            color: "white",
          },
        },
        select: {
          fill: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0.1, "#000"],
              [0.3, "#333"],
            ],
          },
          stroke: "#000000",
          style: {
            color: "yellow",
          },
        },
      },
    },
    inputStyle: {
      backgroundColor: "#333",
      color: "silver",
    },
    labelStyle: {
      color: "silver",
    },
  },

  navigator: {
    handles: {
      backgroundColor: "#666",
      borderColor: "#AAA",
    },
    outlineColor: "#CCC",
    maskFill: "rgba(16, 16, 16, 0.5)",
    series: {
      color: "#7798BF",
      lineColor: "#A6C7ED",
    },
  },

  scrollbar: {
    barBackgroundColor: {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: [
        [0.4, "#888"],
        [0.6, "#555"],
      ],
    },
    // barBorderColor: '#CCC',
    buttonArrowColor: "#CCC",
    buttonBackgroundColor: {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: [
        [0.4, "#888"],
        [0.6, "#555"],
      ],
    },
    buttonBorderColor: "#CCC",
    rifleColor: "#FFF",
    trackBackgroundColor: {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: [
        [0, "#000"],
        [1, "#333"],
      ],
    },
    trackBorderColor: "#666",
  },

  // special colors for some of the
  legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
  background2: "rgb(35, 35, 70)",
  dataLabelsColor: "#444",
  textColor: "#C0C0C0",
  maskColor: "rgba(255,255,255,0.3)",
};

// Apply the theme
// Highcharts.setOptions(Highcharts.theme);
// })
