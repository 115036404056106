import { useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { downloadFile } from "lib/file-download";
import { ReactComponent as WindowsIcon } from "svg/windows.svg";
import { ReactComponent as AppleIcon } from "svg/apple.svg";
import { ReactComponent as CloudDownloadIcon } from "svg/download-cloud-fill.svg";

const appDownloadIcons = {
  windows: <WindowsIcon className="download-btn_icon windows-icon" />,
  apple: <AppleIcon className="download-btn_icon apple-icon" />,
  cloud: <CloudDownloadIcon className="download-btn_icon windows-icon" />,
};

interface AppDownloadButtonProps {
  className?: string;
  url: string;
  dim?: boolean;
  label: string;
  icon: keyof typeof appDownloadIcons;
  download?: boolean;
  target?: string;
  href?: boolean;
}

export default function AppDownloadButton(props: AppDownloadButtonProps) {
  const { className, url, label, icon, dim, download, target, href } = props;

  const icons = {
    windows: <WindowsIcon className="download-btn_icon windows-icon" />,
    apple: <AppleIcon className="download-btn_icon apple-icon" />,
    cloud: <CloudDownloadIcon className="download-btn_icon windows-icon" />,
  };

  const commonProps = {
    className: classnames(
      "btn-red btn-red_hover download-btn",
      {
        "btn-red_dim": dim,
      },
      className,
    ),
    target,
    download,
  };

  const content = (
    <>
      {icons[icon]}
      <span>{label}</span>
    </>
  );

  if (href) {
    return (
      <a {...commonProps} href={url}>
        {content}
      </a>
    );
  }

  return (
    <Link {...commonProps} to={url}>
      {content}
    </Link>
  );
}

interface NewAppDownloadButtonProps {
  className?: string;
  url: string;
  filename: string;
  dim?: boolean;
  label: string;
  icon: keyof typeof appDownloadIcons;
}
export function NewAppDownloadButton(props: NewAppDownloadButtonProps) {
  const { className, url, filename, label, icon, dim } = props;
  const [fetching, setFetching] = useState(false);
  const [progress, setProgress] = useState(0);
  const classNames = classnames(
    "btn-red download-btn",
    {
      "btn-red_hover": !dim && !fetching,
      "btn-red_dim": dim || fetching,
    },
    className,
  );

  const onClick = () => {
    if (fetching) return;

    setFetching(true);
    downloadFile(url, filename, {
      onProgress: (currentReceived, totalSize) => {
        setProgress(currentReceived / totalSize);
      },
      onComplete: () => setFetching(false),
      onError: (err) => {
        setFetching(false);
        setProgress(0);
        console.error(err);
      },
    });
  };

  return (
    <button className={classNames} onClick={onClick}>
      {appDownloadIcons[icon]}
      <span>{fetching ? `Downloading (${Math.floor(progress * 100)}%)` : label}</span>
    </button>
  );
}
