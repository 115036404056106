// RESPONSIVE
export const DEFAULT_BREAKPOINT = "DESKTOP_LARGE";
export const WINDOW_BREAKPOINTS_RANGE = {
  MOBILE_SMALL: [0, 399], // xxxs
  MOBILE_MEDIUM: [400, 599], // xxs
  MOBILE_LARGE: [600, 899], //xs
  TABLET: [900, 1139], // sm
  DESKTOP_SMALL: [1140, 1359], // md
  DESKTOP_MEDIUM: [1360, 1469], // lg
  DESKTOP_LARGE: [1470, 9999999999], // xl
};

// "xxxs": "360px",
// "xxs": "400px",
// "xs": "600px",
// "sm": "900px",
// "md": "1140px",
// "lg": "1360px",
// "xl": "1920px"

export const WINDOW_BREAKPOINTS = {
  MOBILE: ["MOBILE_SMALL", "MOBILE_MEDIUM", "MOBILE_LARGE"],
  TABLET: "TABLET",
  DESKTOP: ["DESKTOP_SMALL", "DESKTOP_MEDIUM", "DESKTOP_LARGE"],
};
