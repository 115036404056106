import { RoleS, getRoleSVG } from "@ugg/shared/utils/role-helpers";
import { QueueTypeS, QueueTypeN } from "@ugg/shared/utils/queue-type-helpers";

// ------------------------------------
// COMMON OPTIONS
// ------------------------------------

export const ROLE_OPTIONS = [
  { value: RoleS.TOP, label: "Top", img: getRoleSVG(RoleS.TOP), imgActive: getRoleSVG(RoleS.TOP, { active: true }) },
  { value: RoleS.JUNGLE, label: "Jungle", img: getRoleSVG(RoleS.JUNGLE), imgActive: getRoleSVG(RoleS.JUNGLE, { active: true }) },
  { value: RoleS.MIDDLE, label: "Middle", img: getRoleSVG(RoleS.MIDDLE), imgActive: getRoleSVG(RoleS.MIDDLE, { active: true }) },
  { value: RoleS.BOTTOM, label: "Bot", img: getRoleSVG(RoleS.BOTTOM), imgActive: getRoleSVG(RoleS.BOTTOM, { active: true }) },
  {
    value: RoleS.SUPPORT,
    label: "Support",
    img: getRoleSVG(RoleS.SUPPORT),
    imgActive: getRoleSVG(RoleS.SUPPORT, { active: true }),
  },
];

export const ALL_ROLE_OPTIONS = [{ value: RoleS.ALL, label: "All Roles", img: getRoleSVG(RoleS.ALL) }, ...ROLE_OPTIONS];

export const QUEUE_TYPE_ID_OPTIONS = [
  { label: "All Ranked", value: "all_ranked" },
  { label: "Ranked Solo", value: QueueTypeS.RANKED_SOLO },
  { label: "Ranked Flex", value: QueueTypeS.RANKED_FLEX },
  { label: "ARAM", value: QueueTypeS.ARAM },
  { label: "Normal Blind", value: QueueTypeS.NORMAL_BLIND },
  { label: "Normal Draft", value: QueueTypeS.NORMAL_DRAFT },
  { label: "Quickplay", value: QueueTypeS.QUICKPLAY },
];

export const QUEUE_TYPE_RANKED = ["all_ranked", QueueTypeS.RANKED_SOLO, QueueTypeS.RANKED_FLEX];

export const QUEUE_TYPE_ID_MAP = {
  all_ranked: [QueueTypeN.RANKED_SOLO, QueueTypeN.RANKED_FLEX],
  [QueueTypeS.RANKED_SOLO]: [QueueTypeN.RANKED_SOLO],
  [QueueTypeS.RANKED_FLEX]: [QueueTypeN.RANKED_FLEX],
  [QueueTypeS.ARAM]: [QueueTypeN.ARAM],
  [QueueTypeS.NORMAL_BLIND]: [QueueTypeN.NORMAL_BLIND],
  [QueueTypeS.NORMAL_DRAFT]: [QueueTypeN.NORMAL_DRAFT],
  [QueueTypeS.CLASH]: [QueueTypeN.CLASH],
  [QueueTypeS.QUICKPLAY]: [QueueTypeN.QUICKPLAY],
};

export const QUEUE_TYPE_OPTIONS = [
  { value: QueueTypeS.RANKED_SOLO, label: "Ranked Solo" },
  { value: QueueTypeS.ARAM, label: "ARAM" },
  { value: QueueTypeS.RANKED_FLEX, label: "Ranked Flex" },
  { value: QueueTypeS.NORMAL_BLIND, label: "Normal Blind" },
  { value: QueueTypeS.NORMAL_DRAFT, label: "Normal Draft" },
  { value: QueueTypeS.CLASH, label: "Clash" },
  { value: QueueTypeS.NEXUS_BLITZ, label: "Nexus Blitz" },
];

export const ALL_QUEUE_TYPE_OPTIONS = [{ value: "all", label: "All Queue Types" }, ...QUEUE_TYPE_OPTIONS];

export const SEASON_OPTIONS = [
  { value: "24", label: "Season 14-3" },
  { value: "23", label: "Season 14-2" },
  { value: "22", label: "Season 14-1" },
  { value: "21", label: "Season 13-2" },
  { value: "20", label: "Season 13-1" },
  { value: "19", label: "Preseason 13" },
  { value: "18", label: "Season 12" },
  { value: "17", label: "Preseason 12" },
  { value: "16", label: "Season 11" },
  { value: "15", label: "Preseason 11" },
];
