export function stdevAndMean(values: number[]) {
  const mean = values.reduce((acc, curr) => (acc += curr), 0) / values.length;
  const stdev = Math.sqrt(values.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / values.length);

  return { mean, stdev };
}

export function round(value: number, precision = 0) {
  if (precision < 0) {
    throw new Error("Precision value must be > 0.");
  } else if (precision === 0) {
    return Math.round(value);
  } else {
    return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
  }
}

export function numberWithCommas(num: number) {
  if (num === 0) return 0;
  if (!num) return "";

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getPercetange(num1: any, num2: any, decimalPlace = 0) {
  if (isNaN(num1) || isNaN(num2) || num2 === 0) {
    return null;
  }
  const percentage = (num1 / num2) * 100;

  if (decimalPlace > 0) {
    return Math.round(percentage * (10 * decimalPlace)) / (10 * decimalPlace);
  } else {
    return Math.round(percentage);
  }
}

export function thousandCondense(num: number) {
  if (num > 1000) {
    return `${(num / 1000).toFixed(1)}k`;
  }

  return num;
}
