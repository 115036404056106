import { window } from "global";
import { createProvider, setGlobal, addReducer } from "reactn";
import { getUserLanguage, DEFAULT_LANG } from "lib/locale-helpers";
import { addCookie, getCookie, getWindowBreakpoint, shouldOpenSideNav } from "lib/general-helper";
// Reducers
const initReducers = (Provider) => {
  const actions = [UPDATE_STATIC_JSON];

  actions.forEach((action) => {
    if (Provider) {
      Provider.addReducer(action.type, action.actionFn);
    } else {
      addReducer(action.type, action.actionFn);
    }
  });
};

const UPDATE_STATIC_JSON = {
  type: "UPDATE_STATIC_JSON",
  actionFn: (global, dispatch, url, value) => ({
    [url]: value,
  }),
};

// Used on both server and client
// Return Provider component to wrap app
export const reactnInit = (props = {}) => {
  const { req, initState = {} } = props;
  const Provider = createProvider({
    adblock: false,
    isDetectingAdblock: false,
    manualSideNav: false,
    expandedSideNav: false,
    clientChampionFavorites: undefined,
    clientSummonerFavorites: undefined,
    language: getUserLanguage({ req }) || DEFAULT_LANG,
    settingsNotification: false,
    verificationBar: false,
    allowCssTransition: false,
    ...initState,
  });

  initReducers(Provider);

  return Provider;
};

// Initialized only on client side
export const setClientReactNState = (Provider) => {
  // Remove all existing local/session storage authToken and set to cookies
  const prevAuthToken = window && (localStorage.getItem("authToken") || sessionStorage.getItem("authToken"));
  if (window && prevAuthToken) {
    if (!getCookie("authToken")) {
      addCookie("authToken", prevAuthToken);
    }
    localStorage.removeItem("authToken");
    sessionStorage.removeItem("authToken");
  }

  const authToken = window && getCookie("authToken");
  const windowBreakpoint = getWindowBreakpoint(window && window.innerWidth);
  const curLang = Provider.getGlobal().language;

  Provider.setGlobal({
    isDetectingAdblock: true,
    manualSideNav: windowBreakpoint !== "DESKTOP_LARGE" ? false : shouldOpenSideNav(),
    expandedSideNav: false,
    authToken: authToken,
    loggingIn: false,
    clientRegion: localStorage.getItem("summonerRegion") || "na1",
    language: getUserLanguage({ language: curLang }) || DEFAULT_LANG, // Global var language is used to handle
    responsive: windowBreakpoint,
  });
};

const globalReactN = reactnInit();
export default globalReactN;
