import * as React from "react";
import type { SVGProps } from "react";
export const SvgLolUggLogoBlue = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 143 46" {...props}>
    <path fill="#3273fa" d="M45.924 44.96a3.476 3.476 0 1 1 2.464 1.043 3.35 3.35 0 0 1-2.464-1.043" />
    <path
      fill="#fff"
      d="M97.347 16.759v-.192q0-11.757-12.03-11.757H74.61q-12.03 0-12.03 11.757v17.664q0 11.765 12.03 11.765h10.683q12.03 0 12.03-11.765v-9.655H81.26l-2.791 6.114h12.03v3.54q0 5.724-5.165 5.716H74.61q-5.172 0-5.173-5.715V16.575q0-5.706 5.173-5.707h10.683q5.165 0 5.165 5.707v3.197zM142.277 16.759v-.192q0-11.757-12.027-11.757h-10.713q-12.027 0-12.027 11.757v17.664q0 11.765 12.027 11.765h10.68q12.029 0 12.028-11.765v-9.655h-16.052l-2.79 6.114h12.027v3.54q0 5.724-5.172 5.716h-10.72q-5.164 0-5.164-5.715V16.575q0-5.706 5.163-5.707h10.68q5.173 0 5.173 5.707v3.197zM27.181 4.28v31.971a20.37 20.37 0 0 1-10.04 2.716A20.33 20.33 0 0 1 7.066 36.25V4.28L0 7.376v32.718l1.599 1.072A28.1 28.1 0 0 0 17.124 46a28.1 28.1 0 0 0 15.51-4.835l1.599-1.04V7.376z"
    />
    <path fill="#3273fa" d="M16.827 0 13.37 1.6v23.036c1.129.332 2.298.502 3.473.504a12.4 12.4 0 0 0 3.48-.504V1.6z" />
  </svg>
);
