import { useLocation } from "react-router-dom";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import { pathToRegexp } from "path-to-regexp";

export enum CHAMPION_PAGES {
  OVERVIEW = "build",
  ITEM_PATHS = "items-paths",
  ITEMS = "items",
  SPELLS_ABILITIES = "spells-abilities",
  RUNE_SETS = "rune-sets",
  RUNES_TABLE = "runes-table",
  MATCHUPS = "matchups",
  COUNTERS = "counters",
  DUOS = "duos",
}

export enum SPECIAL_CHAMPION_PAGES {
  OVERVIEW_ARAM = "overview_aram",
  OVERVIEW_ARURF = "overview_arurf",
  OVERVIEW_URF = "overview_urf",
  OVERVIEW_PICK_URF = "overview_urf",
  OVERVIEW_ONE_FOR_ALL = "overview_one_for_all",
  OVERVIEW_NEXUS_BLITZ = "overview_nexus_blitz",
  OVERVIEW_ARENA = "overview_arena",
}

// ------------------------------------------------------------------------------------------------------------
// CHAMPION PAGES BY URL
// ------------------------------------------------------------------------------------------------------------

const urlPages: Record<CHAMPION_PAGES | SPECIAL_CHAMPION_PAGES, string | string[]> = {
  [CHAMPION_PAGES.OVERVIEW]: AppRoutes.CHAMPION_PROFILE_BUILD,
  [SPECIAL_CHAMPION_PAGES.OVERVIEW_ARAM]: AppRoutes.CHAMPION_PROFILE_BUILD_ARAM,
  [SPECIAL_CHAMPION_PAGES.OVERVIEW_ARURF]: AppRoutes.CHAMPION_PROFILE_BUILD_ARURF,
  // [SPECIAL_CHAMPION_PAGES.OVERVIEW_URF]: AppRoutes.CHAMPION_PROFILE_BUILD_URF,
  [SPECIAL_CHAMPION_PAGES.OVERVIEW_PICK_URF]: AppRoutes.CHAMPION_PROFILE_BUILD_PICK_URF,
  [SPECIAL_CHAMPION_PAGES.OVERVIEW_ONE_FOR_ALL]: AppRoutes.CHAMPION_PROFILE_BUILD_ONE_FOR_ALL,
  [SPECIAL_CHAMPION_PAGES.OVERVIEW_NEXUS_BLITZ]: AppRoutes.CHAMPION_PROFILE_BUILD_NEXUS_BLITZ,
  [SPECIAL_CHAMPION_PAGES.OVERVIEW_ARENA]: AppRoutes.CHAMPION_PROFILE_BUILD_ARENA,
  [CHAMPION_PAGES.ITEM_PATHS]: AppRoutes.CHAMPION_PROFILE_ITEM_PATHS,
  [CHAMPION_PAGES.ITEMS]: AppRoutes.CHAMPION_PROFILE_ITEMS,
  [CHAMPION_PAGES.SPELLS_ABILITIES]: AppRoutes.CHAMPION_PROFILE_SPELLS_ABILITIES,
  [CHAMPION_PAGES.RUNE_SETS]: AppRoutes.CHAMPION_PROFILE_RUNE_SETS,
  [CHAMPION_PAGES.RUNES_TABLE]: AppRoutes.CHAMPION_PROFILE_RUNES_TABLE,
  [CHAMPION_PAGES.MATCHUPS]: AppRoutes.CHAMPION_PROFILE_MATCHUPS,
  [CHAMPION_PAGES.COUNTERS]: AppRoutes.CHAMPION_PROFILE_COUNTERS,
  [CHAMPION_PAGES.DUOS]: AppRoutes.CHAMPION_PROFILE_DUOS,
};

export function useChampionPage() {
  const location = useLocation();
  const { pathname } = location;

  const foundPage = Object.entries(urlPages).find(([page, pagePath]) => {
    const execPaths = Array.isArray(pagePath) ? pagePath : [pagePath];
    for (const path of execPaths) {
      if (pathToRegexp(path).exec(pathname)) {
        return page as keyof typeof urlPages;
      }
    }
  });

  return (foundPage && foundPage[0]) as keyof typeof urlPages | undefined;
}
