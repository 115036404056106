import React, { useContext } from "react";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { RAC_useChampionMini } from "./context-functions/champions";
import { getRACFunctions } from "./context-functions/getRACFunctions";

const DATA_SRC = "https://static.bigbrain.gg/assets/lol/riot_patch_update";
const ENV_DIR = (staging) => (staging ? "staging" : "prod");

export const RiotAssetsContext = React.createContext({});

export function getRiotAssetsContext() {
  return useContext(RiotAssetsContext);
}

export const RiotAssetsProvider = (props) => {
  const { fallback, staging, ssr = false } = props;
  const path = `${DATA_SRC}/${ENV_DIR(staging)}`;

  const versions = useJSONFetcher(`${path}/versions.json`, { ssr });
  const backupChampions = useJSONFetcher(`${path}/backup-champions.json`, { ssr });
  // const championNicknames = useJSONFetcher(`${path}/champion-nicknames.json`, {ssr});
  const legacyItems = useJSONFetcher(`${path}/legacy-items.json`, { ssr });
  const legacyRunes = useJSONFetcher(`${path}/legacy-runes.json`, { ssr });
  const ornnItems = useJSONFetcher(`${path}/ornn-items.json`, { ssr });
  // const statShards = useJSONFetcher(`${path}/stat-shards.json`, { ssr });

  const latestPatch = versions.data && versions.data[0];
  const championMini = RAC_useChampionMini(
    backupChampions.data,
    versions.data,
  )({
    ssr,
    skip: backupChampions.loading || backupChampions.error || versions.loading || versions.error,
  });

  const requiredData = Object.entries({
    championMini,
    backupChampions,
    legacyItems,
    legacyRunes,
    ornnItems,
    // statShards,
    versions,
  });

  const isAllDataFetched = requiredData.every(([key, value]) => value.data && !value.loading && !value.error);

  // console.log("isAllDataFetched", isAllDataFetched)
  if (!isAllDataFetched) {
    return fallback || null;
  }

  const mappedData = { staging };
  requiredData.forEach(([key, value]) => (mappedData[key] = value.data));
  const RAC_FUNCTIONS = getRACFunctions(mappedData);
  // console.log("all fetched")
  return <RiotAssetsContext.Provider value={RAC_FUNCTIONS}>{props.children}</RiotAssetsContext.Provider>;
};
