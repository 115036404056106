export function getMSWinRateColor(winRate) {
  if (winRate >= 70) {
    return "#ff9b00";
  } else if (winRate >= 60) {
    return "#3273fa";
  } else if (winRate >= 55) {
    return "#7ea4f4";
  } else if (winRate >= 45) {
    return "#ffffff";
  } else if (winRate >= 40) {
    return "#fcb1b2";
  } else {
    return "#ff4e50";
  }
}
