import { QueueTypeS } from "./queue-type-helpers";
import { capitalizeFirstLetter } from "./string";

export enum RankS {
  CHALLENGER = "challenger",
  MASTER = "master",
  DIAMOND = "diamond",
  PLATINUM = "platinum",
  GOLD = "gold",
  SILVER = "silver",
  BRONZE = "bronze",
  OVERALL = "overall",
  UNKNOWN = "unknown",
  PLATINUM_PLUS = "platinum_plus",
  DIAMOND_PLUS = "diamond_plus",
  IRON = "iron",
  GRANDMASTER = "grandmaster",
  MASTER_PLUS = "master_plus",
  DIAMOND_2_PLUS = "diamond_2_plus",
  EMERALD = "emerald",
  EMERALD_PLUS = "emerald_plus",
  UNRANKED = "unranked",
}
export enum RankN {
  CHALLENGER = 1,
  MASTER = 2,
  DIAMOND = 3,
  PLATINUM = 4,
  GOLD = 5,
  SILVER = 6,
  BRONZE = 7,
  OVERALL = 8,
  UNKNOWN = 9,
  PLATINUM_PLUS = 10,
  DIAMOND_PLUS = 11,
  IRON = 12,
  GRANDMASTER = 13,
  MASTER_PLUS = 14,
  DIAMOND_2_PLUS = 15,
  EMERALD = 16,
  EMERALD_PLUS = 17,
  UNRANKED = 0,
}

export class RankC {
  private readonly stringId: string;
  private readonly numId: number;
  private static RankS_Obj = Object.fromEntries(Object.entries(RankS));
  private static RankN_Obj: Record<string, RankN> = Object.fromEntries(
    Object.entries(RankN)
      .map(([key, value]) => [key, Number(value)])
      .filter((rank) => typeof rank[1] === "number" && !isNaN(rank[1])),
  );

  constructor(stringValue: string, numValue: number) {
    this.stringId = stringValue;
    this.numId = numValue;
  }

  public toString() {
    return this.stringId;
  }
  public toInt() {
    return this.numId;
  }
  public static convertToString(numValue: number) {
    for (const [key, value] of Object.entries(this.RankN_Obj)) {
      if (value === numValue) {
        return this.RankS_Obj[key];
      }
    }
    return "";
  }
  public static convertToInt(stringValue: string) {
    for (const [key, value] of Object.entries(this.RankS_Obj)) {
      if (value === stringValue) {
        return this.RankN_Obj[key];
      }
    }
    return 0;
  }
}

export const availableTiers = [
  RankS.CHALLENGER,
  RankS.GRANDMASTER,
  RankS.MASTER,
  RankS.DIAMOND,
  RankS.EMERALD,
  RankS.PLATINUM,
  RankS.GOLD,
  RankS.SILVER,
  RankS.BRONZE,
  RankS.IRON,
];

export function getTierColor(tier: string) {
  switch (tier && tier.toLowerCase()) {
    case RankS.IRON:
      return "#51484A";
    case RankS.BRONZE:
      return "#8C523A";
    case RankS.SILVER:
      return "#80989D";
    case RankS.GOLD:
      return "#CD8837";
    case RankS.PLATINUM:
      return "#25ACD6";
    case RankS.EMERALD:
      return "#149C3A";
    case RankS.DIAMOND:
      return "#8141EB";
    case RankS.MASTER:
      return "#A4584E";
    case RankS.GRANDMASTER:
      return "#CD4545";
    case RankS.CHALLENGER:
      return "#F4C874";
    default:
      return "";
  }
}

export function tierLetter(tier: string) {
  switch (tier && tier.toLowerCase()) {
    case RankS.IRON:
      return "I";
    case RankS.BRONZE:
      return "B";
    case RankS.SILVER:
      return "S";
    case RankS.GOLD:
      return "G";
    case RankS.PLATINUM:
      return "P";
    case RankS.EMERALD:
      return "E";
    case RankS.DIAMOND:
      return "D";
    case RankS.MASTER:
      return "M";
    case RankS.GRANDMASTER:
      return "GM";
    case RankS.CHALLENGER:
      return "C";
    default:
      return "U";
  }
}

export function letterToTier(tierLetter: string) {
  switch (tierLetter) {
    case "I":
      return RankS.IRON;
    case "B":
      return RankS.BRONZE;
    case "S":
      return RankS.SILVER;
    case "G":
      return RankS.GOLD;
    case "P":
      return RankS.PLATINUM;
    case "E":
      return RankS.EMERALD;
    case "D":
      return RankS.DIAMOND;
    case "M":
      return RankS.MASTER;
    case "GM":
      return RankS.GRANDMASTER;
    case "C":
      return RankS.CHALLENGER;
    default:
      return RankS.UNRANKED;
  }
}

export function getNextTierLetter(tierLetter: string) {
  switch (tierLetter) {
    case "I":
      return "B";
    case "B":
      return "S";
    case "S":
      return "G";
    case "G":
      return "P";
    case "P":
      return "E";
    case "E":
      return "D";
    case "D":
      return "M";
    case "M":
      return "GM";
    case "GM":
      return "C";
    case "C":
      return "C";
    default:
      return "unranked";
  }
}

export function convertRomanNumeral(roman: string) {
  switch ((roman || "").toUpperCase()) {
    case "I":
      return 1;
    case "II":
      return 2;
    case "III":
      return 3;
    case "IV":
      return 4;
    case "V":
      return 5;
    default:
      return 0;
  }
}

export function convertTierToValue(tier: string) {
  switch (tier && tier.toLowerCase()) {
    case RankS.CHALLENGER:
      return 10;
    case RankS.GRANDMASTER:
      return 9;
    case RankS.MASTER:
      return 8;
    case RankS.DIAMOND:
      return 7;
    case RankS.EMERALD:
      return 6;
    case RankS.PLATINUM:
      return 5;
    case RankS.GOLD:
      return 4;
    case RankS.SILVER:
      return 3;
    case RankS.BRONZE:
      return 2;
    case RankS.IRON:
      return 1;
    default:
      return 0;
  }
}

export function getRankNameNoLp(tier: string, rank: string, shortened = false) {
  const lowerCaseTier = (tier || "").toLowerCase();
  const newTier = capitalizeFirstLetter(lowerCaseTier || "");

  if (
    lowerCaseTier === RankS.CHALLENGER ||
    lowerCaseTier === RankS.GRANDMASTER ||
    lowerCaseTier === RankS.MASTER ||
    lowerCaseTier === RankS.UNRANKED
  ) {
    return shortened ? tierLetter(newTier) : newTier;
  } else {
    return shortened ? `${tierLetter(newTier)}${convertRomanNumeral(rank)}` : `${newTier} ${convertRomanNumeral(rank)}`;
  }
}

export function getProBuildRankName(tier: string, division: string, shortened = false) {
  if (tier === RankS.UNRANKED) {
    return shortened ? "-" : getRankNameNoLp(RankS.UNRANKED, "");
  }
  return getRankNameNoLp(tier || "", division || "", shortened);
}

// returns higher rank
export type RankType = {
  tier: string;
  rank: string;
  wins: number;
  losses: number;
  role: string;
  seasonId: number;
  queueType: string;
};
export function compareRanks(rank1: RankType, rank2: RankType) {
  if (rank1 && !rank2) {
    return rank1;
  }
  if (!rank1 && rank2) {
    return rank2;
  }

  const tierDiff = convertTierToValue(rank1.tier) - convertTierToValue(rank2.tier);
  if (tierDiff > 0) {
    // compare tier
    return rank1;
  } else if (tierDiff < 0) {
    return rank2;
  } else {
    // compare division
    const divisionDiff = convertRomanNumeral(rank1.rank) - convertRomanNumeral(rank2.rank);
    if (divisionDiff > 0) {
      return rank2;
    } else if (divisionDiff < 0) {
      return rank1;
    } else {
      // compare total matcehs played
      const totalMatches1 = (rank1.wins || 0) + (rank1.losses || 0);
      const totalMatches2 = (rank2.wins || 0) + (rank2.losses || 0);
      return totalMatches1 >= totalMatches2 ? rank1 : rank2;
    }
  }
}

export function selectRank(queueType: "all" | string, season: number, rankScores: Array<RankType>) {
  if (!rankScores) {
    return null;
  }

  let rankToDisplay: Array<RankType> | undefined = undefined;
  // Choose highest rank from ranked solo and flex
  if (queueType === "all") {
    rankToDisplay = rankScores
      .filter((rank) => rank.seasonId === season)
      .filter((rank) => rank.queueType === QueueTypeS.RANKED_SOLO || rank.queueType === QueueTypeS.RANKED_FLEX)
      .filter((rank) => !rank.role);
  } else if (queueType === QueueTypeS.RANKED_SOLO || queueType === QueueTypeS.RANKED_FLEX) {
    rankToDisplay = rankScores
      .filter((rank) => rank.seasonId === season)
      .filter((rank) => rank.queueType === queueType)
      .filter((rank) => !rank.role);
  } else {
    rankToDisplay = rankScores
      .filter((rank) => rank.seasonId === season)
      .filter((rank) => rank.queueType === QueueTypeS.RANKED_SOLO)
      .filter((rank) => !rank.role);
  }

  rankToDisplay.sort((a, b) => {
    return a === compareRanks(a, b) ? -1 : 1;
  });

  return rankToDisplay && rankToDisplay[0];
}

export function getRankLabel(tier: string) {
  switch (tier && tier.toLowerCase()) {
    case RankS.IRON:
      return "Iron";
    case RankS.BRONZE:
      return "Bronze";
    case RankS.SILVER:
      return "Silver";
    case RankS.GOLD:
      return "Gold";
    case RankS.PLATINUM:
      return "Platinum";
    case RankS.EMERALD:
      return "Emerald";
    case RankS.DIAMOND:
      return "Diamond";
    case RankS.MASTER:
      return "Master";
    case RankS.GRANDMASTER:
      return "Grandmaster";
    case RankS.CHALLENGER:
      return "Challenger";
    case RankS.PLATINUM_PLUS:
      return "Platinum +";
    case RankS.EMERALD_PLUS:
      return "Emerald +";
    default:
      return "";
  }
}
