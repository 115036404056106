import React, { useMemo } from "react";
import classnames from "classnames";
import moment from "moment";
import MatchLPDisplay from "./MatchLPDisplay";
import { useMatchLPs } from "@ugg/shared/components/SummonerProfiles/overview/match-history/match-history-helpers";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { numToRoleMapping, getRoleSVG } from "lib/role_helper";

const RecentMatches = (props) => {
  const { getChampionImg } = getRiotAssetsContext();
  const { matches, rankSnapshots, size = 10 } = props;

  const roleDistriubtion = useMemo(() => {
    const roleCount = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    matches.slice(0, size).forEach((match) => {
      roleCount[match.role] += 1;
    });

    return Object.entries(roleCount)
      .map((el) => [numToRoleMapping(el[0]), Math.round((el[1] / size) * 100)])
      .sort((a, b) => b[1] - a[1])
      .slice(0, 2)
      .filter((el) => el[1] > 0);
  }, [matches]);

  // Remove all remakes
  const filteredMatches = useMemo(
    () =>
      matches
        .filter((match) => match.matchDuration > 300)
        .concat(Array(size).fill(null))
        .slice(0, size),
    [matches],
  );

  const matchLPs = useMatchLPs(matches, rankSnapshots);

  return (
    <div className="player_recent-matches">
      <div className="recent-matches_header">
        <div className="recent-matches_title">Recent Games</div>
        <div className="recent-matches_roles">
          {roleDistriubtion.map(([role, playRate]) => (
            <div key={role} className="role">
              {getRoleSVG(role)}
              <span className="off-white">{playRate}%</span>
            </div>
          ))}
        </div>
      </div>
      <div className="recent-matches_list">
        {filteredMatches.map((match, index) => {
          if (!match) {
            return <div key={index} className="recent-matches_match" />;
          }

          const matchEndTime = match.matchCreationTime + match.matchDuration * 1000;
          const fromNow = moment(matchEndTime).locale("multi-search-match").fromNow();

          return (
            <div
              key={index}
              className={classnames("recent-matches_match", {
                "recent-matches_match__win": match.win,
                "recent-matches_match__loss": !match.win,
              })}
            >
              <div className="champion-image-container">
                <img src={getChampionImg(match.championId)} />
              </div>
              <div className="match-lp">
                <MatchLPDisplay matchLP={matchLPs[match.matchId]} serverLP={match.lpInfo} />
              </div>
              <div className="kda">
                <span className="off-white">{match.kills || 0}</span>
                <span className="backslash"> / </span>
                <span className="off-white">{match.deaths || 0}</span>
                <span className="backslash"> / </span>
                <span className="off-white">{match.assists || 0}</span>
              </div>
              <div className="off-white match-time">{fromNow}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecentMatches;
