import { useApolloClient, useQuery, useMutation } from "@apollo/client";
import { URL } from "@ugg/shared/api/graphql/queries";

export const useUrl = (vars) => {
  const client = useApolloClient();
  return () => {
    client
      .query({
        query: URL,
        variables: vars,
      })
      .then((response) => {
        const { data, errors } = response;
        if (errors) {
          console.log(errors);
        } else {
          // console.log(data);
        }
      });
  };
};
