import { window } from "global";
import React, { useEffect, useRef, useState } from "react";
import NotificationDropdown from "./NotificationDropdown";
import { ReactComponent as IconBell } from "svg/new-filled-notification.svg";
import { ReactComponent as PriorityNotification } from "svg/notification-exclamation.svg";

const NotificationIcon = (props) => {
  const notificationRef = useRef();
  const { notificationSeen, newNotifications, toggleNotificationDropdown, priorityNotification } = props;

  useEffect(() => {
    if (window) {
      const handleOutsideClick = (e) => {
        const path = e.path || (e.composedPath && e.composedPath());
        if (
          notificationRef.current &&
          e.target !== null &&
          !notificationRef.current.contains(e.target) &&
          !path.includes(notificationRef.current)
        ) {
          toggleNotificationDropdown(false);
        }
      };

      window.addEventListener("click", handleOutsideClick);
      return () => window.removeEventListener("click", handleOutsideClick);
    }
  }, [toggleNotificationDropdown]);

  return (
    <div ref={notificationRef} className="notification-icon" onClick={() => toggleNotificationDropdown()}>
      {!notificationSeen && newNotifications.length > 0 && (
        <>
          <div className="new-announcement" />
          <div className="background-circle" />
        </>
      )}
      {priorityNotification ? <PriorityNotification width="20px" /> : <IconBell width="14px" />}
    </div>
  );
};

export default NotificationIcon;
