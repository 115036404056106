import React from "react";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const RuneTooltip = (props) => {
  const { getWorkingPatch, getRuneJSON, useRiotRunes } = getRiotAssetsContext();
  const { runeId, patch, language } = props;

  const { data: runes, loading: fetchingRunes, error: errorRunes } = useRiotRunes({ patch, language, skip: true });

  const isOlderPatch = (patch) => {
    const normalizedPatch = getWorkingPatch(patch);

    if (patch) {
      const season = patch.split(".")[0];
      const week = patch.split(".")[1];

      if (season == 8 && week <= 7 && week >= 1) {
        return true;
      }
    }
    return false;
  };

  const data = getRuneJSON(runeId, { patch, language, optionalData: runes });
  if (!data) return null;

  const description = isOlderPatch(patch) ? data.shortDesc : data.longDesc;

  return (
    <div className="tooltip-rune">
      <div className="name mb-[12px]">{data.name}</div>
      {data.longDesc && <div dangerouslySetInnerHTML={{ __html: description }} />}
    </div>
  );
};

export default RuneTooltip;
