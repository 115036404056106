export function alphanumeric(string: string) {
  return string.replace(/[^A-Za-z0-9]/g, "");
}

export function capitalizeFirstLetter(string: string) {
  if (!string) {
    return "";
  }

  return `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;
}
