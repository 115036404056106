// Sets our anonymous id for logged out users for Analytics tracking
// like Amplitude

import { v4 as uuidv4 } from "uuid";
import { window } from "global";

export function getSetAnonId() {
  if (window && window.localStorage) {
    let anonId = window.localStorage.getItem("anonId");
    if (anonId === null) {
      anonId = `ugg-${uuidv4()}`;
      window.localStorage.setItem("anonId", anonId);
    }
    return anonId;
  } else {
    return "";
  }
}
