import { ChangeEventHandler, CSSProperties } from "react";
import classNames from "classnames";

interface CheckboxProps {
  className?: string;
  checkboxId: string;
  isChecked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  // Checkbox override
  checkboxWidth?: number;
  checkboxBorderRadius?: number;
  checkboxBgUnchecked?: CSSProperties["color"];
  checkboxBgChecked?: CSSProperties["color"];
  checkmarkColor?: CSSProperties["color"];
}

export function Checkbox(props: CheckboxProps) {
  const { className, checkboxId, isChecked, onChange } = props;

  const {
    checkboxWidth = 16,
    checkboxBorderRadius = 3,
    checkboxBgUnchecked = "#313160",
    checkboxBgChecked = "#3273FA",
    checkmarkColor = "#FFFFFF",
  } = props;

  const rotatedCheckmarkDim = checkboxWidth - checkboxWidth / 5;
  const checkmarkHeight = (2 * rotatedCheckmarkDim) / (3 * Math.sin(45));
  const checkmarkWidth = checkmarkHeight / 2;
  const checkmarkThickness = checkmarkHeight / 5;

  return (
    <div className={classNames("flex item-center", className)}>
      <div className="relative flex-none" style={{ width: checkboxWidth, height: checkboxWidth }}>
        <input id={checkboxId} className="absolute opacity-0" type="checkbox" checked={isChecked} onChange={onChange} />
        <label
          htmlFor={checkboxId}
          className="absolute top-0 left-0 w-full h-full cursor-pointer hover:opacity-80"
          style={{
            width: checkboxWidth,
            height: checkboxWidth,
            borderRadius: checkboxBorderRadius,
            backgroundColor: isChecked ? checkboxBgChecked : checkboxBgUnchecked,
          }}
        >
          <div
            className="absolute top-0 right-0 translate-x-[-50%] translate-y-[53%] rotate-[45deg] origin-top-right"
            style={{
              visibility: isChecked ? "visible" : "hidden",
              width: checkmarkWidth,
              height: checkmarkHeight,
              borderRightWidth: checkmarkThickness,
              borderBottomWidth: checkmarkThickness,
              borderColor: checkmarkColor,
            }}
          />
        </label>
      </div>
    </div>
  );
}
