import React from "react";
import TooltipContainer from "../TooltipContainer";
import ChampionSkillTooltip from "../tooltipInfoTypes/ChampionSkillTooltip";
import ChampionPassiveTooltip from "../tooltipInfoTypes/ChampionPassiveTooltip";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const skillSlotMap = ["Q", "W", "E", "R"];

function getSkillSlot(skillSlot) {
  switch (skillSlot) {
    case "Q":
    case "0":
    case 0:
      return 0;
    case "W":
    case "1":
    case 1:
      return 1;
    case "E":
    case "2":
    case 2:
      return 2;
    case "R":
    case "3":
    case 3:
      return 3;
    default:
      return -1;
  }
}

const ChampionSkill = (props) => {
  const {
    getChampionSkillJSON,
    getChampionPassiveJSON,
    getPassiveAbilityImg,
    getChampionSkillImgs,
    getIndividualChampion,
    useChampionIndiv,
  } = getRiotAssetsContext();
  const {
    children,
    className,
    championId,
    skillSlot,
    isPassive,
    patch,
    language,
    forceOrientation,
    disableTooltip,
    ssr,
    skip,
    webp,
  } = props;

  const {
    data: championIndiv,
    loading: fetchingChampionIndiv,
    error: errorChampionIndiv,
  } = useChampionIndiv(championId, { patch, ssr, skip, language });

  const championJSON = championIndiv || getIndividualChampion(championId, { patch, language });

  if (!championJSON) return null;

  const finalSkillSlot = getSkillSlot(skillSlot);
  let imgSrc = "",
    skillJSON,
    skillName = "",
    tooltip = null;
  if (isPassive) {
    imgSrc = getPassiveAbilityImg(championId, { patch, optionalData: championJSON, webp });
    skillJSON = getChampionPassiveJSON(championId, { patch, language, optionalData: championJSON });
    skillName = skillJSON.name;
    tooltip = <ChampionPassiveTooltip championId={championId} language={language} />;
  } else {
    imgSrc = getChampionSkillImgs(championId, { patch, optionalData: championJSON, webp })[finalSkillSlot];
    skillJSON = getChampionSkillJSON(championId, finalSkillSlot, { patch, language, optionalData: championJSON });
    skillName = skillJSON.name;
    tooltip = <ChampionSkillTooltip championId={championId} skillSlot={finalSkillSlot} patch={patch} language={language} />;
  }
  const alt = `${championJSON.name}'s ${isPassive ? "Passive" : skillSlotMap[finalSkillSlot]}: ${skillName}`;

  if (typeof children === "function") {
    return (
      <TooltipContainer tooltipInfo={tooltip} forceOrientation={forceOrientation} disableTooltip={disableTooltip}>
        {children({ imgSrc, skillName, tooltip })}
      </TooltipContainer>
    );
  }

  return (
    <TooltipContainer tooltipInfo={tooltip} forceOrientation={forceOrientation} disableTooltip={disableTooltip}>
      <img className={className} src={imgSrc} alt={alt} />
    </TooltipContainer>
  );
};

export default ChampionSkill;
