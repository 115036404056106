import { window } from "global";
import React from "react";
import TooltipContainer from "../TooltipContainer";
import SummonerSpellTooltip from "../tooltipInfoTypes/SummonerSpellTooltip";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const SummonerSpell = (props) => {
  const { getSummonerSpells, getSummonerSpellImg, useRiotSummoners } = getRiotAssetsContext();
  const { spellId, patch, language, className, style, disableTooltip, ssr, skip, webp } = props;

  const { data: summoners, loading: fetchingSummoners, error: errorSummoners } = useRiotSummoners({ patch, ssr, language, skip });

  const spellsJSON = summoners || getSummonerSpells({ language, patch });
  const imgSrc = getSummonerSpellImg(spellId, { optionalData: spellsJSON, webp });
  const data = spellsJSON && spellsJSON[spellId];

  const name = (data && data.name) || "";

  const tooltipInfo = <SummonerSpellTooltip spellId={spellId} patch={patch} language={language} />;

  return (
    <TooltipContainer tooltipInfo={tooltipInfo} disableTooltip={disableTooltip}>
      <img className={className} style={style} src={imgSrc} alt={`Summoner Spell ${name}`} />
    </TooltipContainer>
  );
};

export default React.memo(SummonerSpell);

// SummonerSpell.whyDidYouRender = true
