import { useHistory, useLocation } from "react-router-dom";
import { global_exemptions } from "./valid-params";
import { useUGGPatches } from "@ugg/shared/api/requests/patches";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { compareObjects } from "@ugg/shared/utils/object";

export function getValidParamKeys<T>(params: T, page: keyof T) {
  const validPageParams: Record<string, any> = params[page] || {};
  const validKeys: Record<string, true> = {};

  if (!validPageParams) return validKeys;

  Object.keys(validPageParams).forEach((key) => {
    if (key === "exemptions") {
      const exemptions: Array<string> = validPageParams["exemptions"];
      exemptions.forEach((exemption) => {
        validKeys[exemption] = true;
      });
    } else if (key !== "default") {
      validKeys[key] = true;
    }
  });

  return validKeys;
}

/**
 * @param {string} page - Page type to get valid param options
 * @param {object} queryParams - Query parameters to validate
 * @param {boolean} keepDefaultParams - Set to true if default params should be kept
 */
// Removes params with invalid keys or values
export function useValidateQueryParams<T>(validData: T, ssr = false) {
  const { useChampionMini } = getRiotAssetsContext();
  const { data: championMini } = useChampionMini({ ssr });
  const uggPatches = useUGGPatches({ ssr });

  return (
    page: keyof T | undefined,
    queryParams: Record<string, any>,
    keepDefaultParams = false,
    overrideValidParams?: Record<string, any>,
  ) => {
    let validParameters = validData; // default champion stats params

    const validPageParams: Record<string, any> = overrideValidParams || (page && validParameters[page]) || {};
    const defaultParams = validPageParams.default || {};
    const filters = { ...defaultParams, ...queryParams };

    let validatedParams = keepDefaultParams ? { ...filters } : { ...queryParams };

    const currentParams = Object.keys(validatedParams);
    currentParams.forEach((key) => {
      const paramValue = validatedParams[key];

      // Remove if default param value
      if (defaultParams[key] === paramValue) {
        if (!keepDefaultParams) {
          delete validatedParams[key];
        }
      } else if (validPageParams[key] === undefined) {
        // Check any exemptions
        const checkGlobalExemption = global_exemptions.includes(key);
        const checkPageExemption = validPageParams["exemptions"] ? validPageParams["exemptions"].includes(key) : false;
        if (key === "opp" || key === "champion") {
          const isValidValue = Object.values(championMini || {}).find((champion) => {
            return (
              champion.id.toLowerCase() === paramValue.toLowerCase() || champion.name.toLowerCase() === paramValue.toLowerCase()
            );
          });

          if (!isValidValue) {
            delete validatedParams[key];
          }
        }
        if (checkGlobalExemption === false && checkPageExemption === false) {
          delete validatedParams[key];
        }
      } else {
        let isValidValue = true;
        if (Array.isArray(validPageParams[key])) {
          const params: Array<{ value: any }> = validPageParams[key];
          isValidValue = !!params.find((option) => {
            return option.value === paramValue;
          });
        }
        // Remove if not valid param value
        if (!isValidValue) {
          delete validatedParams[key];
        }
      }
    });

    const { data } = uggPatches;
    const { patch: defaultPatch } = defaultParams || {};
    const { options, max } = validPageParams.patch || {};
    const maxUGGPatches = (data || []).slice(0, max);
    // Delete if default patch
    if (
      !keepDefaultParams &&
      ((defaultPatch === "latest" && maxUGGPatches[0] === validatedParams.patch) ||
        (defaultPatch !== "latest" && defaultPatch === validatedParams.patch))
    ) {
      delete validatedParams.patch;
    }
    // Delete if invalid patch
    if (
      validatedParams.patch &&
      ((options === "latest" && !maxUGGPatches.includes(validatedParams.patch)) ||
        (Array.isArray(options) && !options.includes(validatedParams.patch)))
    ) {
      delete validatedParams.patch;
    }
    // Replace "latest" with valid patch value
    if (keepDefaultParams && defaultPatch === "latest" && (!validatedParams.patch || validatedParams.patch === "latest")) {
      validatedParams.patch = maxUGGPatches[0];
    }

    return validatedParams;
  };
}

export const useValidateURL = () => {
  const location = useLocation<any>();
  const history = useHistory();

  return (currentParams: Record<string, any>, validatedParams: Record<string, any>) => {
    const isParamsClean = compareObjects(currentParams, validatedParams);

    if (!isParamsClean) {
      return history.replace({
        pathname: location.pathname,
        search: new URLSearchParams(validatedParams).toString(),
        state: {
          ...location.state,
          validation: true,
        },
      });
    }
  };
};
