import { window } from "global";
import { useEffect, useLayoutEffect, useRef, useCallback, useContext } from "react";
import { throttle } from "lodash";
import { useGlobal, useState } from "reactn";
import { parseQueryString } from "lib/general-helper";
import { useLocation } from "react-router-dom";
import { LightModeContext, LightModeDispatchContext } from "components/LightModeContext";

export function useQueryString() {
  const location = useLocation();
  const queryString = location.search.substring(1);

  return parseQueryString(queryString);
}

export const useCompare = (val) => {
  const prevVal = usePrevious(val);

  return prevVal !== val;
};

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useSideNav = () => {
  const [expandedSideNav, setExpandedSideNav] = useGlobal("expandedSideNav");

  const toggleSideNav = (toggleBool, { saveToggle } = {}) => {
    if (saveToggle === true) {
      if (toggleBool) {
        localStorage.setItem("TOGGLE_AND_SAVE_SIDE_NAV_KEY", "true");
      } else {
        localStorage.setItem("TOGGLE_AND_SAVE_SIDE_NAV_KEY", "false");
      }
    }

    setExpandedSideNav(toggleBool);
  };

  return [expandedSideNav, toggleSideNav];
};

// Mainly used to close side nav when a link a clicked
export function useMobileToggleSideNav() {
  const [windowBreakpoint] = useGlobal("responsive");
  const [expandedSideNav, setExpandedSideNav] = useSideNav();
  const mobileBreakpoints = ["MOBILE_SMALL", "MOBILE_MEDIUM", "MOBILE_LARGE"];

  const toggle = useCallback(() => {
    if (mobileBreakpoints.includes(windowBreakpoint)) {
      setExpandedSideNav(!expandedSideNav);
    }
  }, [windowBreakpoint, expandedSideNav]);

  return toggle;
}

// Scroll position
export const SCROLL_DIRECTION_DOWN = "down";
export const SCROLL_DIRECTION_UP = "up";

/**
 * Adds listener to scroll position of an element, or the window if no element is present.
 * @param element - The ref of the element to listen on. If no value is passed, the document is used instead.
 * @param {Object} options - Options for the scroll listener.
 * @param {boolean} options.disable - While true, removes the listener and prevents it from being re-added.
 * @param {boolean} options.preventScrollBounce - If true, prevents mobile scroll bounce from occuring. Defaults to false.
 * @param {int} options.throttleTime - Time in ms to pass to throttle function. Defaults to 500ms.
 */
export const useScrollPosition = (element, { disable = false, preventBounce = false, throttleTime = 500 }) => {
  const [scroll, setScroll] = useState({
    x: 0,
    y: 0,
    direction: SCROLL_DIRECTION_UP,
    max: 0,
  });

  // Set target element and function to fetch
  const htmlElement = element?.current || window;
  //const getTarget = (e) => (e.target === document ? e.target.scrollingElement : e.target);
  // Have to check against window specifically and fetch the document scrolling element,
  // so that fetching the scroll position is consistent.
  const getTarget = (e) => (e.currentTarget === window ? e.currentTarget.document.scrollingElement : e.currentTarget);

  // Set-up handler
  const scrollHandler = (e) => {
    if (!e.target) {
      return;
    }

    // Apparently when window has the scroll event attached to it document ends up being the actual target, and the
    // scroll elements are obscured behind scrollingElement.
    const target = getTarget(e);
    if (!target) {
      return;
    }

    const yPos = target.scrollTop;
    setScroll((prev) => ({
      x: target.scrollLeft,
      y: yPos,
      direction: prev.y < yPos ? SCROLL_DIRECTION_DOWN : SCROLL_DIRECTION_UP,
      max: target.scrollTopMax,
    }));
  };

  const scrollHandlerThrottled = useCallback(throttle(scrollHandler, throttleTime), []);

  const preventBounceHandler = (e) => {
    const target = getTarget(e);
    const { scrollTop, scrollHeight, clientHeight } = target;

    // Move to top if above top
    if (scrollTop <= 0) {
      htmlElement.scrollTo(0, 1);
      return;
    }

    // Move to bottom if below bottom
    if (Math.ceil(scrollHeight - scrollTop) <= clientHeight) {
      htmlElement.scrollTo(0, scrollHeight - clientHeight);
    }
  };

  useEffect(() => {
    if (htmlElement) {
      const listeners = [];

      if (!disable) {
        htmlElement.addEventListener("scroll", scrollHandlerThrottled);
        listeners.push({ event: "scroll", action: scrollHandlerThrottled });
      }
      if (preventBounce) {
        htmlElement.addEventListener("touchstart", preventBounceHandler);
        listeners.push({ event: "touchstart", preventBounceHandler });
      }

      return () => {
        listeners.forEach(({ event, action }) => {
          htmlElement.removeEventListener(event, action);
        });
      };
    }
  }, [element, disable, preventBounce]);

  return scroll;
};

export const useLightMode = () => {
  const lightMode = useContext(LightModeContext);
  const setLightMode = useContext(LightModeDispatchContext);

  const setLightModeEnabled = (lightModeEnabled) => {
    if (lightModeEnabled) {
      localStorage.setItem("uggLightModeEnabled", true);
    } else {
      localStorage.removeItem("uggLightModeEnabled");
    }

    setLightMode((prevState) => ({
      ...prevState,
      enabled: lightModeEnabled,
    }));
  };

  return {
    lightMode,
    lightModeOn: lightMode.enabled && lightMode.allowed,
    setLightModeEnabled,
  };
};
