import * as React from "react";
import type { SVGProps } from "react";
export const SvgLolTwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="#5C5C8E"
      d="M16.66 4.242a6.3 6.3 0 0 1-1.802.494A3.15 3.15 0 0 0 16.237 3a6.3 6.3 0 0 1-1.992.761A3.137 3.137 0 0 0 8.9 6.621a8.9 8.9 0 0 1-6.465-3.276 3.136 3.136 0 0 0 .971 4.188 3.1 3.1 0 0 1-1.42-.393l-.001.039A3.14 3.14 0 0 0 4.5 10.255a3.16 3.16 0 0 1-1.416.054 3.14 3.14 0 0 0 2.93 2.178 6.3 6.3 0 0 1-4.644 1.299 8.87 8.87 0 0 0 4.808 1.41c5.77 0 8.925-4.78 8.925-8.925q0-.205-.01-.406a6.4 6.4 0 0 0 1.565-1.623"
    />
  </svg>
);
