export function downloadFile(
  url: string,
  filename: string,
  options: {
    onProgress?: (currentReceived: number, totalSize: number) => void;
    onComplete?: () => void;
    onError?: (err: any) => void;
  },
) {
  fetch(url)
    .then(async (response) => {
      const reader = response.clone().body?.getReader();
      const contentLength = Number(response.headers.get("Content-Length") || 0);
      let receivedLength = 0; // received that many bytes at the moment
      while (true && reader) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        receivedLength += value.length;
        options.onProgress?.(receivedLength, contentLength);
      }
      return response.blob();
    })
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement("a");
      a.href = blobURL;
      a.style.setProperty("display", "none");

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      options?.onComplete?.();
    })
    .catch((err) => {
      options.onError?.(err);
    });
}

export function appFilename(campaign?: string | null) {
  return `U.GG Setup${campaign ? "-" + campaign : ""}.exe`;
}
