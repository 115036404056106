import { ROLE_OPTIONS, REGION_OPTIONS, RANK_OPTIONS_WITH_D2 } from "../common";

// ------------------------------------
//  COUNTERS
// ------------------------------------

export const COUNTERS_QUEUE_TYPE_OPTIONS = [
  { value: "ranked_solo_5x5", label: "Ranked Solo" },
  { value: "ranked_flex_sr", label: "Ranked Flex" },
];

export const COUNTERS_OPTIONS = {
  queueType: COUNTERS_QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  patch: { options: "latest", max: 5 },
};

export const COUNTERS_DEFAULT = {
  queueType: COUNTERS_OPTIONS.queueType[0].value,
  region: COUNTERS_OPTIONS.region[0].value,
  rank: COUNTERS_OPTIONS.rank[1].value,
  patch: COUNTERS_OPTIONS.patch.options,
};
