import React from "react";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const AugmentTooltip = (props) => {
  const { useRiotArenaAugments, getArenaAugments } = getRiotAssetsContext();
  const { augmentId, patch, language } = props;

  const { data: augment, loading: fetchingAugment, error: errorAugment } = useRiotArenaAugments({ augmentId, patch, language });

  const augmentsJSON = augment || getArenaAugments({ patch, language });
  const data = augmentsJSON && augmentsJSON[augmentId];

  if (!data) {
    return null;
  }

  return (
    <div className="tooltip-arena-augment">
      <div className="name mb-[12px]">{data.name}</div>
      <div dangerouslySetInnerHTML={{ __html: data.desc }} />
    </div>
  );
};

export default AugmentTooltip;
