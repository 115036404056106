import React, { Component } from "react";
import { getNotFoundImg } from "@outplayed/riot-assets";

export default class NoTableData extends Component {
  render() {
    return (
      <div className="no-table-data">
        <img src={getNotFoundImg()} alt="No Rows Rengar" />
        <div className="text">Sorry, no data available.</div>
        <div className="text">If you believe this is an error, try checking again later.</div>
      </div>
    );
  }
}
