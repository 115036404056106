import { useMemo, useContext, createContext } from "react";

interface UGGAssetsContextInterface {
  staging: boolean;
}

export const UGGAssetsContext = createContext<UGGAssetsContextInterface>({
  staging: false,
});

export function useUGGAssetsContext() {
  return useContext(UGGAssetsContext);
}

interface UGGAssetsProviderProps {
  staging?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export const UGGAssetsProvider = (props: UGGAssetsProviderProps) => {
  const { staging } = props;

  const value = useMemo(() => {
    return {
      staging: !!staging,
    };
  }, [staging]);

  return <UGGAssetsContext.Provider value={value}>{props.children}</UGGAssetsContext.Provider>;
};
