import Fuse from "fuse.js";
import { useSearchPages } from "lib/hooks/searchHooks";
import { sendSearchBarEvent } from "lib/googleEventsHelper";

const fuseOpts = {
  shouldSort: true,
  includeScore: true,
  // tokenize: true,
  // matchAllTokens: true,
  keys: ["key"],
};

export function useBestSearchMatch() {
  const { data: searchPages } = useSearchPages();

  return (query, searchId = undefined) => {
    sendSearchBarEvent(query, searchId);

    if (searchPages) {
      var fuse = new Fuse(searchPages, fuseOpts);
      let res = fuse.search((query && query.trim()) || "");

      return res;
    } else {
      return [];
    }
  };
}
