import React from "react";
import TooltipContainer from "../TooltipContainer";
import StatShardTooltip from "../tooltipInfoTypes/StatShardTooltip";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const StatShard = (props) => {
  const { useStatShards, getStatShardJSON, getStatShardImg } = getRiotAssetsContext();
  const { children, className, style, shardRow, shardId, disableTooltip, patch, ssr } = props;
  const { data, loading, error } = useStatShards({ patch, ssr });
  const [statShardPatch, statShardJSON] = data || [];

  const json = getStatShardJSON(statShardJSON, shardRow, shardId);
  const name = (json && json.name) || "";
  const imgSrc = getStatShardImg(shardId, { patch, optionalData: data, webp: true });
  const tooltipInfo = <StatShardTooltip shardRow={shardRow} shardId={shardId} patch={patch} />;

  if (typeof children === "function") {
    return (
      <TooltipContainer tooltipInfo={tooltipInfo} disableTooltip={disableTooltip}>
        {children({ imgSrc, shardName: name, tooltipInfo })}
      </TooltipContainer>
    );
  }

  return (
    <TooltipContainer tooltipInfo={tooltipInfo} disableTooltip={disableTooltip}>
      <img className={className} style={style} src={imgSrc} alt={`The ${name} Shard`} />
    </TooltipContainer>
  );
};

export default StatShard;
