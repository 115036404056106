export const STATIC_UGG_URL = "https://static.bigbrain.gg/assets";
export const STATIC_LOL_URL = "https://static.bigbrain.gg/assets/lol";
export const STATIC_PBS_URL = "https://static.bigbrain.gg/assets/probuildstats";
export const STATIC_OW_URL = "https://static.bigbrain.gg/lol/static/images/overwolf_app";
export const CDRAGON_URL = "https://raw.communitydragon.org";

// DATA
function getStaticDataUrl(patch, lang, filename) {
  const language = lang || "en_US";
  return `${STATIC_LOL_URL}/riot_static/${patch}/data/${language}/${filename}`;
}

function getRunesReforgedData(patch, lang = "en_US") {
  return getStaticDataUrl(patch, lang, "runesReforged.json");
}

function getStatShardData(staging) {
  const env = staging ? "staging" : "prod";
  return `${STATIC_LOL_URL}/riot_patch_update/${env}/stat-shards-v2.json`;
}

function getChampionData(patch, lang = "en_US") {
  return getStaticDataUrl(patch, lang, "champion.json");
}

function getChampionFullData(patch, lang = "en_US") {
  return getStaticDataUrl(patch, lang, "championFull.json");
}

function getIndividualChampionData(patch, championId, lang = "en_US") {
  const filename = `champion/${championId}.json`;
  return getStaticDataUrl(patch, lang, filename);
}

function getItemData(patch, lang = "en_US", itemId) {
  if (itemId) {
    return getStaticDataUrl(patch, lang, `items/${itemId}.json`);
  }

  return getStaticDataUrl(patch, lang, "item.json");
}

function getSummonerData(patch, lang = "en_US") {
  return getStaticDataUrl(patch, lang, "summoner.json");
}

function getCDragonAugmentData(patch = "latest", lang = "en_us") {
  return `${CDRAGON_URL}/${patch}/cdragon/arena/${lang.toLowerCase()}.json`;
}

// IMAGES
function getStaticImgUrl(patch, filename, { webp } = {}) {
  const url = `${STATIC_LOL_URL}/riot_static/${patch}/img/${filename}`;
  return webp ? url.replace(/\.(png|jpg|jpeg)$/g, ".webp") : url;
}

function getStaticOverwolfImg(filename, { webp } = {}) {
  const url = `${STATIC_OW_URL}/${filename}.png`;
  return webp ? url.replace(/\.(png|jpg|jpeg)$/g, ".webp") : url;
}

function uggGetProfileIconImg(patch, iconId, { webp } = {}) {
  return getStaticImgUrl(patch, `profileicon/${iconId}.png`, { webp });
}

function uggGetChampionImg(patch, championPath, { webp } = {}) {
  return getStaticImgUrl(patch, `champion/${championPath}`, { webp });
}

function uggGetChampionSplashImg(patch, championPath, { webp } = {}) {
  if (championPath === "MasterYi_33.jpg") {
    if (webp) {
      return getUrl("lol/backup_splash/MasterYi_33.webp");
    }
    return getUrl("lol/backup_splash/MasterYi_33.jpg");
  }
  return getStaticImgUrl(patch, `splash/${championPath}`, { webp });
}

function uggGetRuneImg(patch, iconPath, { webp } = {}) {
  return getStaticImgUrl(patch, iconPath.replace("perk-images", "small-perk-images").replace(".dds", ".png"), { webp });
}

function uggGetStatShardImg(patch, iconPath, { webp } = {}) {
  return getStaticImgUrl(patch, `perk-images/StatMods/${iconPath}`, { webp });
}

function uggGetItemImg(patch, itemPath, { webp } = {}) {
  return getStaticImgUrl(patch, `item/${itemPath}`, { webp });
}

function uggGetSpriteImg(patch, spritePath, { webp } = {}) {
  return getStaticImgUrl(patch, `sprite/${spritePath}`, { webp });
}

function uggGetSpellImg(patch, spellPath, { webp } = {}) {
  return getStaticImgUrl(patch, `spell/${spellPath}`, { webp });
}

function uggGetPassiveImg(patch, passivePath, { webp } = {}) {
  return getStaticImgUrl(patch, `passive/${passivePath}`, { webp });
}

function getTeamUrl(team) {
  const normalizedTeam = team.toLowerCase().replace(/ /g, "");
  return `${STATIC_PBS_URL}/team_icons/${normalizedTeam}.png`;
}

function getArenaAugmentImg(imgSrc, patch = "latest") {
  return `${CDRAGON_URL}/${patch}/game/${imgSrc}`;
}

// PLAYERS
function getStaticPlayerUrl(player) {
  return `${STATIC_LOL_URL}/players/${player}.png`;
}

function getStaticPlayer2Url(player) {
  return `${STATIC_PBS_URL}/player_images/small_2x/${player}.png`;
}

function getPlayerCountryFlag(country) {
  const normalizedCountry = country.toLowerCase().replace(" ", "-");
  return `${STATIC_PBS_URL}/country_flags/${normalizedCountry}.svg`;
}

function getRankUrl(rank) {
  if (rank === "allranks") {
    return `${STATIC_LOL_URL}/ranks/small/${rank}.svg`;
  }

  return `${STATIC_LOL_URL}/ranks/small/${rank}.png`;
}

function get2DRankUrl(rank) {
  return `${STATIC_LOL_URL}/ranks/2d/${rank && rank.toLowerCase()}.svg`;
}

function getS9RankUrl(rank) {
  return `${STATIC_LOL_URL}/ranks/s9_ranks/small/${rank}.png`;
}

function getS12RankUrl(rank) {
  return `${STATIC_LOL_URL}/s12_rank_icons/${rank}.png`;
}

function getS13RankUrl(rank, mini) {
  if (typeof rank !== "string") {
    return "";
  }

  if (mini) {
    return `${STATIC_LOL_URL}/ranks/s13/mini/${rank.toLowerCase()}.svg`;
  }

  return `${STATIC_LOL_URL}/ranks/s13/${rank.toLowerCase()}.png`;
}

function getS9ProfileRankUrl(tier, division) {
  if (!tier || typeof tier !== "string" || tier === "unranked") {
    return `${STATIC_LOL_URL}/ranks/s9_ranks/large/unranked.png`;
  }

  function convertRomanNumeral(roman) {
    switch (roman.toUpperCase()) {
      case "I":
        return 1;
      case "II":
        return 2;
      case "III":
        return 3;
      case "IV":
        return 4;
      case "V":
        return 5;
      default:
        return "";
    }
  }

  let numDivision = division;
  if (isNaN(division)) {
    numDivision = convertRomanNumeral(division);
  }
  return `${STATIC_LOL_URL}/ranks/s9_ranks/large/${tier.toLowerCase()}-${numDivision}.png`;
}

// PLAYER TYPES
function getPlayerTypeUrl(playerType) {
  return `${STATIC_LOL_URL}/player_types/${playerType}.svg`;
}

// ROLES
function getRoleUrl(role) {
  return `${STATIC_LOL_URL}/roles/${role}.svg`;
}

function getColorRoleUrl(role, type) {
  if (type === "blue" || type === "red") {
    return `${STATIC_LOL_URL}/roles/colored/${role}-${type}.svg`;
  }

  return `${STATIC_LOL_URL}/roles/${role}.svg`;
}

// REGIONS
function getRegionUrl(region) {
  return `${STATIC_LOL_URL}/regions/${region}.svg`;
}

const regions = {
  BR: `${STATIC_UGG_URL}/ugg/icons/regions/selector/br-selector.svg`,
  EUN: `${STATIC_UGG_URL}/ugg/icons/regions/selector/eun-selector.svg`,
  EUW: `${STATIC_UGG_URL}/ugg/icons/regions/selector/euw-selector.svg`,
  JP: `${STATIC_UGG_URL}/ugg/icons/regions/selector/jp-selector.svg`,
  KR: `${STATIC_UGG_URL}/ugg/icons/regions/selector/kr-selector.svg`,
  LAN: `${STATIC_UGG_URL}/ugg/icons/regions/selector/lan-selector.svg`,
  LAS: `${STATIC_UGG_URL}/ugg/icons/regions/selector/las-selector.svg`,
  NA: `${STATIC_UGG_URL}/ugg/icons/regions/selector/na-selector.svg`,
  OCE: `${STATIC_UGG_URL}/ugg/icons/regions/selector/oce-selector.svg`,
  RU: `${STATIC_UGG_URL}/ugg/icons/regions/selector/ru-selector.svg`,
  TR: `${STATIC_UGG_URL}/ugg/icons/regions/selector/tr-selector.svg`,
  World: `${STATIC_UGG_URL}/ugg/icons/regions/selector/global-selector.svg`,
};

const mastheadRegions = {
  BR: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/br-searchbar.svg`,
  EUN: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/eun-searchbar.svg`,
  EUW: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/euw-searchbar.svg`,
  JP: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/jp-searchbar.svg`,
  KR: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/kr-searchbar.svg`,
  LAN: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/lan-searchbar.svg`,
  LAS: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/las-searchbar.svg`,
  NA: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/na-searchbar.svg`,
  OCE: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/oce-searchbar.svg`,
  RU: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/ru-searchbar.svg`,
  TR: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/tr-searchbar.svg`,
  PH: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/ph-searchbar.svg`,
  SG: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/sg-searchbar.svg`,
  TH: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/th-searchbar.svg`,
  TW: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/tw-searchbar.svg`,
  VN: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/vn-searchbar.svg`,
  ME: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/me-searchbar.svg`,
  World: `${STATIC_UGG_URL}/ugg/icons/regions/searchbar/World.svg`,
};

const mastheadRegionsPBS = {
  BR: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/BR.svg`,
  CN: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/CN.svg`,
  EU: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/EU.svg`,
  JP: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/JP.svg`,
  KR: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/KR.svg`,
  LAN: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/LAN.svg`,
  LAS: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/LAS.svg`,
  LAT: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/LAT.svg`,
  NA: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/NA.svg`,
  OCE: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/OCE.svg`,
  PCS: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/PCS.svg`,
  RU: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/RU.svg`,
  TR: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/TR.svg`,
  World: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/World.svg`,
};
const regionsPBS = {
  BR: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/BR.svg`,
  EU: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/EU.svg`,
  JP: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/JP.svg`,
  KR: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/KR.svg`,
  LAN: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/LAN.svg`,
  LAS: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/LAS.svg`,
  NA: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/NA.svg`,
  OCE: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/OCE.svg`,
  RU: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/RU.svg`,
  TR: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/TR.svg`,
  CN: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/CN.svg`,
  World: `${STATIC_UGG_URL}/ugg/icons/regions/region-pbs/World.svg`,
};

function getBlockRegionUrl(region, isMasthead) {
  return isMasthead ? mastheadRegions[region] : regions[region];
}

function getMastheadRegionImg(region) {
  return mastheadRegions[region];
}

function getBlockRegionUrlPBS(region, isMasthead) {
  return isMasthead ? mastheadRegionsPBS[region] : regionsPBS[region];
}

function getLeagueSeriesUrl(league) {
  return `${STATIC_LOL_URL}/league_series/${league}.png`;
}

// ICONS
function getIcon(iconPath) {
  return `${STATIC_UGG_URL}/${iconPath}`;
}

// IMAGES
function getRankBorderImg(filename) {
  return `${STATIC_UGG_URL}/lol/rank_borders/round/${filename}`;
}

function getUrl(path) {
  return `${STATIC_UGG_URL}/${path}`;
}

function getNotFoundImg() {
  return `${STATIC_UGG_URL}/lol/art/rengar.png`;
}

function getCustomRunesImg(id) {
  return `${STATIC_LOL_URL}/runes/${id}.png`;
}

export {
  getRunesReforgedData,
  getStatShardData,
  getChampionData,
  getChampionFullData,
  getIndividualChampionData,
  getItemData,
  getSummonerData,
  uggGetProfileIconImg,
  uggGetChampionImg,
  uggGetChampionSplashImg,
  uggGetRuneImg,
  uggGetStatShardImg,
  uggGetItemImg,
  uggGetSpriteImg,
  uggGetSpellImg,
  uggGetPassiveImg,
  getTeamUrl,
  getStaticPlayerUrl,
  getStaticPlayer2Url,
  getPlayerCountryFlag,
  getRankUrl,
  get2DRankUrl,
  getS9RankUrl,
  getS12RankUrl,
  getS13RankUrl,
  getS9ProfileRankUrl,
  getPlayerTypeUrl,
  getRoleUrl,
  getColorRoleUrl,
  getRegionUrl,
  getBlockRegionUrl,
  getBlockRegionUrlPBS,
  getMastheadRegionImg,
  getLeagueSeriesUrl,
  getIcon,
  getRankBorderImg,
  getUrl,
  getNotFoundImg,
  getCustomRunesImg,
  getStaticOverwolfImg,
  getCDragonAugmentData,
  getArenaAugmentImg,
};
