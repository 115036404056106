import { ROLES, getRoleSVG } from "lib/role_helper.js";
import { getS13RankUrl, getRegionUrl, getLeagueSeriesUrl } from "@outplayed/riot-assets";

// ------------------------------------
// GENERAL OPTIONS
// ------------------------------------

export const ROLE_OPTIONS = [
  { value: ROLES.TOP, label: "Top", img: getRoleSVG(ROLES.TOP), imgActive: getRoleSVG(ROLES.TOP, { active: true }) },
  { value: ROLES.JUNGLE, label: "Jungle", img: getRoleSVG(ROLES.JUNGLE), imgActive: getRoleSVG(ROLES.JUNGLE, { active: true }) },
  { value: ROLES.MIDDLE, label: "Middle", img: getRoleSVG(ROLES.MIDDLE), imgActive: getRoleSVG(ROLES.MIDDLE, { active: true }) },
  { value: ROLES.BOTTOM, label: "Bot", img: getRoleSVG(ROLES.BOTTOM), imgActive: getRoleSVG(ROLES.BOTTOM, { active: true }) },
  {
    value: ROLES.SUPPORT,
    label: "Support",
    img: getRoleSVG(ROLES.SUPPORT),
    imgActive: getRoleSVG(ROLES.SUPPORT, { active: true }),
  },
];

export const ALL_ROLE_OPTIONS = [
  { value: ROLES.ALL, label: "All Roles", img: getRoleSVG(ROLES.ALL) },
  { value: ROLES.TOP, label: "Top", img: getRoleSVG(ROLES.TOP), imgActive: getRoleSVG(ROLES.TOP, { active: true }) },
  { value: ROLES.JUNGLE, label: "Jungle", img: getRoleSVG(ROLES.JUNGLE), imgActive: getRoleSVG(ROLES.JUNGLE, { active: true }) },
  { value: ROLES.MIDDLE, label: "Middle", img: getRoleSVG(ROLES.MIDDLE), imgActive: getRoleSVG(ROLES.MIDDLE, { active: true }) },
  { value: ROLES.BOTTOM, label: "Bot", img: getRoleSVG(ROLES.BOTTOM), imgActive: getRoleSVG(ROLES.BOTTOM, { active: true }) },
  {
    value: ROLES.SUPPORT,
    label: "Support",
    img: getRoleSVG(ROLES.SUPPORT),
    imgActive: getRoleSVG(ROLES.SUPPORT, { active: true }),
  },
];

export const NEXUS_BLITZ_ROLE_OPTIONS = [
  { value: ROLES.JUNGLE, label: "Jungle", img: getRoleSVG(ROLES.JUNGLE), imgActive: getRoleSVG(ROLES.JUNGLE, { active: true }) },
  { value: ROLES.LANE, label: "Lane", img: getRoleSVG(ROLES.BOTTOM), imgActive: getRoleSVG(ROLES.BOTTOM, { active: true }) },
];

export const ALL_NEXUS_BLITZ_ROLE_OPTIONS = [
  { value: ROLES.ALL, label: "All Roles", img: getRoleSVG(ROLES.ALL) },
  ...NEXUS_BLITZ_ROLE_OPTIONS,
];

export const QUEUE_TYPE_OPTIONS = [
  { value: "ranked_solo_5x5", label: "Ranked Solo" },
  { value: "normal_aram", label: "ARAM" },
  { value: "ranked_flex_sr", label: "Ranked Flex" },
  { label: "Quickplay", value: "quickplay" },
  { value: "normal_draft_5x5", label: "Normal Draft" },
  // { value: "normal_blind_5x5", label: "Normal Blind" },
  { value: "nexus_blitz", label: "Nexus Blitz" },
  { value: "arena", label: "Arena" },
  { value: "urf", label: "URF" },
  { value: "arurf", label: "ARURF" },
  { value: "one_for_all", label: "One for All" },
];

export const RANK_OPTIONS_WITH_D2 = [
  { value: "platinum_plus", label: "Platinum +", img: getS13RankUrl("platinum_plus", true) },
  { value: "emerald_plus", label: "Emerald +", img: getS13RankUrl("emerald_plus", true) },
  { value: "diamond_plus", label: "Diamond +", img: getS13RankUrl("diamond_plus", true) },
  { value: "diamond_2_plus", label: "Diamond 2 +", img: getS13RankUrl("diamond_2_plus", true) },
  { value: "master_plus", label: "Master +", img: getS13RankUrl("master_plus", true) },
  { value: "overall", label: "All Ranks", img: getS13RankUrl("all_ranks", true) },
  { value: "challenger", label: "Challenger", img: getS13RankUrl("challenger", true) },
  { value: "grandmaster", label: "Grandmaster", img: getS13RankUrl("grandmaster", true) },
  { value: "master", label: "Master", img: getS13RankUrl("master", true) },
  { value: "diamond", label: "Diamond", img: getS13RankUrl("diamond", true) },
  { value: "emerald", label: "Emerald", img: getS13RankUrl("emerald", true) },
  { value: "platinum", label: "Platinum", img: getS13RankUrl("platinum", true) },
  { value: "gold", label: "Gold", img: getS13RankUrl("gold", true) },
  { value: "silver", label: "Silver", img: getS13RankUrl("silver", true) },
  { value: "bronze", label: "Bronze", img: getS13RankUrl("bronze", true) },
  { value: "iron", label: "Iron", img: getS13RankUrl("iron", true) },
];

export const REGION_OPTIONS = [
  { value: "world", label: "World", img: getRegionUrl("world") },
  { value: "na1", label: "NA", img: getRegionUrl("NA") },
  { value: "euw1", label: "EUW", img: getRegionUrl("EUW") },
  { value: "kr", label: "KR", img: getRegionUrl("flags/kr"), flag: true },
  { value: "br1", label: "BR", img: getRegionUrl("flags/br"), flag: true },
  { value: "eun1", label: "EUN", img: getRegionUrl("EUN") },
  { value: "jp1", label: "JP", img: getRegionUrl("flags/jp"), flag: true },
  { value: "la1", label: "LAN", img: getRegionUrl("LAN") },
  { value: "la2", label: "LAS", img: getRegionUrl("LAS") },
  { value: "oc1", label: "OCE", img: getRegionUrl("OCE") },
  { value: "ru", label: "RU", img: getRegionUrl("flags/ru"), flag: true },
  { value: "tr1", label: "TR", img: getRegionUrl("flags/tr"), flag: true },
  { value: "ph2", label: "PH", img: getRegionUrl("PH") },
  { value: "sg2", label: "SG", img: getRegionUrl("SG") },
  { value: "th2", label: "TH", img: getRegionUrl("TH") },
  { value: "tw2", label: "TW", img: getRegionUrl("TW") },
  { value: "vn2", label: "VN", img: getRegionUrl("VN") },
  { value: "me1", label: "ME", img: getRegionUrl("ME") },
];

export const LEAGUE_SERIES_OPTIONS = [
  { value: "world", label: "World", img: getLeagueSeriesUrl("world-white") },
  { value: "lck", label: "KR", img: getLeagueSeriesUrl("LCK-white") },
  { value: "lpl", label: "CN", img: getLeagueSeriesUrl("LPL") },
  { value: "lec", label: "EU", img: getLeagueSeriesUrl("LEC-white") },
  { value: "lcs", label: "NA", img: getLeagueSeriesUrl("LCS-white") },
  { value: "lms", label: "TW", img: getLeagueSeriesUrl("LMS") },
  { value: "cblol", label: "BR", img: getLeagueSeriesUrl("CBLOL") },
  { value: "lcl", label: "CIS", img: getLeagueSeriesUrl("LCL") },
  { value: "lla", label: "LAT", img: getLeagueSeriesUrl("LLA") },
  { value: "lst", label: "SEA", img: getLeagueSeriesUrl("LST-white") },
  { value: "opl", label: "OCE", img: getLeagueSeriesUrl("OPL") },
  { value: "tcl", label: "TR", img: getLeagueSeriesUrl("TCL") },
  { value: "vcs", label: "VN", img: getLeagueSeriesUrl("VCS") },
  { value: "ljl", label: "JP", img: getLeagueSeriesUrl("LJL") },
];

export const ALL_CHAMPIONS_OPTIONS = [
  { value: "true", label: "true" },
  { value: "false", label: "false" },
];

export const TOGGLE_OPTIONS = [
  { value: "true", label: "true" },
  { value: "false", label: "false" },
];

// ------------------------------------
// TIER LIST
// ------------------------------------

export const TIER_LIST_QUEUE_TYPE_OPTIONS = [
  { value: "ranked_solo_5x5", label: "Ranked Solo" },
  // { value: "arurf", label: "ARURF" },
  // { value: "nexus_blitz", label: "Nexus Blitz" },
  { value: "normal_aram", label: "ARAM" },
  { value: "ranked_flex_sr", label: "Ranked Flex" },
  // { value: "normal_blind_5x5", label: "Normal Blind" },
  { value: "quickplay", label: "Quickplay" },
  { value: "normal_draft_5x5", label: "Normal Draft" },
];

export const TIER_LIST_ROLE_OPTIONS = [
  { value: "tier-list", label: "All Roles", img: getRoleSVG(ROLES.ALL), path: "/lol/tier-list" },
  {
    value: "top-lane-tier-list",
    label: "Top",
    img: getRoleSVG(ROLES.TOP),
    imgActive: getRoleSVG(ROLES.TOP, { active: true }),
    path: "/lol/top-lane-tier-list",
  },
  {
    value: "jungle-tier-list",
    label: "Jungle",
    img: getRoleSVG(ROLES.JUNGLE),
    imgActive: getRoleSVG(ROLES.JUNGLE, { active: true }),
    path: "/lol/jungle-tier-list",
  },
  {
    value: "mid-lane-tier-list",
    label: "Middle",
    img: getRoleSVG(ROLES.MIDDLE),
    imgActive: getRoleSVG(ROLES.MIDDLE, { active: true }),
    path: "/lol/mid-lane-tier-list",
  },
  {
    value: "adc-tier-list",
    label: "Bot",
    img: getRoleSVG(ROLES.BOTTOM),
    imgActive: getRoleSVG(ROLES.BOTTOM, { active: true }),
    path: "/lol/adc-tier-list",
  },
  {
    value: "support-tier-list",
    label: "Support",
    img: getRoleSVG(ROLES.SUPPORT),
    imgActive: getRoleSVG(ROLES.SUPPORT, { active: true }),
    path: "/lol/support-tier-list",
  },
];

export const TIER_LIST_OPTIONS = {
  queueType: TIER_LIST_QUEUE_TYPE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  allChampions: ALL_CHAMPIONS_OPTIONS,
  patch: { options: "latest", max: 5 },
  role: TIER_LIST_ROLE_OPTIONS,
};

export const TIER_LIST_DEFAULT = {
  queueType: TIER_LIST_OPTIONS.queueType[0].value,
  region: TIER_LIST_OPTIONS.region[0].value,
  rank: TIER_LIST_OPTIONS.rank[1].value,
  patch: TIER_LIST_OPTIONS.patch.options,
  allChampions: "false",
  role: TIER_LIST_OPTIONS.role[0].value,
};

// ------------------------------------
// DUO TIER LIST
// ------------------------------------

export const DUO_TIER_LIST_QUEUE = [
  { value: "ranked_solo_5x5", label: "Ranked Solo" },
  { value: "ranked_flex_sr", label: "Ranked Flex" },
];

const DUO_TIER_LIST_ROLE_OPTIONS = [
  { value: "adc_support", label: "Bot/Support" },
  { value: "middle_jungle", label: "Middle/Jungle" },
  { value: "top_jungle", label: "Top/Jungle" },
];

export const DUO_TIER_LIST_OPTIONS = {
  queueType: DUO_TIER_LIST_QUEUE,
  duo: DUO_TIER_LIST_ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  patch: { options: "latest", max: 5 },
};

export const DUO_TIER_LIST_DEFAULT = {
  queueType: DUO_TIER_LIST_OPTIONS.queueType[0].value,
  duo: DUO_TIER_LIST_OPTIONS.duo[0].value,
  rank: DUO_TIER_LIST_OPTIONS.rank[1].value,
  region: DUO_TIER_LIST_OPTIONS.region[0].value,
  patch: DUO_TIER_LIST_OPTIONS.patch.options,
};

// ------------------------------------
// COMBAT
// ------------------------------------

export const COMBAT_OPTIONS = {
  queueType: QUEUE_TYPE_OPTIONS,
  role: ALL_ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  allChampions: ALL_CHAMPIONS_OPTIONS,
  patch: { options: "latest", max: 5 },
};

export const COMBAT_DEFAULT = {
  queueType: COMBAT_OPTIONS.queueType[0].value,
  role: COMBAT_OPTIONS.role[0].value,
  region: COMBAT_OPTIONS.region[0].value,
  rank: COMBAT_OPTIONS.rank[1].value,
  patch: COMBAT_OPTIONS.patch.options,
  allChampions: "false",
};

// ------------------------------------
// OBJECTIVES
// ------------------------------------

export const OBJECTIVES_OPTIONS = {
  queueType: QUEUE_TYPE_OPTIONS,
  role: ALL_ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  allChampions: ALL_CHAMPIONS_OPTIONS,
  patch: { options: "latest", max: 5 },
};

export const OBJECTIVES_DEFAULT = {
  queueType: OBJECTIVES_OPTIONS.queueType[0].value,
  role: OBJECTIVES_OPTIONS.role[0].value,
  region: OBJECTIVES_OPTIONS.region[0].value,
  rank: OBJECTIVES_OPTIONS.rank[1].value,
  patch: OBJECTIVES_OPTIONS.patch.options,
  allChampions: "false",
};

// ------------------------------------
// OVERVIEW (BUILD)
// ------------------------------------

export const TEST_ROLE_OPTIONS = [
  { value: ROLES.TOP, label: "Top", img: getRoleSVG(ROLES.TOP), imgActive: getRoleSVG(ROLES.TOP, { active: true }) },
  { value: ROLES.JUNGLE, label: "Jungle", img: getRoleSVG(ROLES.JUNGLE), imgActive: getRoleSVG(ROLES.JUNGLE, { active: true }) },
  { value: "mid", label: "Middle", img: getRoleSVG(ROLES.MIDDLE), imgActive: getRoleSVG(ROLES.MIDDLE, { active: true }) },
  { value: ROLES.BOTTOM, label: "Bot", img: getRoleSVG(ROLES.BOTTOM), imgActive: getRoleSVG(ROLES.BOTTOM, { active: true }) },
  {
    value: ROLES.SUPPORT,
    label: "Support",
    img: getRoleSVG(ROLES.SUPPORT),
    imgActive: getRoleSVG(ROLES.SUPPORT, { active: true }),
  },
];

export const TEST_ALL_ROLE_OPTIONS = [
  { value: ROLES.ALL, label: "All Roles", img: getRoleSVG(ROLES.ALL) },
  { value: ROLES.TOP, label: "Top", img: getRoleSVG(ROLES.TOP), imgActive: getRoleSVG(ROLES.TOP, { active: true }) },
  { value: ROLES.JUNGLE, label: "Jungle", img: getRoleSVG(ROLES.JUNGLE), imgActive: getRoleSVG(ROLES.JUNGLE, { active: true }) },
  { value: "mid", label: "Middle", img: getRoleSVG(ROLES.MIDDLE), imgActive: getRoleSVG(ROLES.MIDDLE, { active: true }) },
  { value: ROLES.BOTTOM, label: "Bot", img: getRoleSVG(ROLES.BOTTOM), imgActive: getRoleSVG(ROLES.BOTTOM, { active: true }) },
  {
    value: ROLES.SUPPORT,
    label: "Support",
    img: getRoleSVG(ROLES.SUPPORT),
    imgActive: getRoleSVG(ROLES.SUPPORT, { active: true }),
  },
];

export const OVERVIEW_OPTIONS = {
  queueType: QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  patch: { options: "latest", max: 5 },
};

export const OVERVIEW_DEFAULT = {
  queueType: QUEUE_TYPE_OPTIONS[0].value,
  region: REGION_OPTIONS[0].value,
  rank: RANK_OPTIONS_WITH_D2[1].value,
  patch: OVERVIEW_OPTIONS.patch.options,
  opp: "allChampions",
};

// ------------------------------------
//  ITEMS, SPELLS, RUNES
// ------------------------------------

export const ADV_BUILD_OPTIONS = {
  queueType: QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  patch: { options: "latest", max: 5 },
};

export const ADV_BUILD_DEFAULT = {
  queueType: ADV_BUILD_OPTIONS.queueType[0].value,
  region: ADV_BUILD_OPTIONS.region[0].value,
  rank: ADV_BUILD_OPTIONS.rank[1].value,
  patch: ADV_BUILD_OPTIONS.patch.options,
};

// ------------------------------------
//  ITEMS
// ------------------------------------

export const ITEMS_OPTIONS = {
  queueType: QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  patch: { options: "latest", max: 5 },
};

export const ITEMS_DEFAULT = {
  queueType: ITEMS_OPTIONS.queueType[0].value,
  region: ITEMS_OPTIONS.region[0].value,
  rank: ITEMS_OPTIONS.rank[1].value,
  patch: ITEMS_OPTIONS.patch.options,
};

// ------------------------------------
//  RUNES
// ------------------------------------

export const RUNES_OPTIONS = {
  queueType: QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  patch: { options: "latest", max: 5 },
};

export const RUNES_DEFAULT = {
  queueType: RUNES_OPTIONS.queueType[0].value,
  region: RUNES_OPTIONS.region[0].value,
  rank: RUNES_OPTIONS.rank[1].value,
  patch: RUNES_OPTIONS.patch.options,
};

// ------------------------------------
//  MATCHUPS
// ------------------------------------

export const MATCHUPS_QUEUE_TYPE_OPTIONS = [
  { value: "ranked_solo_5x5", label: "Ranked Solo" },
  { value: "ranked_flex_sr", label: "Ranked Flex" },
];

export const MATCHUPS_OPTIONS = {
  queueType: MATCHUPS_QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  allChampions: ALL_CHAMPIONS_OPTIONS,
  patch: { options: "latest", max: 5 },
};

export const MATCHUPS_DEFAULT = {
  queueType: MATCHUPS_OPTIONS.queueType[0].value,
  region: MATCHUPS_OPTIONS.region[0].value,
  rank: MATCHUPS_OPTIONS.rank[1].value,
  patch: MATCHUPS_OPTIONS.patch.options,
  allChampions: "false",
};

// ------------------------------------
//  COUNTERS
// ------------------------------------

export const COUNTERS_QUEUE_TYPE_OPTIONS = [
  { value: "ranked_solo_5x5", label: "Ranked Solo" },
  { value: "ranked_flex_sr", label: "Ranked Flex" },
];

export const COUNTERS_OPTIONS = {
  queueType: COUNTERS_QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  patch: { options: "latest", max: 5 },
};

export const COUNTERS_DEFAULT = {
  queueType: COUNTERS_OPTIONS.queueType[0].value,
  region: COUNTERS_OPTIONS.region[0].value,
  rank: COUNTERS_OPTIONS.rank[1].value,
  patch: COUNTERS_OPTIONS.patch.options,
};

// ------------------------------------
//  CHAMPION DUOS
// ------------------------------------

export const DUOS_QUEUE_TYPE_OPTIONS = [
  { value: "ranked_solo_5x5", label: "Ranked Solo" },
  { value: "ranked_flex_sr", label: "Ranked Flex" },
];

export const DUOS_OPTIONS = {
  queueType: DUOS_QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  allChampions: ALL_CHAMPIONS_OPTIONS,
  patch: { options: "latest", max: 5 },
};

export const DUOS_DEFAULT = {
  queueType: DUOS_OPTIONS.queueType[0].value,
  region: DUOS_OPTIONS.region[0].value,
  rank: DUOS_OPTIONS.rank[1].value,
  patch: DUOS_OPTIONS.patch.options,
  allChampions: "false",
};

export const WORLDS_PROBUILDS_OPTIONS = {
  role: ALL_ROLE_OPTIONS,
  league: LEAGUE_SERIES_OPTIONS,
};

export const WORLDS_PROBUILDS_DEFAULT = {
  role: ALL_ROLE_OPTIONS[0].value,
  league: LEAGUE_SERIES_OPTIONS[0].value,
  league2: null,
};
