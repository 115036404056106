import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export default class ButtonSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      init: false,
    };

    this.successButton = React.createRef();
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  componentDidMount() {
    this.setState({ init: true });
    if (this.props.bForceSubmitOnEnter) {
      document.addEventListener("keydown", this.handleKeyPress, false);
    }
  }

  componentWillUnMount() {
    if (this.props.bForceSubmitOnEnter) {
      document.removeEventListener("keydown", this.handleKeyPress, false);
    }
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      if (this.successButton && this.successButton.current) {
        this.successButton.current.click();
      }
    }
  }

  onClickHandler(e) {
    const { disable, onClick } = this.props;
    if (!disable && onClick) {
      onClick(e);
    }
  }

  render() {
    const { init } = this.state;
    const { className, label, disable, isLoading, success, bForceSubmitOnEnter, ...otherProps } = this.props;

    const buttonClassNames = classNames(className, "btn-success", {
      "btn-success_disable": disable,
      "btn-success_loading": init && isLoading,
      "btn-success_toggle": init && success,
    });

    return (
      <div className={buttonClassNames} {...otherProps} ref={this.successButton} onClick={this.onClickHandler}>
        <img className="btn-checkmark" src={"https://static.bigbrain.gg/assets/ugg/icons/checkmark-white.svg"} alt="Checkmark" />
        {isLoading ? (
          <img className="btn-loader" src={"https://static.bigbrain.gg/assets/ugg/icons/spinny-boy.svg"} alt="Loader" />
        ) : (
          <div className="btn-label">{label}</div>
        )}
      </div>
    );
  }
}

ButtonSuccess.defaultProps = {
  className: "",
  label: "",
  disable: false,
  isLoading: false,
  success: false,
  bForceSubmitOnEnter: false,
};
