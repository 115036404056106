import classNames from "classnames";
import { useMemo } from "react";
import { twMerge, extendTailwindMerge, getDefaultConfig } from "tailwind-merge";

export type ColorSchemeDefinition = Partial<Record<string, classNames.Argument>>;

export type ClassNameFunction<P> = (args: P) => string;

export type ColorSchemeProp<T extends ColorSchemeDefinition, P extends string = never> = {
  classes: T;
  common?: classNames.Argument;
  scheme?: keyof T | P;
  default: keyof T | P;
};

export function useColorScheme<T extends ColorSchemeDefinition, P extends string = never>({
  classes,
  className = "",
  scheme,
  common,
  ...rest
}: ColorSchemeProp<T, P> & { className?: string }) {
  const schemeClassName = useMemo(() => {
    const schemeClasses = getSchemeClasses(classes, scheme, rest.default);
    return twMerge(classNames(schemeClasses, common, className));
  }, [classes, scheme, className, rest]);
  return schemeClassName;
}

export function useColorSchemeFn<T extends ColorSchemeDefinition, P = any, K extends string = never>({
  classes,
  className = "",
  scheme,
  common,
  ...rest
}: ColorSchemeProp<T, K> & { className?: string | ClassNameFunction<P> }) {
  const schemeClassName = useMemo(() => {
    const schemeClasses = getSchemeClasses(classes, scheme, rest.default);
    if (typeof className === "function") {
      return (...args: Parameters<typeof className>) => twMerge(classNames(schemeClasses, common, className(...args)));
    }
    return twMerge(classNames(schemeClasses, common, className));
  }, [classes, scheme, className]);
  return schemeClassName;
}

function getSchemeClasses<T extends ColorSchemeDefinition, K extends string | undefined = never>(
  classes: T,
  scheme: keyof T | K,
  defaultScheme: keyof T | K,
) {
  if (scheme && Object.keys(classes).some((k) => scheme == k)) {
    return classes[scheme];
  } else if (defaultScheme && Object.keys(classes).some((k) => defaultScheme == k)) {
    return classes[defaultScheme];
  }

  return "";
}
