import React from "react";

const name_map = {
  adblock: [0, 520],
  "adblock-p": [0, 930],
  disconnect: [0, 1310],
  ublock: [0, 1730],
};

const introDesc =
  `Select your adblocker from the list below and follow the instructions to whitelist U.GG. ` +
  `Don't see the application or extension you're using? Please refer to your adblocker's website for more ` +
  `information on how to whitelist us.`;

const ad = [
  <p key={1}>
    Click on the Adblock application icon in the top right corner of your browser or just to the left of the site's URL.
  </p>,
  <p key={2}>
    Within the dropdown menu, click on <b>"Don’t run on pages on this site"</b> or "Don't run on pages on this domain."
  </p>,
  <p key={3}>
    When the pop-up appears, click <b>"Exclude."</b>
  </p>,
  <p key={4}>The page will reload, and you’re good to go!</p>,
];

const adp = [
  <p key={1}>
    Click on the Adblock Plus application icon in the top right corner of your browser or just to the left of the site's URL.
  </p>,
  <p key={2}>
    When the pop-up appears, click on <b>"Enabled on this site."</b>
  </p>,
  <p key={3}>
    It will switch to say <b>"Disabled on this site."</b>
  </p>,
  <p key={4}>Reload the page and you’re good to go!</p>,
];

const dis = [
  <p key={1}>
    Click on the Disconnect application icon in the top right corner of your browser or just to the left of the site's URL.
  </p>,
  <p key={2}>
    Within the dropdown menu, about halfway down the pop-up after the <b>"Content"</b> list item, you will see{" "}
    <b>"Whitelist site."</b>
  </p>,
  <p key={3}>
    Click on those words – <b>"Whitelist site."</b>
  </p>,
  <p key={4}>Reload the page and you’re good to go!</p>,
];

const ub = [
  <p key={1}>
    Click on the uBlock/uBlock Origin application icon in the top right corner of your browser or just to the left of the site's
    URL.
  </p>,
  <p key={2}>
    When the pop-up appears, click on the <b>large blue power icon</b> at the top of the pop-up.
  </p>,
  <p key={3}>
    When it turns gray, click the <b>refresh icon</b> that has appeared.
  </p>,
  <p key={4}>It will reload the page and you’re good to go!</p>,
];

const Instructions = (props) => {
  if (props.list) {
    return (
      <div className="list">
        {props.list.map((el, index) => (
          <div className="item" key={index}>
            <div className="num">{index + 1}.</div>
            <div className="inst">{el}</div>
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
};

const AdWhitelist = (props) => {
  const scroll = (e, name) => {
    window.scrollTo(name_map[name][0], name_map[name][1]);
  };

  return (
    <div className="ad-whitelist-container">
      <div className="intro">
        <div className="title">How to Whitelist U.GG</div>
        <div className="desc">{introDesc}</div>
        <div className="ad-blocker-list">
          <div
            className="blocker"
            onClick={(e) => {
              scroll(e, "adblock");
            }}
          >
            Adblock
          </div>
          <div
            className="blocker"
            onClick={(e) => {
              scroll(e, "adblock-p");
            }}
          >
            Adblock Plus
          </div>
          <div
            className="blocker"
            onClick={(e) => {
              scroll(e, "disconnect");
            }}
          >
            Disconnect
          </div>
          <div
            className="blocker"
            onClick={(e) => {
              scroll(e, "ublock");
            }}
          >
            uBlock Origin/uBlock
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="adblock">
        <div className="title">Adblock</div>
        <Instructions list={ad} />
      </div>
      <div className="adblock">
        <div className="title">Adblock Plus</div>
        <Instructions list={adp} />
      </div>
      <div className="adblock">
        <div className="title">Disconnect</div>
        <Instructions list={dis} />
      </div>
      <div className="adblock">
        <div className="title">uBlock Origin/uBlock</div>
        <Instructions list={ub} />
      </div>
    </div>
  );
};

export default AdWhitelist;
