import { REGION_OPTIONS, RANK_OPTIONS_WITH_D2 } from "../common";
import { RoleS } from "../../../utils/role-helpers";

// ------------------------------------
// DUO TIER LIST
// ------------------------------------

export const DUO_TIER_LIST_QUEUE = [
  { value: "ranked_solo_5x5", label: "Ranked Solo" },
  { value: "ranked_flex_sr", label: "Ranked Flex" },
];

export const DUO_TIER_LIST_ROLE_OPTIONS = [
  { value: `${RoleS.BOTTOM}_${RoleS.SUPPORT}`, label: "Bot/Support" },
  { value: `${RoleS.MIDDLE}_${RoleS.JUNGLE}`, label: "Middle/Jungle" },
  { value: `${RoleS.TOP}_${RoleS.JUNGLE}`, label: "Top/Jungle" },
];

export const DUO_TIER_LIST_OPTIONS = {
  queueType: DUO_TIER_LIST_QUEUE,
  duo: DUO_TIER_LIST_ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  patch: { options: "latest", max: 5 },
};

export const DUO_TIER_LIST_DEFAULT = {
  queueType: DUO_TIER_LIST_OPTIONS.queueType[0].value,
  duo: DUO_TIER_LIST_OPTIONS.duo[0].value,
  rank: DUO_TIER_LIST_OPTIONS.rank[1].value,
  region: DUO_TIER_LIST_OPTIONS.region[0].value,
  patch: DUO_TIER_LIST_OPTIONS.patch.options,
};
