import * as React from "react";
import type { SVGProps } from "react";
export const SvgGameWowGrey = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 29.247c7.316 0 13.247-5.93 13.247-13.247 0-7.316-5.93-13.247-13.247-13.247C8.684 2.753 2.753 8.683 2.753 16c0 7.316 5.93 13.247 13.247 13.247M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M2.064 3.9v4.473L6.308 3.9zM29.82 28.444v-4.473l-4.243 4.473zM29.82 3.9v4.473L25.578 3.9zM2.064 28.444v-4.473l4.244 4.473zM6.079 7.914h5.276l-.115 2.236 2.122 10.094 2.81-11.183 2.867 11.183 1.893-11.126v-.516c-.057-.191-.218-.596-.401-.688h5.39c-.497.707-1.537 2.351-1.72 3.269-.184.917-1.568 9.29-2.237 13.362v.459c.172.516.55 1.548.688 1.548H17.09c.153-.325.413-1.239.23-2.294-.184-1.055-.766-3.651-1.033-4.817a699 699 0 0 0-1.09 5.104c-.137.688.172 1.625.344 2.007h-5.62c.872-.826.975-1.682.918-2.007C9.73 19.88 7.467 10.357 7.283 9.577c-.183-.78-.88-1.434-1.204-1.663"
    />
  </svg>
);
