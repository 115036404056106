import * as React from "react";
import uuid from "uuid/v4";

const SvgComponent = (props) => {
  const id1 = React.useRef(uuid());
  const id2 = React.useRef(uuid());
  const id3 = React.useRef(uuid());
  const id4 = React.useRef(uuid());

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path fill={`url(#${id1.current})`} d="M9.485 2.083H4.472L5.79 3.93v12.137l-1.583 1.847h11.345l1.847-2.639H9.485V2.083Z" />
      <path
        fill={`url(#${id2.current})`}
        d="M4.736 5.356a7.364 7.364 0 0 0-2.111 5.17c0 2.008.801 3.83 2.101 5.161l.01-.01v-1.65a6.303 6.303 0 0 1-1.056-3.5c0-1.295.389-2.5 1.056-3.502V5.356Z"
      />
      <path
        fill={`url(#${id3.current})`}
        d="M15.156 14.22A6.333 6.333 0 0 0 10.54 4.216V3.157a7.388 7.388 0 0 1 5.872 11.063h-1.256Z"
      />
      <path fill={`url(#${id4.current})`} d="M13.78 14.22a5.277 5.277 0 0 0-3.24-8.944v8.944h3.24Z" />
      <defs>
        <linearGradient id={id1.current} x1={0.852} x2={15.419} y1={17.914} y2={0.433} gradientUnits="userSpaceOnUse">
          <stop stopColor="#AF893D" />
          <stop offset={0.234} stopColor="#D29D40" />
          <stop offset={0.531} stopColor="#FFCC74" />
          <stop offset={0.823} stopColor="#E8B55D" />
        </linearGradient>
        <linearGradient id={id2.current} x1={0.852} x2={15.419} y1={17.914} y2={0.433} gradientUnits="userSpaceOnUse">
          <stop stopColor="#AF893D" />
          <stop offset={0.234} stopColor="#D29D40" />
          <stop offset={0.531} stopColor="#FFCC74" />
          <stop offset={0.823} stopColor="#E8B55D" />
        </linearGradient>
        <linearGradient id={id3.current} x1={0.852} x2={15.419} y1={17.914} y2={0.433} gradientUnits="userSpaceOnUse">
          <stop stopColor="#AF893D" />
          <stop offset={0.234} stopColor="#D29D40" />
          <stop offset={0.531} stopColor="#FFCC74" />
          <stop offset={0.823} stopColor="#E8B55D" />
        </linearGradient>
        <radialGradient
          id={id4.current}
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(-47.911 19.69 -1.188) scale(11.0214)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.119} stopColor="#117D8D" />
          <stop offset={0.642} stopColor="#2FEBEB" />
          <stop offset={0.866} stopColor="#117D8D" />
        </radialGradient>
      </defs>
    </svg>
  );
};
export default SvgComponent;
