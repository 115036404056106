import { useJSONFetcher } from "@outplayed/json-fetcher";
import { RAC_getWorkingPatch, RAC_comparePatch, normalizeToRiotPatch } from "./patches";
import { getJSON } from "../common";
import {
  getRunesReforgedData,
  getStatShardData,
  uggGetRuneImg,
  getCustomRunesImg,
  uggGetStatShardImg,
} from "../static-url-helpers";

export function RAC_useRiotRunes(versions) {
  return ({ patch, language, skip, ssr, onCompleted } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const runesURL = getRunesReforgedData(riotPatch, language);

    return useJSONFetcher(runesURL, { skip, ssr, onCompleted });
  };
}

const runesCache = {};
export function RAC_getRunes(versions) {
  return ({ patch, language } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const cacheKey = `${riotPatch}_${language}`;
    if (!(cacheKey in runesCache)) {
      let url = getRunesReforgedData(riotPatch, language);
      const runes = getJSON(url);
      if (!runes) return null;

      runesCache[cacheKey] = runes;
    }
    return runesCache[cacheKey];
  };
}

export function RAC_getRuneJSON(versions) {
  return (id, { patch, language, optionalData } = {}) => {
    const curPatch = RAC_getWorkingPatch(versions)(patch);
    const runes = optionalData || RAC_getRunes(versions)({ patch: curPatch, language });
    if (!runes) return null;

    for (let i = 0; i < runes.length; i++) {
      if (runes[i].id === Number(id)) {
        return {
          ...runes[i],
          type: "tree",
        };
      }

      const { slots } = runes[i];
      for (let j = 0; j < slots.length; j++) {
        const perks = slots[j].runes;

        for (let k = 0; k < perks.length; k++) {
          if (perks[k].id === Number(id))
            return {
              ...perks[k],
              type: j === 0 ? "keystone" : "rune",
            };
        }
      }
    }

    return null;
  };
}

// Specific use case (Runes Table page)
export function RAC_getRunesList(versions) {
  return ({ patch, optionalData } = {}) => {
    const curPatch = RAC_getWorkingPatch(versions)(patch);
    const runes = optionalData || RAC_getRunes(versions)({ patch: curPatch });
    if (!runes) return null;

    const paths = {};
    runes.forEach((path) => {
      const [keystones, runes_1, runes_2, runes_3] = path.slots.map((set) => {
        return set.runes.map((rune) => rune.id);
      });
      paths[path.id] = {
        name: path.name,
        keystones,
        runes_1,
        runes_2,
        runes_3,
      };
    });

    return paths;
  };
}

const runeImgCache = {};
export function RAC_getRuneImg(versions) {
  return (id, { patch, optionalData, webp } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const cacheKey = `${id}_${riotPatch}`;
    const runes = optionalData || RAC_getRunes(versions)({ patch: riotPatch });
    if (!runes) return "";
    if (!optionalData && runeImgCache[cacheKey]) return runeImgCache[cacheKey];

    for (let path of runes) {
      if (path.id === Number(id)) {
        // use custom small runes
        const img = getCustomRunesImg(Number(id));
        if (!optionalData) {
          runeImgCache[cacheKey] = img;
        }
        return img;
      } else {
        const perks = path.slots;
        for (let runes of perks) {
          for (let rune of runes.runes) {
            if (rune.id === Number(id)) {
              const img = uggGetRuneImg(riotPatch, rune.icon, { webp });
              if (!optionalData) {
                runeImgCache[cacheKey] = img;
              }
              return img;
            }
          }
        }
      }
    }
    return "";
  };
}

export function RAC_getRuneKeystoneId(versions) {
  return (perks, { patch, optionalData } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const runes = optionalData || RAC_getRunes(versions)({ patch: riotPatch });
    if (!runes) return null;

    const keystones = [];
    runes.forEach((path) => keystones.push(...path.slots[0].runes));
    const foundKeystone = keystones.find((keystone) => (perks || []).includes(parseInt(keystone.id)));
    return foundKeystone ? foundKeystone.id : null;
  };
}

function findValidStatShardData(versions, patch, data) {
  if (!data) return null;

  const riotPatch = RAC_getWorkingPatch(versions)(patch);
  const entries = Object.entries(data);
  const validStatShards = entries.filter(([patch]) => !!normalizeToRiotPatch(patch, versions));
  validStatShards.sort((a, b) => RAC_comparePatch(versions)(b[0], a[0]));
  for (let [patch, json] of validStatShards) {
    if (RAC_comparePatch(versions)(riotPatch, patch) >= 0) {
      return [patch, json];
    }
  }
  return null;
}

export function RAC_useStatShards(staging, versions) {
  return ({ patch, skip, ssr, onCompleted } = {}) => {
    const runesURL = getStatShardData(staging);

    const res = useJSONFetcher(runesURL, { skip, ssr, onCompleted });
    const data = findValidStatShardData(versions, patch, res.data);
    return { ...res, data };
  };
}

const statShardsCache = {};
export function RAC_getStatShards(staging, versions) {
  return ({ patch } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const cacheKey = `${riotPatch}`;
    if (!(cacheKey in statShardsCache)) {
      let url = getStatShardData(staging);
      const json = getJSON(url);
      const statShards = findValidStatShardData(versions, patch, json);
      if (!statShards) return null;

      statShardsCache[cacheKey] = statShards;
    }
    return statShardsCache[cacheKey];
  };
}

export function getStatShardJSON(data, row, id) {
  if (!data) return null;

  if (row === undefined) {
    for (const currentRow of data) {
      for (const shard of currentRow.shards) {
        if (shard.id === Number(id)) {
          return shard;
        }
      }
    }
    return null;
  }

  const rowData = data[row];
  return rowData?.shards.find((shard) => shard.id === Number(id));
}

const statShardImgCache = {};
export function RAC_getStatShardImg(staging, versions) {
  return (id, { patch, optionalData, webp } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const cacheKey = `${id}_${riotPatch}`;
    const statShards = optionalData || RAC_getStatShards(staging, versions)({ patch: riotPatch });
    if (!statShards) return "";
    if (!optionalData && statShardImgCache[cacheKey]) return statShardImgCache[cacheKey];

    const [statShardpatch, shards] = statShards;
    const validPatch = RAC_getWorkingPatch(versions)(statShardpatch);
    for (let row of shards) {
      for (let shard of row.shards) {
        if (shard.id === Number(id)) {
          const img = uggGetStatShardImg(validPatch, shard.img, { webp });
          if (!optionalData) {
            statShardImgCache[cacheKey] = img;
          }
          return img;
        }
      }
    }
    return "";
  };
}
