import { useLocation } from "react-router-dom";

export const SPECIAL_GAME_MODES = {
  ARENA: {
    active: false,
    newTag: true,
    urlName: "arena",
    queueType: "arena",
    buildPageName: "overview_arena",
    tierListPageName: "arena-tier-list",
    queueTypeName: "Arena",
    seo: {
      h1Suffix: "Arena Build",
      blurbType: "Arena",
      build: "champion_overview_arena",
    },
    uggApi: {
      hasRank: false,
      hasRole: false,
    },
    tierListSettings: {
      title: { main: "Arena Tier List" },
      showAgainst: false,
      showBan: true,
      showRole: false,
      showTier: true,
      filterProps: {
        disable: ["role", "rank", "queueType"],
      },
    },
    filterProps: {
      disable: ["queueType"],
    },
  },
  ARENA_DUO: {
    active: false,
    newTag: true,
    urlName: "arena-duo",
    queueType: "arena-duo",
    buildPageName: "overview_arena",
    tierListPageName: "arena-duo-tier-list",
    queueTypeName: "Arena Duos",
    seo: {
      h1Suffix: "Arena Build",
      blurbType: "Arena",
      build: "champion_overview_arena",
    },
    uggApi: {
      hasRank: false,
      hasRole: false,
    },
    tierListSettings: {
      title: { main: "Arena Duos" },
      showAgainst: false,
      showBan: false,
      showRole: false,
      showTier: true,
      filterProps: {
        disable: ["role", "rank", "queueType"],
      },
    },
  },
  ARURF: {
    active: false,
    newTag: false,
    urlName: "arurf",
    queueType: "arurf",
    buildPageName: "overview_arurf",
    tierListPageName: "tier-list",
    queueTypeName: "ARURF",
    seo: {
      h1Suffix: "ARURF Build & Runes",
      blurbType: "ARURF",
      build: "champion_overview_arurf",
    },
    uggApi: {
      hasRank: false,
      hasRole: false,
    },
    tierListSettings: {
      title: { main: "ARURF Tier List" },
      showAgainst: false,
      showBan: false,
      showRole: false,
      showTier: true,
      filterProps: {
        disable: ["role", "rank", "queueType"],
      },
    },
    filterProps: {
      disable: ["queueType"],
    },
  },
  URF: {
    active: false,
    newTag: false,
    urlName: "urf",
    queueType: "pick_urf",
    buildPageName: "overview_urf",
    tierListPageName: "tier-list",
    queueTypeName: "URF",
    seo: {
      h1Suffix: "URF Build & Runes",
      blurbType: "URF",
      build: "champion_overview_urf",
    },
    uggApi: {
      hasRank: false,
      hasRole: false,
    },
    tierListSettings: {
      title: { main: "URF Tier List" },
      showAgainst: false,
      showBan: false,
      showRole: false,
      showTier: true,
      filterProps: {
        disable: ["role", "rank", "queueType"],
      },
    },
    filterProps: {
      disable: ["queueType"],
    },
  },
  NEXUS_BLITZ: {
    active: false,
    newTag: false,
    urlName: "nexus-blitz",
    queueType: "nexus_blitz",
    buildPageName: "overview_nexus_blitz",
    tierListPageName: "nexus-blitz-tier-list",
    queueTypeName: "Nexus Blitz",
    seo: {
      h1Suffix: "Nexus Blitz Build & Runes",
      blurbType: "Nexus Blitz",
      build: "champion_overview_nexus_blitz",
    },
    uggApi: {
      hasRank: false,
      hasRole: true,
    },
    tierListSettings: {
      title: { main: "Nexus Blitz Tier List" },
      showAgainst: false,
      showBan: true,
      showRole: true,
      showTier: true,
      filterProps: {
        disable: ["rank", "queueType"],
      },
    },
    filterProps: {
      disable: ["queueType"],
    },
  },
  ONE_FOR_ALL: {
    active: true,
    newTag: false,
    urlName: "one-for-all",
    queueType: "one_for_all",
    buildPageName: "overview_one_for_all",
    tierListPageName: "tier-list",
    queueTypeName: "One for All",
    seo: {
      h1Suffix: "One for All Build & Runes for",
      blurbType: "One for All",
      build: "champion_overview_one_for_all",
    },
    uggApi: {
      hasRank: false,
      hasRole: true,
    },
    tierListSettings: {
      title: { main: "One for All Tier List" },
      showAgainst: true,
      showBan: true,
      showRole: false,
      showTier: true,
      filterProps: {
        disable: ["rank", "role", "queueType"],
      },
    },
    filterProps: {
      disable: ["queueType"],
    },
  },
  ARAM: {
    active: true,
    newTag: false,
    urlName: "aram",
    queueType: "normal_aram",
    buildPageName: "overview_aram",
    tierListPageName: "tier-list",
    queueTypeName: "ARAM",
    seo: {
      h1Suffix: "ARAM Builds & Runes",
      blurbType: "ARAM",
      build: "champion_overview_aram",
    },
    uggApi: {
      hasRank: false,
      hasRole: false,
    },
    tierListSettings: {
      title: { main: "ARAM Tier List" },
      showAgainst: false,
      showBan: false,
      showRole: false,
      showTier: true,
      filterProps: {
        disable: ["rank", "role"],
      },
    },
  },
};

export function getSpecialGameModeTierListSettings(queueType) {
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (queueType === value.queueType) {
      return value.tierListSettings;
    }
  }
}

export function getSpecialGameModeUggApiSettings(queueType) {
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (queueType === value.queueType) {
      return value.uggApi;
    }
  }
}

export function getSpecialGameModeTierListTitles() {
  let titles = {};
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    titles[`${value.urlName}-tier-list`] = value.tierListSettings.title;
  }
  return titles;
}

export function getSpecialAbsolutePage(page) {
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (page === `${value.urlName}-tier-list`) {
      return value.tierListPageName;
    }
  }
  return null;
}

export function getSpecialBlurbType(page) {
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (page === value.buildPageName) {
      return value.seo.blurbType;
    }
  }
  return null;
}

export function getSpecialUrlName() {
  let urlNames = [];
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    urlNames.push(value.urlName);
  }
  return urlNames;
}

export function getSpecialSSRPage(page) {
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (page === value.buildPageName) {
      return true;
    }
  }
  return null;
}

// export function getSpecialGameModePaths(normalizedChampionName) {
//   let paths = [];

//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (value.active) {
//       paths.push({
//         path: `/lol/champions/${value.urlName}/${normalizedChampionName}-${value.urlName}`,
//         label: value.queueTypeName,
//         new: value.newTag,
//         activePaths: [
//           `/lol/champions/${value.urlName}/${normalizedChampionName}-${value.urlName}`,
//         ],
//       });
//     }
//   }
//   return paths;
// }

export function getSpecialGameModePaths(normalizedChampionName, livePaths) {
  let paths = [];

  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (livePaths?.includes(value.queueType)) {
      const urlName = value.urlName;
      const urlName2 = value.queueType === "arena" ? "arena-build" : urlName;
      const path =
        normalizedChampionName !== "kayn"
          ? `/lol/champions/${urlName}/${normalizedChampionName}-${urlName2}`
          : `/lol/champions/${urlName}/red/${normalizedChampionName}-${urlName2}`;
      paths.push({
        path: path,
        label: value.queueTypeName,
        new: value.newTag,
        activePaths: [`/lol/champions/${urlName}/${normalizedChampionName}-${urlName2}`],
      });
    }
  }
  return paths;
}

export function getSpecialTierLists(includedLolPrefix = true) {
  let tierLists = [];
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (["arena-tier-list", "arena-duo-tier-list"].includes(value.tierListPageName)) {
      continue;
    } else if (includedLolPrefix) {
      tierLists.push(`/lol/${value.urlName}-tier-list`);
    } else {
      tierLists.push(`${value.urlName}-tier-list`);
    }
  }
  return tierLists;
}

// export function getSpecialGameModeTierListPaths() {
//   let paths = [];
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (value.active) {
//       paths.push({
//         path: `/lol/${value.urlName}-tier-list`,
//         label: `${value.queueTypeName} Tier List`,
//         new: value.newTag,
//         activePaths: [`/lol/${value.urlName}-tier-list`],
//       });
//     }
//   }
//   return paths;
// }

export function getSpecialGameModeTierListPaths(livePaths) {
  let paths = [];
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (livePaths.includes(value.queueType)) {
      paths.push({
        path: `/lol/${value.urlName}-tier-list`,
        label: `${value.queueTypeName}${value.urlName !== "arena-duo" ? " Tier List" : ""}`,
        new: value.newTag,
        activePaths: [`/lol/${value.urlName}-tier-list`],
      });
    }
  }
  return paths;
}

export function getSpecialPageH1Suffix(page) {
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (page === value.buildPageName) {
      return value.seo.h1Suffix;
    }
  }
  return null;
}

export function getSpecialGameModeOverviewPage(pathname) {
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (pathname.includes(`champions/${value.urlName}`)) {
      return value.buildPageName;
    }
  }
  return false;
}

export function getSpecialGameModeSeoName(pathname) {
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (pathname.includes(`champions/${value.urlName}`)) {
      return value.seo.build;
    }
  }
  return false;
}

export function getSpecialGameModeSeoNameFromPage(page) {
  for (let [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (page === value.buildPageName) {
      return value.seo.build;
    }
  }
}

export function getSpecialGameModeFilterProps(page) {
  for (let [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (page === value.buildPageName) {
      let filterProps = {
        ...(value?.filterProps || {}),
        collapsable: false,
        overrideDefaultFilters: {
          queueType: value.queueType,
        },
      };
      return filterProps;
    }
  }
}

export function getSpecialGameModeStatsTablesFilterProps(queue_type_tier_list_page) {
  for (let [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (queue_type_tier_list_page === `${value.urlName}-tier-list` && queue_type_tier_list_page === "nexus-blitz-tier-list") {
      let filterProps = {
        overrideDefaultFilters: {
          queueType: value.queueType,
        },
        page: "nexus-blitz-tier-list",
        enable: [],
        disable: value.tierListSettings.filterProps.disable,
        allRoles: true,
        collapsable: false,
      };
      return filterProps;
    } else if (queue_type_tier_list_page === `${value.urlName}-tier-list`) {
      let filterProps = {
        overrideDefaultFilters: {
          queueType: value.queueType,
        },
        page: "tier-list",
        enable: [],
        disable: value.tierListSettings.filterProps.disable,
        allRoles: false,
        collapsable: false,
      };
      return filterProps;
    }
  }
}

export function getQueryParams(pathname, queryParams) {
  let newQueryParams;
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (pathname.includes(`/${value.urlName}`)) {
      let newQueryParams = {
        ...queryParams,
        queueType: value.queueType,
      };
      return newQueryParams;
    }
  }
}

export function getSpecialQueueTypeBuildOrTierList(page, location) {
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (page === value.buildPageName || location.pathname.includes(`${value.urlName}-tier-list`)) {
      return value.queueType;
    }
  }
}

function getSpecialFromTierList(location) {
  if (!location || !location.pathname) {
    return null;
  }
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (location.pathname.includes(`${value.urlName}-tier-list`)) {
      return value;
    }
  }
  return null;
}

export function getSpecialQueueType() {
  const location = useLocation();
  let special = getSpecialFromTierList(location);
  if (special) {
    return special.queueType;
  }
}

export function getQueueTypeNameFromQueryParam(name) {
  if (!name) {
    return null;
  }
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (value.queueType === name) {
      return value.queueTypeName;
    }
  }
  return null;
}

export function getQueueTypeName(location) {
  let special = getSpecialFromTierList(location);
  if (special) {
    return special.queueTypeName;
  }
}

export function getAllQueueTypeNames() {
  const queueTypeNames = [];
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    queueTypeNames.push({
      queueType: value.queueType,
      buildPageName: value.buildPageName,
      tierListPageName: value.tierListPageName,
      urlName: value.urlName,
    });
  }
  return queueTypeNames;
}

export function getSpecialGameModeBuildPath(location, normalizedChampionName) {
  let special = getSpecialFromTierList(location);
  if (special) {
    return `/lol/champions/${special.urlName}/${normalizedChampionName}-${special.urlName}`;
  }
}

export function getSpecialQueryParams(location, prevLocation) {
  // Compare the previous pathname to this one and if we are coming from the aram page, add the queueType aram to the filter; because that page won't have it in the filter.
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (
      location &&
      prevLocation &&
      location.pathname !== prevLocation.pathname &&
      prevLocation.pathname.includes(`${value.urlName}-tier-list`) &&
      !location.pathname.includes("tier-list")
    ) {
      return {
        queueType: value.queueType,
      };
    }
  }
  return null;
}

export function getForcedParams(location) {
  if (!location || !location.pathname) {
    return null;
  }
  const pathname = location.pathname;
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (pathname.includes(`/${value.urlName}`) || pathname.includes(`${value.urlName}-tier-list`)) {
      let newForcedParams = {
        queueType: value.queueType,
      };
      return newForcedParams;
    }
  }
}

export function isSpecialGameModeByQueueType(queueType) {
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (queueType === value.queueType) {
      return true;
    }
  }
  return false;
}

export function isSpecialGameModeByPage(page) {
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (page === value.buildPageName) {
      return true;
    }
  }
  return false;
}

export function showBuildToughestMatchups(location) {
  if (!location) {
    return true;
  }
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (location.pathname.includes(`champions/${value.urlName}`)) {
      return value.tierListSettings.showAgainst;
    }
  }
  return true;
}

export function showBuildOTPStats(location) {
  if (!location) {
    return true;
  }
  for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
    if (location.pathname.includes(`champions/${value.urlName}`)) {
      return false;
    }
  }
  return true;
}
