import React, { useState } from "react";
import { useGlobal } from "reactn";
import ButtonSuccess from "components/Buttons/ButtonSuccess";
import RegionSelector from "components/Masthead/RegionSelector";
import { useHistory } from "react-router-dom";
import { stripMultiSearch } from "./multi-search-filter";

const textPlaceholder = `Tyler1 joined the lobby
Bjergsen joined the lobby
Doublelift joined the lobby
CoreJJ joined the lobby
Alphari joined the lobby`;

const MSTextBox = (props) => {
  const history = useHistory();
  const [globalRegion] = useGlobal("clientRegion");
  const { multiSearchVariables } = props;
  const { riotUserName, riotTagLine, regionId } = multiSearchVariables;
  const [currentRegion, setCurrentRegion] = useState(regionId || globalRegion);
  const [textValue, setTextValue] = useState(
    riotUserName.map((el, index) => (el ? `${el}#${riotTagLine[index]}` : "")).join(", "),
  );

  const onSearch = () => {
    const parsedText = stripMultiSearch(textValue);
    history.push({ search: `summoners=${parsedText.map((str) => str.replace("#", "-")).join(",")}&region=${currentRegion}` });
  };

  return (
    <div className="multisearch-search-container">
      <div className="search-header">
        <div className="region-selector">
          <RegionSelector
            currentRegion={currentRegion}
            onApply={(region) => setCurrentRegion(region)}
            isMasthead
            disableTooltip
          />
        </div>
        <div className="header-text">Search multiple Riot IDs or paste your game lobby.</div>
      </div>
      <div className="textarea-container">
        <textarea onChange={(e) => setTextValue(e.target.value)} value={textValue} autoFocus />
        {textValue === "" && (
          <div className="textarea-placeholder">
            Tyler1 #NA1 joined the lobby <br />
            Bjergsen #NA1 joined the lobby <br />
            Doublelift #NA1 joined the lobby <br />
            CoreJJ #NA1 joined the lobby <br />
            Alphari #NA1 joined the lobby
          </div>
        )}
      </div>
      <div className="search-btn-row">
        <ButtonSuccess className="btn-blue search-btn" label="Search" onClick={onSearch} />
      </div>
    </div>
  );
};

export default MSTextBox;
