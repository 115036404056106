import { ROLE_OPTIONS, REGION_OPTIONS, RANK_OPTIONS_WITH_D2, ALL_CHAMPIONS_OPTIONS } from "../common";

// ------------------------------------
//  MATCHUPS
// ------------------------------------

export const MATCHUPS_QUEUE_TYPE_OPTIONS = [
  { value: "ranked_solo_5x5", label: "Ranked Solo" },
  { value: "ranked_flex_sr", label: "Ranked Flex" },
];

export const MATCHUPS_OPTIONS = {
  queueType: MATCHUPS_QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  allChampions: ALL_CHAMPIONS_OPTIONS,
  patch: { options: "latest", max: 5 },
};

export const MATCHUPS_DEFAULT = {
  queueType: MATCHUPS_OPTIONS.queueType[0].value,
  region: MATCHUPS_OPTIONS.region[0].value,
  rank: MATCHUPS_OPTIONS.rank[1].value,
  patch: MATCHUPS_OPTIONS.patch.options,
  allChampions: "false",
};
