import { useJSONFetcher } from "@outplayed/json-fetcher";
import { getCDragonAugmentData } from "../static-url-helpers";
import { RAC_getWorkingPatch, normalizeToBasicPatch } from "./patches";
import { getJSON } from "../common";

function remapAugmentData(data) {
  const map = {};
  (data || []).forEach((augment) => {
    map[augment.id] = augment;
  });

  return map;
}

export function RAC_useRiotArenaAugments(versions) {
  return ({ augmentId, patch, language, skip, ssr } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const cdragonPatch = normalizeToBasicPatch(riotPatch) || "latest";
    const augmentsURL = getCDragonAugmentData(cdragonPatch, language);
    const onCompleted = (url, json) => {
      const { augments } = json;
      return remapAugmentData(augments);
    };

    return useJSONFetcher(augmentsURL, { skip, ssr, onCompleted });
  };
}

const augmentsCache = {};
export function RAC_getArenaAugments(versions) {
  return ({ patch, language } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const cdragonPatch = normalizeToBasicPatch(riotPatch) || "latest";
    const cacheKey = `${cdragonPatch}_${language}`;
    if (!(cacheKey in augmentsCache)) {
      let url = getCDragonAugmentData(cdragonPatch, language);
      try {
        const data = getJSON(url);
        const { augments } = data || {};

        if (!augments) return null;

        augmentsCache[cacheKey] = remapAugmentData(augments);
      } catch (err) {
        console.error(err);
        augmentsCache[cacheKey] = null; // Temp fix if data doesn't exist on cdragon
        return null;
      }
    }
    return augmentsCache[cacheKey];
  };
}
