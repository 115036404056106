import React, { Component } from "react";
import PropTypes from "prop-types";
import Reaptcha from "reaptcha";

import { recaptchaSiteKeyV2, recaptchaSiteKeyV3 } from "api/devConstants";

export default class RecaptchaWrapper extends Component {
  render() {
    const { error, onVerify, onExpire, setRef } = this.props;

    return (
      <div className="recaptcha-container">
        {error && <div className="recaptcha-error">{error}</div>}
        <Reaptcha
          ref={setRef}
          sitekey={recaptchaSiteKeyV2}
          onVerify={onVerify}
          onExpire={onExpire}
          onError={() => console.log("error recaptcha")}
        />
      </div>
    );
  }
}

RecaptchaWrapper.defaultProps = {
  onVerify: () => {},
  onExpire: () => {},
  setRef: () => {},
};
