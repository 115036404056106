import { BaseButtonProps, Button } from "@ugitgud/legos/ui/form/button/Button";
import classNames from "classnames";
import React from "react";

const overLineColors = {
  orange:
    "dark:border-t-accent-orange-700/50 dark:ui-toggled:border-t-accent-orange-700 dark:ui-selected:border-t-accent-orange-700 dark:hover:border-t-accent-orange-700",
  blue: "dark:border-t-accent-blue-400/50 dark:ui-toggled:border-t-accent-blue-400 dark:ui-selected:border-t-accent-blue-400 dark:hover:border-t-accent-blue-400",
};

export type OverlineColor = keyof typeof overLineColors;

export type OverlineButtonProps<T extends React.ElementType> = BaseButtonProps<T> & {
  overlineColor?: OverlineColor;
};

export const OverlineButton = React.forwardRef(
  <T extends React.ElementType = "button">(
    props: OverlineButtonProps<T>,
    ref: React.RefObject<React.ElementRef<T>> | React.ForwardedRef<React.ElementRef<T>>,
  ) => {
    const { overlineColor = "orange", className, ...rest } = props;
    return (
      <Button<T>
        className={`border-t-[1px] focus:outline-0 ${overLineColors[overlineColor]} ${classNames(className)}`}
        {...rest}
        ref={ref}
      />
    );
  },
);
