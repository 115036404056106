import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { useUGGPatches } from "lib/hooks/patchHooks";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getItemDescription } from "../components/Items/ItemsPage/getItem";

const REPLACE_CHAMPION = "{champion}";
const REPLACE_ROLE = "{role}";
const REPLACE_REGION = "{region}";
const REPLACE_LOWER_ID = "LOWER_CHAMPION_ID";

export const useSeoPages = () => {
  const { data: uggPatches } = useUGGPatches();
  const LATEST_PATCH = uggPatches[0].replace("_", ".");
  const SEASON_TEXT = `S${LATEST_PATCH.split(".")[0]}`;
  const DESCRIPTION_KEYWORDS_SUFFIX = `${SEASON_TEXT} Patch ${LATEST_PATCH}`;

  return {
    index: {
      title: `U GG: The Best League of Legends Builds LoL Build Champion Probuilds LoL Runes Tier List Counters Guides`,
      description:
        "Best Builds from the Best Data. Riot-approved U.GG provides the best League of Legends builds, LoL runes, Probuilds, Tier List, Counters, and more.",
      keywords: `League of legends builds, gg, lol build, league builds, lol runes`,
      url: "https://u.gg",
    },
    open_search: {
      title: "U.GG Open Search",
      description:
        `Search U.GG for League of Legends Statistics. Find Meta Champion ` +
        `Tier Lists, Item and Rune combinations, Pro Builds, Champion ` +
        `Guides, and Champion Counters by Role. Patch ${LATEST_PATCH}.`,
      url: "https://u.gg/opensearch",
    },
    about: {
      title: "U.GG About",
      description:
        `About U.GG. Learn more about how we provide you with the best ` +
        `source for League of Legends Statistics. Find Meta Champion Tier ` +
        `Lists, Item and Rune combinations, Pro Builds, Champion Guides, ` +
        `and Champion Counters by Role. Patch ${LATEST_PATCH}.`,
      url: "https://u.gg/about",
    },
    terms_of_service: {
      title: "U.GG Terms of Service & Cookies Policy",
      description:
        `Terms of Service. U.GG is, at its heart, a platform to help gamers. ` +
        `Although it is certainly possible to use our platform without creating ` +
        `an account, we do believe that any visitor to our site must abide by ` +
        `the following Terms of Service. Patch ${LATEST_PATCH}.`,
      url: "https://u.gg/terms-of-service",
    },
    privacy_policy: {
      title: "Privacy Policy",
      description:
        `Privacy Policy. U.GG is, at its heart, a platform to help gamers. ` +
        `All Users and their data are protected by our Privacy Policy outlined here.`,
      url: "https://u.gg/privacy-policy",
    },
    privacy_shield: {
      title: "Privacy Shield",
      description:
        `U.GG abides by the principles outlined in the EU-U.S. Privacy Shield framework ` +
        `and the Swiss Privacy Shield with regards to data collection and transmission.`,
      url: "https://u.gg/privacy-shield",
    },
    careers: {
      title: "Careers",
      description: `U.GG Careers `,
      url: "https://u.gg/careers",
    },
    faq: {
      title: "U.GG Frequently Asked Questions",
      description:
        `Frequently Asked Questions. Why U.GG? What is Unique about ` +
        `U.GG? How does U.GG monetize? How do I navigate U.GG? How does ` +
        `the Search Bar work? What is the U.GG Recommended Build? What ` +
        `filtering options does U.GG offer? Why are your tier lists sorted ` +
        `by win rate, despite being called a tier list? Patch ${LATEST_PATCH}.`,
      url: "https://u.gg/faq",
    },
    "tier-list": {
      title: `U.GG LoL Tier List - Patch {patch} Best Champions for All Roles in League of Legends`,
      description: `The only LoL Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best champions for Patch {patch} on every role.`,
      keywords: "Lol tier list, league of legends tier list",
      url: "https://u.gg/lol/tier-list",
    },
    "adc-tier-list": {
      title: `U.GG ADC Tier List - Patch {patch} Best ADC Champions in League of Legends`,
      description: `The only ADC Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best ADC champions for Patch {patch}.`,
      keywords: "adc tier list, adc lol",
      url: "https://u.gg/lol/adc-tier-list",
    },
    "support-tier-list": {
      title: `U.GG Support Tier List - Patch {patch} Best Supp Champions in League of Legends`,
      description: `The only Support Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best supp champions for Patch {patch}.`,
      keywords: "support tier list, lol support tier list",
      url: "https://u.gg/lol/support-tier-list",
    },
    "top-lane-tier-list": {
      title: `U.GG Top Lane Tier List - Patch {patch} Best Top Champions in League of Legends`,
      description: `The only Top Lane Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best top champions for Patch {patch}.`,
      keywords: "top lane tier list, top tier list",
      url: "https://u.gg/lol/top-lane-tier-list",
    },
    "mid-lane-tier-list": {
      title: `U.GG Mid Lane Tier List - Patch {patch} Best Mid Champions in League of Legends`,
      description: `The only Mid Lane Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best mid champions for Patch {patch}.`,
      keywords: "mid lane tier list, mid tier list",
      url: "https://u.gg/lol/mid-lane-tier-list",
    },
    "jungle-tier-list": {
      title: `U.GG Jungle Tier List - Patch {patch} Best Jungle Champions in League of Legends`,
      description: `The only Jungle Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best jungle champions for Patch {patch}.`,
      keywords: "jungle tier list, Lol jungle tier list",
      url: "https://u.gg/lol/jungle-tier-list",
    },
    "arurf-tier-list": {
      title: `ARURF TIER LIST - LEAGUE OF LEGENDS PATCH {patch}`,
      description: `LoL ARURF Tier List with Riot-approved stats of U.GG. Best ARURF champions based on millions of League of Legends matches. Sort by win rate, tier, role, rank, and region. Patch {patch}`,
      keywords: "ARURF tier list, Lol ARURF tier list",
      url: "https://u.gg/lol/arurf-tier-list",
    },
    "urf-tier-list": {
      title: `URF TIER LIST - LEAGUE OF LEGENDS PATCH {patch}`,
      description: `LoL URF Tier List with Riot-approved stats of U.GG. Best URF champions based on millions of League of Legends matches. Sort by win rate, tier, role, rank, and region. Patch {patch}`,
      keywords: "URF tier list, Lol URF tier list",
      url: "https://u.gg/lol/urf-tier-list",
    },
    "aram-tier-list": {
      title: `U.GG ARAM Tier List - Patch {patch} Best ARAM Champions in League of Legends`,
      description: `The only ARAM Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best ARAM champions for Patch {patch}.`,
      keywords: "ARAM tier list, Lol ARAM tier list",
      url: "https://u.gg/lol/aram-tier-list",
    },
    "one-for-all-tier-list": {
      title: `ONE FOR ALL TIER LIST - LEAGUE OF LEGENDS PATCH {patch}`,
      description: `LoL One for All Tier List with Riot-approved stats of U.GG. Best One for All champions based on millions of League of Legends matches. Sort by win rate, tier, role, rank, and region. Patch {patch}`,
      keywords: "One for All tier list, Lol One for All tier list",
      url: "https://u.gg/lol/one-for-all-tier-list",
    },
    "nexus-blitz-tier-list": {
      title: `NEXUS BLITZ TIER LIST - LEAGUE OF LEGENDS PATCH {patch}`,
      description: `LoL Nexus Blitz Tier List with Riot-approved stats of U.GG. Best Nexus Blitz champions based on millions of League of Legends matches. Sort by win rate, tier, role, rank, and region. Patch {patch}`,
      keywords: "Nexus Blitz tier list, Lol Nexus Blitz tier list",
      url: "https://u.gg/lol/nexus-blitz-tier-list",
    },
    "arena-tier-list": {
      title: `ARENA TIER LIST - LEAGUE OF LEGENDS PATCH {patch}`,
      description: `The only Arena Tier List you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best Arena champions for Patch {patch}.`,
      keywords: "Arena tier list, Lol Arena tier list",
      url: "https://u.gg/lol/arena-tier-list",
    },
    "arena-duo-tier-list": {
      title: `ARENA DUO TIER LIST - LEAGUE OF LEGENDS PATCH {patch}`,
      description: `The only Arena Duos Tier List you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best Arena champion duos for Patch {patch}.`,
      keywords: "Arena Duo tier list, Lol Arena Duo tier list",
      url: "https://u.gg/lol/arena-duo-tier-list",
    },
    "duo-tier-list": {
      title: `U.GG Duo Tier List - Patch {patch} Best Champion Duos in League of Legends`,
      description: `The only Duo Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best champion duos in bot and mid jungle for Patch {patch}.`,
      url: "https://u.gg/lol/duo-tier-list",
    },
    combat: {
      title: `U.GG Combat Tier List - Patch {patch} Best Combat Champions in League of Legends`,
      description: `The only Combat Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best champions by combat metrics for Patch {patch}.`,
      url: "https://u.gg/lol/combat",
    },
    objectives: {
      title: `U.GG Objectives Tier List - Patch {patch} Best Objectives Champions in League of Legends`,
      description: `The only Objectives Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best champions by objective control for Patch {patch}.`,
      url: "https://u.gg/lol/objectives",
    },
    champions: {
      title: `${SEASON_TEXT} - LoL Champion Builds, Runes, Pro Builds, Counters Guides`,
      description:
        `Find the best lol league of legends champs champions statistics, win rates, builds, ` +
        `runes, pro builds probuilds, counters, matchups, items,` +
        `spells and abilities, and duos guides as roles top, jungle, mid, bot, support ` +
        `on ranked solo/duo/flex, aram, and normal blind/draft. ` +
        `${DESCRIPTION_KEYWORDS_SUFFIX}`,
      url: "https://u.gg/lol/champions",
    },
    champion_leaderboards: {
      title: `Best {champion} Players in {region} - U.GG`,
      description: `{region}’s best {champion} players, one-tricks, pros, and more. U.GG’s Champion Leaderboard for all regions in D2+.`,
      url: "https://u.gg/lol/champion-leaderboards",
    },
    champion_overview: {
      title: `{champion} Build with Highest Winrate - LoL Runes, Items, and Skill Order`,
      description: `{champion} build with the highest winrate runes and items in every role. U.GG analyzes millions of LoL matches to give you the best LoL champion build. Patch {patch}`,
      keywords: `{champion} build, {champion} runes`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/build",
    },
    champion_overview_ap_build: {
      title: `AP {champion} Build and Runes - U GG`,
      description: `AP {champion} with U.GG's best data for every build. The highest win rate AP {champion} build, from rune set to skill order to item path, for {role}. LoL Patch {patch}`,
      keywords: `AP {champion} build, AP {champion} runes`,
      url: "https://u.gg/lol/champions/ap/LOWER_CHAMPION_ID/build",
    },
    champion_overview_region: {
      tr_tr: {
        title: `{champion} Build - U.GG LoL {champion} Rün`,
        description: `U.GG {champion} build, en iyi {champion} rünler kazanma oranı ve seçilme oranı'na göre gösterir. Yetenek sırası, çekirdek eşyaları ve oyun sonu eşyalarıyla, bu {champion} build, League S10 {patch} için eksiksiz bir LoL {champion} {role} rün verir.`,
        keywords: `{champion} build, {champion} Rün`,
        url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/build",
      },
    },
    champion_overview_aram: {
      title: `{champion} ARAM Build - U.GG {champion} Runes + Item Guide for ARAM`,
      description: `U.GG {champion} ARAM build shows best {champion} ARAM runes by WR and popularity. With skill order and items, this {champion} guide offers a full LoL {champion} ARAM build for Patch {patch}`,
      keywords: `{champion} ARAM build, {champion} runes`,
      url: "https://u.gg/lol/champions/aram/LOWER_CHAMPION_ID-aram",
    },
    champion_overview_aram_ap_build: {
      title: `AP {champion} ARAM Build - U.GG AP {champion} Runes + Item Guide ARAM`,
      description: `U.GG AP {champion} ARAM shows best AP {champion} runes by WR and popularity. Get AP {champion} skill order and items, plus other ARAM builds, for LoL Patch {patch}`,
      keywords: `AP {champion} ARAM build, AP {champion} runes`,
      url: "https://u.gg/lol/champions/aram/ap/LOWER_CHAMPION_ID-aram",
    },
    champion_overview_arurf: {
      title: `{champion} ARURF Build - U.GG {champion} Runes + Item Guide for ARURF`,
      description: `{champion} ARURF Build - Find the best {champion} Runes and Items for ARURF. U.GG analyzes millions of ARURF matches to give you the best {champion} ARURF guide. Patch {patch}`,
      keywords: `{champion} ARURF build, {champion} runes`,
      url: "https://u.gg/lol/champions/arurf/LOWER_CHAMPION_ID-arurf",
    },
    champion_overview_urf: {
      title: `{champion} URF Build - U.GG {champion} Runes + Item Guide for URF`,
      description: `{champion} URF Build - Find the best {champion} Runes and Items for URF. U.GG analyzes millions of URF matches to give you the best {champion} URF guide. Patch {patch}`,
      keywords: `{champion} URF build, {champion} runes`,
      url: "https://u.gg/lol/champions/urf/LOWER_CHAMPION_ID-urf",
    },
    champion_overview_one_for_all: {
      title: `{champion} One for All Build - U.GG {champion} Runes + Item Guide for One for All`,
      description: `{champion} One for All Build - Find the best {champion} Runes and Items for One for All. U.GG analyzes millions of One for All matches to give you the best {champion} One for All guide. Patch {patch}`,
      keywords: `{champion} One for All build, {champion} runes`,
      url: "https://u.gg/lol/champions/one-for-all/LOWER_CHAMPION_ID-one-for-all",
    },
    champion_overview_nexus_blitz: {
      title: `{champion} Nexus Blitz Build - U.GG {champion} Runes + Item Guide for Nexus Blitz`,
      description: `{champion} Nexus Blitz Build - Find the best {champion} Runes and Items for Nexus Blitz. U.GG analyzes millions of Nexus Blitz matches to give you the best {champion} Nexus Blitz guide. Patch {patch}`,
      keywords: `{champion} Nexus Blitz build, {champion} runes`,
      url: "https://u.gg/lol/champions/nexus-blitz/LOWER_CHAMPION_ID-nexus-blitz",
    },
    champion_overview_arena: {
      title: `{champion} Arena Build - Best Items, Augments, Duos`,
      description: `{champion} Arena Build with the best items, augments, and duos. U.GG analyzes millions of matches to give you the best {champion} Arena guide. Patch {patch}`,
      keywords: `{champion} Arena build, {champion} augments`,
      url: "https://u.gg/lol/champions/arena/LOWER_CHAMPION_ID-arena",
    },
    champion_items: {
      title: `${SEASON_TEXT} - Best {champion} Items Builds Guides :: U.GG`,
      description:
        `Find the best League of Legends {champion} items guide. Top, jungle, ` +
        `mid, bot, support roles on ranked solo/duo/flex, aram, and normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/items",
    },
    champion_item_paths: {
      title: `${SEASON_TEXT} - Best {champion} Items Paths Builds Guides :: U.GG`,
      description:
        `Find the best League of Legends champion {champion} items paths guide. Top, jungle, ` +
        `mid, bot, support roles on ranked solo/duo/flex, aram, and normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/item-paths",
    },
    champion_spells: {
      title: `${SEASON_TEXT} - Best {champion} Spells & Abilities Builds Guides :: U.GG`,
      description:
        `Find the best League of Legends champion {champion} spells and abilities guide. ` +
        `Top, jungle, mid, bot, support roles on ranked solo/duo/flex, aram, and normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/spells-abilities",
    },
    champion_runes_table: {
      title: `{champion} {role} Runes Table for ${SEASON_TEXT} :: U.GG`,
      description:
        `Find the best League of Legends champion {champion} runes guide. Top, jungle, ` +
        `mid, bot, support roles on ranked solo/duo/flex, aram, and normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/runes",
    },
    champion_rune_sets: {
      title: `{champion} {role} Runes Sets for ${SEASON_TEXT} :: U.GG`,
      description:
        `Find the best League of Legends champion {champion} rune sets guide. Top, jungle, ` +
        `mid, bot, support roles on ranked solo/duo/flex, aram, and normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/rune-sets",
    },
    champion_counters: {
      title: `{champion} Counter - Best Counters from Best Data LoL Patch {patch} - U.GG`,
      description: `{champion} Counter. Best Counter Picks from the Best Data. Riot-approved U.GG analyzes millions of LoL matches. Sort by role, rank, region. Patch {patch}`,
      keywords: `{champion} counter`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/counter",
    },
    champion_matchups: {
      title: `${SEASON_TEXT} - Best {champion} Matchups Builds Guides :: U.GG`,
      description:
        `Find the best League of Legends {champion} matchups guide. Top, jungle, ` +
        `mid, bot, support roles on ranked solo/duo/flex, aram, ranked flex, and normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/matchups",
    },
    champion_duos: {
      title: `${SEASON_TEXT} - Best {champion} Duos Builds Guides :: U.GG`,
      description:
        `Find the best League of Legends {champion} duos guide. Top, jungle, ` +
        `mid, bot, support roles on ranked solo/duo/flex, aram, normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/duos",
    },
    test_champion_overview_default: {
      title: `{champion} Build for {role} with Highest Winrate - LoL Runes, Items`,
      description: `Everything you need for {champion} {role}. The highest win rate {champion} {role} build, from rune set to skill order to item path, in Platinum +. LoL Patch {patch}`,
      keywords: `{champion} build, {champion} runes`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/build",
    },
    test_champion_overview_role: {
      title: `{champion} {role} Build with Highest Winrate - LoL Runes, Items`,
      description: `Everything you need for {champion} {role}. The highest win rate {champion} {role} build, from rune set to skill order to item path, in Platinum +. LoL Patch {patch}`,
      keywords: `{champion} build, {champion} runes`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/build",
    },
    items: {
      title: `Full Items Catalog | League of Legends Wiki`,
      description: `All in-game items for League of Legends, including mythics, legendary, starting items, and consumables. Up-to-date stats, passives, actives, and gold costs.`,
      url: "https://u.gg/lol/items",
    },
    item_overview: {
      title: `{itemName} | {itemType} Item | League of Legends Wiki`,
      description: `{itemDescription}`,
      keywords: `{itemName}`,
      url: "https://u.gg/lol/items/LOWER_ITEM_NAME",
    },
  };
};

export function useChampionProfileSEO() {
  const getPageTitleAndDesc = usePageTitleAndDesc();
  const seoPages = useSeoPages();
  const location = useLocation();

  return (page, { champion, patch, region, role } = {}) => {
    let seoName = page;
    if (page === "matchups") {
      seoName = "champion_matchups";
    } else if (page === "champion_counters") {
      seoName = "champion_counters";
    } else if (page === "duos") {
      seoName = "champion_duos";
    } else if (page === "build") {
      seoName = "champion_overview";
    } else if (page === "items") {
      seoName = "champion_items";
    } else if (page === "runes") {
      seoName = "champion_runes";
    } else if (page === "runes-table") {
      seoName = "champion_runes_table";
    } else if (page === "rune-sets") {
      seoName = "champion_rune_sets";
    } else if (page === "item-paths") {
      seoName = "champion_item_paths";
    } else if (page === "spells-abilities") {
      seoName = "champion_spells";
    } else if (page === "champion_overview" || page === "champion_overview_aram") {
      if (region) {
        if (seoPages["champion_overview_region"][region]) {
          seoName = "champion_overview_region";
        } else {
          seoName = page;
        }
      } else {
        seoName = page;
      }
    }

    return seoName ? getPageTitleAndDesc(seoName, { champion, patch, region, role }) : null;
  };
}

export function usePageTitleAndDesc() {
  const { getChampionImg, getNormalizedChampionName, getItemImg, getItemType } = getRiotAssetsContext();
  const seoPages = useSeoPages();
  const multi = useParams().multi;

  const roleTextMap = {
    adc: "ADC",
    support: "Support",
    middle: "Mid",
    mid: "Mid",
    top: "Top",
    jungle: "Jungle",
  };

  return (page, { champion, itemId, itemData, itemWiki, patch, region, role } = {}) => {
    if (multi === "ap") {
      page = page + "_ap_build";
    }
    let championName, lowerId;
    let pageData = seoPages[page];

    if (!pageData) {
      return null;
    }

    if (pageData[region]) {
      pageData = pageData[region];
    }

    let title = pageData.title;
    let description = pageData.description;
    let keywords = pageData.keywords;
    let url = pageData.url;
    let metaImg = <meta property="og:image" content="https://static.bigbrain.gg/assets/lol/icons/ugg_meta_128x128.jpg" />;

    if (champion) {
      // console.log(champion)
      championName = champion.name;
      lowerId = getNormalizedChampionName(champion.key);
      const imgUrl = getChampionImg(champion.key);
      metaImg = <meta property="og:image" content={imgUrl} />;
      title = title.replace(new RegExp(REPLACE_CHAMPION, "g"), championName);
      description = description.replace(new RegExp(REPLACE_CHAMPION, "g"), championName);
      keywords = keywords && keywords.replace(new RegExp(REPLACE_CHAMPION, "g"), championName);
      url = url.replace(new RegExp(REPLACE_LOWER_ID, "g"), lowerId);
    }

    if (itemId && itemData && itemWiki) {
      const originalName = itemData["name"];
      const itemDescription = getItemDescription(itemId, itemData, itemWiki, { render: false });
      const itemType = getItemType(itemId, itemWiki);
      title = title.replace("{itemName}", originalName);
      title = title.replace("{itemType}", itemType);
      description = description.replace("{itemDescription}", itemDescription);
      const imgUrl = getItemImg(itemId);
      metaImg = <meta property="og:image" content={imgUrl} />;
      keywords = keywords.replace("{itemName}", originalName);
      url = url.replace("LOWER_ITEM_NAME", originalName);
    }
    if (patch) {
      const patchSplit = patch.split(/_|\./g);
      const seoPatch = `${patchSplit[0]}.${patchSplit[1]}`;
      title = title.replace(new RegExp("{patch}", "g"), seoPatch);
      description = description.replace(new RegExp("{patch}", "g"), seoPatch);
    }
    if (role) {
      const roleText = roleTextMap[role];
      title = title.replace(new RegExp(REPLACE_ROLE, "g"), roleText);
      description = description.replace(new RegExp(REPLACE_ROLE, "g"), roleText);
    }
    if (region) {
      title = title.replace(new RegExp(REPLACE_REGION, "g"), region);
      description = description.replace(new RegExp(REPLACE_REGION, "g"), region);
    }
    if (champion && role && page === "test_champion_overview_role") {
      const testRole = role === "middle" ? "mid" : role;
      url = `https://u.gg/lol/champions/${lowerId}/build/${testRole}`;
    }

    return (
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        {keywords && <meta name="keywords" content={keywords} />}
        <meta property="og:title" content={title} />
        <meta property="og:url" content={url} />
        <meta property="og:description" content={description} />
        {metaImg}
      </Helmet>
    );
  };
}
