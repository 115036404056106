import { STATIC_LOL_URL } from "@outplayed/riot-assets";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { useUGGAssetsContext } from "@ugg/shared/components/UGGAssetsProvider";

interface Args {
  apiKey?: string;
  patch?: string;
  ssr?: boolean;
  skip?: boolean;
  staging?: boolean;
}

export function useUGGApiVersions(args?: Args) {
  const { apiKey = undefined, ssr = false, skip = false, patch = undefined } = args || {};
  const { staging } = useUGGAssetsContext();
  const envDir = staging ? "staging" : "prod";
  const url = `${STATIC_LOL_URL}/riot_patch_update/${envDir}/ugg/ugg-api-versions.json`;
  const fetchState = useJSONFetcher<{ [patch: string]: { [apiKey: string]: string } }>(url, { ssr, skip });

  if (fetchState.data && apiKey) {
    let latestPatch = null;
    if (!patch) {
      latestPatch = Object.keys(fetchState.data).sort((a, b) => {
        const aSplit = a.split("_");
        const bSplit = b.split("_");

        for (let i = 0; i < Math.min(aSplit.length, bSplit.length); i++) {
          const _a = parseInt(aSplit[i]) || 0;
          const _b = parseInt(bSplit[i]) || 0;
          if (_a === _b) continue;
          else return _a > _b ? -1 : 1;
        }
        return 0;
      })[0] as Args["patch"];
    }

    const keyPatch = patch || latestPatch;
    const data = keyPatch ? fetchState.data[keyPatch][apiKey] : null;
    return { ...fetchState, data };
  }

  return fetchState;
}
