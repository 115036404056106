import * as React from "react";
import type { SVGProps } from "react";
export const SvgGameHelldiversGrey = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 19 18" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.035 0 7.115 2.287c.04.014.076.04.1.074a.2.2 0 0 1 .032.116l-.47 6.709a.18.18 0 0 0 .055.143l2.112 2.073c.07.069.074.177.01.251l-2.84 3.282a.2.2 0 0 1-.221.053l-1.674-.658a.2.2 0 0 0-.22.051.2.2 0 0 0-.038.068l-.783 2.53a.193.193 0 0 1-.128.125l-2.994.888a.2.2 0 0 1-.113 0l-2.993-.888a.2.2 0 0 1-.128-.125l-.784-2.53a.19.19 0 0 0-.102-.115.2.2 0 0 0-.156-.004l-1.673.658a.2.2 0 0 1-.222-.053L.08 11.653a.18.18 0 0 1 .01-.25l2.112-2.074a.19.19 0 0 0 .055-.143l-.47-6.709a.2.2 0 0 1 .033-.116.2.2 0 0 1 .1-.074zm-.083 12.423L7.884 13.93a.137.137 0 0 0 .03.189l.394.297c.06.044.145.038.196-.016l.48-.506a.07.07 0 0 1 .05-.021.07.07 0 0 1 .05.02l.481.507c.051.054.137.06.196.016l.394-.297a.137.137 0 0 0 .03-.19l-1.068-1.506a.103.103 0 0 0-.165 0m4.02-3.774c-1.038 0-1.88.813-1.88 1.814 0 1 .842 1.814 1.88 1.814 1.036 0 1.878-.813 1.878-1.814s-.842-1.814-1.879-1.814m-7.874 0c-1.037 0-1.879.813-1.879 1.814 0 1 .842 1.814 1.88 1.814 1.036 0 1.878-.813 1.878-1.814s-.842-1.814-1.879-1.814"
      clipRule="evenodd"
    />
  </svg>
);
