import { RAC_getChampions, RAC_getIndividualChampion } from "./champions";
import { RAC_getWorkingPatch } from "./patches";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { uggGetSpellImg, uggGetPassiveImg, getChampionFullData } from "../static-url-helpers";
import { getJSON } from "../common";

export function RAC_getChampionSkillJSON(championMini, backupChampions, versions) {
  return (id, skillSlot, { patch, language, optionalData } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const getIndividualChampion = RAC_getIndividualChampion(championMini, backupChampions, versions);
    const champion = optionalData || getIndividualChampion(id, { patch, language });
    if (!champion) return null;

    const { spells, skills } = champion;
    if (spells) {
      const val = champion.spells[skillSlot];
      return {
        ...val,
        img: uggGetSpellImg(riotPatch, val.image, { webp: optionalData }),
        champId: champion.id,
      };
    } else if (skills) {
      const val = champion.skills[skillSlot];
      return {
        ...val,
        img: uggGetSpellImg(riotPatch, val.image, { webp: optionalData }),
        champId: champion.id,
      };
    } else return null;
  };
}

export function RAC_listChampionSkills(versions) {
  return ({ patch, language, webp, optionalData } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    let champs;
    if (optionalData) {
      champs = optionalData;
    } else {
      const results = getJSON(getChampionFullData(riotPatch, language));
      if (results && results.data) {
        champs = results.data;
      }
    }
    const results =
      champs == null
        ? []
        : Object.keys(champs)
            .map((x) => {
              const { skills, spells, passive } = champs[x];
              if (spells) {
                // Riot data
                const mapped = spells.map((spell, slot) => {
                  const image = spell.image.full;
                  return {
                    ...spell,
                    champId: x,
                    slot,
                    img: uggGetSpellImg(riotPatch, image, { webp }),
                  };
                });
                return [
                  ...mapped,
                  {
                    ...passive,
                    champId: x,
                    id: `${champs[x].id}Passive`,
                    slot: mapped.length,
                    img: uggGetPassiveImg(riotPatch, passive.image.full, {
                      webp,
                    }),
                  },
                ];
              } else if (skills) {
                // Backup data
                const mapped = skills.map((spell, slot) => {
                  return {
                    ...spell,
                    champId: x,
                    slot,
                    img: uggGetSpellImg(riotPatch, image, { webp }),
                  };
                });
                return [
                  ...mapped,
                  {
                    ...passive,
                    champId: x,
                    id: `${x}Passive`,
                    slot: mapped.length,
                    img: uggGetPassiveImg(riotPatch, passive.image.full, {
                      webp,
                    }),
                  },
                ];
              } else {
                return [];
              }
            })
            .flat();
    return results;
  };
}

export function RAC_getChampionTooltipsJSON(versions) {
  return (patch) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const url = `https://static.bigbrain.gg/assets/lol/tooltips/${riotPatch}/champion_tooltips.json`;
    return useJSONFetcher(url);
  };
}

export function RAC_getChampionPassiveJSON(championMini, backupChampions, versions) {
  return (id, { patch, language, optionalData } = {}) => {
    const getIndividualChampion = RAC_getIndividualChampion(championMini, backupChampions, versions);
    const champion = optionalData || getIndividualChampion(id, { patch, language });
    if (!champion) return null;

    return champion.passive;
  };
}

export function RAC_getPassiveAbilityImg(championMini, backupChampions, versions) {
  return (id, { patch, optionalData, webp } = {}) => {
    const getIndividualChampion = RAC_getIndividualChampion(championMini, backupChampions, versions);
    const champion = optionalData || getIndividualChampion(id, { patch });
    if (!champion) {
      return "";
    }

    if (champion.passive.image.full) {
      if (champion.id == "Sett") {
        // Sett's icon in riot static data is bugged and show's Cho's passive
        return "https://static.bigbrain.gg/assets/lol/riot_static/fix/Sett_P.png";
      } else {
        const riotPatch = RAC_getWorkingPatch(versions)(patch);
        return uggGetPassiveImg(riotPatch, champion.passive.image.full, {
          webp,
        });
      }
    } else {
      return champion.passive.image;
    }
  };
}

//returns array of skill images (Q -> W -> E -> R)
export function RAC_getChampionSkillImgs(championMini, backupChampions, versions) {
  return (id, { patch, optionalData, webp } = {}) => {
    const getIndividualChampion = RAC_getIndividualChampion(championMini, backupChampions, versions);
    const champion = optionalData || getIndividualChampion(id, { patch });
    if (!champion) return [];

    const { spells, skills } = champion;
    if (spells) {
      // Riot data
      return spells.map((spell) => {
        const riotPatch = RAC_getWorkingPatch(versions)(patch);
        const image = spell.image.full;
        return uggGetSpellImg(riotPatch, image, { webp });
      });
    } else if (skills) {
      // Backup data
      return skills.map((spell) => {
        return spell.image;
      });
    } else {
      return [];
    }
  };
}

//returns array of skill names (Q -> W -> E -> R)
export function RAC_getChampionSkillNames(championMini, backupChampions, versions) {
  return (id, { patch, language, optionalData } = {}) => {
    const getIndividualChampion = RAC_getIndividualChampion(championMini, backupChampions, versions);
    const champion = optionalData || getIndividualChampion(id, { patch, language });
    if (!champion) return [];

    const { spells, skills } = champion;
    return (spells || skills || []).map((spell) => {
      return spell.name;
    });
  };
}
