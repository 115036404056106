import * as patches from "./patches";
import * as champions from "./champions";
import * as skills from "./skills";
import * as items from "./items";
import * as runes from "./runes";
import * as profiles from "./profiles";
import * as summonerSpells from "./summoner-spells";
import * as arenaAugments from "./arena-augments";
import * as itemsWiki from "./items-wiki";

export function getRACFunctions(riotAssets) {
  const { staging, championMini, backupChampions, legacyItems, legacyRunes, ornnItems, versions } = riotAssets;

  return {
    // patches
    comparePatch: patches.RAC_comparePatch(versions),
    normalizeToBasicPatch: patches.normalizeToBasicPatch,
    getWorkingPatch: patches.RAC_getWorkingPatch(versions), // done
    getLegacyItemPatch: patches.RAC_getLegacyItemPatch(legacyItems, versions), // done
    getLegacyRunePatch: patches.RAC_getLegacyRunePatch(legacyRunes, versions), // done
    // champions
    useBackupChampions: champions.RAC_useBackupChampions(backupChampions), // done
    useChampionMini: champions.RAC_useChampionMini(backupChampions, versions), // done
    useChampionFull: champions.RAC_useChampionFull(backupChampions, versions), // done
    useChampionIndiv: champions.RAC_useChampionIndiv(championMini, backupChampions, versions), // done
    useChampionIndivByName: champions.RAC_useChampionIndivByName(championMini, backupChampions, versions),
    getChampions: champions.RAC_getChampions(backupChampions, versions), // done
    getIndividualChampion: champions.RAC_getIndividualChampion(championMini, backupChampions, versions), // done
    getChampionIdByName: champions.RAC_getChampionIdByName(championMini, backupChampions), // done
    getChampionJSONByName: champions.RAC_getChampionJSONByName(championMini, backupChampions), // done
    getChampionName: champions.RAC_getChampionName(championMini, backupChampions), // done
    getNormalizedChampionName: champions.RAC_getNormalizedChampionName(championMini, backupChampions), //done
    getChampionNameId: champions.RAC_getChampionNameId(championMini, backupChampions), // done
    getChampionImg: champions.RAC_getChampionImg(championMini, backupChampions, versions), // done
    getChampionImgFromSprite: champions.RAC_getChampionImgFromSprite(championMini, backupChampions, versions), // done
    getChampionLoadingImg: champions.RAC_getChampionLoadingImg(championMini, backupChampions), // done
    getChampionSplashImg: champions.RAC_getChampionSplashImg(championMini, versions), // done
    // skills
    getChampionTooltipJSON: skills.RAC_getChampionTooltipsJSON(versions),
    getChampionSkillJSON: skills.RAC_getChampionSkillJSON(championMini, backupChampions, versions), // done
    getChampionPassiveJSON: skills.RAC_getChampionPassiveJSON(championMini, backupChampions, versions), // done
    getPassiveAbilityImg: skills.RAC_getPassiveAbilityImg(championMini, backupChampions, versions), // done
    getChampionSkillImgs: skills.RAC_getChampionSkillImgs(championMini, backupChampions, versions), // done
    getChampionSkillNames: skills.RAC_getChampionSkillNames(championMini, backupChampions, versions), // done
    listChampionSkills: skills.RAC_listChampionSkills(versions),
    // items
    useRiotItems: items.RAC_useRiotItems(versions, ornnItems), // done
    useNewRiotItems: items.RAC_useNewRiotItems(versions), // done
    useRiotMythicItems: items.RAC_useRiotMythicItems(versions, ornnItems), // done
    useRiotBootItems: items.RAC_useRiotBootItems(versions, ornnItems), // done
    useRiotCompletedItems: items.RAC_useRiotCompletedItems(versions, ornnItems), // done
    getItems: items.RAC_getItems(versions), // done
    getItemImgFromSprite: items.RAC_getItemImgFromSprite(versions), // done
    getItemImg: items.RAC_getItemImg(versions), // done
    mergeWithOrnnJson: items.RAC_mergeWithOrnnJson(ornnItems), // done
    isMythicItem: items.isMythicItem, //done
    isCompletedItem: items.isCompletedItem, //done
    isOrnnItem: items.isOrnnItem, //done
    getNormalizedItemNameFromId: items.RAC_getNormalizedItemNameFromId(versions),
    getItemIdFromName: items.RAC_getItemIdFromName(versions), //This returns the outer function
    getItemType: items.getItemType,
    // runes
    useRiotRunes: runes.RAC_useRiotRunes(versions), // done
    getRunes: runes.RAC_getRunes(versions), // done
    getRuneJSON: runes.RAC_getRuneJSON(versions), // done
    getRunesList: runes.RAC_getRunesList(versions), // done
    getRuneImg: runes.RAC_getRuneImg(versions), // done
    getRuneKeystoneId: runes.RAC_getRuneKeystoneId(versions), // done
    getStatShardJSON: runes.getStatShardJSON, // done
    useStatShards: runes.RAC_useStatShards(staging, versions),
    getStatShards: runes.RAC_getStatShards(staging, versions),
    getStatShardImg: runes.RAC_getStatShardImg(staging, versions),
    // summoner spells
    useRiotSummoners: summonerSpells.RAC_useRiotSummoners(versions), // done
    getSummonerSpells: summonerSpells.RAC_getSummonerSpells(versions), // done
    getSummonerSpellImg: summonerSpells.RAC_getSummonerSpellImg(versions), // done
    // profiles
    getProfileIconImg: profiles.RAC_getProfileIconImg(versions), // done
    // arena
    useRiotArenaAugments: arenaAugments.RAC_useRiotArenaAugments(versions),
    getArenaAugments: arenaAugments.RAC_getArenaAugments(versions),
    // items wiki
    useItemsWiki: itemsWiki.RAC_useItemsWiki(versions),
  };
}
