import image404 from "@ugg/shared/assets/images/404-dark.png";

interface AppCrashErrorPageProps {
  loadingErrorReport: boolean;
  errorStack: { message: string; stack: string } | undefined;
}

export default function AppCrashErrorPage(props: AppCrashErrorPageProps) {
  const { loadingErrorReport, errorStack } = props;

  return (
    <div className="app-crash-error-page">
      <div className="error-container">
        <img className="error-image" src={image404} />
        <div className="error-message">
          <div>An error caused this feature to crash.</div>
          <div>
            Please contact us at <span style={{ textDecoration: "underline", color: "#ffffff" }}>support@u.gg</span>.
          </div>
          {loadingErrorReport && (
            <div className="spinthatshit-loader">
              <div className="spinner" />
            </div>
          )}
        </div>
        {errorStack?.message && (
          <div className="error-console">
            <pre>{"    Error: " + errorStack?.message}</pre>
            <pre>{errorStack?.stack}</pre>
          </div>
        )}
      </div>
    </div>
  );
}
