import { STATIC_LOL_URL } from "@outplayed/riot-assets";
import { useJSONFetcher } from "@outplayed/json-fetcher";

export function useUGGPatches({ ssr, skip } = {}) {
  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  return useJSONFetcher(`${STATIC_LOL_URL}/riot_patch_update/${envDir}/ugg/patches.json`, { ssr, skip });
}

function compareNewestPatches(a, b) {
  if (a.start_time < b.start_time) return 1;
  if (a.start_time > b.start_time) return -1;
  return 0;
}

export function useBlastedPatches(options = {}) {
  return useJSONFetcher("https://lolpatches.s3.us-east-2.amazonaws.com/patches.json", {
    onCompleted: (url, data) => {
      if (data.patches) {
        // patch list can contain future patches, filter them out and sort by timestamp
        let patches = data.patches.filter((patch) => patch.start_time <= new Date().getTime() / 1000);
        patches = patches.sort(compareNewestPatches);
        return patches;
      } else {
        return undefined;
      }
    },
    ...options,
  });
}
