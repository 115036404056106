import { getApiRegion } from "../utils/region-helpers";

export const API_VERSION = "1.5";
export const API_ROOT_PATH = `https://stats.u.gg/lol/${API_VERSION}`;
export const API_ROOT_PATH_V2 = `https://stats2.u.gg/lol/${API_VERSION}`;
export const API_UGG = "https://api.u.gg";

export function getApiUggRoot(region: string) {
  if (region) {
    return `https://${getApiRegion(region).toLowerCase()}.api.u.gg`;
  }

  return API_UGG;
}

export function getApiRoot(patch?: string) {
  if (!patch) {
    return API_ROOT_PATH_V2;
  }

  const patchSplit = patch.split("_");
  const season = parseInt(patchSplit[0] || "");
  const version = parseInt(patchSplit[1] || "");

  if (season < 8 || (season == 8 && version <= 23)) {
    return API_ROOT_PATH;
  }

  return API_ROOT_PATH_V2;
}

export function regionMapping(region: string) {
  switch (region) {
    case "na1":
      return 1;
    case "euw1":
      return 2;
    case "kr":
      return 3;
    case "eun1":
      return 4;
    case "br1":
      return 5;
    case "la1":
      return 6;
    case "la2":
      return 7;
    case "oc1":
      return 8;
    case "ru":
      return 9;
    case "tr1":
      return 10;
    case "jp1":
      return 11;
    case "world":
      return 12;
    case "ph2":
      return 13;
    case "sg2":
      return 14;
    case "th2":
      return 15;
    case "tw2":
      return 16;
    case "vn2":
      return 17;
    case "me1":
      return 18;
    default:
      return -1;
  }
}

export function roleMapping(role: string) {
  switch (role) {
    case "jungle":
      return 1;
    case "support":
    case "supp":
      return 2;
    case "adc":
      return 3;
    case "top":
      return 4;
    case "middle":
    case "mid":
      return 5;
    case "none":
      return 6;
    case "all":
      return 7;
    default:
      return -1;
  }
}

export function queueTypeMapping(queueType: string) {
  switch (queueType) {
    case "normal_draft_5x5":
      return 400;
    case "ranked_solo_5x5":
      return 420;
    case "normal_blind_5x5":
      return 430;
    case "ranked_flex_sr":
      return 440;
    case "normal_aram":
      return 450;
    case "clash_5x5":
      return 500;
    case "nexus_blitz":
      return 1300;
    default:
      return -1;
  }
}

export function queueTypeStringMapping(queueType: number) {
  switch (queueType) {
    case 400:
      return "normal_draft_5x5";
    case 420:
      return "ranked_solo_5x5";
    case 430:
      return "normal_blind_5x5";
    case 440:
      return "ranked_flex_sr";
    case 450:
      return "normal_aram";
    case 500:
      return "clash_5x5";
    case 900:
      return "urf";
    case 1020:
      return "one_for_all";
    case 1300:
      return "nexus_blitz";
    default:
      return "";
  }
}
