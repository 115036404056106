import { window } from "global";
import { useMemo } from "react";
import { useGlobal } from "reactn";
import { ApolloProvider, ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { initApolloClient } from "@ugg/shared/api/graphql/apollo-init";

interface ApolloContainerProps {
  ssrClient?: ApolloClient<NormalizedCacheObject>;
  children: React.ReactNode;
}

export default function ApolloContainer(props: ApolloContainerProps) {
  const { ssrClient } = props;
  const [authToken] = useGlobal("authToken");

  const client = useMemo(() => {
    if (ssrClient) {
      return ssrClient;
    }

    // Client Side
    const apolloState = window?.__APOLLO_STATE__;
    return initApolloClient(authToken || "", apolloState, {
      customHeaders: {
        "x-app-type": "web",
        "x-app-version":
          process.env.BUILD_TYPE === "dugg" || process.env.NODE_ENV !== "production" ? "dev" : process.env.COMMIT_HASH,
      },
    });
  }, [authToken]);

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
