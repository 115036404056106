import { Icon, IconProps } from "@ugitgud/legos/ui/icon/Icon";
import { WithClassName } from "@ugitgud/legos/ui/shared";
import classNames from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

export type HoverIconProps = WithClassName<{ up: IconProps["icon"]; down: IconProps["icon"]; size: IconProps["size"] }>;

export const HoverIcon = React.forwardRef<HTMLDivElement, HoverIconProps>(({ up, down, className, size }, ref) => {
  return (
    <div ref={ref} className={twMerge(classNames("inline-flex relative items-center justify-center", className))}>
      <Icon size={size} icon={down} />
      <Icon size={size} className="absolute group-hover:opacity-100 group-ui-toggled:opacity-100 opacity-0" icon={up} />
    </div>
  );
});
