import React from "react";
import classnames from "classnames";

const PromoProgress = (props) => {
  const { promoProgress } = props;

  return (
    <div className="promos">
      <div className="promo-progress">
        {promoProgress.split("").map((outcome, index) => {
          const promoGameClassNames = classnames("promo_game", {
            promo_game__win: outcome === "W",
            promo_game__loss: outcome === "L",
          });
          return <div key={index} className={promoGameClassNames} />;
        })}
      </div>
    </div>
  );
};

export default PromoProgress;
