import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class CustomInput extends Component {
  render() {
    const { error, hideErrorMessage, onCreateRef, children, label, rightLabel, rightLabelLink, ...inputProps } = this.props;

    const BuildRightLabel = () => {
      if (!rightLabel) return null;

      if (rightLabelLink) {
        return (
          <Link to={`/${rightLabelLink}`}>
            <div className="right-label">{rightLabel}</div>
          </Link>
        );
      } else {
        return <div className="right-label">{rightLabel}</div>;
      }
    };

    return (
      <div className="custom-input-container">
        <div className="input-label-container">
          {label && <div className="input-label">{label}</div>}
          <BuildRightLabel />
        </div>
        {error && <div className={`input-error-msg ${hideErrorMessage ? "input-error-msg_hide" : ""}`}>{error}</div>}
        <div style={{ position: "relative" }}>
          <input ref={onCreateRef} {...inputProps} />
          {children}
        </div>
      </div>
    );
  }
}
