import React from "react";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const ItemTooltip = (props) => {
  const { getItems, useRiotItems } = getRiotAssetsContext();
  const { itemId, patch, language } = props;

  const { data: items, loading: fetchingItems, error: errorItems } = useRiotItems({ itemId, patch, language, skip: true });

  const itemsJSON = items || getItems({ patch, language });
  const data = itemsJSON && itemsJSON[itemId];
  if (!data) return null;

  return (
    <div className="tooltip-item">
      <div className="name mb-[12px]">{data.name}</div>
      {data.plaintext && <div className="item-description-short mb-[12px]">{data.plaintext}</div>}
      <div dangerouslySetInnerHTML={{ __html: data.description }} />
      {data.gold && (
        <div className="cost mt-[12px]">
          {`Cost: ${data.gold.total} `}
          <span className="base-price">{`(${data.gold.base})`}</span>
        </div>
      )}
    </div>
  );
};

export default ItemTooltip;
