import React from "react";
import { getRoleUrl, getColorRoleUrl } from "@outplayed/riot-assets";
import { ReactComponent as BotIcon } from "svg/roles/bot.svg";
import { ReactComponent as BotActiveIcon } from "svg/roles/bot-active.svg";
import { ReactComponent as BotInactiveIcon } from "svg/roles/bot-inactive.svg";
import { ReactComponent as SupportIcon } from "svg/roles/support.svg";
import { ReactComponent as SupportActiveIcon } from "svg/roles/support-active.svg";
import { ReactComponent as SupportInactiveIcon } from "svg/roles/support-inactive.svg";
import { ReactComponent as TopIcon } from "svg/roles/top.svg";
import { ReactComponent as TopActiveIcon } from "svg/roles/top-active.svg";
import { ReactComponent as TopInactiveIcon } from "svg/roles/top-inactive.svg";
import { ReactComponent as JungleIcon } from "svg/roles/jungle.svg";
import { ReactComponent as JungleActiveIcon } from "svg/roles/jungle-active.svg";
import { ReactComponent as JungleInactiveIcon } from "svg/roles/jungle-inactive.svg";
import { ReactComponent as MidIcon } from "svg/roles/mid.svg";
import { ReactComponent as MidActiveIcon } from "svg/roles/mid-active.svg";
import { ReactComponent as MidInactiveIcon } from "svg/roles/mid-inactive.svg";
import { ReactComponent as AllIcon } from "svg/roles/all.svg";
import { ReactComponent as AllInactiveIcon } from "svg/roles/all-inactive.svg";
import { ReactComponent as AramIcon } from "svg/roles/aram.svg";
import { ReactComponent as AramInactiveIcon } from "svg/roles/aram-inactive.svg";

import { ReactComponent as BotIconLight } from "svg/roles/bot-light.svg";
import { ReactComponent as BotActiveIconLight } from "svg/roles/bot-active-light.svg";
import { ReactComponent as BotInactiveIconLight } from "svg/roles/bot-inactive-light.svg";
import { ReactComponent as SupportIconLight } from "svg/roles/support-light.svg";
import { ReactComponent as SupportActiveIconLight } from "svg/roles/support-active-light.svg";
import { ReactComponent as SupportInactiveIconLight } from "svg/roles/support-inactive-light.svg";
import { ReactComponent as TopIconLight } from "svg/roles/top-light.svg";
import { ReactComponent as TopActiveIconLight } from "svg/roles/top-active-light.svg";
import { ReactComponent as TopInactiveIconLight } from "svg/roles/top-inactive-light.svg";
import { ReactComponent as JungleIconLight } from "svg/roles/jungle-light.svg";
import { ReactComponent as JungleActiveIconLight } from "svg/roles/jungle-active-light.svg";
import { ReactComponent as JungleInactiveIconLight } from "svg/roles/jungle-inactive-light.svg";
import { ReactComponent as MidIconLight } from "svg/roles/mid-light.svg";
import { ReactComponent as MidActiveIconLight } from "svg/roles/mid-active-light.svg";
import { ReactComponent as MidInactiveIconLight } from "svg/roles/mid-inactive-light.svg";
import { ReactComponent as AllIconLight } from "svg/roles/all-light.svg";
import { ReactComponent as AllInactiveIconLight } from "svg/roles/all-inactive-light.svg";
import { ReactComponent as AramIconLight } from "svg/roles/aram-light.svg";
import { ReactComponent as AramInactiveIconLight } from "svg/roles/aram-inactive-light.svg";

const ROLES = {
  ALL: "all",
  TOP: "top",
  JUNGLE: "jungle",
  MIDDLE: "middle",
  BOTTOM: "adc", // change this to bottom
  SUPPORT: "support",
  NONE: "none",
  ARAM: "aram",
  URF: "urf",
  LANE: "lane",
};

const ROLES_BACKEND = {
  ALL: "all",
  TOP: "top",
  JUNGLE: "jungle",
  MIDDLE: "mid",
  BOTTOM: "adc", // change this to bottom
  SUPPORT: "supp",
  NONE: "none",
  ARAM: "aram",
  LANE: "lane",
};

export function numToRoleMapping(num) {
  switch (Number(num)) {
    case 1:
      return ROLES.JUNGLE;
    case 2:
      return ROLES.SUPPORT;
    case 3:
      return ROLES.BOTTOM;
    case 4:
      return ROLES.TOP;
    case 5:
      return ROLES.MIDDLE;
    case 7:
      return ROLES.ALL;
    case 8:
      return ROLES.LANE;
    default:
      return "";
  }
}

export function backendNumToRoleMapping(num) {
  switch (Number(num)) {
    case 1:
      return ROLES_BACKEND.JUNGLE;
    case 2:
      return ROLES_BACKEND.SUPPORT;
    case 3:
      return ROLES_BACKEND.BOTTOM;
    case 4:
      return ROLES_BACKEND.TOP;
    case 5:
      return ROLES_BACKEND.MIDDLE;
    case 7:
      return ROLES_BACKEND.ALL;
    case 8:
      return ROLES_BACKEND.LANE;
    default:
      return "";
  }
}

function normalizeRole(role) {
  switch (role) {
    case "top":
      return ROLES.TOP;
    case "middle":
    case "mid":
      return ROLES.MIDDLE;
    case "jungle":
    case "jung":
      return ROLES.JUNGLE;
    case "bottom":
    case "adc":
    case "bot":
      return ROLES.BOTTOM;
    case "support":
    case "supp":
    case "sup":
      return ROLES.SUPPORT;
    case "all":
      return ROLES.ALL;
    case "lane":
      return ROLES.LANE;
    default:
      return role;
  }
}

function getRoleImg(role, theme, active) {
  switch (normalizeRole(role)) {
    case ROLES.ALL:
      return getRoleUrl("all", theme, active);
    case ROLES.TOP:
      return getRoleUrl("top", theme, active);
    case ROLES.JUNGLE:
      return getRoleUrl("jung", theme, active);
    case ROLES.MIDDLE:
      return getRoleUrl("mid", theme, active);
    case ROLES.BOTTOM:
      return getRoleUrl("bot", theme, active);
    case ROLES.SUPPORT:
      return getRoleUrl("supp", theme, active);
    case ROLES.LANE:
      return getRoleUrl("bot", theme, active);
    default:
      return undefined;
  }
}

function getRoleSVG(role, { active, inactive, lightModeOn } = {}) {
  if (!lightModeOn) {
    switch (normalizeRole(role)) {
      case ROLES.ALL:
        return inactive ? <AllInactiveIcon /> : <AllIcon />;
      case ROLES.TOP:
        return active ? <TopActiveIcon /> : inactive ? <TopInactiveIcon /> : <TopIcon />;
      case ROLES.JUNGLE:
        return active ? <JungleActiveIcon /> : inactive ? <JungleInactiveIcon /> : <JungleIcon />;
      case ROLES.MIDDLE:
        return active ? <MidActiveIcon /> : inactive ? <MidInactiveIcon /> : <MidIcon />;
      case ROLES.BOTTOM:
        return active ? <BotActiveIcon /> : inactive ? <BotInactiveIcon /> : <BotIcon />;
      case ROLES.SUPPORT:
        return active ? <SupportActiveIcon /> : inactive ? <SupportInactiveIcon /> : <SupportIcon />;
      case ROLES.ARAM:
        return inactive ? <AramInactiveIcon /> : <AramIcon />;
      case ROLES.LANE:
        return active ? <BotActiveIcon /> : inactive ? <BotInactiveIcon /> : <BotIcon />;
      default:
        return null;
    }
  } else {
    switch (normalizeRole(role)) {
      case ROLES.ALL:
        return inactive ? <AllInactiveIconLight /> : <AllIconLight />;
      case ROLES.TOP:
        return active ? <TopActiveIconLight /> : inactive ? <TopInactiveIconLight /> : <TopIconLight />;
      case ROLES.JUNGLE:
        return active ? <JungleActiveIconLight /> : inactive ? <JungleInactiveIconLight /> : <JungleIconLight />;
      case ROLES.MIDDLE:
        return active ? <MidActiveIconLight /> : inactive ? <MidInactiveIconLight /> : <MidIconLight />;
      case ROLES.BOTTOM:
        return active ? <BotActiveIconLight /> : inactive ? <BotInactiveIconLight /> : <BotIconLight />;
      case ROLES.SUPPORT:
        return active ? <SupportActiveIconLight /> : inactive ? <SupportInactiveIconLight /> : <SupportIconLight />;
      case ROLES.ARAM:
        return inactive ? <AramInactiveIconLight /> : <AramIconLight />;
      case ROLES.LANE:
        return active ? <BotActiveIconLight /> : inactive ? <BotInactiveIconLight /> : <BotIconLight />;
      default:
        return null;
    }
  }
}

function getColorRoleImg(role, type) {
  switch (normalizeRole(role)) {
    case ROLES.ALL:
      return getColorRoleUrl("all", type);
    case ROLES.TOP:
      return getColorRoleUrl("top", type);
    case ROLES.JUNGLE:
      return getColorRoleUrl("jung", type);
    case ROLES.MIDDLE:
      return getColorRoleUrl("mid", type);
    case ROLES.BOTTOM:
      return getColorRoleUrl("bot", type);
    case ROLES.SUPPORT:
      return getColorRoleUrl("supp", type);
    case ROLES.LANE:
      return getColorRoleUrl("bot", type);
    default:
      return undefined;
  }
}

function getRoleLabel(role) {
  switch (normalizeRole(role)) {
    case 1:
    case "jungle":
      return "Jungle";
    case 2:
    case "support":
    case "supp":
      return "Support";
    case 3:
    case "adc":
      return "ADC";
    case 4:
    case "top":
      return "Top";
    case 5:
    case "mid":
    case "middle":
      return "Mid";
    case 6:
      return "";
    case 7:
    case "all":
      return "All Roles";
    case "aram":
      return "ARAM";
    case "urf":
      return "URF";
    case 8:
    case "lane":
      return "Lane";
    default:
      return "";
  }
}

function getRoleId(role) {
  switch (normalizeRole(role)) {
    case ROLES.ALL:
      return 7;
    case ROLES.TOP:
      return 4;
    case ROLES.JUNGLE:
      return 1;
    case ROLES.MIDDLE:
      return 5;
    case ROLES.BOTTOM:
      return 3;
    case ROLES.SUPPORT:
      return 2;
    case ROLES.LANE:
      return 8;
    default:
      return undefined;
  }
}

const sortTeamByRoles = (team) => {
  const teamOrder = [
    getRoleId(ROLES.TOP),
    getRoleId(ROLES.JUNGLE),
    getRoleId(ROLES.MIDDLE),
    getRoleId(ROLES.BOTTOM),
    getRoleId(ROLES.SUPPORT),
  ];

  return team.slice().sort((player1, player2) => {
    if (!player1.role || !player2.role) return 0;
    return teamOrder.indexOf(player1.role) - teamOrder.indexOf(player2.role);
  });
};

export { ROLES, getRoleImg, getRoleSVG, getColorRoleImg, normalizeRole, getRoleLabel, getRoleId, sortTeamByRoles };
