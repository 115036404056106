import TooltipContainerImport from "./src/TooltipContainer";
export * from "./src/tooltipImages";
export * from "./src/TooltipZoomProvider";
export const TooltipContainer = TooltipContainerImport;

export const TooltipOrientations = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
};
