import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import CustomInput from "components/CustomInput";

class ForgotPasswordInput extends Component {
  constructor() {
    super();
  }

  render() {
    const { className, value, error, placeholder, onChange, tabIndex, autoFocus } = this.props;

    return (
      <div className="password-input_with-forgot-pw">
        <CustomInput
          className={className}
          type="password"
          value={value}
          error={error}
          onChange={onChange}
          placeholder={placeholder}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
        ></CustomInput>
      </div>
    );
  }
}

ForgotPasswordInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

ForgotPasswordInput.defaultProps = {
  className: "",
  placeholder: "",
  tabIndex: -1,
};

export default ForgotPasswordInput;
