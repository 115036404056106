import React from "react";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const SummonerSpellTooltip = (props) => {
  const { getSummonerSpells, useRiotSummoners } = getRiotAssetsContext();
  const { spellId, patch, language } = props;

  const { data: spells, loading: fetchingSpells, error: errorSpells } = useRiotSummoners({ patch, language, skip: true });

  let spellsJSON = spells || getSummonerSpells({ language, patch });

  const data = spellsJSON && spellsJSON[spellId];
  if (!data) return null;

  return (
    <div className="tooltip-summoner-spell">
      <div className="name mb-[12px]">{data.name}</div>
      <div dangerouslySetInnerHTML={{ __html: data.description }} />
    </div>
  );
};

export default SummonerSpellTooltip;
