import { addReducer } from "reactn";

const UPDATE_STATIC_JSON = {
  type: "UPDATE_STATIC_JSON",
  actionFn: (global, dispatch, url, value) => ({
    [url]: value,
  }),
};

export const initJSONFetcher = (Provider) => {
  const actions = [UPDATE_STATIC_JSON];

  actions.forEach((action) => {
    if (Provider) {
      Provider.addReducer(action.type, action.actionFn);
    } else {
      addReducer(action.type, action.actionFn);
    }
  });
};
