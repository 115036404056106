import { useQuery, QueryHookOptions } from "@apollo/client";
import gql from "graphql-tag";
import { LolSettings } from "@ugg/shared/api/graphql/queries";

export const UserStateSchema = `
  firstName
  lastName
  isVerified
  googleAnalyticsId
  loginState {
    token
  }
  lolSettings {
    ${LolSettings}
  }
  filterDefaults {
    filterTierList {
      mmr
      queueType
      regionId
      role
      showAllChampions
    }
    filterBuildsAndRunes {
      mmr
      queueType
      regionId
    }
  }
  premium {
    isPremium
    premiumVersion
    freeTrialAvailable
    isOnFreeTrial
    hasFailedPayment
    paymentFailureDate
  }
`;

export interface UserState {
  firstName: string;
  lastName: string;
  isVerified: boolean;
  googleAnalyticsId: string;
  loginState: {
    token: string;
  };
  lolSettings: {
    summoners: Array<{
      isDefault: boolean;
      regionId: string;
      riotTagLine: string;
      riotUserName: string;
      verified: boolean;
      iconUrl: string;
      iconId: string;
    }>;
    favoriteChampions: Array<{
      championId: number;
    }>;
    favoriteSummoners: Array<{
      regionId: string;
      riotTagLine: string;
      riotUserName: string;
      iconUrl: string;
      iconId: string;
    }>;
  };
  filterDefaults: {
    filterTierList: {
      mmr: string;
      queueType: string;
      regionId: string;
      role: string;
      showAllChampions: boolean;
    };
    filterBuildsAndRunes: {
      mmr: string;
      queueType: string;
      regionId: string;
    };
  };
  premium: {
    isPremium: boolean;
    premiumVersion: string;
    freeTrialAvailable: boolean;
    isOnFreeTrial: boolean;
    hasFailedPayment: boolean;
    paymentFailureDate: number;
  };
}

export const GET_USER_STATE = gql`
  query {
    getState {
      ${UserStateSchema}
    }
  }
`;

export interface UserStateData {
  getState: UserState;
}

// Get user premium data
export const useUserPremiumState = (options?: QueryHookOptions) => {
  const { data, ...restQuery } = useQuery<UserStateData>(GET_USER_STATE, options);
  return { ...restQuery, data: data?.getState.premium };
};

export const useUserState = (options?: QueryHookOptions) => {
  return useQuery<UserStateData>(GET_USER_STATE, options);
};

// Get user's summoner name data
export const useUserSummonerName = (options?: QueryHookOptions) => {
  const { data: userState, ...restQuery } = useQuery<UserStateData>(GET_USER_STATE, options);
  const { lolSettings } = (userState && userState.getState) || {};
  const { summoners } = lolSettings || {};
  const { riotUserName, riotTagLine, regionId, verified, iconId } = (summoners && summoners[0]) || {};

  return { ...restQuery, data: summoners?.[0] ? { riotUserName, riotTagLine, regionId, verified, iconId } : null };
};
