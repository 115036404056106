import React, { Component, useEffect } from "react";
import { useGlobal } from "reactn";
import PropTypes from "prop-types";
import classnames from "classnames";

import CustomInput from "components/CustomInput";
import RegionSelector from "components/Masthead/RegionSelector";

const SummonerFinder = (props) => {
  const [clientRegion] = useGlobal("clientRegion");
  const {
    color,
    regionSelectorPosition,
    placeholder,
    riotId,
    summonerRegion,
    onSummonerChange,
    onRegionChange,
    tabIndex,
    inputRef,
    autoFocus,
    error,
    dontDefaultUniversalRegion,
  } = props;

  useEffect(() => {
    // Default initial region to saved universal region
    if (!dontDefaultUniversalRegion && !summonerRegion) {
      onRegionChange(clientRegion);
    }
  }, []);

  const summonerFinderClassNames = classnames({
    "summoner-finder": true,
    "summoner-finder_white": color === "white",
  });

  const regionClassNames = classnames({
    "summoner-finder_region-selector": true,
    "summoner-finder_region-selector__right": regionSelectorPosition === "right",
  });

  return (
    <div className={summonerFinderClassNames}>
      <div className={regionClassNames}>
        <RegionSelector currentRegion={summonerRegion} onApply={onRegionChange} disableTooltip isMasthead />
      </div>
      <CustomInput
        onCreateRef={inputRef}
        value={riotId}
        onChange={onSummonerChange}
        placeholder={placeholder}
        type="text"
        tabIndex={tabIndex}
        autoFocus={autoFocus}
        error={error}
        hideErrorMessage
      />
    </div>
  );
};

export default SummonerFinder;

SummonerFinder.propTypes = {
  riotId: PropTypes.string.isRequired,
  summonerRegion: PropTypes.string.isRequired,
  onSummonerChange: PropTypes.func.isRequired,
  onRegionChange: PropTypes.func.isRequired,
};

SummonerFinder.defaultProps = {
  placeholder: "Riot ID",
};
