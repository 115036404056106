import { useState, useEffect, useRef } from "react";
import { useGlobal } from "reactn";
import classNames from "classnames";

const AVAILABLE_REGIONS = [
  { value: "na1", label: "North America", shorthandLabel: "NA" },
  { value: "euw1", label: "Europe West", shorthandLabel: "EUW" },
  { value: "eun1", label: "Europe Nordic & East", shorthandLabel: "EUN" },
  { value: "kr", label: "Korea", shorthandLabel: "KR" },
  { value: "br1", label: "Brazil", shorthandLabel: "BR" },
  { value: "jp1", label: "Japan", shorthandLabel: "JP" },
  { value: "ru", label: "Russia", shorthandLabel: "RU" },
  { value: "oc1", label: "Oceania", shorthandLabel: "OCE" },
  { value: "tr1", label: "Turkey", shorthandLabel: "TR" },
  { value: "la1", label: "Latin America North", shorthandLabel: "LAN" },
  { value: "la2", label: "Latin America South", shorthandLabel: "LAS" },
  { value: "ph2", label: "Philippines", shorthandLabel: "PH" },
  { value: "sg2", label: "Singapore", shorthandLabel: "SG" },
  { value: "th2", label: "Thailand", shorthandLabel: "TH" },
  { value: "tw2", label: "Taiwan", shorthandLabel: "TW" },
  { value: "vn2", label: "Vietnam", shorthandLabel: "VN" },
  { value: "me1", label: "Middle East", shorthandLabel: "ME" },
] as const;

type AvailableRegionReducType = Record<string, (typeof AVAILABLE_REGIONS)[number]>;
const KEY_TO_AVAILABLE_REGION = AVAILABLE_REGIONS.reduce<AvailableRegionReducType>((acc, curr) => {
  acc[curr.value] = curr;

  return acc;
}, {});

interface SearchBarRegionSelectorProps {
  theme: "dark" | "light";
  noLabels?: boolean;
}

export default function SearchBarRegionSelector(props: SearchBarRegionSelectorProps) {
  const { theme, noLabels = false } = props;
  const [clientRegion, setClientRegion] = useGlobal("clientRegion");
  const [open, setOpen] = useState(false);
  const regionSelectorRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const innerMenuRef = useRef<HTMLDivElement>(null);
  const [menuHeight, setMenuHeight] = useState<number | undefined>(noLabels ? 250 : undefined);

  const clientRegionData = KEY_TO_AVAILABLE_REGION[clientRegion] || {};

  const onRegionSelect = (value: string) => {
    localStorage.setItem("summonerRegion", value);
    setClientRegion(value);
    setOpen(false);
  };

  useEffect(() => {
    const outsideClick = (e: MouseEvent) => {
      if (
        regionSelectorRef.current &&
        e.target !== null &&
        !regionSelectorRef.current.contains(e.target as HTMLElement) &&
        document.contains(e.target as HTMLElement)
      ) {
        setOpen(false);
      }
    };

    const windowResize = (e: UIEvent) => {
      if (menuRef.current && innerMenuRef.current && !noLabels) {
        const outerMenu = menuRef.current.getBoundingClientRect();
        const innerMenu = innerMenuRef.current.getBoundingClientRect();
        if (window.innerHeight - innerMenu.bottom >= 0) {
          setMenuHeight(undefined);
        } else {
          setMenuHeight(window.innerHeight - outerMenu.top - 15);
        }
      }
    };

    window.addEventListener("click", outsideClick);
    window.addEventListener("resize", windowResize);
    return () => {
      window.removeEventListener("click", outsideClick);
      window.removeEventListener("click", windowResize);
    };
  }, [open]);

  return (
    <div
      className={classNames(`search-bar-region-selector search-bar-region-selector_theme__${theme}`, {
        "no-labels-homepage": noLabels,
      })}
      ref={regionSelectorRef}
    >
      {clientRegionData.value && (
        <div
          className={classNames("menu-button", "region-tag", "selected", `bg-${clientRegionData.value}`)}
          onClick={() => setOpen((prevState) => !prevState)}
        >
          {clientRegionData.shorthandLabel}
        </div>
      )}
      {open && (
        <div className="menu" ref={menuRef} style={{ height: menuHeight || "auto" }}>
          <div className="inner-menu" ref={innerMenuRef}>
            {!noLabels ? <div className="title">Region</div> : null}
            <div className="options">
              {AVAILABLE_REGIONS.map(({ value, label, shorthandLabel }) => (
                <li key={value} onClick={() => onRegionSelect(value)}>
                  <div className={classNames("region-tag", `bg-${value}`, value === clientRegion ? "selected" : "")}>
                    {shorthandLabel}
                  </div>
                  {!noLabels ? <div className="region-label">{label}</div> : null}
                </li>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
