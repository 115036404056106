import * as React from "react";
import type { SVGProps } from "react";
export const SvgGameLolGrey = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 32 32" {...props}>
    <path fill="currentColor" d="M15.176 3.333H7.155l2.11 2.955v19.419l-2.532 2.955h18.152l2.955-4.221H15.176z" />
    <path
      fill="currentColor"
      d="M7.577 8.57A11.78 11.78 0 0 0 4.2 16.842c0 3.213 1.282 6.127 3.362 8.257l.015-.017v-2.638a10.1 10.1 0 0 1-1.689-5.602c0-2.072.622-3.998 1.69-5.602zM24.25 22.752a10.1 10.1 0 0 0 1.901-5.91c0-5.311-4.087-9.668-9.287-10.097V5.052c6.134.433 10.976 5.546 10.976 11.79 0 2.153-.576 4.171-1.582 5.91z"
    />
  </svg>
);
