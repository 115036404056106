import { normalizeRole, RoleS } from "@ugg/shared/utils/role-helpers";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { stdevAndMean } from "@ugg/shared/utils/math";
import { TierList, Champion, Counter, Role, RoleData } from "@ugg/shared/interfaces/tier-lists/tier-list.interface";

export interface BadAgainstChampion {
  champion_id: Counter[0];
  wins: Counter[1];
  matches: Counter[2];
  win_rate: number;
  opp_win_rate: number;
}

export interface TierListChampionInfo {
  champion_id: Champion[0];
  champion_link: {
    champion_id: Champion[0];
    role: RoleS;
  };
  tier: {
    pick_rate: number;
    stdevs: number;
  };
  worst_against: {
    bad_against: BadAgainstChampion[];
    champion_id: Champion[0];
    role: RoleS;
  };
  role: RoleS;
  matches: Champion[3];
  win_rate: number;
  pick_rate: number;
  ban_rate: number;
  avg_damage: number;
  avg_kda: number;
  avg_cs: number;
  avg_gold: number;
}

export function cleanTierList(data: TierList) {
  const win_rates: Partial<Record<RoleS, TierListChampionInfo[]>> = {};
  const winRatesArray = data[0];
  const ban_rates = data[1];
  const last_updated_at = data[2];
  const queue_type_total_matches = Math.round(data[3]);

  const entries: Array<[Role, RoleData]> = Object.entries(winRatesArray) as any;
  for (const [role, roleData] of entries) {
    const normalizedRole = normalizeRole(role) as RoleS;
    const role_array: TierListChampionInfo[] = [];
    roleData.forEach((champInfo) => {
      const bad_against_mini = champInfo[1];
      let bad_against: BadAgainstChampion[] = [];
      bad_against_mini.forEach((baddie) => {
        const new_baddie = {
          champion_id: baddie[0],
          wins: baddie[1],
          matches: baddie[2],
          win_rate: (1 - baddie[1] / baddie[2]) * 100,
          opp_win_rate: (baddie[1] / baddie[2]) * 100,
        };
        bad_against.push(new_baddie);
      });

      const champion_id = champInfo[0];

      const cur_champ_info = {
        champion_id,
        worst_against: {
          bad_against,
          champion_id,
          role: normalizedRole,
        },
        wins: champInfo[2],
        matches: champInfo[3],
        damage: champInfo[4],
        gold: champInfo[5],
        kills: champInfo[6],
        assists: champInfo[7],
        deaths: champInfo[8],
        cs: champInfo[9],
      };

      const calc_info = {
        win_rate: (cur_champ_info.wins / cur_champ_info.matches) * 100,
        pick_rate: (cur_champ_info.matches / queue_type_total_matches) * 100,
        ban_rate: ban_rates[champion_id] ? (ban_rates[champion_id] / ban_rates["total_matches"]) * 100 : 0,
        avg_damage: cur_champ_info.damage / cur_champ_info.matches / 1000,
        avg_kda: (cur_champ_info.kills + cur_champ_info.assists) / cur_champ_info.deaths,
        avg_cs: cur_champ_info.cs / cur_champ_info.matches,
        avg_gold: cur_champ_info.gold / cur_champ_info.matches,
      };

      const champ_info: TierListChampionInfo = {
        ...calc_info,
        champion_id,
        role: normalizedRole,
        champion_link: {
          champion_id,
          role: normalizedRole,
        },
        worst_against: cur_champ_info["worst_against"],
        matches: cur_champ_info.matches,
        tier: {
          pick_rate: undefined!,
          stdevs: undefined!,
        },
        // effective_win_rate
      };

      role_array.push(champ_info);
    });

    const sorted_role_array = role_array.sort((a, b) => {
      // Sort by Win Rate
      return b.win_rate - a.win_rate;

      // Sort by Tier
      // if(a.tier.stdevs > b.tier.stdevs) {
      //   if(a.tier.pick_rate >= .5 && b.tier.pick_rate >= .5 ) return 1;
      //   else if(a.tier.pick_rate >= .5 && b.tier.pick_rate < .5 ) return 1;
      //   else if(a.tier.pick_rate < .5 && b.tier.pick_rate >= .5 ) return -1;
      //   else if(a.tier.pick_rate < .5 && b.tier.pick_rate < .5) {
      //     if(a.tier.stdevs > b.tier.stdevs) return 1;
      //     else if(a.tier.stdevs < b.tier.stdevs) return -1;
      //   }
      // }
      // else if(a.tier.stdevs < b.tier.stdevs) {
      //   if(a.tier.pick_rate >= .5 && b.tier.pick_rate >= .5 ) return -1;
      //   else if(a.tier.pick_rate >= .5 && b.tier.pick_rate < .5 ) return 1;
      //   else if(a.tier.pick_rate < .5 && b.tier.pick_rate >= .5 ) return -1;
      //   else if(a.tier.pick_rate < .5 && b.tier.pick_rate < .5) {
      //     if(a.tier.stdevs > b.tier.stdevs) return 1;
      //     else if(a.tier.stdevs < b.tier.stdevs) return -1;
      //   }
      // }
      // return 0
    });

    win_rates[normalizedRole] = sorted_role_array;
  }

  const pickRateMap: { [championId: Champion[0]]: number } = {};
  const roleArray = Object.values(win_rates);
  for (const champions of roleArray) {
    champions.forEach((champion) => {
      if (pickRateMap[champion.champion_id]) {
        pickRateMap[champion.champion_id] += champion.pick_rate;
      } else {
        pickRateMap[champion.champion_id] = champion.pick_rate;
      }
    });
  }

  for (const champions of roleArray) {
    const effective_win_rates: { [championId: Champion[0]]: number } = [];
    champions.forEach((champion) => {
      const { champion_id, ban_rate, pick_rate, win_rate } = champion;

      const total_pick_rate = pickRateMap[champion_id];
      const true_ban_rate = (pick_rate / total_pick_rate) * ban_rate;

      let effective_win_rate =
        win_rate >= 50
          ? (true_ban_rate / 100) * ((win_rate / 100 + 0.53) / 2) +
            (pick_rate / 100) * (win_rate / 100) +
            (1 - true_ban_rate / 100 - pick_rate / 100) * 0.5
          : (true_ban_rate / 100) * ((win_rate / 100 + 0.505) / 2) +
            (pick_rate / 100) * (win_rate / 100) +
            (1 - true_ban_rate / 100 - pick_rate / 100) * 0.5;

      effective_win_rate = effective_win_rate * 100;

      effective_win_rates[champion_id] = effective_win_rate;
    });

    const maths = stdevAndMean(Object.values(effective_win_rates));
    for (let i = 0; i < champions.length; i++) {
      const effective_win_rate = effective_win_rates[champions[i]["champion_id"]];
      champions[i]["tier"] = {
        pick_rate: champions[i].pick_rate,
        stdevs: (effective_win_rate - maths.mean) / maths.stdev,
      };
    }
  }

  const all_roles_array: TierListChampionInfo[] = roleArray.reduce((acc, curr) => {
    return [...acc, ...curr];
  }, []);
  const sorted_roles_array = all_roles_array.sort((a, b) => {
    // Sort by Win Rate
    return b.win_rate - a.win_rate;
  });

  win_rates[RoleS.ALL] = sorted_roles_array;

  return {
    win_rates,
    last_updated_at,
    total_matches: data[3],
    queue_type_total_matches,
  };
}

export function selectWinRate(data: ReturnType<typeof cleanTierList>, params: { queueType: string; role: string }) {
  let role_win_rates, last_updated, total_matches_count;

  if (!data) {
    return { role_win_rates, last_updated, total_matches_count };
  }

  let { queueType, role } = params;

  if (
    queueType === QueueTypeS.ARAM ||
    queueType === QueueTypeS.PICK_URF ||
    queueType === QueueTypeS.ARURF ||
    queueType === QueueTypeS.ARENA
  ) {
    role = RoleS.NONE;
  } else {
    role = normalizeRole(role, RoleS.ALL);
  }

  role_win_rates = data.win_rates[role as RoleS];
  last_updated = data.last_updated_at;
  total_matches_count = data.queue_type_total_matches;

  return { role_win_rates, last_updated, total_matches_count };
}
