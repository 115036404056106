import React from "react";
import { twMerge } from "tailwind-merge";
import { useMemo } from "react";
import sizes from "@ugitgud/legos/plugins/sizes.json";
import classNames from "classnames";
import {
  ButtonSize,
  IconButtonSize,
  PolymorphicProps,
  WithClassName,
  WithColorScheme,
  WithToggled,
  XOR,
} from "@ugitgud/legos/ui/shared";
import { useColorScheme } from "@ugitgud/legos/hooks/useColorScheme";

const colorSchemeClasses = {
  primary: "dark:bg-accent-blue-400 dark:hover:bg-accent-blue-600 dark:text-white",
  secondary:
    "dark:bg-lavender-500 dark:hover:bg-lavender-600 dark:text-white dark:ui-toggled:bg-lavender-500 dark:ui-toggled:font-bold",
  "secondary-alt":
    "dark:bg-purple-400 dark:hover:bg-lavender-600 dark:text-white dark:ui-toggled:bg-lavender-500 dark:ui-toggled:font-bold",
  ghost: "dark:bg-transparent dark:hover:bg-lavender-600 dark:text-white",
};

export type ButtonColorScheme = keyof typeof colorSchemeClasses;

type ButtonExtraProps = WithClassName & WithToggled & WithColorScheme<{}, ButtonColorScheme>;
type IconChildrenProps<TChildren = React.ReactNode> = XOR<
  {
    icon: JSX.Element;
    size?: IconButtonSize;
    children?: never;
  },
  {
    icon?: never;
    size?: ButtonSize;
    children: TChildren;
  }
>;

export type BaseButtonProps<T extends React.ElementType, TChildren = React.ReactNode, P = {}> = PolymorphicProps<T> &
  IconChildrenProps<TChildren> &
  ButtonExtraProps;

export const Button = React.forwardRef(
  <T extends React.ElementType = "button">(props: BaseButtonProps<T>, ref: React.ForwardedRef<React.ElementRef<T>>) => {
    const { as, toggled, icon, children, size = "md", colorScheme, className, ...rest } = props;
    const dataProp = useMemo(() => (typeof toggled !== "undefined" ? { "data-toggled": toggled } : {}), [toggled]);
    const colorSchemeDef = useColorScheme({
      classes: colorSchemeClasses,
      scheme: colorScheme,
      common: "leading-none",
      default: "secondary",
    });
    const sizeClasses = icon ? `icon-button-${size}` : sizes.buttonSizes[size as ButtonSize];
    const mergedClasses = useMemo(
      () => twMerge(`flex items-center justify-center group rounded-sm ${classNames(sizeClasses, colorSchemeDef, className)}`),
      [size, className],
    );

    const finalProps = {
      ...dataProp,
      ...rest,
      children: icon || children,
      className: mergedClasses,
    };

    const Component = (as as React.ElementType) || ("button" as React.ElementType);
    return <Component {...finalProps} ref={ref} />;
  },
);
