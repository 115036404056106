export function splitRiotId(riotId: string, delimiter = "#") {
  let [inputRiotUserName, inputRiotTagLine = ""] = (riotId || "").split(delimiter || "#");
  inputRiotUserName = inputRiotUserName.trim();

  return [inputRiotUserName, inputRiotTagLine];
}

export function normalizeRiotId(riotId: string): string;
export function normalizeRiotId(riotId: { riotUserName: string; riotTagLine: string }): {
  riotUserName: string;
  riotTagLine: string;
};
export function normalizeRiotId(
  riotId: string | { riotUserName: string; riotTagLine: string },
): string | { riotUserName: string; riotTagLine: string } {
  if (typeof riotId === "string") {
    const [name, tag] = splitRiotId(riotId);
    return `${name.toLowerCase().replace(/ /g, "")}#${tag.toLowerCase()}`;
  }

  return {
    riotUserName: riotId.riotUserName.toLowerCase().replace(/ /g, ""),
    riotTagLine: riotId.riotTagLine.toLowerCase(),
  };
}
