import React, { useContext } from "react";

export const TooltipContext = React.createContext();

export const TooltipZoomProvider = (props) => {
  {
    const value = isNaN(props.zoom) || props.zoom <= 0 ? 1 : props.zoom;
    return <TooltipContext.Provider value={value}>{props.children}</TooltipContext.Provider>;
  }
};
