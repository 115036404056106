import { STATIC_LOL_URL } from "@outplayed/riot-assets";
import { useJSONFetcher, JSONFetcherOptions } from "@outplayed/json-fetcher";
import { useUGGAssetsContext } from "@ugg/shared/components/UGGAssetsProvider";

type BlastedPatchType = {
  id: `${number}_${number}`;
  season: `${number}`;
  start_time: number;
};
function compareNewestPatches(a: BlastedPatchType, b: BlastedPatchType) {
  if (a.start_time < b.start_time) return 1;
  if (a.start_time > b.start_time) return -1;
  return 0;
}

type UGGPatchesData = Array<`${number}_${number}`>;
export function useUGGPatches(options?: { ssr?: boolean; skip?: boolean }) {
  const { ssr = false, skip = false } = options || {};
  const { staging } = useUGGAssetsContext();
  const envDir = staging ? "staging" : "prod";
  return useJSONFetcher<UGGPatchesData>(`${STATIC_LOL_URL}/riot_patch_update/${envDir}/ugg/patches.json`, { ssr, skip });
}

type BlastedPatchesData = { patches: BlastedPatchType[] };
export function useBlastedPatches(options?: JSONFetcherOptions<BlastedPatchesData, BlastedPatchType[]>) {
  return useJSONFetcher<BlastedPatchesData, BlastedPatchType[]>("https://lolpatches.s3.us-east-2.amazonaws.com/patches.json", {
    onCompleted: (url, data) => {
      if (data.patches) {
        // patch list can contain future patches, filter them out and sort by timestamp
        let patches = data.patches.filter((patch) => patch.start_time <= new Date().getTime() / 1000);
        patches = patches.sort(compareNewestPatches);
        return patches;
      } else {
        return [];
      }
    },
    ...(options || {}),
  });
}
