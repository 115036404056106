import { normalizeRole, RoleS } from "@ugg/shared/utils/role-helpers";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { stdevAndMean } from "@ugg/shared/utils/math";
import { ArenaTierList, Champion, GoodWith } from "@ugg/shared/interfaces/tier-lists/arena-tier-list.interface";

interface GoodWithChampion {
  champion_id: GoodWith[0];
  wins: GoodWith[1];
  matches: GoodWith[2];
  win_rate: number;
}

export interface ArenaTierListChampionInfo {
  champion_id: Champion[0];
  tier: {
    pick_rate: number;
    stdevs: number;
  };
  best_with: {
    good_with: GoodWithChampion[];
    champion_id: Champion[0];
  };
  matches: Champion[3];
  top1_rate: number;
  top2_rate: number;
  avg_placement: number;
  pick_rate: number;
  ban_rate: number;
  avg_damage: number;
  avg_kda: number;
  avg_cs: number;
  avg_gold: number;
}

export function cleanArenaTierList(data: ArenaTierList) {
  const win_rates: { [RoleS.NONE]: ArenaTierListChampionInfo[] } = { [RoleS.NONE]: [] };
  const winRatesArray = data[0];
  const ban_rates = data[1];
  const last_updated_at = data[2];
  const queue_type_total_matches = Math.round(data[3]);

  const parsedData = winRatesArray[RoleS.NONE].map((champInfo) => {
    const good_with_mini = champInfo[1];
    let good_with: GoodWithChampion[] = [];
    good_with_mini.forEach((champ) => {
      good_with.push({
        champion_id: champ[0],
        wins: champ[1],
        matches: champ[2],
        win_rate: (1 - champ[1] / champ[2]) * 100,
      });
    });

    const champion_id = champInfo[0];

    const cur_champ_info = {
      champion_id,
      best_with: {
        good_with,
        champion_id,
      },
      top2Matches: champInfo[2],
      matches: champInfo[3],
      damage: champInfo[4],
      gold: champInfo[5],
      kills: champInfo[6],
      assists: champInfo[7],
      deaths: champInfo[8],
      cs: champInfo[9],
      top1Matches: champInfo[10],
      totalPlacements: champInfo[11],
    };

    const calc_info = {
      top1_rate: (cur_champ_info.top1Matches / cur_champ_info.matches) * 100,
      top2_rate: (cur_champ_info.top2Matches / cur_champ_info.matches) * 100,
      avg_placement: cur_champ_info.totalPlacements / cur_champ_info.matches,
      pick_rate: (cur_champ_info.matches / queue_type_total_matches) * 100,
      ban_rate: ban_rates[champion_id] ? (ban_rates[champion_id] / ban_rates["total_matches"]) * 100 : 0,
      avg_damage: cur_champ_info.damage / cur_champ_info.matches / 1000,
      avg_kda: (cur_champ_info.kills + cur_champ_info.assists) / cur_champ_info.deaths,
      avg_cs: cur_champ_info.cs / cur_champ_info.matches,
      avg_gold: cur_champ_info.gold / cur_champ_info.matches,
    };

    const champ_info: ArenaTierListChampionInfo = {
      ...calc_info,
      champion_id,
      best_with: cur_champ_info["best_with"],
      matches: cur_champ_info.matches,
      tier: {
        pick_rate: undefined!,
        stdevs: undefined!,
      },
      // effective_win_rate
    };

    return champ_info;
  });

  const sorted_role_array = parsedData.sort((a, b) => {
    return b.top2_rate - a.top2_rate;
  });

  win_rates[RoleS.NONE] = sorted_role_array;

  const pickRateMap: { [championId: Champion[0]]: number } = {};
  const roleArray = Object.values(win_rates);
  for (const champions of roleArray) {
    champions.forEach((champion) => {
      if (pickRateMap[champion.champion_id]) {
        pickRateMap[champion.champion_id] += champion.pick_rate;
      } else {
        pickRateMap[champion.champion_id] = champion.pick_rate;
      }
    });
  }

  for (const champions of roleArray) {
    const effective_win_rates: { [championId: Champion[0]]: number } = [];
    champions.forEach((champion) => {
      const { champion_id, ban_rate, pick_rate, top2_rate } = champion;

      const total_pick_rate = pickRateMap[champion_id];
      const true_ban_rate = (pick_rate / total_pick_rate) * ban_rate;

      let effective_win_rate =
        top2_rate >= 50
          ? (true_ban_rate / 100) * ((top2_rate / 100 + 0.53) / 2) +
            (pick_rate / 100) * (top2_rate / 100) +
            (1 - true_ban_rate / 100 - pick_rate / 100) * 0.5
          : (true_ban_rate / 100) * ((top2_rate / 100 + 0.505) / 2) +
            (pick_rate / 100) * (top2_rate / 100) +
            (1 - true_ban_rate / 100 - pick_rate / 100) * 0.5;

      effective_win_rate = effective_win_rate * 100;

      effective_win_rates[champion_id] = effective_win_rate;
    });

    const maths = stdevAndMean(Object.values(effective_win_rates));
    for (let i = 0; i < champions.length; i++) {
      const effective_win_rate = effective_win_rates[champions[i]["champion_id"]];
      champions[i]["tier"] = {
        pick_rate: champions[i].pick_rate,
        stdevs: (effective_win_rate - maths.mean) / maths.stdev,
      };
    }
  }

  return {
    win_rates,
    last_updated_at,
    total_matches: queue_type_total_matches,
  };
}
