import dark404Img from "../../assets/images/404-dark.png";

export default function Error404() {
  return (
    <div className="error-404 content-side-padding">
      <div className="page-not-found">
        <div>
          <div>
            <div>
              <h1>THIS PAGE</h1>
              <h1>DOESN'T EXIST</h1>
            </div>
            <div className="grey-text" style={{ marginTop: 30 }}>
              <span>Just like your team's map awareness.</span>
            </div>
          </div>
          <hr />
          <div>
            <div>
              <span>The page you're looking for isn't available.</span>
            </div>
            <div>
              <span>Try to search again.</span>
            </div>
          </div>
        </div>
        <img className="img-404" src={dark404Img} alt="404" />
      </div>
    </div>
  );
}
