import React, { useState } from "react";
import { useGlobal } from "reactn";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_USER_SETTINGS } from "@ugg/shared/api/graphql/queries";
import VerificationRiotIdPage from "./VerificationRiotIdPage";
import VerificationIconPage from "./VerificationIconPage";
import { directToPage } from "lib/redirect-helpers";
import { useLoginState } from "lib/hooks/userHooks";

const VerificationContainer = (props) => {
  const [currentStep, setStep] = useState(1);
  const [iconID, setIconID] = useState(null);
  const history = useHistory();
  const [loggingIn] = useGlobal("loggingIn");
  const isLoggedIn = useLoginState();

  if (!loggingIn && !isLoggedIn) {
    directToPage("home")();
  }

  const { loading, error, data } = useQuery(GET_USER_SETTINGS, {
    fetchPolicy: "network-only",
  });

  const incStep = (data) => {
    setStep(currentStep + 1);
    setIconID(data);
  };

  if (loading) {
    return (
      <div className="verification-container">
        <div className="spinthatshit-loader"></div>
      </div>
    );
  } else if (error || !data) {
    return null;
  }

  const { regionId, riotUserName, riotTagLine } = data.getSettings.lolSettings.summoners[0];
  return (
    <div className="verification-container">
      <div className="logo-container">
        <img className="logo" src="https://static.bigbrain.gg/assets/ugg/logo/ugg-logo.svg" onClick={() => history.push("/")} />
      </div>
      <div className="divider" />
      {currentStep === 1 && (
        <VerificationRiotIdPage
          riotId={riotUserName && riotTagLine ? `${riotUserName} #${riotTagLine}` : ""}
          regionId={regionId}
          incStep={incStep}
        />
      )}
      {currentStep === 2 && <VerificationIconPage iconID={iconID} />}
    </div>
  );
};

export default VerificationContainer;
