import React, { useRef } from "react";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const ChampionSkillTooltip = (props) => {
  const { useChampionIndiv, getIndividualChampion, getChampionTooltipJSON } = getRiotAssetsContext();
  const { patch, language, championId, skillSlot } = props;

  const isMissingData = useRef(false);
  const {
    data: championIndiv,
    loading: fetchingChampionIndiv,
    error: errorChampionIndiv,
  } = useChampionIndiv(championId, { patch, language, skip: true });
  const { data: championTooltip, loading: fetchingChampionTooltip, error: errorChampionTooltip } = getChampionTooltipJSON(patch);

  const getVarType = (type) => {
    switch (type) {
      case "spelldamage":
        return "% AP";
      case "@dynamic.abilitypower":
        return "% AP";
      case "attackdamage":
        return "% AD";
      case "bonusattackdamage":
        return "% bonus AD";
      case "armor":
        return "%";
      case "health":
        return "%";
      case "bonushealth":
        return "%";
      case "@text":
        return "";
      default:
        return "";
    }
  };

  const cleanValue = (value) => {
    return value.replace(/{/g, "").replace(/}/g, "");
  };

  const getVarFromRiot = (skillJSON, match, championId) => {
    const clean = cleanValue(match);
    const variable = match.substring(3, match.length - 3);
    const varType = variable.charAt(0);
    const varIndex = variable.substr(1);
    const vars = skillJSON.vars.find((el) => el.key === variable);
    let newSubstr = "?";
    switch (varType) {
      case "e":
        newSubstr = skillJSON.effectBurn[varIndex];
        break;
      case "a":
        newSubstr = vars === undefined ? "?" : `${Math.round(vars.coeff * 100)}${getVarType(vars.link)}`;
        break;
      case "f":
        newSubstr = vars === undefined ? "?" : `${Math.round(vars.coeff * 100)}${getVarType(vars.link)}`;
        // Vi
        if (championId === "254" && vars && vars.link === "@special.viw") {
          newSubstr = `1% per ${vars.coeff} bonus AD`;
        }
        // Nasus
        if (championId === "75" && vars && vars.link === "@stacks") {
          newSubstr = `total stacks`;
        }
        break;
      default:
        newSubstr = vars === undefined ? "?" : vars.coeff;
    }

    if (vars && Array.isArray(vars.coeff)) {
      newSubstr = vars.coeff.join("/");
    }

    if (newSubstr === undefined) {
      newSubstr = "?";
    }

    if (newSubstr === "?") {
      if (championTooltip) {
        const championIdName = championJSON["id"];
        if (championTooltip[championIdName]) {
          const skillSlotMatches = championTooltip[championIdName][skillSlot];
          const cleanTrim = clean.trim();
          if (cleanTrim in skillSlotMatches && skillSlotMatches[cleanTrim] !== null) {
            newSubstr = skillSlotMatches[cleanTrim];
          }
        }
      }
    }

    return newSubstr;
  };

  const replaceVars = (skillJSON) => {
    isMissingData.current = false;

    let tooltip = skillJSON.tooltip;
    if (!tooltip) return "";

    let allMatches = tooltip.match(/{([^}]+)}/g) || [];
    allMatches = allMatches.map((x) => {
      return x.concat("}");
    });

    let completedVars = [];
    for (let i = 0; i < allMatches.length; i++) {
      let completedVar = getVarFromRiot(skillJSON, allMatches[i], championId);
      completedVars.push(completedVar);
    }

    if (completedVars.includes("?")) {
      isMissingData.current = true;
    }

    for (let i = 0; i < allMatches.length; i++) {
      tooltip = tooltip.replace(allMatches[i], completedVars[i]);
    }

    return tooltip;
  };

  const championJSON = championIndiv || getIndividualChampion(championId, { patch, language });

  if (!championJSON?.skills && !championJSON?.spells) {
    return null;
  }

  const { skills, spells } = championJSON;
  const data = (spells || skills)[skillSlot];
  const tooltip = replaceVars(data);

  return (
    <div className="tooltip-champion-skill">
      <div className="mb-[12px]">
        <div className="name">{data.name}</div>
        {data.cooldown && <div className="cooldown">{`Cooldown: ${data.cooldownBurn}`}</div>}
      </div>
      <div dangerouslySetInnerHTML={{ __html: tooltip }} />
      {isMissingData.current && (
        <div className="no-data-message mt-[12px]">{'"?" indicates missing or incorrect data from Riot API'}</div>
      )}
    </div>
  );
};

export default ChampionSkillTooltip;
