import { useHistory } from "react-router-dom";
import { amplitudeLog } from "lib/general-helper";

export const pages = {
  home: "/",
  boost: "/discover-boost",
  login: "/login",
  signup: "/signup",
  verification: "/verification",
  // premium: "/premium-plans",
  performanceAnalysis: "/reports/14/year-in-review",
  loadingYIR: "/year-in-review/loading",
  yirLanding: "/year-in-review",
  // premium checkout
  premiumPayment: "/boost/payment",
  premiumPlansCheckout: "/boost/plans",
  premiumBillingCheckout: "/boost/billing",
  // settings
  accountSettings: "/settings/my-account",
  premiumSettings: "/settings/boost",
  renewPlan: "/settings/boost/renew",
  // changePlan: "/settings/boost/change-plan",
  cancelPlan: "/settings/boost/cancel-plan",
  liveGameSplash: "/lg-splash",
};

export const directToPage = (pageName, paramsObj) => {
  const { urlParams, searchParams, state } = paramsObj ? paramsObj : {};
  const history = useHistory();
  if (pages[pageName]) {
    let pathName = pages[pageName];
    let paramString = "";
    if (searchParams) {
      paramString = "?";
      searchParams.forEach(([key, val]) => {
        paramString = `${paramString}${key}=${val}&`;
      });
      paramString = paramString.substring(0, paramString.length - 1);
    }

    if (urlParams) {
      let urlElements = pathName.split("/");
      let currentRepParam = 0;
      pathName = urlElements
        .map((element) => {
          if (element.charAt(0) === ":") {
            currentRepParam = Math.min(currentRepParam + 1, urlParams.length);
            return urlParams[currentRepParam - 1];
          }
          return element;
        })
        .join("/");
    }

    return () =>
      history.push({
        pathname: pathName,
        search: paramString,
        state,
      });
  } else {
    console.error(`Could not find '${pageName}' in pages map. Check inside lib/redirect-helpers to set up your redirect`);
  }
};

export const directToPageWithLogging = (pageName, paramsObj, logTitle, logOptions) => {
  const navToPage = directToPage(pageName, paramsObj);
  return () => {
    amplitudeLog(logTitle, logOptions);
    navToPage();
  };
};
