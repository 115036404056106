import React from "react";
import MultiSearchPlayerContainer from "./components/MultiSearchPlayerContainer";
import { getNotFoundImg } from "@outplayed/riot-assets";

const MultiSearchError = () => (
  <div className="content-section error-container">
    <img src={getNotFoundImg()} alt="No data found" />
    Sorry, we could not find any matches. Please check for typos.
  </div>
);

const MultiSearchResultsContainer = (props) => {
  const { regionId, loading, data, error } = props;
  const { getMultisearch } = data || {};

  if (loading) {
    return (
      <div className="loader-container">
        <div className="spinthatshit-loader">
          <div className="spinner"></div>
        </div>
      </div>
    );
  } else if (error) {
    return <MultiSearchError />;
  } else {
    const validProfiles = getMultisearch.filter((profile) => profile.riotUserName && profile.riotTagLine);
    if (validProfiles.length === 0) {
      return <MultiSearchError />;
    }

    return (
      <div className="multisearch-results">
        {validProfiles.map((player, index) => {
          return <MultiSearchPlayerContainer key={index} data={player} regionId={regionId} />;
        })}
      </div>
    );
  }
};

export default MultiSearchResultsContainer;
