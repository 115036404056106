import { window } from "global";
import { useEffect, useContext, useCallback } from "react";
import { useGlobal, useDispatch } from "reactn";
import { JSONContext } from "./JSONContext";
import fetch from "cross-fetch";

// For useJSONFetcher:
// Because there are slight delays between global state updates,
// the same endpoint may be called multiple times if requested
// in a small timeframe. Having another form of cache to quickly
// check the loading state will prevent this from happening.
const localFetchingCache = {};

/**
 * @param {string} fullURL - Fully constructed url
 * @param {object} options - Additional options
 * @param {boolean} options.skip - Set to 'true' to ignore loading
 * @param {function} options.onCompleted - Function to format data on success before saving state
 * @param {function} options.onError - Function to format data  on success before saving state
 * @param {string} customCacheKey - Custom Key mapped to data (default set to url)
 * @param {RequestInit} requestInit - fetch request init
 */
export const useJSONFetcher = (
  url,
  { skip = false, ssr = false, onCompleted = undefined, onError = undefined, customCacheKey = "", requestInit } = {},
) => {
  const cacheKey = customCacheKey || url;

  // NOTE: Why we're using reducer/dispatch instead of 'setState' returned from 'useGlobal':
  // Calling the 'setState' from 'useGlobal' after the fetch resolves/rejects
  // does not update the global state (I DON'T KNOW WHY!!! Probably a hooks thing. - Yuuwie)
  // However, using dispatch will behave as expected
  const dispatchStaticJSON = useDispatch("UPDATE_STATIC_JSON");
  const setJSONData = (value) => dispatchStaticJSON(cacheKey, value);

  // https://stats2.u.gg/lol/1.1/pro_builds/103/pro/1.3.0.json
  const jsonContext = useContext(JSONContext);
  const [
    jsonData = {
      data: undefined,
      loading: false,
      error: undefined,
      idle: false, // true only if skipped and not fetching
    },
  ] = useGlobal(cacheKey);

  const request = useCallback(
    (requestInit) => {
      fetch(url, requestInit)
        .then((res) => res.json())
        .then((json) => {
          localFetchingCache[cacheKey] = false;
          setJSONData({
            data: onCompleted ? onCompleted(url, json, cacheKey) : json,
            loading: false,
            error: null,
            idle: false,
          });
        })
        .catch((error) => {
          console.error(error);
          localFetchingCache[cacheKey] = false;
          setJSONData({
            data: onError ? onError(url, error, cacheKey) : null,
            loading: false,
            error,
            idle: false,
          });
        });
    },
    [url, cacheKey, onCompleted, onError],
  );

  const { data, loading, error } = jsonData;

  // Handle server side requests
  if (ssr && !window && !skip) {
    const pushRequest = () => {
      const request = () =>
        fetch(url)
          .then((res) => res.json())
          .then((json) => {
            jsonContext.loading[cacheKey] = false;
            return {
              data: onCompleted ? onCompleted(url, json, cacheKey) : json,
              loading: false,
              error: null,
              idle: false,
            };
          })
          .catch((error) => {
            // console.log(error);
            jsonContext.loading[cacheKey] = false;
            return {
              data: onError ? onError(url, error, cacheKey) : null,
              loading: false,
              error,
              idle: false,
            };
          });

      if (jsonContext.cache && jsonContext.cache[cacheKey]) {
        if (jsonContext.data) {
          jsonContext.data[cacheKey] = jsonContext.cache[cacheKey];
        } else {
          jsonContext.data = { [cacheKey]: jsonContext.cache[cacheKey] };
        }
      } else if (!(jsonContext.data && jsonContext.data[cacheKey]) && !(jsonContext.loading && jsonContext.loading[cacheKey])) {
        if (!jsonContext.requests) {
          jsonContext.loading = { [cacheKey]: true };
          jsonContext.requests = { [cacheKey]: request() };
        } else if (jsonContext.requests && !jsonContext.requests[cacheKey]) {
          jsonContext.loading[cacheKey] = true;
          jsonContext.requests[cacheKey] = request();
        }
      }
    };

    pushRequest();
  }

  useEffect(() => {
    // If server side or if 'skip' is true, ignore fetch
    // May need to change behavior if data is needed on server side
    if ((!ssr && !window) || skip) {
      return;
    }

    if (!loading && data === undefined && error === undefined) {
      // Start loading state
      if (localFetchingCache[cacheKey]) {
        return;
      } else {
        setJSONData({
          data: undefined,
          loading: true,
          error: undefined,
          idle: false,
        });
        localFetchingCache[cacheKey] = true;
      }

      request(requestInit);
      // fetch(url)
      //   .then((res) => res.json())
      //   .then((json) => {
      //     localFetchingCache[cacheKey] = false;
      //     setJSONData({
      //       data: onCompleted ? onCompleted(url, json, cacheKey) : json,
      //       loading: false,
      //       error: null,
      //       idle: false,
      //     });
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     localFetchingCache[cacheKey] = false;
      //     setJSONData({
      //       data: onError ? onError(url, error, cacheKey) : null,
      //       loading: false,
      //       error,
      //       idle: false,
      //     });
      //   });
    }
  }, [url, jsonData, cacheKey, request, requestInit]);

  if (skip) {
    return { data: undefined, loading: false, error: undefined, idle: true, refetch: request };
  }

  // On ssr, returned cached context data if exists
  if (jsonContext.data) {
    if (jsonContext.data[cacheKey]) {
      return jsonContext.data[cacheKey];
    }
  }

  if (data === undefined && error === undefined) {
    return { ...jsonData, idle: false, loading: true, refetch: request };
  }

  return { ...jsonData, refetch: request };
};
