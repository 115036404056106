import React from "react";
import { ButtonSize } from "@ugitgud/legos/ui/shared";
import classNames from "classnames";
import { OverlineButton, OverlineButtonProps } from "@ugitgud/legos/ui/form/button/OverlineButton";
import { HoverIcon } from "@ugitgud/legos/ui/util/HoverIcon";
import { SupportedGames } from "@ugitgud/legos/ui/nav/GameSelector";

export const GameNavButton = React.forwardRef(
  <T extends React.ElementType = "a">(
    props: Omit<OverlineButtonProps<T>, "size" | "icon"> & {
      size?: ButtonSize;
      iconToggled: JSX.Element;
      icon: JSX.Element;
      game: SupportedGames;
    },
    ref: React.RefObject<React.ElementRef<T>> | React.ForwardedRef<React.ElementRef<T>>,
  ) => {
    const { overlineColor = "orange", className, children, icon, iconToggled, as = "a", size = "xs", ...rest } = props;
    return (
      <OverlineButton
        className={classNames(
          "dark:hover:text-white h-[30px] whitespace-nowrap text-xxxs px-3 dark:ui-toggled:text-white border-0 dark:ui-toggled:border-t dark:ui-active:text-white dark:text-accent-gray-200 rounded-none dark:ui-toggled:font-medium dark:bg-purple-550 dark:ui-toggled:bg-purple-400 dark:hover:bg-purple-400 flex gap-2 font-medium",
          props.className,
        )}
        size={size}
        overlineColor={overlineColor}
        as={as}
        ref={ref}
        {...rest}
      >
        <HoverIcon down={icon} up={iconToggled} size="sm" /> {props.children}
      </OverlineButton>
    );
  },
);
