import React, { Component } from "react";

const ChecklistItem = (props) => {
  const { isChecked, label } = props;

  return (
    <div className={`checklist-item ${isChecked ? "checklist-item_checked" : "checklist-item_unchecked"} `}>
      {isChecked ? (
        <img
          className="checklist-item_icon"
          src={"https://static.bigbrain.gg/assets/ugg/icons/checkmark-green.svg"}
          alt="Checkmark"
        />
      ) : (
        <img className="checklist-item_icon" src={"https://static.bigbrain.gg/assets/ugg/icons/close-red.svg"} alt="X" />
      )}
      <div className="checklist-item_label">{label}</div>
    </div>
  );
};

export default class PasswordChecker extends Component {
  constructor(props) {
    super(props);

    this.state = this.validatePassword(props.password);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.password !== this.props.password) {
      const newValidationState = this.validatePassword(this.props.password);
      this.setState({ ...newValidationState });
    }
  }

  validatePassword(password) {
    const validationState = { ...this.state };

    if (!password) {
      return {
        hasLetter: false,
        hasNumber: false,
        minCharacters: false,
      };
    } else {
      // Minimum 8 characters
      validationState.minCharacters = password.length >= 8;
      // At least one letter
      validationState.hasLetter = password.match(/[a-zA-Z]+/g);
      // At least one number
      validationState.hasNumber = password.match(/[\d]+/g);

      return validationState;
    }
  }

  render() {
    const { hasLetter, hasNumber, minCharacters } = this.state;

    return (
      <div className="password-checker">
        <ChecklistItem isChecked={hasLetter} label="At least one letter" />
        <ChecklistItem isChecked={hasNumber} label="At least one number" />
        <ChecklistItem isChecked={minCharacters} label="Minimum 8 characters" />
      </div>
    );
  }
}
