export function normalizeToRiotPatch(patch, versions) {
  if (!patch) return null;

  let patchSplit = patch.split(/_|\./g);
  if (patchSplit.length < 2) return null;

  const normalizedPatch = `${patchSplit[0]}.${patchSplit[1]}`;
  const validRiotPatch = versions.find((patch) => {
    const riotPatchSplit = patch.split(/_|\./g);
    const normalizedRiotPatch = `${riotPatchSplit[0]}.${riotPatchSplit[1]}`;
    return normalizedRiotPatch === normalizedPatch;
  });

  return validRiotPatch;
}

export function normalizeToBasicPatch(patch, separator = ".") {
  const [season, minor] = (typeof patch === "string" && patch.split(/_|\./g)) || [];
  return season && minor ? `${season}${separator}${minor}` : "";
}

export function RAC_comparePatch(versions) {
  return (patch1, patch2) => {
    const normalizedPatch1 = normalizeToRiotPatch(patch1, versions);
    const normalizedPatch2 = normalizeToRiotPatch(patch2, versions);

    if (!normalizedPatch1 && !normalizedPatch2) return 0;
    if (!normalizedPatch1) return 1;
    if (!normalizedPatch2) return -1;

    const season1 = Number(normalizedPatch1.split(".")[0]);
    const season2 = Number(normalizedPatch2.split(".")[0]);
    const week1 = Number(normalizedPatch1.split(".")[1]);
    const week2 = Number(normalizedPatch2.split(".")[1]);

    if (season1 === season2) {
      if (week1 === week2) {
        return 0;
      } else if (week1 < week2) {
        return -1;
      } else {
        return 1;
      }
    } else if (season1 < season2) {
      return -1;
    } else {
      return 1;
    }
  };
}

export function RAC_getWorkingPatch(versions) {
  const latestPatch = versions && versions[0];
  return (patch) => {
    const normalizedPatch = normalizeToRiotPatch(patch, versions);
    return normalizedPatch || latestPatch;
  };
}

export function RAC_getLegacyItemPatch(legacyItems, versions) {
  return (itemId, patch) => {
    const foundItem = legacyItems[itemId];
    if (!normalizeToRiotPatch(patch, versions)) {
      return RAC_getWorkingPatch(versions)(patch);
    }

    const latestAvailablePatch = foundItem && foundItem.patch;

    const patchDiff = RAC_comparePatch(versions)(latestAvailablePatch, patch);
    if (patchDiff >= 0) {
      return patch;
    } else {
      return latestAvailablePatch;
    }
  };
}

export function RAC_getLegacyRunePatch(legacyRunes, versions) {
  return (runeId, patch) => {
    const foundRune = legacyRunes[runeId];
    if (!normalizeToRiotPatch(patch, versions)) {
      return RAC_getWorkingPatch(versions)(patch);
    }

    const latestAvailablePatch = foundRune && foundRune.patch;

    const patchDiff = RAC_comparePatch(versions)(latestAvailablePatch, patch);
    if (patchDiff >= 0) {
      return patch;
    } else {
      return latestAvailablePatch;
    }
  };
}
