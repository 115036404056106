import React from "react";

interface ErrorBoundaryProps {
  children?: React.ReactNode;
  sendError: (error: Error, errorInfo: React.ErrorInfo) => void;
  fallback?: any;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

/**
 * Catch errors that crash the app; not necessarily catch every single error
 * Exceptions:
 * - Event handlers
 * - Asynchronous code (setTimeout, etc)
 * - SSR
 * - Errors thrown in the error boundary itself (rather than its children)
 */

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    // console.log(error, errorInfo)
    this.props.sendError(error, errorInfo);
    ErrorBoundary.getDerivedStateFromError(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback || null;
    }

    return this.props.children;
  }
}
