import { ROLE_OPTIONS, QUEUE_TYPE_OPTIONS, REGION_OPTIONS, RANK_OPTIONS_WITH_D2 } from "../common";

// ------------------------------------
//  RUNE SETS
// ------------------------------------

export const RUNES_OPTIONS = {
  queueType: QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  patch: { options: "latest", max: 5 },
};

export const RUNES_DEFAULT = {
  queueType: RUNES_OPTIONS.queueType[0].value,
  region: RUNES_OPTIONS.region[0].value,
  rank: RUNES_OPTIONS.rank[1].value,
  patch: RUNES_OPTIONS.patch.options,
};
