import { useJSONFetcher } from "@outplayed/json-fetcher";
import { RAC_getWorkingPatch } from "./patches";
import { getItemData } from "../static-url-helpers";

export function parseItems(data) {
  // Lists of everything
  const ornnItems = [];
  const mythicItems = [];
  const bootItems = [];
  const trinketItems = [];
  const starterItems = [];
  const consumableItems = [];
  const distributedItems = [];
  const basicItems = [];
  const epicItems = [];
  const legendaryItems = [];

  const normalizedNames = {};

  const removedItems = {
    Emberknife: 1035,
    Hailblade: 1039,
    "Obsidian Edge": 1040,
    "Penetrating Bullets": 1500,
    Fortification: 1501,
    "Reinforced Armor": 1502,
    Vanguard: 1504,
    "Lightning Rod": 1505,
    Overcharged: 1507,
    "Anti-tower Socks": 1508,
    Gusto: 1509,
    "Phreakish Gusto": 1510,
    "Super Mech Armor": 1511,
    "Super Mech Power Field": 1512,
    "Turret Plating": 1515,
    "Structure Bounty": 1516,
    OverchargedHA: 1520,
    "Tower Power-up": 1522,
    "Warden's Eye": 1503,
    OvererchargedHA: 1520,
    "Tower Power-Up": 1522,
    "Scarecrow Effigy": 3330,
    "The Golden Spatula": 4403,
    "Stirring Wardstone": 4641,
    "Relic Shield": 3858,
    "Spectral Sickle": 3862,
    "Spellthief's Edge": 3850,
    "Steel Shoulderguards": 3854,
    Stopwatch: 2419,
    "Perfectly Timed Stopwatch": 2423,
    "Aegis of the Legion": 3105,
    "Chalice of Blessing": 3012,
    Frostfang: 3851,
    "Harrowing Crescent": 3863,
    "Ironspike Whip": 6029,
    "Leeching Leer": 4635,
    "Lifewell Pendant": 3023,
    Rageknife: 6677,
    "Runesteel Spaulders": 3855,
    "Targon's Buckler": 3859,
    "Black Mist Scythe": 3864,
    "Bulwark of the Mountain": 3860,
    "Chemtech Putrifier": 3011,
    "Demonic Embrace": 4637,
    "Gargoyle Stoneplate": 3193,
    "Pauldrons of Whiterock": 3857,
    "Prowler's Claw": 6693,
    "Shard of True Ice": 3853,
    "Silvermere Dawn": 6035,
    "Crown of the Shattered Queen": 4644,
    "Divine Sunderer": 6632,
    "Duskblade of Draktharr": 6691,
    Evenshroud: 3001,
    Everfrost: 6656,
    Galeforce: 6671,
    Goredrinker: 6630,
    "Liandry's Anguish": 6653,
    "Night Harvester": 4636,
    "Radiant Virtue": 6667,
    Caesura: 7024,
    Deicide: 7017,
    "Draktharr's Shadowcarver": 7002,
    Equinox: 7023,
    "Eternal Winter": 7014,
    Typhoon: 7006,
    "Ceaseless Hunger": 7015,
    Vespertide: 7010,
    "Sandshrike's Claw": 7000,
    "Primordial Dawn": 7027,
  };

  const removedItemsIds = Object.values(removedItems);
  const itemsComingSoon = ["Gangplank Placeholder"];

  const missing_epics = [
    "3851", // Frostfang
    "3855", // Runesteel Spaulders
    "3859", // Targon's Buckler
    "3863", // Harrowing Crescent
    "4638", // Watchful Wardstone
  ];

  const sortedObject = data;

  // Add keys for imperial mandate (Missing from Riot)
  sortedObject[4005]["description"] =
    "<mainText><stats><attention>40</attention> Ability Power<br><attention>200</attention> Health<br><attention>20</attention> Ability Haste<br><attention>100%</attention> Base Mana Regen</stats><br><li><passive>Coordinated Fire:</passive> Abilities that <status>Slow</status> or <status>Immobilize</status> a champion deal bonus damage and mark them. Ally champion damage detonates the mark, dealing additional damage and granting you both Move Speed.";

  const isOrnnItem = (id) => "requiredAlly" in sortedObject[id] || sortedObject[id]["description"].includes("ornnBonus");
  const isArenaOnly = (id) => {
    const arenaEnabled = sortedObject[id].maps["21"];
    let flag = true;
    Object.entries(sortedObject[id].maps).forEach(([mapId, enabled]) => {
      if (parseInt(mapId) !== 21 && enabled) {
        flag = false;
      }
    });
    return flag && arenaEnabled;
  };

  for (var key in sortedObject) {
    //Skip over removed items
    if (
      removedItems.hasOwnProperty(sortedObject[key]["name"]) ||
      itemsComingSoon.includes(sortedObject[key]["name"]) ||
      //To skip arena items
      parseInt(key) > 9999
    ) {
    } // Skipping Raise Morale, name isn't formatted (Gangplanks)
    else if (key === "3901" || key === "3902" || key === "3903" || key === "3600" || key === "1104" || key === "2421") {
    } // Ornn 27
    else if (isOrnnItem(key)) {
      ornnItems.push([sortedObject[key]["name"], parseInt(key)]);
    } // Mythic 27
    else if (sortedObject[key]["description"].includes("Mythic Passive")) {
      mythicItems.push([sortedObject[key]["name"], parseInt(key)]);
    } // Boots 8
    else if (
      sortedObject[key]["tags"].includes("Boots") &&
      (!sortedObject[key].hasOwnProperty("inStore") || sortedObject[key]["inStore"] === true)
    ) {
      bootItems.push([sortedObject[key]["name"], parseInt(key)]);
    } // Trinkets 3
    else if (sortedObject[key]["description"].includes("Trinket") && !sortedObject[key].hasOwnProperty("requiredChampion")) {
      trinketItems.push([sortedObject[key]["name"], parseInt(key)]);
    } // Consumable 7
    else if (sortedObject[key]["tags"].includes("Consumable") && sortedObject[key]["tags"].length > 2) {
      consumableItems.push([sortedObject[key]["name"], parseInt(key)]);
    } // Basic 16
    else if (
      (sortedObject[key].hasOwnProperty("into") &&
        !sortedObject[key].hasOwnProperty("from") &&
        !sortedObject[key]["description"].includes("Legendary") &&
        !sortedObject[key]["name"].includes(" Stopwatch") &&
        !sortedObject[key]["name"].includes("Tear of the Goddess") &&
        sortedObject[key]["gold"]["purchasable"] === true) ||
      key === "2424"
    ) {
      basicItems.push([sortedObject[key]["name"], parseInt(key)]);
    } // Epic 45
    else if (
      missing_epics.includes(key) ||
      (sortedObject[key].hasOwnProperty("from") &&
        sortedObject[key].hasOwnProperty("into") &&
        !sortedObject[key]["description"].includes("Legendary") &&
        sortedObject[key]["into"].filter((id) => !removedItemsIds.includes(id)).every((id) => !isOrnnItem(id)))
    ) {
      epicItems.push([sortedObject[key]["name"], parseInt(key)]);
    } // Starter 19 (18 + BS)
    else if (
      !sortedObject[key].hasOwnProperty("from") &&
      !sortedObject[key]["description"].includes(" Legendary") &&
      sortedObject[key]["gold"].hasOwnProperty("purchasable") &&
      sortedObject[key]["gold"]["purchasable"] === true
    ) {
      starterItems.push([sortedObject[key]["name"], parseInt(key)]);
    } // Distributed
    // Less than 1200 to be safe
    // Add 4643, we don't have it now but it's vigilant wardstone
    else if (
      !sortedObject[key]["gold"]["purchasable"] &&
      sortedObject[key]["gold"]["total"] < 1200 &&
      !sortedObject[key]["specialRecipe"]
    ) {
      distributedItems.push([sortedObject[key]["name"], parseInt(key)]);
    } //Legendary
    else {
      legendaryItems.push([sortedObject[key]["name"], parseInt(key)]);
    }

    //Not being used right now
    const name = sortedObject[key]["name"];
    const normalizedName = name.replace(/ /g, "-").replace(/'/g, "").toLowerCase();
    normalizedNames[parseInt(key)] = { originalName: name, normalizedName: normalizedName };
  }

  //remove watchful wardstone and add to epics
  epicItems.push(basicItems.pop());

  //Sort alphabetically
  ornnItems.sort();
  mythicItems.sort();
  bootItems.sort();
  trinketItems.sort();
  starterItems.sort();
  consumableItems.sort();
  distributedItems.sort();
  basicItems.sort();
  epicItems.sort();
  legendaryItems.sort();

  //Add item id to new array
  const ornnItemsSorted = ornnItems.map(([_, secondElement]) => secondElement);
  const mythicItemsSorted = mythicItems.map(([_, secondElement]) => secondElement);
  const bootItemsSorted = bootItems.map(([_, secondElement]) => secondElement);
  const trinketItemsSorted = trinketItems.map(([_, secondElement]) => secondElement);
  const starterItemsSorted = starterItems.map(([_, secondElement]) => secondElement);
  const consumableItemsSorted = consumableItems.map(([_, secondElement]) => secondElement);
  const distributedItemsSorted = distributedItems.map(([_, secondElement]) => secondElement);
  const basicItemsSorted = basicItems.map(([_, secondElement]) => secondElement);
  const epicItemsSorted = epicItems.map(([_, secondElement]) => secondElement);
  const legendaryItemsSorted = legendaryItems.map(([_, secondElement]) => secondElement);

  const availableItemsString = Object.keys(sortedObject);
  const availableItemsNumbers = availableItemsString.map((str) => parseInt(str));

  const allItems = {
    starterItems: starterItemsSorted,
    consumableItems: consumableItemsSorted,
    trinketItems: trinketItemsSorted,
    distributedItems: distributedItemsSorted,
    bootItems: bootItemsSorted,
    basicItems: basicItemsSorted,
    epicItems: epicItemsSorted,
    legendaryItems: legendaryItemsSorted,
    mythicItems: mythicItemsSorted,
    ornnItems: ornnItemsSorted,
    availableItems: availableItemsNumbers,
  };

  return allItems;
}

export function RAC_useItemsWiki(versions) {
  return ({ ssr = false } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)();
    const itemsURL = getItemData(riotPatch);

    const onCompleted = (url, json) => {
      return parseItems(json.data);
    };

    return useJSONFetcher(itemsURL, {
      customCacheKey: "items-wiki",
      ssr: ssr,
      onCompleted,
    });
  };
}
