import { useJSONFetcher, JSONFetcherOptions } from "@outplayed/json-fetcher";
import { getApiRoot } from "@ugg/shared/api/api-helpers";
import { useUGGApiVersions } from "@ugg/shared/api/requests/ugg-api-versions";
import { SPECIAL_TIER_LIST_PAGES } from "@ugg/shared/pages/tier-list-pages";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params/valid-params";

// Data Parsers
import { cleanArenaTierList } from "@ugg/shared/api/data-parser/tier-lists/arena-tier-list";

// Interfaces
import { ArenaTierList } from "@ugg/shared/interfaces/tier-lists/arena-tier-list.interface";

// ------------------------------------------------------------------------------------------------------------

interface TierListParams {
  region: string;
  patch: string;
  queueType: string;
  rank: string;
}

// ------------------------------------------------------------------------------------------------------------
// ARENA TIER LIST
// ------------------------------------------------------------------------------------------------------------

function useArenaTierUrl(params: TierListParams) {
  const { data: version } = useUGGApiVersions({
    apiKey: "champion_ranking",
    patch: params.patch,
  });

  const root = getApiRoot(params.patch);
  return [`${root}/champion_ranking`, params.region, params.patch, "arena", "overall", `${version}.json`].join("/");
}

export function useArenaTierList<K = ReturnType<typeof cleanArenaTierList>>(
  options?: { params?: Record<string, any> } & JSONFetcherOptions<ArenaTierList, K>,
) {
  const { params = {}, ssr = false, skip = false, onCompleted, customCacheKey } = options || {};
  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedQueryParams = validateQueryParams(SPECIAL_TIER_LIST_PAGES.ARENA_TIER_LIST, params, true);
  const url = useArenaTierUrl(validatedQueryParams);

  if (onCompleted) {
    return useJSONFetcher<ArenaTierList, K>(url, {
      skip,
      ssr,
      customCacheKey,
      onCompleted,
    });
  }

  return useJSONFetcher<ArenaTierList, ReturnType<typeof cleanArenaTierList>>(url, {
    skip,
    ssr,
    onCompleted: (url, json) => cleanArenaTierList(json),
    customCacheKey: `arena_${url}`,
  });
}
