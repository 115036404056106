import React, { Component, useState, useEffect } from "react";
import { fetchRawApi, getApiRoot } from "api/apiHelpers.js";
import NotificationIcon from "./NotificationIcon";
import NotificationDropdown from "./NotificationDropdown";
const moment = require("moment");

let env = "bugg"; // "prod"

if (process.env.NODE_ENV === "production") {
  env = "prod";
}

const NotificationContainer = (props) => {
  const [notificationData, setNotificationData] = useState(null);
  const [notificationSeen, setNotificationSeen] = useState(true);
  const [newNotifications, setNewNotifications] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [priorityNotification, setPriorityNotification] = useState(false);
  const [adToggleNotification, setadToggleNotification] = useState(false);

  // Checking if the ggbets ad is working.
  useEffect(() => {
    let timer = setInterval(() => {
      let toggle = window.sessionStorage.getItem("ggbet");
      if (toggle) {
        clearInterval(timer);
        setadToggleNotification(true);
        setDisplayDropdown(true);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    fetchRawApi(`https://static.bigbrain.gg/assets/lol/notifications/${env}/Notification.json`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const lastTimeViewed = localStorage.getItem("lastNotificationSeenTime");
        const notifications = res.announcements;
        let newNotifications = [];
        let notificationSeen = true;
        let maxTimeSeen = lastTimeViewed;
        let priorityNotificationExists = false;
        notifications.forEach((notification, index) => {
          notification.dateMade = moment(new Date(notification.timeStamp)).fromNow();
          if (lastTimeViewed === null || notification.timeStamp > lastTimeViewed) {
            notificationSeen = false;
            newNotifications.push(index);
            if (notification.timeStamp > maxTimeSeen) maxTimeSeen = notification.timeStamp;
            if (notification.priorityNotificationState == true) {
              priorityNotificationExists = true;
            }
          }
        });

        setPriorityNotification(priorityNotificationExists);
        setNotificationData(res);
        setNotificationSeen(notificationSeen);
        setNewNotifications(newNotifications);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (priorityNotification) {
      setDisplayDropdown(true);
    }
  }, [priorityNotification]);

  const toggleNotificationDropdown = (forcedState) => {
    const toggleState = forcedState === undefined ? !displayDropdown : forcedState;
    setDisplayDropdown(toggleState);
  };

  return (
    <div className="Notification">
      {displayDropdown && (
        <NotificationDropdown
          notificationData={notificationData}
          newNotifications={newNotifications}
          notificationSeen={notificationSeen}
          setNotificationSeen={setNotificationSeen}
          setNewNotifications={setNewNotifications}
          adToggleNotification={adToggleNotification}
        />
      )}
      <NotificationIcon
        toggleNotificationDropdown={toggleNotificationDropdown}
        notificationSeen={notificationSeen}
        newNotifications={newNotifications}
        priorityNotification={priorityNotification}
      />
    </div>
  );
};

export default NotificationContainer;
