import { useEffect, useMemo } from "react";
import { useGlobal } from "reactn";
import { STATIC_LOL_URL } from "@outplayed/riot-assets";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getChampionBuildUrl, getChampionCountersUrl, getSpecialGameModeChampionBuildUrl } from "@ugg/shared/routes/app-routes";

/***********************************
 * Search Bar Pages
 ***********************************/
const SECTIONS = [
  { id: "build", title: "Build", suffix: "", path: "build" },
  { id: "probuild", title: "Pro Builds", suffix: " Pro Builds", path: "pro-build" },
];
const ITEM_SECTIONS = [{ title: "Items", suffix: "", path: "" }];

const BUILD_PAGE_TAGS = [
  {
    id: "ranked",
    title: "Ranked",
    path: getChampionBuildUrl,
  },
  {
    id: "aram",
    title: "ARAM",
    path: (champId) => getSpecialGameModeChampionBuildUrl(champId, "aram"),
  },
  {
    id: "arena",
    title: "Arena",
    path: (champId) => getSpecialGameModeChampionBuildUrl(champId, "arena"),
  },
  {
    id: "counters",
    title: "Counters",
    path: getChampionCountersUrl,
  },
];

const TIER_LIST_GAME_MODES = [
  {
    id: "ranked",
    key: "",
    title: "",
    path: "/lol/tier-list",
    tags: [
      {
        id: "top-lane",
        key: "top",
        title: "Top",
        path: "/lol/top-lane-tier-list",
      },
      {
        id: "jungle",
        key: "jungle",
        title: "Jungle",
        path: "/lol/jungle-tier-list",
      },
      {
        id: "mid-lane",
        key: "mid",
        title: "Mid",
        path: "/lol/mid-lane-tier-list",
      },
      {
        id: "adc",
        key: "adc",
        title: "ADC",
        path: "/lol/adc-tier-list",
      },
      {
        id: "support",
        key: "support",
        title: "Support",
        path: "/lol/support-tier-list",
      },
    ],
  },
  {
    id: "arena",
    key: "arena",
    title: "Arena",
    path: "/lol/arena-tier-list",
    tags: [
      {
        id: "",
        key: "solo",
        title: "Solo",
        path: "/lol/arena-tier-list",
      },
      {
        id: "duo",
        key: "duo",
        title: "Duo",
        path: "/lol/arena-duo-tier-list",
      },
    ],
  },
  {
    id: "aram",
    key: "aram",
    title: "ARAM",
    path: "/lol/aram-tier-list",
  },
];

export function getSearchBarPage(key, displayName, champId, lowerId, pageName, language) {
  const isNickname = key.toLowerCase() !== lowerId && key.toLowerCase() !== displayName.toLowerCase();

  if (pageName === "pro-build") {
    return {
      key: key,
      displayName: displayName,
      url: `https://probuildstats.com/champion/${lowerId}`,
      champId: champId,
      section: pageName,
      isNickname,
      targetHref: true,
    };
  }

  if (pageName === "build") {
    let url = champId != 141 ? `/lol/champions/${lowerId}/${pageName}` : `/lol/champions/red/${lowerId}/${pageName}`;
    if (language !== "en_US") {
      url = `/lol/${language.toLowerCase()}/champions/${lowerId}/${pageName}`;
    }

    const tags = BUILD_PAGE_TAGS.filter((tag) => key.toLowerCase().includes(tag.id));
    return {
      key: key,
      displayName: displayName,
      url: url,
      champId: champId,
      section: pageName,
      isNickname,
      tags: (tags.length > 0 ? tags : BUILD_PAGE_TAGS).map((tag) => ({
        id: tag.id,
        key: `${key} ${tag.id}`,
        title: tag.title,
        url: tag.path(lowerId),
      })),
    };
  }

  if (pageName === "aram") {
    const kaynPath = champId != 141 ? `/lol/champions/${lowerId}/${pageName}` : `/lol/champions/red/${lowerId}/${pageName}`;
    return {
      key: key,
      displayName: displayName,
      url: kaynPath,
      champId: champId,
      section: pageName,
      isNickname,
    };
  }

  return {
    key: key,
    displayName: displayName,
    url: `/lol/champions/${lowerId}/${pageName}`,
    champId: champId,
    section: pageName,
    isNickname,
  };
}

function getTierListSearchBarPage(key, displayName, role, gameMode, language) {
  const roleStr = role === "" ? role : role + "-";
  return {
    key: key,
    displayName: displayName,
    url: `/${roleStr}tier-list`,
    section: pageName,
  };
}

function useSearchBarPages({ language, ssr, skip } = {}) {
  const [globalLanguage] = useGlobal("language");
  const [savedResults, setSavedResults] = useGlobal("search-bar-pages");
  const { useChampionMini, getNormalizedChampionName, getItems, getNormalizedItemNameFromId, useItemsWiki } =
    getRiotAssetsContext();
  const championMini = useChampionMini({ language, ssr, skip }); // includes backup champions
  const itemsJSON = getItems();
  const itemsList = useItemsWiki({ ssr });
  const availableItems = itemsList?.data?.availableItems;

  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  const url = `${STATIC_LOL_URL}/riot_patch_update/${envDir}/champion-nicknames.json`;
  const championNicknames = useJSONFetcher(url, { ssr, skip });

  const searchBarPages = useMemo(() => {
    if (savedResults) {
      return savedResults;
    } else if (!championMini.data || !championNicknames.data) {
      return null;
    }
    // Build search bar pages
    const champPages = SECTIONS.map((curSection) => {
      const champPages = Object.values(championMini.data).reduce((accChampion, curChampion) => {
        const { name, key, id } = curChampion;
        const normalizedName = getNormalizedChampionName(key);
        const nicknames = championNicknames.data[id] || [];

        const variations = new Set([id, name, ...(nicknames || [])]);
        const gameModes = BUILD_PAGE_TAGS.map((x) => x.id);
        const championPages = [...variations]
          .map((variation) => {
            // Add non mode related page first, so it's the default
            let champPages = [
              getSearchBarPage(
                `${variation}${curSection.suffix}`,
                `${name}${curSection.suffix}`,
                key,
                normalizedName,
                curSection.path,
                globalLanguage,
              ),
            ];

            if (curSection.id === "build") {
              champPages = champPages.concat(
                gameModes.map((gm) =>
                  getSearchBarPage(
                    `${variation} ${gm}${curSection.suffix}`,
                    `${name}${curSection.suffix}`,
                    key,
                    normalizedName,
                    curSection.path,
                    globalLanguage,
                  ),
                ),
              );
            }

            return champPages;
          })
          .flat();

        return accChampion.concat(championPages);
      }, []);
      return { title: curSection.title, pages: champPages };
    });

    const itemPages =
      itemsJSON &&
      ITEM_SECTIONS.map((curSection) => {
        const pages = Object.keys(itemsJSON)
          .filter((id) => availableItems?.includes(parseInt(id)))
          .map((itemId) => {
            return {
              key: itemsJSON[itemId].name,
              displayName: itemsJSON[itemId].name,
              url: `/lol/items/${getNormalizedItemNameFromId(itemId, { optionalData: itemsJSON })}`,
              itemId: itemId,
              section: "items",
            };
          });
        return { title: curSection.title, pages };
      });

    const tierListPages = TIER_LIST_GAME_MODES.map((mode) => {
      const modeKey = mode.key === "" ? mode.key : mode.key + " ";
      const modeTitle = mode.title === "" ? mode.title : mode.title + " ";

      // Add base page first
      const basePage = {
        key: `${modeKey}tier lists`,
        displayName: `${modeTitle}Tier List`,
        url: mode.path,
        section: "pages",
        tags: (mode.tags || []).map((tag) => {
          return {
            id: tag.id,
            key: tag.key,
            title: tag.title,
            url: tag.path,
            active: true,
          };
        }),
      };

      // Do a page for each tag
      const tagPages = (mode.tags || []).map((tag) => {
        // Add the tag in front and back for tier lists, for better searching
        const front = {
          key: `${tag.key} ${modeKey}tier list`,
          displayName: `${modeTitle}Tier List`,
          url: tag.path,
          section: "pages",
          tags: (mode.tags || []).map((tag2) => {
            return {
              id: tag2.id,
              key: tag2.key,
              title: tag2.title,
              url: tag2.path,
              active: tag.key === tag2.key,
            };
          }),
        };

        const back = {
          key: `${modeKey}tier list ${tag.key}`,
          displayName: `${modeTitle}Tier List`,
          url: tag.path,
          section: "pages",
          tags: (mode.tags || []).map((tag2) => {
            return {
              id: tag2.id,
              key: tag2.key,
              title: tag2.title,
              url: tag2.path,
              active: tag.key === tag2.key,
            };
          }),
        };

        return [front, back];
      });

      return [basePage, ...tagPages.flat()];
    }).flat();
    const pagesSection = { title: "Pages", pages: tierListPages };

    const searchBarPages = [...champPages, pagesSection, ...(itemPages || [])];
    return searchBarPages;
  }, [savedResults, championMini.data, championNicknames.data, itemsJSON, globalLanguage]);

  // Save results globally so we don't have to build the pages again
  // Commented out because the lang will go back to the previous lang of a saved page.
  // useEffect(() => {
  //   if (!savedResults && searchBarPages) {
  //     setSavedResults(searchBarPages);
  //   }
  // }, [savedResults, searchBarPages, globalLanguage])

  if (championMini.loading || championNicknames.loading) {
    return { data: null, error: null, loading: !championMini.loading || !championNicknames.loading };
  } else if (championMini.error || championNicknames.error) {
    return { data: null, loading: false, error: !championMini.error || !championNicknames.error };
  } else {
    return { data: searchBarPages, loading: false, error: null };
  }
}

/****************************************************
 * Search Pages (OpenSearch, Search Bar Matching)
 ****************************************************/
function getSearchPages(name, lowerId, displayName, champId, language) {
  const isNickname = name.toLowerCase() !== lowerId && name.toLowerCase() !== displayName.toLowerCase();

  return [
    {
      key: `${name}`,
      displayName: `${displayName}`,
      id: champId,
      url: language !== "en_US" ? `/lol/${language.toLowerCase()}/champions/${lowerId}/build` : `/lol/champions/${lowerId}/build`,
      isNickname,
      isDefaultPage: true,
    },
    {
      key: `${name} Items`,
      displayName: `${displayName} Items`,
      id: champId,
      url: `/lol/champions/${lowerId}/items`,
      isNickname,
    },
    {
      key: `${name} Spells`,
      displayName: `${displayName} Spells`,
      id: champId,
      url: `/lol/champions/${lowerId}/spells-abilities`,
      isNickname,
    },
    {
      key: `${name} Abilities`,
      displayName: `${displayName} Abilities`,
      id: champId,
      url: `/lol/champions/${lowerId}/spells-abilities`,
      isNickname,
    },
    {
      key: `${name} Runes`,
      displayName: `${displayName} Runes`,
      id: champId,
      url: `/lol/champions/${lowerId}/runes`,
      isNickname,
    },
    {
      key: `${name} Rune Sets`,
      displayName: `${displayName} Rune Sets`,
      id: champId,
      url: `/lol/champions/${lowerId}/runes-sets`,
      isNickname,
    },
    {
      key: `${name} Counters`,
      displayName: `${displayName} Counters`,
      id: champId,
      url: `/lol/champions/${lowerId}/counters`,
      isNickname,
    },
    {
      key: `${name} Matchups`,
      displayName: `${displayName} Matchups`,
      id: champId,
      url: `/lol/champions/${lowerId}/matchups`,
      isNickname,
    },
    {
      key: `${name} Duos`,
      displayName: `${displayName} Duos`,
      id: champId,
      url: `/lol/champions/${lowerId}/duos`,
      isNickname,
    },
    {
      key: `${name} Pro Builds`,
      displayName: `${displayName} Pro Builds`,
      id: champId,
      url: `https://probuildstats.com/champion/${lowerId}`,
      isNickname,
      targetHref: true,
    },
  ];
}

function useSearchPages({ language, ssr, skip } = {}) {
  const [globalLanguage] = useGlobal("language");
  const [savedResults, setSavedResults] = useGlobal("search-pages");
  const { useChampionMini, getNormalizedChampionName, getItems, getNormalizedItemNameFromId } = getRiotAssetsContext();
  const championMini = useChampionMini({ language, ssr, skip }); // includes backup champions
  const itemsJSON = getItems();
  const availableItems = itemsJSON && Object.keys(itemsJSON);

  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  const url = `${STATIC_LOL_URL}/riot_patch_update/${envDir}/champion-nicknames.json`;
  const championNicknames = useJSONFetcher(url, { ssr, skip });

  const searchPages = useMemo(() => {
    if (savedResults) {
      return savedResults;
    } else if (!championMini.data || !championNicknames.data) {
      return null;
    }
    // Build search bar pages
    const champPages = Object.values(championMini.data).reduce((accChampion, curChampion) => {
      const { name, key, id } = curChampion;
      const normalizedName = getNormalizedChampionName(key);
      const nicknames = championNicknames.data[id] || [];

      const variations = new Set([id, name, ...(nicknames || [])]);
      const championPages = [...variations].reduce((acc, variation) => {
        const pages = getSearchPages(variation, normalizedName, name, key, globalLanguage);
        return acc.concat(pages);
      }, []);
      return accChampion.concat(championPages);
    }, []);

    const itemPages =
      itemsJSON &&
      Object.keys(itemsJSON)
        .filter((id) => availableItems?.includes(parseInt(id)))
        .map((itemId) => {
          return {
            key: itemsJSON[itemId].name,
            displayName: itemsJSON[itemId].name,
            url: `/lol/items/${getNormalizedItemNameFromId(itemId, { optionalData: itemsJSON })}`,
            itemId: itemId,
            section: "items",
          };
        });
    const searchBarPages = [...champPages, ...(itemPages || [])];
    return searchBarPages;
  }, [savedResults, championMini.data, championNicknames.data, itemsJSON, globalLanguage]);

  // Save results globally so we don't have to build the pages again
  // Commented out because the lang will go back to the previous lang of a saved page.
  // useEffect(() => {
  //   if (!savedResults && searchPages) {
  //     setSavedResults(searchPages);
  //   }
  // }, [savedResults, searchPages, globalLanguage])

  if (championMini.loading || championNicknames.loading) {
    return { data: null, error: null, loading: !championMini.loading || !championNicknames.loading };
  } else if (championMini.error || championNicknames.error) {
    return { data: null, loading: false, error: !championMini.error || !championNicknames.error };
  } else {
    return { data: searchPages, loading: false, error: null };
  }
}

export { useSearchBarPages, useSearchPages };
