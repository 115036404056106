import React from "react";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const ChampionPassiveTooltip = (props) => {
  const { useChampionIndiv, getIndividualChampion } = getRiotAssetsContext();
  const { championId, language } = props;
  const {
    data: championIndiv,
    loading: fetchingChampionIndiv,
    error: errorChampionIndiv,
  } = useChampionIndiv(championId, { language, skip: true });

  const championJSON = championIndiv || getIndividualChampion(championId, { language });

  if (!championJSON) {
    return null;
  }

  const { name, description } = championJSON.passive;

  return (
    <div className="tooltip-champion-skill">
      <div className="name mb-[12px]">{name}</div>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default ChampionPassiveTooltip;
