import React, { Component, useEffect } from "react";
import { useGlobal } from "reactn";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { usePageTitleAndDesc } from "lib/seo_helper.js";
import { useSearchPages } from "lib/hooks/searchHooks";
import { useBestSearchMatch } from "lib/searchHelper.js";
import { generateBuildAndRunes } from "lib/FilterPreferences/QueryParams";
import { useQueryString } from "hooks/general-hooks";
import { useManualSummonerProfileInitSimple } from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";
import { splitRiotId } from "@ugg/shared/utils/riot-id-helpers";
import { getOldProfileOverviewUrl, getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";
import { SummonerProfileLoader } from "@ugg/shared/components/SummonerProfiles/SummonerProfileLoader";

// We want the landing page to open search once so that our search engine
// gets stored in the user's chrome://settings/searchEngines?search=search
// However on subsequent searches from the landing page, we don't want to
// do this since it is slower
var LANDING_PAGE_OPEN_SEARCHES_ONLY_ONCE_PER_SESSION = false;

const OpenSearchRedirect = (props) => {
  const history = useHistory();
  const getPageTitleAndDesc = usePageTitleAndDesc();
  const getBestSearchMatch = useBestSearchMatch();
  const [clientRegion] = useGlobal("clientRegion");
  const [loggingIn] = useGlobal("loggingIn");

  const { data: userState } = useQuery(GET_USER_STATE);
  const { filterDefaults } = (userState && userState.getState) || {};
  const championSearchParams = generateBuildAndRunes(filterDefaults);

  const { data: searchPages, loading: loadingSearchPages, error: errorSearchPages } = useSearchPages();

  const { query } = useQueryString();
  const decodedQuery = decodeURIComponent(query).replace(new RegExp("\\+", "g"), " ");
  const [queryRiotUserName, queryRiotTagLine] = splitRiotId(decodedQuery, "-");

  const [getSummonerProfile, { data: dataSummoner, loading: loadingSummoner, error: errorSummoner }] =
    useManualSummonerProfileInitSimple(clientRegion, queryRiotUserName, queryRiotTagLine, {});

  useEffect(() => {
    LANDING_PAGE_OPEN_SEARCHES_ONLY_ONCE_PER_SESSION = true;
  }, []);

  useEffect(() => {
    if (!loggingIn && !loadingSearchPages) {
      if (searchPages) {
        search(decodedQuery);
      } else {
        history.replace({ pathname: "/" });
      }
    }
  }, [loggingIn, searchPages]);

  useEffect(() => {
    if (dataSummoner) {
      const { profileInitSimple } = dataSummoner;
      const { playerInfo } = profileInitSimple;
      const { riotUserName, riotTagLine } = playerInfo;
      history.replace({
        pathname: getProfileOverviewUrl(clientRegion, riotUserName, riotTagLine),
      });
    } else if (errorSummoner) {
      history.replace({
        pathname: getOldProfileOverviewUrl(clientRegion, decodedQuery),
        state: { search: 404 },
      });
    }
  }, [loadingSummoner]);

  const search = (val) => {
    if (val.trim() === "") {
      return history.replace({ pathname: "/" });
    }
    const matches = getBestSearchMatch(val);
    const noNicknames = matches.filter((match) => !match.item.isNickname);
    // Check exact name
    const hasExactMatch = matches.length > 0 && Math.round(matches[0].score * 1000000) / 1000000 === 0;
    // Check exact nickname
    const noNicknameMatch = noNicknames.length > 0 && noNicknames[0];
    let resToUse = hasExactMatch && matches[0];
    // Check name substring match
    if (noNicknameMatch) {
      const strippedMatchValue = noNicknameMatch.item.key.toLowerCase();
      const strippedSearchValue = val.toLowerCase();
      if (strippedMatchValue.indexOf(strippedSearchValue) === 0 && noNicknameMatch.item.isDefaultPage === true) {
        resToUse = noNicknameMatch;
      }
    }
    if (resToUse) {
      if (resToUse.item.targetHref) {
        window && window.open(resToUse.item.url);
        history.replace({
          pathname: "/",
        });
      } else {
        history.replace({
          pathname: resToUse.item.url,
          search: championSearchParams || "",
        });
      }
    } else {
      // getSummonerProfile();
      history.replace({
        pathname: "/",
      });
    }
  };

  return (
    <div className="w-full">
      {getPageTitleAndDesc("open_search")}
      <SummonerProfileLoader className="w-full h-[calc(100vh-var(--masthead-height)-var(--game-nav-height)-36px)]" />
    </div>
  );
};

export { LANDING_PAGE_OPEN_SEARCHES_ONLY_ONCE_PER_SESSION };
export default OpenSearchRedirect;
