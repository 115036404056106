import * as React from "react";
import type { SVGProps } from "react";
export const SvgGameTft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#game-tft_svg__a)">
      <mask
        id="game-tft_svg__b"
        width={16}
        height={16}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path fill="#fff" d="M16 0H0v16h16z" />
      </mask>
      <g fill="#FFB60C" mask="url(#game-tft_svg__b)">
        <path d="M11.56 7.932c2.224 2.044 2.22 4.168 2.22 4.168-.83-1.788-1.868-2.22-1.868-2.22 2.324 3.034.08 3.4.08 3.4s-3.528.846-7.856-3.162C-.19 6.11 4.024 5.056 4.024 5.056s-4.332.292-2.156 4.148c2.436 3.908 7.258 5.452 7.258 5.452s-2.898-.02-5.31-1.888c0 0 3.082 3.05 7.856 3.226s3.432-3.848 3.432-3.848-1.106-2.688-3.544-4.216z" />
        <path d="m6.066 3.23-.02 7.232c1.536 1.1 2.89 1.572 3.908 1.758l.02-8.988h4.172L15.756 0H.244l1.624 3.23z" />
      </g>
    </g>
    <defs>
      <clipPath id="game-tft_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
