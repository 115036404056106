import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { SvgLolDiscordIcon as DiscordIcon } from "@ugitgud/legos/ui/icon/SVG/LolDiscordIcon";
import { SvgLolFacebookIcon as FacebookIcon } from "@ugitgud/legos/ui/icon/SVG/LolFacebookIcon";
import { SvgLolInstagramIcon as InstagramIcon } from "@ugitgud/legos/ui/icon/SVG/LolInstagramIcon";
import { SvgLolTwitterIcon as TwitterIcon } from "@ugitgud/legos/ui/icon/SVG/LolTwitterIcon";
import { SvgLolYoutubeIcon as YouTubeIcon } from "@ugitgud/legos/ui/icon/SVG/LolYoutubeIcon";
import { SvgLolUggLogoBlue as UGGLogo } from "@ugitgud/legos/ui/icon/SVG/LolUggLogoBlue";
import "../styles/footer.scss";

type FooterProps = {
  className?: string;
  discordLink?: string;
  facebookLink?: string;
  instagramLink?: string;
  twitterLink?: string;
  redditLink?: string;
  youtubeLink?: string;
  disclaimerText?: string;
  path: string;
  width?: string;
  paddingLeft?: string;
};

const Footer: React.FC<FooterProps> = ({
  className,
  discordLink = "2nDpSxmU",
  facebookLink = "UdotGG",
  instagramLink = "UdotGG",
  twitterLink = "UdotGG",
  redditLink = "",
  youtubeLink = "user/enthusiastgamingch",
  disclaimerText = "U.GG Stats isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends.",
  path,
  width,
  paddingLeft,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== "undefined") {
        setIsMobile(window.innerWidth <= 780);
      }
    };

    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth <= 780);
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, []);

  return (
    <footer className={classNames("footer", className)}>
      <div className="footer-container">
        <div className="footer-logo-section">
          <UGGLogo className="footer-logo" />
          <p className="footer-copyright">© 2017-{currentYear} Outplayed Inc.</p>
          <p className="footer-disclaimer">{disclaimerText}</p>
        </div>
        <div className="all-links">
          {!isMobile && <div />}
          <div className="footer-links-section footer-section">
            <h3 className="footer-title">Links</h3>
            <ul className="footer-links-list">
              <li className="footer-link-item">
                <a href="/about" className="footer-link">
                  About
                </a>
              </li>
              <li className="footer-link-item">
                <a href="/faq" className="footer-link">
                  FAQ
                </a>
              </li>
              <li className="footer-link-item">
                <a href="/careers" className="footer-link">
                  Careers
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-terms-section footer-section">
            <h3 className="footer-title">Terms</h3>
            <ul className="footer-links-list">
              <li className="footer-link-item">
                <a href="/privacy-shield" className="footer-link">
                  Privacy Shield
                </a>
              </li>
              <li className="footer-link-item">
                <a href="/privacy-policy" className="footer-link">
                  Privacy Policy
                </a>
              </li>
              <li className="footer-link-item">
                <a href="/terms-of-service" className="footer-link">
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-socials-section footer-section">
            <h3 className="footer-title">Socials</h3>
            <ul className="footer-links-list">
              <li className="footer-link-item">
                <DiscordIcon className="footer-icon" />
                <a href={`https://discord.gg/${discordLink}`} target="_blank" rel="noopener noreferrer" className="footer-link">
                  Discord
                </a>
              </li>
              <li className="footer-link-item">
                <TwitterIcon className="footer-icon" />
                <a href={`https://twitter.com/${twitterLink}`} target="_blank" rel="noopener noreferrer" className="footer-link">
                  Twitter
                </a>
              </li>
              <li className="footer-link-item">
                <YouTubeIcon className="footer-icon" />
                <a href={`https://youtube.com/${youtubeLink}`} target="_blank" rel="noopener noreferrer" className="footer-link">
                  YouTube
                </a>
              </li>
              <li className="footer-link-item">
                <InstagramIcon className="footer-icon" />
                <a
                  href={`https://instagram.com/${instagramLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Instagram
                </a>
              </li>
              <li className="footer-link-item">
                <FacebookIcon className="footer-icon" />
                <a
                  href={`https://facebook.com/${facebookLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Facebook
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-brands-section footer-section">
            <h3 className="footer-title">Our Brands</h3>
            <ul className="footer-links-list">
              <li className="footer-link-item">
                <a href="https://www.icy-veins.com/" target="_blank" rel="noopener noreferrer" className="footer-link">
                  Icy Veins
                </a>
              </li>
              <li className="footer-link-item">
                <a href="https://www.thesimsresource.com/" target="_blank" rel="noopener noreferrer" className="footer-link">
                  The Sims Resource
                </a>
              </li>
              <li className="footer-link-item">
                <a href="https://www.addictinggames.com/" target="_blank" rel="noopener noreferrer" className="footer-link">
                  Addicting Games
                </a>
              </li>
              <li className="footer-link-item">
                <a href="https://luminosity.gg/" target="_blank" rel="noopener noreferrer" className="footer-link">
                  Luminosity
                </a>
              </li>
              <li className="footer-link-item">
                <a href="https://www.pocketgamer.com/" target="_blank" rel="noopener noreferrer" className="footer-link">
                  Pocket Gamer
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
