// Wrapper for Google Tag Manager events
import { window } from "global";

function sendSearchBarEvent(searchQuery, searchId = undefined) {
  let curSearchId = searchId || "default";
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "search",
      category: "search-bar",
      searchQuery: searchQuery,
      searchBarType: curSearchId,
    });
  }
}

function pageview(page) {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "page_view",
      category: "manual",
      page: page,
    });
  }
}

export { sendSearchBarEvent, pageview };
