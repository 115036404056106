import { ROLE_OPTIONS, QUEUE_TYPE_OPTIONS, REGION_OPTIONS, RANK_OPTIONS_WITH_D2 } from "../common";

// ------------------------------------
//  ITEM PATHS
// ------------------------------------

export const ITEMS_OPTIONS = {
  queueType: QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  patch: { options: "latest", max: 5 },
};

export const ITEMS_DEFAULT = {
  queueType: ITEMS_OPTIONS.queueType[0].value,
  region: ITEMS_OPTIONS.region[0].value,
  rank: ITEMS_OPTIONS.rank[1].value,
  patch: ITEMS_OPTIONS.patch.options,
};
