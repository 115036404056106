import { window } from "global";
import { getUrl } from "@outplayed/riot-assets";
import _isEqual from "lodash.isequal";
import { WINDOW_BREAKPOINTS_RANGE, DEFAULT_BREAKPOINT } from "lib/constants";
let amplitude;
if (window) {
  amplitude = require("amplitude-js");
}

export function shouldOpenSideNav() {
  const key = window && localStorage.getItem("TOGGLE_AND_SAVE_SIDE_NAV_KEY");

  if (key === "true") return true;
  else if (key === "false") return false;
  else if (window && (key === undefined || key === null)) {
    return window.innerWidth > 1470;
  }
  return false;
}

export function getWindowBreakpoint(width) {
  if (!width) return DEFAULT_BREAKPOINT;

  for (let [breakpoint, breakpointWidth] of Object.entries(WINDOW_BREAKPOINTS_RANGE)) {
    if (width >= breakpointWidth[0] && width <= breakpointWidth[1]) {
      return breakpoint;
    }
  }
  return DEFAULT_BREAKPOINT;
}

// Check current window breakpoint exists in set of breakpoints
export function isWindowBreakpoint(curBreakpoint, breakpoints) {
  if (breakpoints.length === 0) return false;

  return breakpoints.some((breakpoint) => curBreakpoint === breakpoint);
}

export function replacePlusFromURLPath(string, replaceValue = " ") {
  if (!string) return string;
  return string.replace("+", replaceValue);
}

export function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function addCookie(key, value, isSession) {
  if (window) {
    if (isSession) {
      document.cookie = `${key}=${value}; path=/`;
    } else {
      document.cookie = `${key}=${value}; expires=Thu, 01-Jan-2100 00:00:01 GMT; path=/`;
    }
  }
}

export function removeCookie(key) {
  if (window) {
    document.cookie = `${key}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/`;
  }
}

export function getPercetange(num1, num2, decimalPlace = 0) {
  if (isNaN(num1) || isNaN(num2) || num2 === 0) {
    return null;
  }
  const percentage = (num1 / num2) * 100;

  if (decimalPlace > 0) {
    return Math.round(percentage * (10 * decimalPlace)) / (10 * decimalPlace);
  } else {
    return Math.round(percentage);
  }
}

// Check if keys and values are equal
export function compareObjects(obj1, obj2) {
  const objKeys1 = Object.keys(obj1);
  const objKeys2 = Object.keys(obj2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (const key of objKeys1) {
    if (objKeys1[key] !== objKeys2[key]) {
      return false;
    }
  }

  return true;
}

export function getChampionTransformId(id) {
  return id % 10000;
}

export function isArrayEmpty(array) {
  return !Array.isArray(array) || array.length === 0;
}

// Check if multidimensional array exists given an arrays of nested keys
export function mdArrayExists(array, arrayKeys) {
  if (!array) {
    return false;
  }

  for (let i = 0; i < arrayKeys.length; i++) {
    if (array[arrayKeys[i]]) {
      array = array[arrayKeys[i]];
      continue;
    } else {
      return false;
    }
  }
  return array;
}

export function mapVersionsToOptions(versions, size = 2) {
  let mappedVersions = null;

  if (versions) {
    mappedVersions = versions
      .map((version) => {
        return {
          value: version,
          label: version.replace("_", "."),
        };
      })
      .slice(0, size);
  }

  return mappedVersions;
}

export function alphanumeric(string) {
  return string.replace(/[^A-Za-z0-9]/g, "");
}

export function removeTrailingSlash(url) {
  if (url.charAt(url.length - 1) === "/") {
    return url.substring(0, url.length - 1);
  }

  return url;
}

export function parseQueryString(query = "") {
  let formattedQuery = query;
  if (query.charAt(0) === "?") {
    formattedQuery = query.substring(1);
  }

  const decodeQuery = decodeURIComponent(formattedQuery);
  const keys_values = decodeQuery.split("&");
  let queryObj = {};

  for (let param of keys_values) {
    let splitKeyValue = param.split("=");
    if (splitKeyValue[0] && splitKeyValue[1] !== undefined) {
      queryObj[splitKeyValue[0]] = splitKeyValue[1];
    }
  }

  return queryObj;
}

export function buildQueryParams(params) {
  if (!params) return "";

  return Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");
}

export function addQueryParamsToString(search, newParams) {
  const oldParams = parseQueryString(search);
  const replaceParams = Object.assign({}, oldParams, newParams);
  return buildQueryParams(replaceParams);
}

export function compareSearchParams(a, b) {
  const _a = !a || a === "" || (a.length === 1 && a === "?") ? "" : a;
  const _b = !b || b === "" || (b.length === 1 && b === "?") ? "" : b;

  return _a === _b;
}

export function updateQueryParamsIfChanged(history, location, params) {
  if (window && window.location) {
    let curParams = parseQueryString(location.search.substring(1));
    if (!_isEqual(curParams, params)) {
      updateQueryParams(history, location.pathname, params);
    }
  }
}

export function updateQueryParams(history, pathname, params) {
  history.replace({
    pathname: pathname,
    search: `?${buildQueryParams(params)}`,
  });
}

export function removeKeys(obj, arrayOfKeys) {
  const clone = Object.assign({}, obj);

  for (let key of arrayOfKeys) {
    delete clone[key];
  }

  return clone;
}

export function capitalizeFirstLetter(string) {
  if (!string) {
    return "";
  }

  return `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;
}

export function numberWithCommas(num) {
  if (num === 0) return 0;
  if (!num) return "";

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function thousandCondense(num) {
  if (num > 1000) {
    return `${(num / 1000).toFixed(1)}k`;
  }

  return num;
}

export function getMultiKill(maximumKillStreak) {
  switch (maximumKillStreak) {
    case 5:
      return {
        color: "#FF9B00",
        label: "Penta Kill",
        svg: getUrl("lol/icons/pentakill.svg"),
        svgAlt: getUrl("lol/icons/pentakill-light.svg"),
      };
    case 4:
      return {
        color: "#08A6FF",
        label: "Quadra Kill",
        svg: getUrl("lol/icons/quadrakill.svg"),
        svgAlt: getUrl("lol/icons/quadrakill-light.svg"),
      };
    case 3:
      return {
        color: "#7AD0D7",
        label: "Triple Kill",
        svg: getUrl("lol/icons/triplekill.svg"),
        svgAlt: getUrl("lol/icons/triplekill-light.svg"),
      };
    case 2:
      return {
        color: "#4F4F60",
        label: "Double Kill",
        svg: getUrl("lol/icons/doublekill.svg"),
        svgAlt: getUrl("lol/icons/doublekill-light.svg"),
      };
    default:
      return null;
  }
}

export function getWinRateColorClassName(num) {
  if (num >= 70) {
    return "volxd-tier";
  } else if (num >= 60) {
    return "great-tier";
  } else if (num >= 40) {
    return "okay-tier";
  } else {
    return "shinggo-tier";
  }
}

export function getWinRateBestChampsColorClassName(num) {
  if (num >= 70) {
    return "volxd-tier";
  } else if (num >= 60) {
    return "great-tier";
  } else {
    return "gray-okay-tier";
  }
}

export function getWinRateColor(num, lightModeOn, worstColorIsRed = true) {
  if (num >= 70) {
    return "#FF9B00";
  } else if (num >= 60) {
    return "#3273FA";
  } else if (num >= 40) {
    return lightModeOn ? "#383864" : "#FFFFFF";
  } else {
    if (worstColorIsRed) {
      return "#FF4E50";
    } else {
      return lightModeOn ? "#383864" : "#FFFFFF";
    }
  }
}

export function calculateKDA(kills, deaths, assists) {
  if (parseFloat(deaths) === 0) {
    if (parseFloat(kills) > 0 || parseFloat(assists) > 0) {
      return "Perfect";
    } else {
      return "0.00";
    }
  }

  return `${(Math.round(((parseFloat(kills) + parseFloat(assists)) / parseFloat(deaths)) * 100) / 100).toFixed(2)}`;
}

export function getKdaColor(kda, lightModeOn) {
  if (kda === "Perfect") {
    return "#FF9B00";
  }

  if (kda >= 5) {
    return "#FF9B00";
  } else if (kda >= 3) {
    return "#3273FA";
  } else if (kda >= 1) {
    return lightModeOn ? "#5F648A" : "#FFFFFF";
  }
}

export function getKdaColorClassName(kda) {
  if (kda === "Perfect") {
    return "volxd-tier";
  } else if (kda === "0.00") {
    return "shinggo-tier";
  }

  if (kda >= 5) {
    return "volxd-tier";
  } else if (kda >= 3) {
    return "great-tier";
  } else if (kda >= 1) {
    return "okay-tier";
  } else {
    return "shinggo-tier";
  }
}

export function getKdaColorBestChampsClassName(kda) {
  if (kda === "Perfect") {
    return "volxd-tier";
  } else if (kda === "0.00") {
    return "gray-okay-tier";
  }

  if (kda >= 5) {
    return "volxd-tier";
  } else if (kda >= 3) {
    return "great-tier";
  } else if (kda >= 1) {
    return "gray-okay-tier";
  }
}

export function getQueueType(type) {
  switch (type) {
    case 420:
    case "ranked_solo_5x5":
      return "Ranked Solo";
    case 440:
    case "ranked_flex_sr":
      return "Ranked Flex";
    case 430:
    case "normal_blind_5x5":
      return "Normal Blind";
    case 400:
    case "normal_draft_5x5":
      return "Normal Draft";
    case 450:
    case "normal_aram":
      return "ARAM";
    case 490:
    case "quickplay":
      return "Quickplay";
    case 1200:
    case 1300:
    case "nexus_blitz":
      return "Nexus Blitz";
    case 700:
    case "clash_5x5":
      return "Clash";
    case "urf":
      return "URF";
    case 900:
      return "ARURF";
    case 1020:
    case "one_for_all":
      return "One for All";
    case 1400:
    case "ultimate_spellbook":
      return "Ult Spellbook";
    case "ranked_flex_tt":
      return "Twisted Treeline";
    case "ranked_tft":
      return "Teamfight Tactics";
    case 1700:
    case "arena":
      return "Arena";
    case 1900:
    case "pick_urf":
      return "URF";
    case "custom_game":
      return "Custom";
    default:
      return "";
  }
}

export function isRankedQueue(queueType) {
  return !!queueType && (queueType == "ranked_solo_5x5" || queueType == "ranked_flex_sr");
}

export function tierLetter(tier) {
  switch (tier) {
    case "iron":
      return "I";
    case "bronze":
      return "B";
    case "silver":
      return "S";
    case "gold":
      return "G";
    case "platinum":
      return "P";
    case "diamond":
      return "D";
    case "master":
      return "M";
    case "grandmaster":
      return "G";
    case "challenger":
      return "C";
    default:
      return "U";
  }
}

export function tierToNumber(tier) {
  switch (tier) {
    case "bronze":
      return 0;
    case "silver":
      return 500;
    case "gold":
      return 1000;
    case "platinum":
      return 1500;
    case "diamond":
      return 2000;
    case "master":
      return 2500;
    case "challenger":
      return 2500;
    default:
      return 0;
  }
}

export function rankToNumber(rank) {
  if (!rank) return 0;
  switch (rank.toUpperCase()) {
    case "I":
      return 400;
    case "II":
      return 300;
    case "III":
      return 200;
    case "IV":
      return 100;
    case "V":
      return 0;
    default:
      return 0;
  }
}

export function getColorStringForWinrate(winRate) {
  if (winRate > 1) winRate = winRate / 100;
  return winRate > 0.65 ? "orange" : winRate > 0.6 ? "blue" : winRate > 0.55 ? "light-blue" : winRate > 0.5 ? "white" : "grey";
}

export function getColorForWinrate(winRate) {
  if (winRate > 1) winRate = winRate / 100;
  return winRate > 0.65
    ? "#ff9b00"
    : winRate > 0.6
    ? "#08a6ff"
    : winRate > 0.55
    ? "#6bcaff"
    : winRate > 0.5
    ? "#cddcfe"
    : "#5f5f7B";
}

export function calculateLP(matches) {
  if (matches === null) return;

  let highestMasterLP = 0;
  let highestChallengerLP = 0;

  const beforeMasterChallenger = matches.map((match) => {
    const calculatedLP = tierToNumber(match.mmr.tier) + rankToNumber(match.mmr.rank) + match.mmr.lp;
    if (match.mmr.tier === "master") {
      highestMasterLP = calculatedLP > highestMasterLP ? calculatedLP : highestMasterLP;
      return { tier: "master", lp: calculatedLP };
    } else if (match.mmr.tier === "challenger") {
      highestChallengerLP = calculatedLP > highestChallengerLP ? calculatedLP : highestChallengerLP;
      return { tier: "challenger", lp: calculatedLP };
    }

    return calculatedLP;
  });

  if (highestMasterLP > 0 || highestChallengerLP > 0) {
    return beforeMasterChallenger.map((match) => {
      if (match.tier === "master") return match.lp;
      else if (match.tier === "challenger") return match.lp + highestMasterLP - 2500;
      return undefined;
    });
  }

  return beforeMasterChallenger;
}

export function mapRole(role) {
  switch (role) {
    case "top":
      return 1;
    case "jungle":
      return 2;
    case "mid":
      return 3;
    case "adc":
      return 4;
    case "supp":
      return 5;
    default:
      return 6;
  }
}

export function convertRomanNumeral(roman) {
  if (!roman) return "";
  switch (roman.toUpperCase()) {
    case "I":
      return 1;
    case "II":
      return 2;
    case "III":
      return 3;
    case "IV":
      return 4;
    case "V":
      return 5;
    default:
      return "";
  }
}

export const SEO_ROLES = ["top", "jungle", "mid", "adc", "support"];

export function getApiRole(filterType) {
  let apiRole = null;
  switch (filterType) {
    case "top":
      apiRole = "top";
      break;
    case "jungle":
      apiRole = "jungle";
      break;
    case "mid":
    case "middle":
      apiRole = "mid";
      break;
    case "adc":
      apiRole = "adc";
      break;
    case "supp":
    case "support":
      apiRole = "supp";
      break;
    case "lane":
      apiRole = "lane";
      break;
    default:
      apiRole = "all";
  }
  return apiRole;
}

export function getRoleUrl(filterType) {
  let apiRole = null;
  switch (filterType) {
    case "top":
      apiRole = "top";
      break;
    case "jungle":
      apiRole = "jungle";
      break;
    case "mid":
    case "middle":
      apiRole = "mid";
      break;
    case "adc":
      apiRole = "adc";
      break;
    case "supp":
    case "support":
      apiRole = "support";
      break;
    case "lane":
      apiRole = "lane";
      break;
    default:
      apiRole = "all";
  }
  return apiRole;
}

export function getRevApiRole(apiRole) {
  let revApiRole = null;
  switch (apiRole) {
    case "top":
      revApiRole = "top";
      break;
    case "jungle":
      revApiRole = "jungle";
      break;
    case "mid":
    case "middle":
      revApiRole = "middle";
      break;
    case "bot":
    case "adc":
      revApiRole = "adc";
      break;
    case "supp":
    case "support":
      revApiRole = "support";
      break;
    case "lane":
      revApiRole = "lane";
      break;
    default:
      revApiRole = "all";
  }
  return revApiRole;
}

export function skillMapping(skillKey) {
  switch (skillKey.toUpperCase()) {
    case "Q":
      return 0;
    case "W":
      return 1;
    case "E":
      return 2;
    case "R":
      return 3;
    default:
      return -1;
  }
}

export function numToRoleMapping(num) {
  switch (Number(num)) {
    case 1:
      return "jungle";
    case 2:
      return "support";
    case 3:
      return "adc";
    case 4:
      return "top";
    case 5:
      return "middle";
    case 8:
      return "lane";
    default:
      return "";
  }
}

export function normalizePatch(patch) {
  const patchSplit = patch.split(".");
  if (patchSplit.length >= 2) {
    return `${patchSplit[0]}_${patchSplit[1]}`;
  }

  return patch;
}

export function hasPerfScore(matchType) {
  const acceptableTypes = ["ranked_solo_5x5", "normal_blind_5x5", "normal_draft_5x5", "ranked_flex_sr", "clash_5x5"];

  return acceptableTypes.includes(matchType);
}

export function getPerfScore(hardCarryScore, teamplayScore) {
  return Math.round((hardCarryScore + teamplayScore) / 2);
}

export function amplitudeLog(title, options) {
  const lastItem = sessionStorage.getItem("lastAmplitudeLogged");
  if (lastItem !== title) {
    if (amplitude) {
      amplitude.logEvent(title, options);
      sessionStorage.setItem("lastAmplitudeLogged", true);
    }
  }
}

export function amplitudeLogBasic(eventId, options) {
  if (amplitude) {
    amplitude.logEvent(eventId, options);
  }
}

export function getPremiumMap() {
  const creditCardMap = {
    "American Express": 22,
    "Diners Club": 10,
    Discover: 14,
    JCB: 16,
    MasterCard: 2,
    Visa: 1,
  };

  const planMap = {
    "basic_premium-12-month": "12 Months",
    "basic_premium-6-month": "6 Months",
    "basic_premium-1-month": "1 Month",
    basic_premium: "1 Month",
    "": "0",
  };

  const planMapNum = {
    12: "12 Months",
    6: "6 Months",
    1: "1 Month",
    "": "0",
  };

  const durationPlanMap = {
    "basic_premium-12-month": "12",
    "basic_premium-6-month": "6",
    "basic_premium-1-month": "1",
    basic_premium: "1",
  };

  const premiumVersionMapNum = {
    12: "basic_premium-12-month",
    6: "basic_premium-6-month",
    1: "basic_premium-1-month",
  };

  const pricePerMap = {
    "basic_premium-12-month": "year",
    "basic_premium-1-month": "month",
  };

  const termMap = {
    "basic_premium-12-month": "yearly",
    "basic_premium-1-month": "monthly",
  };

  const maps = {
    creditCardMap,
    planMap,
    durationPlanMap,
    premiumVersionMapNum,
    pricePerMap,
    planMapNum,
    termMap,
  };

  return maps;
}

export function formatPremiumPrice(planPricing) {
  let amount = planPricing && planPricing.amount;
  let currency = planPricing && planPricing.currency;

  return `${currency}${amount}`;
}

export function getPlanDurationUnit(months) {
  switch (months) {
    case 1:
      return "month";
    case 12:
      return "year";
    default:
      return "";
  }
}

export function getAppDownloadUrl(params = {}) {
  const validParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];

  const filteredParams = Object.fromEntries(
    Object.entries(params || {}).filter(([key, value]) => {
      return validParams.includes(key);
    }),
  );

  const baseUrl =
    "https://download.overwolf.com/install/Download?Name=U.GG&ExtensionId=edoaelkdajnifpnkdfillhjpaimimibflhkhjngh&Channel=web_dl_btn&PartnerId=4204";
  const url = new URL(baseUrl);
  const { origin, pathname, search } = url;
  const searchWithOWParams = {
    ...parseQueryString(search),
    ...filteredParams,
  };

  return origin + pathname + `?${buildQueryParams(searchWithOWParams)}`;
}

export function isAccountPage(location) {
  const accountPages = ["/signup", "/login", "/recover-account", "/reset-password", "/verification", "/about"];
  if (location && location.pathname) {
    return accountPages.includes(location.pathname);
  }
  return false;
}

export function isPremiumPage(location) {
  if (location && location.pathname) {
    return location.pathname.match(/^\/boost\//);
  }
  return false;
}
