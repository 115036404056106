import gql from "graphql-tag";
import { useQuery, useLazyQuery, QueryHookOptions, LazyQueryHookOptions } from "@apollo/client";
import { PlayerInfo, PlayerInfoInterface, PlayerProfileSuggestions } from "./profile-init";

const PLAYER_INFO_SUGGESTIONS = gql`
  query PlayerProfileSuggestions($query: String!, $regionId: String!) {
    playerProfileSuggestions(query: $query, regionId: $regionId) {
      regionId
      puuid
      riotUserName
      riotTagLine
      riotId
      tier
      rank
      lp
      rankScore
      iconId
      summonerLevel
    }
  }
`;

export interface PlayerInfoSuggestionsData {
  playerProfileSuggestions: PlayerProfileSuggestions[];
}

export function usePlayerInfoSuggestions(queryValue: string, regionId: string, queryOptions?: QueryHookOptions) {
  return useQuery<PlayerInfoSuggestionsData>(PLAYER_INFO_SUGGESTIONS, {
    ...(queryOptions || {}),
    variables: {
      query: queryValue,
      regionId,
    },
  });
}

export function useLazyPlayerInfoSuggestions(queryOptions?: LazyQueryHookOptions) {
  return useLazyQuery<PlayerInfoSuggestionsData>(PLAYER_INFO_SUGGESTIONS, {
    ...(queryOptions || {}),
  });
}
