import React, { useMemo } from "react";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { calculateKDA } from "lib/general-helper";
import { getMSWinRateColor } from "../multi-search-helpers";

const ChampionPerformanceList = (props) => {
  const { getChampionImg } = getRiotAssetsContext();
  const { title, list, size = 5 } = props;

  const performanceList = useMemo(() => {
    return list
      .map((el) => ({
        ...el,
        kda: calculateKDA(el.kills, el.deaths, el.assists),
        winRate: Math.round(((el.wins || 0) / el.games) * 100),
      }))
      .concat(Array(size - list.length).fill(null));
  }, [list]);

  return (
    <div className="player_champion-performance">
      <div className="performance_title">{title}</div>
      <div className="performance_list">
        {performanceList.map((champion, index) => {
          if (!champion || !champion.champId) {
            return <div key={index} className="champion" />;
          }

          return (
            <div key={index} className="champion">
              <div>
                <div className="champion-image-container">
                  <img src={getChampionImg(champion.champId)} />
                </div>
                <span style={{ color: getMSWinRateColor(champion.winRate), fontWeight: 600 }}>{champion.winRate}%</span>
              </div>
              <div className="off-white">{champion.kda}</div>
              <div className="off-white">{champion.games} games</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChampionPerformanceList;
