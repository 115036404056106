import { Redirect, useParams } from "react-router-dom";
import Error404 from "@ugg/shared/components/common/Error404";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";
import { useRiotIdBySummonerName } from "@ugg/shared/api/requests/summoner-profiles/riot-id-by-summoner-name";

export function RiotIdRedirect() {
  const { summonerName, region } = useParams<{ summonerName: string; region: string }>();

  const { data, loading, error } = useRiotIdBySummonerName(region, summonerName);
  const { riotUserName, riotTagLine } = data?.riotIdBySummonerName || {};

  if (riotUserName && riotTagLine) {
    return <Redirect to={getProfileOverviewUrl(region, riotUserName, riotTagLine)} />;
  }
  if (loading) {
    return null;
  }

  return <Error404 />;
}
