import React, { useState, useEffect, useRef } from "react";
import { useGlobal } from "reactn";

import { Link, useLocation, useHistory } from "react-router-dom";
import ForgotPasswordInput from "components/ForgotPasswordInput";
import CustomInput from "components/CustomInput";
import { Checkbox } from "@ugg/shared/components/common/Checkbox";
import RecaptchaWrapper from "components/RecaptchaWrapper";
import ButtonSuccess from "components/Buttons/ButtonSuccess";
import { pages } from "lib/redirect-helpers";

import { useLogin } from "lib/hooks/userHooks";
import { parseQueryString } from "lib/general-helper.js";
import { grecaptchaExecute } from "lib/form-helpers";
import { recaptchaBypass } from "api/devConstants";

export const LoginButton = (props) => {
  const { children, style } = props;

  return (
    <Link style={style} className="login-btn" to="/login">
      {children}
    </Link>
  );
};

const Login = (props) => {
  const history = useHistory();
  const location = useLocation();
  const search = location.search.substring(1);
  const parsedQueryString = parseQueryString(search);
  const [loginError, setLoginError] = useState("");
  const [loggingIn] = useGlobal("loggingIn");
  // Form
  const [form, setForm] = useState({
    email: { value: "", error: "" },
    password: { value: "", error: "" },
    persistLogin: { value: true },
  });

  const { email, password, persistLogin } = form;

  const loginCreds = {
    persistLogin: persistLogin.value,
    email: email.value,
    password: password.value,
  };

  const onCompleted = () => {
    let redirectURL = "/";
    if (parsedQueryString.continue) {
      const splitQuery = parsedQueryString.continue.split("/");
      redirectURL = `/boost/payment?planType=${splitQuery.pop()}`;
    }
    if (parsedQueryString.redirect_url) {
      redirectURL = parsedQueryString.redirect_url;
    }
    window.location.href = redirectURL;
  };

  const onError = (error) => {
    // Bad email or password
    if (error === "unauthorized") {
      setLoginError("Incorrect email/password");
    }
    // Other errors
    else {
      setLoginError("An unexpected error occurred. Please try again.");
    }
  };

  const login = useLogin(loginCreds, onCompleted, onError);

  const onInputChange = (key, value) => {
    setForm({ ...form, [key]: { ...form[key], ...value } });
  };

  const verifyForm = () => {
    let verifiedForm = {};
    let hasError = false;
    function mergeForm(state, error) {
      if (error === true) {
        hasError = true;
      }
      return Object.assign(verifiedForm, state);
    }

    if (email.value !== undefined) {
      if (email.value.trim().length === 0) {
        verifiedForm = mergeForm({ email: { ...email, error: "Missing email" } }, true);
      } else {
        verifiedForm = mergeForm({ email: { ...email, error: "" } });
      }
    }

    // Password
    if (password.value.trim().length === 0) {
      verifiedForm = mergeForm({ password: { ...password, error: "Missing password" } }, true);
    } else {
      verifiedForm = mergeForm({ password: { ...password, error: "" } });
    }

    return { form: verifiedForm, error: hasError };
  };

  const completeLogin = (e) => {
    const verifiedForm = verifyForm();
    setForm({ ...form, ...verifiedForm.form });

    if (verifiedForm.error) return;

    setLoginError("");
    login();
  };

  const switchToSignup = () => {
    let continuePath = [];
    if (parsedQueryString.continue) {
      continuePath.push(`continue=${parsedQueryString.continue}`);
    }
    if (parsedQueryString.redirect_url) {
      continuePath.push(`redirect_url=${parsedQueryString.redirect_url}`);
    }

    // `/signup${continuePath.length > 0 ? `?${continuePath.join("&")}` : ""}`;
    return { pathname: "/signup", search };
  };

  return (
    <div className="login-outer">
      <div className="back-to-home">
        <Link to={pages["home"]}>&lt; Back to Home</Link>
      </div>
      <div className="login">
        <div className="logo-container" style={{ marginBottom: "48px" }}>
          <img className="logo" src="https://static.bigbrain.gg/assets/ugg/logo/ugg-logo.svg" onClick={() => history.push("/")} />
        </div>
        <div className="white-bold" style={{ marginBottom: "12px" }}>
          Welcome back to U.GG
        </div>
        <div className="basic-text">
          {" "}
          New here?{" "}
          <Link to={switchToSignup()}>
            <span>Create an account</span>
          </Link>
        </div>
        <form onSubmit={(e) => e.preventDefault()} className="login_form">
          <CustomInput
            value={email.value}
            error={email.error}
            onChange={(e) => onInputChange("email", { value: e.target.value })}
            placeholder="Email"
            type="email"
            tabIndex={1000}
            autoFocus
            style={{ marginBottom: 30 }}
            label={"Email"}
          />
          <CustomInput
            value={password.value}
            error={password.error}
            onChange={(e) => onInputChange("password", { value: e.target.value })}
            placeholder="Password"
            type="password"
            tabIndex={1000}
            style={{ marginBottom: 0 }}
            label={"Password"}
            rightLabel={"Forgot Password?"}
            rightLabelLink={"recover-account"}
          />
          <div className="flex items-center my-[28px]">
            <Checkbox
              checkboxId="keep-me-logged-in"
              isChecked={persistLogin.value}
              onChange={(e) => onInputChange("persistLogin", { value: e.target.checked })}
            />
            <label className="ml-[8px] text-[16px] text-white leading-normal cursor-pointer" htmlFor="keep-me-logged-in">
              Keep me logged in
            </label>
          </div>
          {loginError && <div className="login-error">{loginError}</div>}
          <ButtonSuccess
            className="login-submit btn-light-blue"
            label="Login"
            isLoading={loggingIn}
            onClick={completeLogin}
            bForceSubmitOnEnter={true}
          />
        </form>
      </div>
    </div>
  );
};

export default Login;
