import React from "react";
import { useUGGPatches } from "lib/hooks/patchHooks";
import { useUGGApiVersions } from "api/uggApiVersions";

const AppDataInitializer = (props) => {
  const staging = process.env.RIOT_PATCH_ASSETS === "staging";
  const patches = useUGGPatches({ staging, ssr: true });
  const versions = useUGGApiVersions({ staging, ssr: true });

  const allDataFetched = [patches, versions].every((el) => el.data && !el.loading && !el.error);

  if (!allDataFetched) {
    return null;
  }

  return props.children;
};

export default AppDataInitializer;
