import { window } from "global";
import React, { useCallback, useEffect } from "react";
import { useGlobal } from "reactn";
import { getWindowBreakpoint } from "lib/general-helper";

const Responsive = (props) => {
  const [responsive, setResponsive] = useGlobal("responsive");

  const resizeHandler = useCallback(() => {
    const breakpoint = getWindowBreakpoint(window.innerWidth);
    if (breakpoint !== responsive) {
      setResponsive(breakpoint);
    }
  }, [responsive]);

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", resizeHandler);

      return () => window.removeEventListener("resize", resizeHandler);
    }
  }, [resizeHandler]);
  // console.log(responsive)
  return null;
};

export default Responsive;
