import React, { useState } from "react";
import RegionSelector from "components/Masthead/RegionSelector";
import CustomInput from "components/CustomInput";
import ButtonSuccess from "components/Buttons/ButtonSuccess";
import { split, useQuery } from "@apollo/client";
import { UPDATE_PRIMARY_SUMMONER, GET_RIOT_VERIFICATION_ICON } from "@ugg/shared/api/graphql/queries";
import { splitRiotId } from "@ugg/shared/utils/riot-id-helpers";

const VerificationRiotIdPage = (props) => {
  const { incStep, regionId, riotId } = props;
  const [inputText, setInputText] = useState(riotId);
  const [region, setRegion] = useState(regionId);
  const [errorMsg, setErrorMsg] = useState("");
  const [fetchState, setFetchState] = useState(0);
  const [inputRiotUserName, inputRiotTagLine] = splitRiotId(inputText);
  console.log(inputRiotUserName, inputRiotTagLine);

  const verifyForm = () => {
    if (inputRiotUserName && inputRiotTagLine) {
      setFetchState(1);
    }
  };

  const { data } = useQuery(UPDATE_PRIMARY_SUMMONER, {
    // Refetch does not work because this is a query and not mutation
    // refetchQueries: [
    //   { query: GET_USER_STATE },
    //   { query: GET_USER_SETTINGS }
    // ],
    variables: {
      regionId: region,
      riotUserName: inputRiotUserName,
      riotTagLine: inputRiotTagLine,
    },
    skip: fetchState !== 1,
  });

  const {
    loading: codeLoading,
    error: codeError,
    data: codeData,
  } = useQuery(GET_RIOT_VERIFICATION_ICON, {
    skip: !data,
    fetchPolicy: "network-only",
  });
  if (codeData) {
    setFetchState(3);
    incStep(codeData.requestRiotVerificationCode.iconId);
  }

  return (
    <div className="summoner-name">
      <div className="small-header">Verify Your Account</div>
      <div className="step-text">Step 1 of 2</div>
      <div className="basic-text">Confirm your Riot ID</div>
      <div className="summoner-input">
        <div className="summoner-input_region-selector">
          <RegionSelector disableTooltip currentRegion={region} onApply={setRegion} isMasthead />
        </div>
        <CustomInput
          value={inputText}
          error={errorMsg}
          onChange={(e) => setInputText(e.target.value)}
          type="text"
          tabIndex={1000}
          placeholder="Riot ID"
          autoFocus
          style={{ marginBottom: 30, paddingLeft: 70 }}
        />
      </div>
      <ButtonSuccess
        className="login-submit btn-light-blue"
        label="Verify"
        isLoading={fetchState !== 0}
        onClick={verifyForm}
        bForceSubmitOnEnter={true}
      />
    </div>
  );
};

export default VerificationRiotIdPage;
