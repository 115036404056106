import { useJSONFetcher } from "@outplayed/json-fetcher";
import { RAC_getWorkingPatch } from "./patches";
import { DDRAGON, getImgFromSpritesheet, getJSON } from "../common";
import {
  getChampionData,
  getChampionFullData,
  getIndividualChampionData,
  uggGetChampionImg,
  uggGetSpriteImg,
  uggGetChampionSplashImg,
} from "../static-url-helpers";

export function RAC_useBackupChampions(backupChampions) {
  return () => backupChampions;
}

export function RAC_useChampionMini(backupChampions, versions) {
  return ({ patch, language, skip, ssr } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const championMiniURL = getChampionData(riotPatch, language);
    const onCompleted = (url, json) => {
      const data = json.data;
      const champions = {};
      for (const champion of Object.values(data)) {
        champions[champion.key] = champion;

        // Unused fields
        delete champions[champion.key].stats;
        delete champions[champion.key].blurb;
      }
      for (const [id, value] of Object.entries(backupChampions)) {
        if (!champions[id]) {
          champions[id] = { isBackup: true, ...value };
        }
      }
      return champions;
    };
    return useJSONFetcher(championMiniURL, { skip, ssr, onCompleted });
  };
}

export function RAC_useChampionFull(backupChampions, versions) {
  return ({ patch, language, skip, ssr } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const championFullURL = getChampionFullData(riotPatch, language);
    const onCompleted = (url, json) => {
      const data = json.data;
      const champions = {};
      for (const champion of Object.values(data)) {
        champions[champion.key] = champion;
      }
      for (const [id, value] of Object.entries(backupChampions)) {
        if (!champions[id]) {
          champions[id] = { isBackup: true, ...value };
        }
      }
      return champions;
    };

    return useJSONFetcher(championFullURL, { skip, ssr, onCompleted });
  };
}

export function RAC_useChampionIndiv(championMini, backupChampions, versions) {
  return (id, { patch, language, skip, ssr } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const championName = championMini[id] && championMini[id].id;
    if (typeof championName === "undefined") {
      return { data: undefined, loading: false };
    }
    const championIndivURL = getIndividualChampionData(riotPatch, championName, language);
    const onCompleted = (url, json) => {
      const id = Object.keys(json.data)[0];
      return json.data[id];
    };
    const onError = (url, error) => {
      return backupChampions[id] || null;
    };

    return useJSONFetcher(championIndivURL, {
      skip,
      ssr,
      onCompleted,
      onError,
    });
  };
}

export function RAC_useChampionIndivByName(championMini, backupChampions, versions) {
  return (name, { patch, language, skip, ssr } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const mini = Object.values(championMini).find(
      (x) => x.id.toLowerCase() === name.toLowerCase() || x.name.toLowerCase() === name.toLowerCase(),
    );
    const championName = mini.id;
    const championIndivURL = getIndividualChampionData(riotPatch, championName, language);
    const onCompleted = (url, json) => {
      const id = Object.keys(json.data)[0];
      return json.data[id];
    };
    const onError = (url, error) => {
      return backupChampions[id] || null;
    };

    return useJSONFetcher(championIndivURL, {
      skip,
      ssr,
      onCompleted,
      onError,
    });
  };
}

const championsCache = {};
export function RAC_getChampions(backupChampions, versions) {
  return ({ patch, language } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const cacheKey = `${riotPatch}_${language}`;
    if (!(cacheKey in championsCache)) {
      let url = getChampionData(riotPatch, language);
      const champions = getJSON(url);
      if (!champions) return null;
      let idToChampMap = {};
      if (champions) {
        for (const [name, value] of Object.entries(champions["data"])) {
          idToChampMap[value.key] = value;
        }
        for (const [id, value] of Object.entries(backupChampions)) {
          if (!idToChampMap[id]) {
            idToChampMap[id] = { isBackup: true, ...value };
          }
        }
      }
      championsCache[cacheKey] = idToChampMap;
    }
    return championsCache[cacheKey];
  };
}

const championCache = {};
export function RAC_getIndividualChampion(championMini, backupChampions, versions) {
  return (id, { patch, language } = {}) => {
    if (!championMini[id]) {
      return backupChampions[id];
    }

    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const cacheKey = `${id}_${riotPatch}_${language}`;
    if (!(cacheKey in championCache)) {
      const championName = championMini[id].id;
      let url = getIndividualChampionData(riotPatch, championName, language);
      const json = getJSON(url);
      if (!json) return null;

      const { data } = json;
      const champion = Object.values(data)[0];
      championCache[cacheKey] = {
        ...champion,
      };
    }
    return championCache[cacheKey];
  };
}

export function RAC_getChampionIdByName(championMini, backupChampions) {
  return (championName) => {
    if (!championName) return 0;

    for (const champions of [Object.values(championMini), Object.values(backupChampions)]) {
      for (const value of champions) {
        if (championName.toLowerCase() === value.id.toLowerCase() || championName.toLowerCase() === value.name.toLowerCase()) {
          return parseInt(value.key);
        }
      }
    }

    return 0;
  };
}

export function RAC_getChampionJSONByName(championMini, backupChampions) {
  return (championName) => {
    if (!championName) return null;

    for (const champions of [championMini, backupChampions]) {
      for (const key in champions) {
        if (
          championName.toLowerCase() === championMini[key].id.toLowerCase() ||
          championName.toLowerCase() === championMini[key].name.toLowerCase()
        ) {
          return champions[key];
        }
      }
    }

    return null;
  };
}

export function RAC_getChampionName(championMini, backupChampions) {
  return (id) => {
    if (championMini[id]) {
      return championMini[id].name;
    } else if (backupChampions[id]) {
      return backupChampions[id].name;
    } else {
      return "";
    }
  };
}

export function RAC_getNormalizedChampionName(championMini, backupChampions) {
  return (id) => {
    let name = "";
    if (championMini[id]) {
      name = championMini[id].id;
    } else if (backupChampions[id]) {
      name = backupChampions[id].id;
    }
    if (name === "MonkeyKing") {
      name = "Wukong";
    }
    return name.toLowerCase();
  };
}

export function RAC_getChampionNameId(championMini, backupChampions) {
  return (id) => {
    let name = "";
    if (championMini[id]) {
      name = championMini[id].id;
    } else if (backupChampions[id]) {
      name = backupChampions[id].id;
    }
    return name;
  };
}

export function RAC_getChampionImg(championMini, backupChampions, versions) {
  return (id, { webp } = {}) => {
    if (championMini[id] && !championMini[id].isBackup) {
      const image = championMini[id].image.full;
      return uggGetChampionImg(RAC_getWorkingPatch(versions)(), image, {
        webp,
      });
    } else if (backupChampions[id]) {
      return backupChampions[id].image;
    } else {
      return "";
    }
  };
}

export function RAC_getChampionImgFromSprite(championMini, backupChampions, versions) {
  return (id, { size, webp } = {}) => {
    const championJSON = championMini[id];

    if (championMini[id] && !championMini[id].isBackup) {
      const sprite = championJSON.image.sprite;
      const x = championJSON.image.x;
      const y = championJSON.image.y;
      const curPatch = RAC_getWorkingPatch(versions)();
      const spritesheetUrl = uggGetSpriteImg(curPatch, sprite, { webp });

      return getImgFromSpritesheet(spritesheetUrl, x, y, size);
    } else if (backupChampions[id]) {
      const altSrc = backupChampions[id].image;
      return getImgFromSpritesheet(null, null, null, size, altSrc);
    } else {
      return null;
    }
  };
}

export function RAC_getChampionLoadingImg(championMini, backupChampions) {
  return (id, imgId = 0) => {
    if (!id || (!championMini[id] && !backupChampions[id])) return "";

    const name = (championMini[id] && championMini[id].id) || (backupChampions[id] && backupChampions[id].id);

    return `${DDRAGON}/cdn/img/champion/loading/${name}_${imgId}.jpg`;
  };
}

export function RAC_getChampionSplashImg(championMini, versions) {
  return (id, { splashId = 0, webp } = {}) => {
    if (!id || !championMini[id]) return "";

    let name = championMini[id].id;
    if (name === "Fiddlesticks") {
      name = "FiddleSticks";
    }
    const latestPatch = RAC_getWorkingPatch(versions)();
    return uggGetChampionSplashImg(latestPatch, `${name}_${splashId}.jpg`, {
      webp,
    });
  };
}
