import { window } from "global";
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { WINDOW_BREAKPOINTS_RANGE } from "lib/constants";

const checkWindowWidth = ({ min, max, at }) => {
  let shouldRender = false;
  if (window) {
    const dimtoCheck = window.innerWidth;

    if ((min && max) || at) {
      if (max === "DESKTOP_LARGE") {
        shouldRender = dimtoCheck >= WINDOW_BREAKPOINTS_RANGE[min || at][0];
      } else if (min === "MOBILE_SMALL") {
        shouldRender = dimtoCheck <= WINDOW_BREAKPOINTS_RANGE[max || at][1];
      } else {
        shouldRender =
          dimtoCheck >= WINDOW_BREAKPOINTS_RANGE[min || at][0] && dimtoCheck < WINDOW_BREAKPOINTS_RANGE[max || at][1];
      }
    }
  }
  return shouldRender;
};

const MediaQuery = (props) => {
  const { at, min, max, renderNullOnFail, isClient, children } = props;
  const [clientInit, setClientInit] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    // server will not reach this step
    if (isClient) {
      setClientInit(true);
    }
  }, []);

  useEffect(() => {
    if (window && renderNullOnFail) {
      const checkIfShouldRender = () => {
        const shouldRender = checkWindowWidth({ min, max, at });
        setRender(shouldRender);
      };

      checkIfShouldRender();
      window.addEventListener("resize", checkIfShouldRender);
      return () => window.removeEventListener("resize", checkIfShouldRender);
    }
  }, [renderNullOnFail]);

  if (renderNullOnFail && !render) return null;
  if (isClient && !clientInit) return null;

  if (at && (min || max)) {
    throw new Error("Choose a single breakpoint or a range of breakpoints");
  }
  const buildClassName = () => {
    const prefixClassName = "media-query";
    let breakPointClassName = "";
    if (at) {
      breakPointClassName = at;
    } else {
      if (min === max) {
        breakPointClassName = min;
      } else {
        const sortedBreakpoints = [min, max];
        breakPointClassName = `${sortedBreakpoints[0]}__${sortedBreakpoints[1]}`;
      }
    }
    return `${prefixClassName}_${breakPointClassName}`;
  };
  const baseClassNames = classnames("media-query", buildClassName());

  // console.log(children)
  if (typeof children === "function") {
    return children(baseClassNames);
  }
  // React.Children.map(children, child => {
  //   console.log(child);
  // })
  if (!children) return null;

  return React.Children.map(
    children,
    (child) =>
      child &&
      React.cloneElement(child, {
        className: classnames(child.props.className, baseClassNames),
      }),
  );
};

export default MediaQuery;
