import { stdevAndMean } from "@ugg/shared/utils/math";
import { ArenaDuoTierList, ArenaDuo, ArenaDuoRole } from "@ugg/shared/interfaces/tier-lists/arena-duo-tier-list.interface";

export interface ArenaDuoInfo {
  duo1: ArenaDuo[0];
  duo2: ArenaDuo[4];
  duoCombinedMatches: ArenaDuo[9];
  duoTop1Rate: number;
  duoTop2Rate: number;
  duoAvgPlacement: number;
  duoBanRate: number;
  duoPickRate: number;
  effectiveWinrate: number;
  stdevs: number;
}

export function parseArenaDuoTierList(data: ArenaDuoTierList) {
  const [duoData, lastUpdated, totalMatches] = data;

  const duoPairs = Object.keys(duoData) as ArenaDuoRole[];

  let newDuoData: Record<ArenaDuoRole, ArenaDuoInfo[]> = {
    none_none: [],
  };

  duoPairs.forEach((duo) => {
    newDuoData[duo] = duoData[duo].map((championData) => {
      const duo1 = championData[0];
      const duo2 = championData[4];
      const duoCombinedMatches = championData[9];
      const duoBans = championData[10];
      const duoTop1 = championData[11];
      const duoTop2 = championData[8];
      const duo1Top2Matches = championData[2];
      const duo1Matches = championData[3];
      const duo2Top2Matches = championData[6];
      const duo2Matches = championData[7];
      const duoTotalPlacements = championData[12];

      const duo1Top2Rate = (duo1Top2Matches / duo1Matches) * 100;
      const duo2Top2Rate = (duo2Top2Matches / duo2Matches) * 100;

      const duoTop1Rate = (duoTop1 / duoCombinedMatches) * 100;
      const duoTop2Rate = (duoTop2 / duoCombinedMatches) * 100;
      const duoAvgPlacement = duoTotalPlacements / duoCombinedMatches;
      const duoBanRate = (duoBans / totalMatches) * 100;
      const duoPickRate = (duoCombinedMatches / totalMatches) * 100;
      const plusMinusWinRate = duoTop2Rate - Math.sqrt(duo1Top2Rate * duo2Top2Rate);

      const effectiveWinrate =
        ((duoBanRate / 100) * (((duoTop2Rate * (1 + plusMinusWinRate)) / 100 + 0.55) / 2) +
          (duoPickRate / 100) * ((duoTop2Rate * (1 + plusMinusWinRate)) / 100) +
          (1 - duoBanRate / 100 - duoPickRate / 100) * 0.5) *
        100;

      const cleanData: ArenaDuoInfo = {
        duo1,
        duo2,
        duoCombinedMatches,
        duoTop1Rate,
        duoTop2Rate,
        duoAvgPlacement,
        duoBanRate,
        duoPickRate,
        effectiveWinrate,
        stdevs: 0,
      };
      return cleanData;
    });
  });

  duoPairs.forEach((duo) => {
    const effectiveWinrates = newDuoData[duo].map((x) => x.effectiveWinrate) as number[];
    const maths = stdevAndMean(effectiveWinrates);

    newDuoData[duo].forEach((championData) => {
      if (championData.effectiveWinrate) {
        championData["stdevs"] = (championData.effectiveWinrate - maths.mean) / maths.stdev;
      }
    });

    newDuoData[duo].sort((a, b) => {
      return b.duoTop2Rate - a.duoTop2Rate;
    });
  });

  return {
    data: newDuoData,
    lastUpdated,
    totalMatches: totalMatches,
  };
}
