import { Route } from "react-router-dom";

export function RouteStatus(props: { statusCode: number; children: React.ReactNode }) {
  const { statusCode } = props;
  return (
    <Route
      render={({ staticContext }) => {
        // we have to check if staticContext exists
        // because it will be undefined if rendered through a BrowserRouter
        if (staticContext) {
          staticContext.statusCode = statusCode;
        }

        return props.children;
      }}
    />
  );
}
