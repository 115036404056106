import React from "react";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useTranslation } from "react-i18next";

const StatShardTooltip = (props) => {
  const { t } = useTranslation();
  const { useStatShards, getStatShards, getStatShardJSON } = getRiotAssetsContext();
  const { shardRow, shardId, patch } = props;

  const { data, loading, error } = useStatShards({ patch });
  const [statShardPatch, statShardJSON] = data || [];

  const rowData = statShardJSON?.[shardRow];
  const json = getStatShardJSON(statShardJSON, shardRow, shardId);
  if (!json) {
    return null;
  }
  return (
    <div className="tooltip-rune">
      {rowData && <div className="name mb-[12px]">{t ? t(rowData.key) : rowData.key}</div>}
      <div>{t ? t(json.tooltip_desc) : json.tooltip_desc}</div>
    </div>
  );
};

export default StatShardTooltip;
