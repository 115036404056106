import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import ButtonSuccess from "components/Buttons/ButtonSuccess";
import { getUrl } from "@outplayed/riot-assets";
import { directToPage } from "lib/redirect-helpers";
import { parseQueryString } from "lib/general-helper";
import { VERIFY_RIOT_CODE } from "@ugg/shared/api/graphql/queries";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const VerificationIconPage = (props) => {
  const { getProfileIconImg } = getRiotAssetsContext();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [errorOnRiotCode, setErrorOnRiotCode] = useState(false);
  const location = useLocation();
  const searchParams = parseQueryString(location.search.substring(1));
  const continueURL = searchParams.continue;
  const directToHome = directToPage("home");
  const directToYearInReview = directToPage("performanceAnalysis");
  const directToAccount = directToPage("accountSettings");

  const {
    loading,
    error: riotCodeError,
    data: riotCodeData,
  } = useQuery(VERIFY_RIOT_CODE, {
    // ** Refetch does not work becuase this is a query and not mutation
    // Settings page fetch policy is set to "network-only"
    // if "network-only" is changed, might need to refetch userState/userSettings when query completed
    skip: !buttonClicked,
    fetchPolicy: "network-only",
  });

  const { loadingUserState, error, data } = useQuery(GET_USER_STATE, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      if (!loading && riotCodeData) {
        if (!continueURL) {
          directToHome();
        } else {
          if (continueURL === "yir") {
            directToYearInReview();
          } else if (continueURL === "settings") {
            directToAccount();
          }
        }
      }
    },
    skip: !riotCodeData || !riotCodeData.verifyRiotCode.success,
  });

  if (riotCodeData && !riotCodeData.verifyRiotCode.success) {
    setButtonClicked(false);
    setErrorOnRiotCode(true);
  }

  const clickCompleteVerification = () => {
    setButtonClicked(true);
  };

  return (
    <div className="code-copy-page">
      <div className="small-header" style={{ marginBottom: "12px" }}>
        Verify Your Account
      </div>
      <div className="step-text" style={{ marginBottom: "12px" }}>
        Step 2 of 2
      </div>
      <div className="basic-text">
        <span>1.&nbsp;</span> Open your League client and login
      </div>
      <div className="basic-text">
        <span>2.&nbsp;</span> Click on your profile icon in the top right.
      </div>
      <img className="img1" src={getUrl("lol/performance_analysis/images/profile_verification_icon.jpg")} />
      <div className="basic-text">
        <span>3.&nbsp;</span> Save the below icon as your profile icon.
      </div>
      <img className="img2" src={getProfileIconImg(props.iconID)} />
      <div className="basic-text">
        <span>4.&nbsp;</span> Once you’ve saved your profile icon, come back to this page and click the button below.
      </div>
      <ButtonSuccess
        className="login-submit btn-light-blue"
        label="Verify"
        isLoading={buttonClicked}
        onClick={clickCompleteVerification}
        style={{
          height: 42,
          marginTop: 14,
        }}
      />
      {(riotCodeError || errorOnRiotCode) && (
        <div className="riot-code-verification-error">
          There was an error attempting to complete verification. Try restarting your League of Legends client and verify again.
        </div>
      )}
    </div>
  );
};

export default VerificationIconPage;
