import React from "react";

const DisabledFeature = (props) => {
  return (
    <div className="content-section disabled-feature">
      <strong>Sorry!</strong>
      <div className="message">Currently, this feature is only supported on desktop.</div>
      <img src="https://static.bigbrain.gg/assets/lol/art/chibis/LeeSin.png" />
    </div>
  );
};

export default DisabledFeature;
