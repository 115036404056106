import { window } from "global";
import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import classnames from "classnames";
import { TooltipContext } from "./TooltipZoomProvider";
import { CSSTransition } from "react-transition-group";

const SIDE_BUFFER_WIDTH = 10;

const Tooltip = (props) => {
  const zoom = useContext(TooltipContext);
  const scale = 1 / (zoom || 1);

  const tooltipRef = useRef();
  const {
    children,
    element,
    showTooltip,
    setShowTooltip,
    setShowPortal,
    hoverableTimeout,
    tooltipHoverable,
    forceOrientation,
    isFixed,
    customStyle,
    customArrowStyle,
    initialTransitionScale,
    transitionDuration,
  } = props;

  const [tooltipState, setTooltipState] = useState({
    disableLTR: false,
    disableTTB: false,
    disableUpdate: false,
    xOverflowType: undefined,
    // left: 0,
    // right: 0,
    // bottom: 0,
    // arrowLeft: 0,
    // arrowRight: 0,
    // orientation: 'top', // top, bottom, left, right
  });
  const { disableLTR, disableTTB, disableUpdate } = tooltipState;

  useEffect(() => {
    if (window && element && tooltipRef && tooltipRef.current && !disableUpdate) {
      let currDisableLTR = disableLTR; // left/right
      let currDisableTTB = disableTTB; // top/bottom

      const mastheadHeight = 0;
      let { arrowLeft, arrowRight, orientation, xOverflowType, disableUpdate } = tooltipState;
      const { left: tooltipLeft, right: tooltipRight } = tooltipRef.current.getBoundingClientRect();
      const width = tooltipRef.current.offsetWidth;
      const height = tooltipRef.current.offsetHeight;

      const elementDimen = element ? element.getBoundingClientRect() : {};
      // For orientation top/bottom
      let top = `${elementDimen.y + elementDimen.height + window.scrollY * scale + 15 - mastheadHeight}px`;
      let bottom = `${elementDimen.y - height + window.scrollY * scale - mastheadHeight - 15}px`;

      // For orientation left/right
      let left = `${elementDimen.x + elementDimen.width + window.scrollX * scale + 15}px`;
      let right = undefined;
      const overflowTop = height + 20 > elementDimen.top;
      const overflowBottom = elementDimen.bottom + height + 10 > window.innerHeight;
      if (overflowBottom && overflowTop) {
        orientation = "top";
      } else if (overflowTop) {
        orientation = "bottom";
      } else {
        orientation = "top";
      }

      if (forceOrientation === "top") {
        orientation = "top";
      } else if (forceOrientation === "bottom") {
        orientation = "bottom";
      } else if (forceOrientation === "left") {
        orientation = "left";
      } else if (forceOrientation === "right") {
        orientation = "right";
      }

      const overflowLeft = elementDimen.x - width / 2 + elementDimen.width / 2 <= SIDE_BUFFER_WIDTH;
      const overflowRight =
        Math.ceil(elementDimen.x + width / 2 + elementDimen.width / 2 + window.scrollX * scale) >=
        document.documentElement.clientWidth * scale;

      if (orientation === "left") {
        right = "auto";
        bottom = "auto";
        top = `${elementDimen.y + elementDimen.height / 2 - height / 2 + (isFixed ? 0 : window.scrollY)}px`;
      } else if (orientation === "right") {
        right = "auto";
        bottom = "auto";
        top = `${elementDimen.y + elementDimen.height / 2 - height / 2 + (isFixed ? 0 : window.scrollY)}px`;
      } else if (!overflowLeft && !overflowRight && !xOverflowType) {
        left = `${Math.round(elementDimen.x - width / 2 + elementDimen.width / 2 + window.scrollX * scale)}px`;
        right = "auto";
        arrowLeft = "50%";
        arrowRight = "auto";
      } else if ((overflowLeft && overflowRight) || xOverflowType === "both") {
        left = `${SIDE_BUFFER_WIDTH}px`;
        right = `${SIDE_BUFFER_WIDTH}px`;
        arrowLeft = `${elementDimen.x + elementDimen.width / 2 - SIDE_BUFFER_WIDTH}px`;
        arrowRight = "auto";
        xOverflowType = "both";
      } else if ((overflowLeft && !overflowRight) || xOverflowType === "left") {
        left = `${SIDE_BUFFER_WIDTH + window.scrollX * scale}px`;
        arrowLeft = `${elementDimen.x + elementDimen.width / 2 - SIDE_BUFFER_WIDTH}px`;
        arrowRight = "auto";
        xOverflowType = "left";
        const addRightBuffer = Math.ceil(tooltipRight) >= document.documentElement.clientWidth - SIDE_BUFFER_WIDTH;
        if (currDisableLTR || addRightBuffer) {
          right = `${SIDE_BUFFER_WIDTH}px`;
          currDisableLTR = true;
        } else {
          right = "auto";
        }
      } else if ((!overflowLeft && overflowRight) || xOverflowType === "right") {
        right = `${SIDE_BUFFER_WIDTH}px`;
        arrowLeft = `${
          elementDimen.left + elementDimen.width / 2 - (document.documentElement.clientWidth - (width + SIDE_BUFFER_WIDTH))
        }px`;
        arrowRight = "auto";
        xOverflowType = "right";
        const addRightLeftBuffer = Math.ceil(tooltipLeft) <= SIDE_BUFFER_WIDTH;
        if (currDisableLTR || addRightLeftBuffer) {
          left = `${SIDE_BUFFER_WIDTH}px`;
          currDisableLTR = true;
        } else {
          left = "auto";
        }
      }

      if (
        tooltipState.left !== left ||
        tooltipState.right !== right ||
        tooltipState.top !== top ||
        tooltipState.bottom !== bottom ||
        tooltipState.arrowLeft !== arrowLeft ||
        tooltipState.arrowRight !== arrowRight ||
        tooltipState.disableUpdate !== disableUpdate ||
        tooltipState.orientation !== orientation
      ) {
        setTooltipState((state) => ({
          ...tooltipState,
          left,
          right,
          top,
          bottom,
          arrowLeft,
          arrowRight,
          orientation,
          disableLTR: currDisableLTR,
          disableTTB: currDisableTTB,
          disableUpdate,
          xOverflowType,
        }));
      } else {
        setTooltipState({
          ...tooltipState,
          disableUpdate: true,
        });
      }
    }
  }, [props, tooltipState]);

  const onMouseEnterTooltip = useCallback(() => {
    if (tooltipHoverable && hoverableTimeout && hoverableTimeout.current) {
      clearTimeout(hoverableTimeout.current);
    }
  }, [tooltipHoverable, hoverableTimeout]);

  const onMouseLeaveTooltip = useCallback(() => {
    if (tooltipHoverable) {
      setShowTooltip(false);
    }
  }, [tooltipHoverable]);

  const style = {
    ...(customStyle || {}),
    position: isFixed ? "fixed" : undefined,
    display: "block",
    visibility: disableUpdate ? "visible" : "hidden",
    zIndex: disableUpdate ? 9999999 : -9999999,
    left: tooltipState.left || 0,
    right: tooltipState.right,
    top: tooltipState.orientation !== "top" ? tooltipState.top : tooltipState.bottom,
    opacity: 0,
    transform: `rotate(360deg) translateZ(0px) scale(${initialTransitionScale})`,
    transition: `transform ${transitionDuration}ms, opacity ${transitionDuration}ms`,
    backfaceVisibility: "hidden",
    perspective: "1000px",
  };

  const tooltipArrowClassNames = classnames("arrow", {
    "arrow-up": tooltipState.orientation === "bottom",
    "arrow-down": tooltipState.orientation === "top",
    "arrow-left": tooltipState.orientation === "right",
    "arrow-right": tooltipState.orientation === "left",
  });

  const tooltipArrowStyle = {
    ...(customArrowStyle || {}),
    left: tooltipState.arrowLeft,
    right: tooltipState.arrowRight,
  };

  return (
    <CSSTransition
      classNames="tooltip-transition"
      in={showTooltip}
      appear
      timeout={{ exit: transitionDuration }}
      mountOnEnter
      unmountOnExit
      onExited={() => setShowPortal(false)}
    >
      <div
        ref={tooltipRef}
        style={style}
        className="ugg-tooltip"
        onMouseEnter={onMouseEnterTooltip}
        onMouseLeave={onMouseLeaveTooltip}
      >
        <div>
          {children}
          <div style={tooltipArrowStyle} className={tooltipArrowClassNames}></div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Tooltip;
