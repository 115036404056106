import { window } from "global";
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import ReactSVG from "react-svg";

import { useLoginState } from "lib/hooks/userHooks";
import { useLanguage } from "lib/hooks/languageHooks";
import { useLogout } from "@ugg/shared/api/requests/accounts/login";
import { LANGUAGE_OPTIONS } from "lib/locale-helpers";
import { ReactComponent as SettingsIcon } from "images/ugg/icons/settings-icon.svg";
import { ReactComponent as LogoutIcon } from "svg/logout-icon.svg";
import { useHistory } from "react-router-dom";
import { uggSupportedLanguages } from "../../../../lib/locale-helpers";

const UserSettings = ({ toggleDropdown }) => {
  const isLoggedIn = useLoginState();

  if (!isLoggedIn) return null;

  return (
    <div className="menu-item-container">
      <Link className="menu-item" to="/settings" onClick={toggleDropdown.bind(null, false)}>
        <div className="menu-item_label">Settings</div>
        <div className="menu-item_icon">
          <SettingsIcon className="cog-icon" />
        </div>
      </Link>
    </div>
  );
};

const LanguageSelector = ({ toggleDropdown }) => {
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const [language, setLanguage] = useLanguage();
  const location = useLocation();
  const history = useHistory();

  const toggleLanguageDropdown = () => {
    setLanguageDropdown(!languageDropdown);
  };

  //This is a temporary holder for language redirection to do a intial SEO test if the language path is benificial for other languages.
  const onChange = (e) => {
    // Always set the language to support legacy functionality.
    setLanguage(e.target.value);
    // TEMPORARY LANGUAGE REDIRECTION CODE.
    let relitivePathLang = location.pathname;
    // Detects when the Language changes and will split into two operations if the language is moving from english to other or other to other.
    if (!relitivePathLang.includes(e.target.value.toLowerCase()) && e.target.value !== "en_US") {
      // Confirms this is not english as english is the only one that does not have the language specified in the route.
      if (!relitivePathLang.includes("/lol/champions/")) {
        // Other to Other.
        for (var i = 0; i < uggSupportedLanguages.length; i++) {
          if (
            relitivePathLang.includes(uggSupportedLanguages[i].toLowerCase()) &&
            e.target.value !== uggSupportedLanguages[i].toLowerCase()
          ) {
            var cattedPath = relitivePathLang.slice(0, 5) + e.target.value.toLowerCase() + relitivePathLang.slice(10);
            history.push(cattedPath);
            break;
          }
        }
      } else {
        // English to Other
        if (relitivePathLang.includes("/lol/champions/") && relitivePathLang.includes("/build")) {
          var langCattedPath = relitivePathLang.slice(0, 5) + e.target.value.toLowerCase() + relitivePathLang.slice(4);
          history.push(langCattedPath);
        }
      }
    } else {
      //Other to english
      if (!relitivePathLang.includes("/lol/champions/") && relitivePathLang.includes("/build")) {
        console.log("this is the positive case");
        var cattedPath = relitivePathLang.slice(0, 4) + relitivePathLang.slice(10);
        history.push(cattedPath);
      }
    }
    toggleDropdown(false);
  };

  const currentLanguageOption = Object.entries(LANGUAGE_OPTIONS).find(([_key, val]) => language === val["value"]);
  const languageString = currentLanguageOption ? currentLanguageOption[1]["label"] : "English";

  return (
    <div className="menu-item-container">
      <div className="menu-item" onClick={toggleLanguageDropdown}>
        <div className="menu-item_label">Language:</div>
        <div className="menu-item_dropdown">
          {languageString}
          <ReactSVG
            wrapperClassName="react-svg"
            path={
              languageDropdown
                ? "https://static.bigbrain.gg/assets/ugg/icons/arrow-up.svg"
                : "https://static.bigbrain.gg/assets/ugg/icons/arrow-down.svg"
            }
          />
        </div>
      </div>
      {languageDropdown && (
        <div className="language-dropdown">
          {LANGUAGE_OPTIONS.map((option) => {
            return (
              <label key={option.value}>
                <input type="radio" value={option.value} checked={language === option.value} onChange={onChange} />
                <span className="radio-button"></span>
                <span>{option.label}</span>
              </label>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Logout = ({ toggleDropdown }) => {
  const logout = useLogout();
  const isLoggedIn = useLoginState();

  const onLogout = () => {
    toggleDropdown(false);
    logout();
  };

  if (!isLoggedIn) return null;

  return (
    <div className="menu-item-container">
      <div className="menu-item" onClick={onLogout}>
        <div className="menu-item_label">Log Out</div>
        <div className="menu-item_icon">
          <LogoutIcon />
        </div>
      </div>
    </div>
  );
};

const SettingsContainer = (props) => {
  const settingsRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const isLoggedIn = useLoginState();

  useEffect(() => {
    if (window) {
      const handleOutsideClick = (e) => {
        const path = e.path || (e.composedPath && e.composedPath());
        if (
          settingsRef &&
          settingsRef.current &&
          e.target !== null &&
          !settingsRef.current.contains(e.target) &&
          !path.includes(settingsRef.current)
        ) {
          setDropdown(false);
        }
      };
      document.addEventListener("click", handleOutsideClick);
      return () => document.addEventListener("click", handleOutsideClick);
    }
  }, []);

  const toggleDropdown = (bool) => {
    if (bool !== undefined) setDropdown(bool);
    else setDropdown(!dropdown);
  };

  const settingsClassNames = classNames(["masthead-settings-container", dropdown ? "masthead-settings-dropdown_open" : ""]);
  return (
    <div ref={settingsRef} className={settingsClassNames}>
      <div className="masthead-settings-icon" onClick={() => toggleDropdown()}>
        <SettingsIcon className="cog-icon" />
      </div>
      {dropdown && (
        <div className="settings-menu">
          <LanguageSelector toggleDropdown={toggleDropdown} />
          <UserSettings toggleDropdown={toggleDropdown} />
          <Logout toggleDropdown={toggleDropdown} />
        </div>
      )}
    </div>
  );
};

export default SettingsContainer;
