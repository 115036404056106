import { RoleS, getRoleSVG } from "@ugg/shared/utils/role-helpers";
import { RankS } from "@ugg/shared/utils/rank-helpers";
import { ROLE_OPTIONS, QUEUE_TYPE_OPTIONS, REGION_OPTIONS, RANK_OPTIONS_WITH_D2 } from "../common";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { AppRoutes } from "@ugg/shared/routes/app-routes";

// ------------------------------------
// OVERVIEW (BUILD)
// ------------------------------------

export const OVERVIEW_QUEUE_TYPE_OPTIONS = [
  { value: QueueTypeS.RANKED_SOLO, label: "Ranked Solo", path: AppRoutes.CHAMPION_PROFILE_BUILD, pathParameters: true },
  { value: QueueTypeS.ARAM, label: "ARAM", path: AppRoutes.CHAMPION_PROFILE_BUILD_ARAM, pathParameters: true, removeParam: true },
  { value: QueueTypeS.RANKED_FLEX, label: "Ranked Flex", path: AppRoutes.CHAMPION_PROFILE_BUILD, pathParameters: true },
  { value: QueueTypeS.QUICKPLAY, label: "Quickplay", path: AppRoutes.CHAMPION_PROFILE_BUILD, pathParameters: true },
  { value: QueueTypeS.NORMAL_DRAFT, label: "Normal Draft", path: AppRoutes.CHAMPION_PROFILE_BUILD, pathParameters: true },
  { value: QueueTypeS.ARENA, label: "Arena", path: AppRoutes.CHAMPION_PROFILE_BUILD_ARENA, pathParameters: true },
  {
    value: QueueTypeS.NEXUS_BLITZ,
    label: "Nexus Blitz",
    path: AppRoutes.CHAMPION_PROFILE_BUILD_NEXUS_BLITZ,
    pathParameters: true,
  },
  { value: QueueTypeS.PICK_URF, label: "URF", path: AppRoutes.CHAMPION_PROFILE_BUILD_PICK_URF, pathParameters: true },
  { value: QueueTypeS.ARURF, label: "ARURF", path: AppRoutes.CHAMPION_PROFILE_BUILD_ARURF, pathParameters: true },
  {
    value: QueueTypeS.ONE_FOR_ALL,
    label: "One for All",
    path: AppRoutes.CHAMPION_PROFILE_BUILD_ONE_FOR_ALL,
    pathParameters: true,
  },
];

export const OVERVIEW_OPTIONS = {
  queueType: OVERVIEW_QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  patch: { options: "latest", max: 5 },
};

export const OVERVIEW_DEFAULT = {
  queueType: OVERVIEW_QUEUE_TYPE_OPTIONS[0].value,
  region: REGION_OPTIONS[0].value,
  rank: RANK_OPTIONS_WITH_D2[1].value,
  patch: OVERVIEW_OPTIONS.patch.options,
  opp: "allChampions",
};

export const SPECIAL_OVERVIEW_DEFAULT = {
  region: REGION_OPTIONS[0].value,
  patch: OVERVIEW_OPTIONS.patch.options,
  rank: RankS.OVERALL,
  role: RoleS.NONE,
};
