import { useMemo } from "react";
import {
  availableTiers,
  convertTierToValue,
  convertRomanNumeral,
  letterToTier,
  getNextTierLetter,
  RankS,
} from "@ugg/shared/utils/rank-helpers";
import { MatchSummary } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";
import { RankSnapshot } from "@ugg/shared/api/requests/summoner-profiles/rank-snapshots";

export function getMaxSeasonPlacements(version: string) {
  return Number(version.replace("_", ".")) > 13.13 ? 5 : 10;
}

function getSnapshotTotalGameDiff(snapshot1: RankSnapshot, snapshot2: RankSnapshot) {
  if (!snapshot1 || !snapshot2) return -1;

  return Math.abs((snapshot1.wins || 0) + (snapshot1.losses || 0) - ((snapshot2.wins || 0) + (snapshot2.losses || 0)));
}

export type MatchLP = {
  lp: number | undefined | null;
  promoTarget: string | undefined | null;
  promoProgress: string | undefined | null;
  promotedTo: Pick<RankSnapshot, "tier" | "rank"> | undefined | null;
  placement: number | undefined | null;
};
function calculateLPDiff(match: MatchSummary, curSnapshot: RankSnapshot, prevSnapshot: RankSnapshot) {
  //Test snapshots
  // const prevSnapshot = {
  //   insertedAt: 1605562238,
  //   losses: 4300,
  //   lp: 80,
  //   promoProgress: null,
  //   queueId: 420,
  //   rank: "iv",
  //   tier: "gold",
  //   wins: 99,
  // };
  // const curSnapshot = {
  //   insertedAt: 1605562238,
  //   losses: 4260,
  //   lp: 15,
  //   promoProgress: null,
  //   queueId: 420,
  //   rank: "i",
  //   tier: "gold",
  //   wins: 99,
  // };

  // Elo decay from inactivity
  const apexTiers = ["master", "grandmaster", "challenger"];
  const decayableTiers = ["diamond"];
  const apexTierDecayRate = 75;

  const calculatedMatchLP: MatchLP = {
    lp: null,
    promoTarget: null,
    promoProgress: null,
    promotedTo: null,
    placement: null,
  };
  // Placement matches (CURRENTLY DO NOT HAVE DATA)
  const maxPlacementGames = getMaxSeasonPlacements(match.version);
  if ((curSnapshot.wins || 0) + (curSnapshot.losses || 0) <= maxPlacementGames) {
    calculatedMatchLP.placement = (curSnapshot.wins || 0) + (curSnapshot.losses || 0);
  }
  // Promo Win/Loss
  else if (prevSnapshot.promoProgress) {
    let targetTier = null;
    for (let tier of availableTiers) {
      const targetTierValue = convertTierToValue(tier);
      const curTierValue = convertTierToValue(prevSnapshot.tier);
      if (targetTierValue - curTierValue === 1) {
        targetTier = tier;
        break;
      }
    }
    calculatedMatchLP.promoTarget = targetTier;

    const promoProgressActive = curSnapshot.promoProgress.match(/N/g);
    if (promoProgressActive && promoProgressActive.length < 5) {
      calculatedMatchLP.promoProgress = curSnapshot.promoProgress;
    } else if (!curSnapshot.promoProgress) {
      if (match.win) {
        calculatedMatchLP.promotedTo = curSnapshot;
      }
      calculatedMatchLP.promoProgress = prevSnapshot.promoProgress.replace("N", match.win ? "W" : "L");
    }
  }
  // LP Gain/Loss (includes interdivision promotion)
  else if (apexTiers.includes(prevSnapshot.tier.toLowerCase())) {
    if (apexTiers.includes(curSnapshot.tier.toLowerCase())) {
      if (match.win && curSnapshot.lp <= prevSnapshot.lp) {
        let baseLP = prevSnapshot.lp;
        while (baseLP > curSnapshot.lp) {
          baseLP -= apexTierDecayRate;
          baseLP = baseLP < 0 ? 0 : baseLP;
        }
        calculatedMatchLP.lp = curSnapshot.lp - baseLP;
      } else {
        calculatedMatchLP.lp = (curSnapshot.lp - prevSnapshot.lp) % apexTierDecayRate;
      }
    }
  } else {
    calculatedMatchLP.lp = curSnapshot.lp - prevSnapshot.lp;
    if (curSnapshot.tier !== prevSnapshot.tier || curSnapshot.rank !== prevSnapshot.rank) {
      calculatedMatchLP.lp += curSnapshot.lp < prevSnapshot.lp ? 100 : -100;
      if (match.win) {
        if (decayableTiers.includes(prevSnapshot.tier.toLowerCase()) && curSnapshot.rank === "I" && prevSnapshot.rank === "IV") {
          calculatedMatchLP.lp = curSnapshot.lp - 75;
        } else if (
          curSnapshot.tier === prevSnapshot.tier &&
          convertRomanNumeral(curSnapshot.rank) < convertRomanNumeral(prevSnapshot.rank)
        ) {
          // Interdivision promotion
          calculatedMatchLP.promotedTo = curSnapshot;
        }
      }
    }
  }

  return calculatedMatchLP;
}

// Pass filtered matches and rank snapshots by queue type
const calculatedMatchLPs = (matches: MatchSummary[], rankSnapshots: RankSnapshot[] = []) => {
  const filteredSnapshots = rankSnapshots
    .filter((curSnapshot, index) => {
      const prevSnapshot = rankSnapshots[index - 1]; // in this case, its the later snapshot
      if (index === 0) return true;

      // Remove dupe snapshots (snapshots with equal total games)
      const totalGameDiff = getSnapshotTotalGameDiff(curSnapshot, prevSnapshot);
      return totalGameDiff <= 0;
    })
    .sort((a, b) => b.insertedAt - a.insertedAt);

  const lpMatches: Record<number, MatchLP> = {};
  // Keep track of already mapped snapshots
  const usedSnapshots = new Set();
  // Sort by ascending order
  const sortedMatches = [...matches].sort((a, b) => a.matchCreationTime - b.matchCreationTime);

  sortedMatches.forEach((match, matchIndex) => {
    const matchEndTimestamp = match.matchCreationTime / 1000 + match.matchDuration;
    const isRemake = match.matchDuration < 300;

    for (let index = 0; index < filteredSnapshots.length - 1; index++) {
      const curSnapshot = filteredSnapshots[index];
      const prevSnapshot = filteredSnapshots[index + 1];

      const totalGameDiff = getSnapshotTotalGameDiff(curSnapshot, prevSnapshot);
      if (totalGameDiff > 1 || isRemake) {
        continue;
      }

      // Get match outcome based on snapshot wins/losses
      const win = (curSnapshot.wins || 0) - (prevSnapshot.wins || 0) === 1;
      const loss = (curSnapshot.losses || 0) - (prevSnapshot.losses || 0) === 1;

      if (
        !usedSnapshots.has(index) &&
        match.win === win &&
        !match.win == loss &&
        curSnapshot.insertedAt > matchEndTimestamp &&
        prevSnapshot.insertedAt < matchEndTimestamp &&
        prevSnapshot.tier &&
        prevSnapshot.rank
      ) {
        // DO NOT map snapshots to multiple matches
        // If more than one match falls between the same snapshots, map the earliest match
        usedSnapshots.add(index);
        lpMatches[match.matchId] = calculateLPDiff(match, curSnapshot, prevSnapshot);
      }
    }
  });

  return lpMatches;
};

// all match, all rank snapshots
export const useMatchLPs = (matches: MatchSummary[], rankSnapshots: RankSnapshot[] = []) => {
  return useMemo(() => {
    let lpMatches: Record<number, MatchLP> = {};
    if (rankSnapshots && rankSnapshots.length > 0) {
      // console.log(rankSnapshots)
      const soloMatches = matches.filter((match) => match.queueType === "ranked_solo_5x5");
      const flexMatches = matches.filter((match) => match.queueType === "ranked_flex_sr");
      const soloSnapshots = rankSnapshots.filter((snapshot) => snapshot.queueId === 420);
      const flexSnapshots = rankSnapshots.filter((snapshot) => snapshot.queueId === 440);

      lpMatches = {
        ...lpMatches,
        ...calculatedMatchLPs(soloMatches, soloSnapshots),
        ...calculatedMatchLPs(flexMatches, flexSnapshots),
      };
    }

    return lpMatches;
  }, [matches, rankSnapshots]);
};

export const mergeLPs = (matchLP: MatchLP, serverLP: MatchLP) => {
  serverLP = {
    ...serverLP,
    placement: serverLP && serverLP.placement != null && serverLP.placement <= 10 ? serverLP.placement : null,
  };

  const mergedLP = {
    ...(matchLP || {}),
    placement: serverLP?.placement,
    promotedTo: serverLP?.promotedTo?.rank
      ? { ...serverLP.promotedTo, tier: letterToTier(serverLP.promotedTo.tier) }
      : matchLP?.promotedTo?.rank
      ? matchLP.promotedTo
      : null,
    promoTarget: serverLP?.promoTarget ? letterToTier(getNextTierLetter(serverLP.promoTarget)) : matchLP?.promoTarget || null,
    promoProgress: serverLP?.promoProgress ? serverLP.promoProgress : matchLP?.promoProgress || null,
    lp: serverLP?.lp && serverLP?.lp > -1000 && serverLP?.lp !== 0 ? serverLP.lp : matchLP?.lp || NaN, // -1000 and lower are error codes
  };
  return mergedLP;
};
