import { window } from "global";
import React, { useState, useEffect, useRef, useCallback, useImperativeHandle } from "react";
import Portal from "./Portal";
import Tooltip from "./Tooltip";
import mobile from "is-mobile";
import classnames from "classnames";

const TooltipContainer = (props, ref) => {
  const elementRef = useRef();
  const hoverableTimeout = useRef();
  const delayTimeout = useRef();
  const [showPortal, setShowPortal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const {
    debug,
    tooltipInfo,
    children,
    forceOrientation,
    isFixed,
    tooltipHoverable,
    customStyle,
    customArrowStyle,
    customClassName,
    disableTooltip,
    forceShow,
  } = props;

  const { initialTransitionScale = 0.95, transitionDuration = 100, delay = 0 } = props;

  const isMobile = mobile();

  const handleOutsideClick = useCallback(
    (e) => {
      if (elementRef && elementRef.current && !elementRef.current.contains(e.target)) {
        setShowTooltip(false);
        e.outplayed_tooltip_clicked = false;
      }
    },
    [elementRef],
  );

  useEffect(() => {
    if (window) {
      window.addEventListener("click", handleOutsideClick);

      return () => {
        window.removeEventListener("click", handleOutsideClick);
        if (tooltipHoverable && hoverableTimeout.current) {
          clearTimeout(hoverableTimeout.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (forceShow) {
      if (tooltipHoverable && hoverableTimeout.current) {
        clearTimeout(hoverableTimeout.current);
      }
      setShowPortal(true);
      setShowTooltip(true);
    }
  }, [forceShow]);

  useEffect(() => {
    if (!showTooltip) {
      if (tooltipHoverable && hoverableTimeout.current) {
        clearTimeout(hoverableTimeout.current);
      }
    }
  }, [showTooltip]);

  const onMouseEnter = useCallback(
    (e) => {
      if (forceShow || disableTooltip || isMobile) return;

      if (tooltipHoverable && hoverableTimeout.current) {
        clearTimeout(hoverableTimeout.current);
      }
      if (delay > 0) {
        delayTimeout.current && clearTimeout(delayTimeout.current);
        delayTimeout.current = setTimeout(() => {
          setShowPortal(true);
          setShowTooltip(true);
        }, delay);
      } else {
        setShowPortal(true);
        setShowTooltip(true);
      }
    },
    [disableTooltip],
  );

  const onMouseLeave = useCallback(
    (e) => {
      if (forceShow || disableTooltip || isMobile) return;

      if (tooltipHoverable) {
        hoverableTimeout.current = setTimeout(() => {
          setShowTooltip(false);
        }, 100);
      } else {
        delayTimeout.current && clearTimeout(delayTimeout.current);
        setShowTooltip(false);
      }
    },
    [disableTooltip],
  );

  // Used for mobile
  const onClick = useCallback(
    (e) => {
      if (disableTooltip || !isMobile) return;
      // Use with caution
      e.outplayed_tooltip_clicked = true;
      if (!showTooltip) {
        setShowTooltip(true);
        setShowPortal(true);
      } else {
        setShowTooltip(false);
      }
    },
    [disableTooltip, showTooltip],
  );

  // Enforce single child rule to ensure mouse/touch events are applied to one element
  const child = React.Children.only(children);
  const commonProps = {
    onMouseEnter,
    onMouseLeave,
    onClick: (e) => {
      child.props.onClick && child.props.onClick(e);
      onClick(e);
    },
    className: classnames(child.props.className, customClassName),
  };

  useImperativeHandle(ref, () => ({
    forceClose: () => setShowTooltip(false),
  }));

  return (
    <React.Fragment>
      {!disableTooltip && showPortal && (
        <Portal dest="tooltip-portal">
          <Tooltip
            element={elementRef.current}
            showTooltip={showTooltip}
            setShowTooltip={setShowTooltip}
            setShowPortal={setShowPortal}
            hoverableTimeout={hoverableTimeout}
            tooltipHoverable={tooltipHoverable}
            forceOrientation={forceOrientation}
            isFixed={isFixed}
            customStyle={customStyle}
            customArrowStyle={customArrowStyle}
            initialTransitionScale={initialTransitionScale}
            transitionDuration={transitionDuration}
            delay={delay}
          >
            {tooltipInfo}
          </Tooltip>
        </Portal>
      )}
      {React.cloneElement(child, {
        ...commonProps,
        ref: (node) => {
          elementRef.current = node;
          if (child.ref) child.ref(node);
        },
      })}
    </React.Fragment>
  );
};

export default React.forwardRef(TooltipContainer);
