import { useColorScheme } from "@ugitgud/legos/hooks/useColorScheme";
import { Icon, IconProps } from "@ugitgud/legos/ui/icon/Icon";
import { WithClassName, WithColorScheme, WithSizes } from "@ugitgud/legos/ui/shared";
import classNames from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

export const tagSizes = ["xs", "sm", "md", "lg", "xl"];

export type TagSizes = "xs" | "sm" | "md" | "lg" | "xl";

export type TagColorSchemes = "primary" | "secondary" | "tertiary" | "accent-green";

export type BaseTagProps = WithClassName<React.PropsWithChildren<{}>> & React.ComponentPropsWithoutRef<"span">;

export type TagLabelProps = WithSizes<BaseTagProps, TagSizes> & { orientation?: "left" | "right" | "center" };

export type TagProps = WithColorScheme<WithSizes<BaseTagProps, TagSizes>, TagColorSchemes>;

const labelSizeClasses = {
  xs: "py-[1px] px-0.5",
  sm: "py-0.5 px-0.5",
  md: "py-0.5 px-1",
  lg: "py-1 px-1.5",
  xl: "py-2 px-3",
};

export const TagLabel = React.forwardRef<HTMLSpanElement, TagLabelProps>(
  ({ className, children, orientation = "center", size = "md", ...props }, ref) => {
    return (
      <span
        ref={ref}
        {...props}
        className={twMerge(
          classNames(
            labelSizeClasses[size],
            {
              "pr-0": orientation === "left",
              "pl-0": orientation === "right",
            },
            "overflow-hidden leading-none",
            className,
          ),
        )}
      >
        {children}
      </span>
    );
  },
);

const textClasses = {
  xs: "text-[8px]",
  sm: "text-xxxs",
  md: "text-xs",
  lg: "text-xs",
  xl: "text-md",
};

export const Tag = React.forwardRef<HTMLSpanElement, TagProps>(
  ({ className, colorScheme, children, size = "md", ...props }, ref) => {
    const classes = useColorScheme({
      classes: {
        secondary: "dark:text-lavender-50 dark:bg-purple-400",
        primary: "dark:text-white dark:bg-accent-blue-400",
        tertiary: "dark:text-lavender-50 dark:bg-purple-100",
        "accent-green": "dark:text-white dark:bg-accent-forest-green-500",
      },
      common: ["inline-flex items-center leading-none whitespace-nowrap gap-1", textClasses[size], tagBorderRadiusClasses[size]],
      default: "secondary",
      className: className,
      scheme: colorScheme,
    });
    return (
      <span ref={ref} {...props} className={classes}>
        {children}
      </span>
    );
  },
);

export type TagIconProps = WithSizes<
  WithColorScheme<WithClassName<BaseTagProps & { icon: IconProps["icon"]; orientation?: "left" | "right" | "center" }>>,
  TagSizes
>;

const tagBorderRadiusClasses = {
  xs: "rounded-xxs",
  sm: "rounded-xxs",
  md: "rounded-xs",
  lg: "rounded-xs",
  xl: "rounded-xs",
};

export const TagIcon = React.forwardRef<HTMLSpanElement, TagIconProps>(
  ({ className, icon, orientation = "center", size = "md", ...props }, ref) => {
    return (
      <span
        ref={ref}
        {...props}
        className={twMerge(
          classNames(
            "inline-flex items-center h-full",
            tagBorderRadiusClasses[size],
            {
              "rounded-l-none pr-0": orientation === "right",
              "rounded-r-none pl-0": orientation === "left",
            },
            className,
          ),
        )}
      >
        <Icon icon={icon} size={size} />
      </span>
    );
  },
);
