import { useJSONFetcher } from "@outplayed/json-fetcher";
import { RAC_getWorkingPatch } from "./patches";
import { getJSON } from "../common";
import { getSummonerData, uggGetSpellImg } from "../static-url-helpers";

export function RAC_useRiotSummoners(versions) {
  return ({ patch, language, skip, ssr } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const spellsURL = getSummonerData(riotPatch, language);
    const onCompleted = (url, json) => {
      const summoners = {};
      for (const spell of Object.values(json.data)) {
        summoners[spell.key] = spell;
      }
      return summoners;
    };

    return useJSONFetcher(spellsURL, { skip, ssr, onCompleted });
  };
}

const summonerCache = {};
export function RAC_getSummonerSpells(versions) {
  return ({ patch, language } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const cacheKey = `${riotPatch}_${language}`;
    if (!(cacheKey in summonerCache)) {
      let url = getSummonerData(riotPatch, language);
      const summs = getJSON(url);
      if (!summs) return null;

      let idToSummoner = {};
      for (const [name, value] of Object.entries(summs.data)) {
        idToSummoner[value.key] = value;
      }
      summonerCache[cacheKey] = idToSummoner;
    }
    return summonerCache[cacheKey];
  };
}

export function RAC_getSummonerSpellImg(versions) {
  return (id, { patch, optionalData, webp } = {}) => {
    let spells = optionalData || RAC_getSummonerSpells(versions)(patch);

    if (spells && spells[id]) {
      const image = spells[id].image.full;
      const riotPatch = RAC_getWorkingPatch(versions)(patch);
      return uggGetSpellImg(riotPatch, image, { webp });
    } else {
      return "";
    }
  };
}
