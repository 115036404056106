import { useApolloClient, useQuery, QueryHookOptions, QueryOptions } from "@apollo/client";
import gql from "graphql-tag";
import { replacePlusFromURLPath } from "@ugg/shared/utils/url-query-params";

const RIOT_ID_BY_SUMMONER_NAME = gql`
  query riotIdBySummonerName($regionId: String!, $summonerName: String!) {
    riotIdBySummonerName(regionId: $regionId, summonerName: $summonerName) {
      riotTagLine
      riotUserName
    }
  }
`;

export interface RiotIdBySummonerName {
  riotTagLine: string;
  riotUserName: string;
}
export interface RiotIdBySummonerNameData {
  riotIdBySummonerName: RiotIdBySummonerName | null;
}

export function useRiotIdBySummonerName(regionId: string, summonerName: string, queryOptions?: QueryHookOptions) {
  return useQuery<RiotIdBySummonerNameData>(RIOT_ID_BY_SUMMONER_NAME, {
    ...(queryOptions || {}),
    variables: {
      regionId: regionId.toLowerCase(),
      summonerName: summonerName ? decodeURIComponent(replacePlusFromURLPath(summonerName.toLowerCase())) : "",
    },
  });
}

export function useClientRiotIdBySummonerName() {
  const client = useApolloClient();

  return (regionId: string, summonerName: string, queryParams?: QueryOptions) => {
    return client.query<RiotIdBySummonerNameData>({
      query: RIOT_ID_BY_SUMMONER_NAME,
      variables: {
        regionId: regionId.toLowerCase(),
        summonerName: summonerName ? decodeURIComponent(replacePlusFromURLPath(summonerName.toLowerCase())) : "",
      },
      ...(queryParams || {}),
    });
  };
}
