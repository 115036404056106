import { window } from "global";
import { useEffect } from "react";
import { TagBasedAdUnits, RampWindow } from "./ramp.interface";

export const globalTagsList = new Set<string>();

interface RampUnitProps {
  type: TagBasedAdUnits;
  id: string;
  pathname?: string;
  search?: string;
  className?: string;
  styles?: React.CSSProperties;
  onAdUnit?: () => void;
}

const RampUnit = ({ type, id, pathname, search, className, styles, onAdUnit }: RampUnitProps) => {
  const rampWindow = window as RampWindow;
  // Function to add ad units
  const addUnits = () => {
    rampWindow.ramp?.que?.push(() => {
      rampWindow.ramp
        ?.addUnits([{ type, selectorId: id }])
        .catch((e: any) => console.warn("Error adding units:", e))
        .finally(() => {
          onAdUnit?.();
          rampWindow.ramp.displayUnits();
        });
    });
  };

  // Cleanup function to remove ad units
  const cleanUp = () => {
    const slots = rampWindow.ramp?.settings?.slots;
    if (!slots) return;

    const slotToRemove = Object.entries(slots).find(([_, slot]) => slot.element?.parentElement?.id === id)?.[0];

    if (slotToRemove) {
      rampWindow?.ramp?.destroyUnits?.([slotToRemove]);
    }
  };

  useEffect(() => {
    globalTagsList.add(id);
    return () => {
      globalTagsList.delete(id);
    };
  }, [id]);

  useEffect(() => {
    addUnits();
    return () => cleanUp();
  }, [type, id, pathname, search]); // Re-run effect on route change

  return <div id={id} className={className} style={styles}></div>;
};

export default RampUnit;
