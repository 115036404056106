import { ROLE_OPTIONS, REGION_OPTIONS, RANK_OPTIONS_WITH_D2, ALL_CHAMPIONS_OPTIONS } from "../common";

// ------------------------------------
//  CHAMPION DUOS
// ------------------------------------

export const DUOS_QUEUE_TYPE_OPTIONS = [
  { value: "ranked_solo_5x5", label: "Ranked Solo" },
  { value: "ranked_flex_sr", label: "Ranked Flex" },
];

export const DUOS_OPTIONS = {
  queueType: DUOS_QUEUE_TYPE_OPTIONS,
  role: ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  allChampions: ALL_CHAMPIONS_OPTIONS,
  patch: { options: "latest", max: 5 },
};

export const DUOS_DEFAULT = {
  queueType: DUOS_OPTIONS.queueType[0].value,
  region: DUOS_OPTIONS.region[0].value,
  rank: DUOS_OPTIONS.rank[1].value,
  patch: DUOS_OPTIONS.patch.options,
  allChampions: "false",
};
