import fetch from "cross-fetch";
import { setContext } from "@apollo/link-context";
import { ApolloClient, InMemoryCache, createHttpLink, NormalizedCacheObject, ApolloLink, from } from "@apollo/client";
import { getGraphQlUrl, getAuthGraphQlUrl } from "./apollo-helpers";

export interface GQLCustomHeaders {
  "x-app-type"?: "web" | "app_win" | "app_mac" | "ow";
  "x-app-version"?: string;
}

export interface ApolloClientOtions {
  ssrMode?: boolean;
  customHeaders?: GQLCustomHeaders;
}

export function initApolloClient(
  authToken: string,
  apolloState: NormalizedCacheObject | undefined | null = undefined,
  options?: ApolloClientOtions,
) {
  const httpLink = createHttpLink({ uri: getGraphQlUrl(), fetch });
  const authApiLink = createHttpLink({ uri: getAuthGraphQlUrl(), fetch });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : "",
      },
    };
  });
  const appTrackingMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...options?.customHeaders,
      },
    }));

    return forward(operation);
  });

  let cache = new InMemoryCache();
  if (apolloState && Object.keys(apolloState).length > 0) {
    cache = new InMemoryCache().restore(apolloState);
  }

  const apolloClient = new ApolloClient({
    cache,
    ssrMode: !!options?.ssrMode,
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === "auth-api",
      from([appTrackingMiddleware, authLink, authApiLink]),
      from([appTrackingMiddleware, authLink, httpLink]),
    ),
  });

  return apolloClient;
}
