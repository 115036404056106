import { useQuery, QueryHookOptions } from "@apollo/client";
import gql from "graphql-tag";

export const GET_HISTORIC_RANKS = gql`
  query historicRanks($queueType: Int!, $riotUserName: String!, $riotTagLine: String!, $regionId: String!) {
    getHistoricRanks(queueType: $queueType, riotUserName: $riotUserName, riotTagLine: $riotTagLine, regionId: $regionId) {
      lp
      queueId
      rank
      regionId
      season
      tier
    }
  }
`;

interface HistoricRank {
  lp: number;
  queueId: number;
  rank: string;
  regionId: string;
  season: number;
  tier: string;
}

export interface HistoricRanksData {
  getHistoricRanks: HistoricRank[];
}

export function useProfileHistoricRanks(
  riotUserName: string,
  riotTagLine: string,
  regionId: string,
  queueType: number,
  queryOptions?: QueryHookOptions,
) {
  return useQuery<HistoricRanksData>(GET_HISTORIC_RANKS, {
    ...(queryOptions || {}),
    variables: {
      riotUserName,
      riotTagLine,
      regionId,
      queueType,
    },
  });
}
