import React from "react";
import TooltipContainer from "../TooltipContainer";
import ArenaAugmentTooltip from "../tooltipInfoTypes/ArenaAugmentTooltip";
import { getRiotAssetsContext, getArenaAugmentImg } from "@outplayed/riot-assets";

const ArenaAugment = (props) => {
  const { getWorkingPatch, useRiotArenaAugments, getArenaAugments, normalizeToBasicPatch } = getRiotAssetsContext();
  const { augmentId, patch, language, className, style, disableTooltip, ssr, skip } = props;
  const riotPatch = getWorkingPatch(patch);

  const {
    data: augment,
    loading: fetchingAugment,
    error: errorAugment,
  } = useRiotArenaAugments({ augmentId, patch, language, ssr, skip });

  const augmentsJSON = augment || getArenaAugments({ patch, language });
  const data = augmentsJSON && augmentsJSON[augmentId];

  if (!data) {
    return null;
  }

  const { iconLarge, name } = data || {};
  const tooltipInfo = <ArenaAugmentTooltip augmentId={augmentId} patch={patch} language={language} />;

  return (
    <TooltipContainer tooltipInfo={tooltipInfo} disableTooltip={disableTooltip}>
      <img className={className} style={style} src={getArenaAugmentImg(iconLarge, normalizeToBasicPatch(riotPatch))} alt={name} />
    </TooltipContainer>
  );
};

export default ArenaAugment;
