import { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useGlobal } from "reactn";
import MobileDetect from "mobile-detect";
import {
  SideNav,
  SideNavHeader,
  Divider,
  GameNavigator,
  Footer,
  Guest,
  Member,
  FooterMenu,
  SideNavLinksContainer,
  SideNavLink,
  SideNavDropdownLink,
  GameNavButton,
} from "@shared/side-nav";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useLoginState } from "lib/hooks/userHooks";
// import SearchBar from "@ugg/shared/components/common/SearchBar/SearchBar";
import SearchBar from "components/SearchBar";
import { useLightMode } from "hooks/general-hooks";
import { useUserState } from "@ugg/shared/api/requests/accounts/user-state";
import { parseQueryString } from "@ugg/shared/utils/url-query-params";
import { generateTierList, convertToRoleTierList } from "lib/FilterPreferences/QueryParams";
import { useActiveSpecialModes } from "@ugg/shared/utils/special-game-modes";
import {
  AppRoutes,
  getLeaderboardsUrl,
  getProfileOverviewUrl,
  getProfileChampionStatsUrl,
  getProfileLiveGameUrl,
} from "@ugg/shared/routes/app-routes";

import { ReactComponent as PentagonHouse } from "svg/pentagon-house.svg";
import { ReactComponent as PaperWriting } from "svg/paper-writing.svg";
import { ReactComponent as Skullhead } from "svg/skull-head.svg";
import { ReactComponent as Trophy } from "svg/trophy.svg";
import { ReactComponent as PaperSearch } from "svg/paper-search.svg";
import { ReactComponent as Camera } from "svg/camera.svg";
import { ReactComponent as Sword } from "svg/sword.svg";
import { ReactComponent as ProBuildStatsLogo } from "svg/probuildstats-logo.svg";
import { SvgMiscDownloadFill } from "@ugitgud/legos/ui/icon/SVG/MiscDownloadFill";

import type { SideNavLink as ISideNavLink } from "@shared/side-nav";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";

const GlobalWrapperLink = (props: ISideNavLink) => {
  const { path, children } = props;
  return <Link to={path}>{children}</Link>;
};

const AnchorWrapperLink = (props: ISideNavLink) => {
  const { path, children } = props;
  return (
    <a href={path} target="_blank">
      {children}
    </a>
  );
};

interface AppSideNavProps {
  open: boolean;
  setOpen: (bool: boolean) => void;
}

export default function AppSideNav(props: AppSideNavProps) {
  const { open, setOpen } = props;
  const location = useLocation();
  const { pathname, search } = location;
  const { getProfileIconImg } = getRiotAssetsContext();
  const { lightModeOn } = useLightMode();
  const [clientRegion] = useGlobal("clientRegion");
  const isLoggedIn = useLoginState();
  const { data: userState, loading: loadingUserState } = useUserState();
  const { lolSettings, filterDefaults } = (userState && userState.getState) || {};
  const { summoners } = lolSettings || {};
  const { riotUserName, riotTagLine, regionId, iconId } = (summoners && summoners[0]) || {};

  const filterParams = generateTierList(filterDefaults);
  const tierListLocation = convertToRoleTierList(parseQueryString(filterParams.substring(1)));

  const liveSpecialModes = useActiveSpecialModes({ ssr: true });

  const md = typeof window !== "undefined" && new MobileDetect(window.navigator.userAgent);
  const isMobile = md && md.mobile();

  const onMobileClick = () => {
    if (isMobile && open) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const sideNav = document.getElementById("side-nav-container");
      const sideNavCssObj = sideNav && window.getComputedStyle(sideNav, null);
      if (sideNav && !sideNav.contains(e.target as HTMLElement)) {
        if (sideNavCssObj && sideNavCssObj["content"] !== '"mobile"') {
          isMobile && setOpen(false);
        }
      }
    };
    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [open]);

  useEffect(() => {
    const sideNav = document.getElementById("side-nav-container");
    const sideNavCssObj = sideNav && window.getComputedStyle(sideNav, null);
    if (sideNavCssObj && sideNavCssObj["content"] === '"mobile"') {
      if (open) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    } else {
      document.body.style.overflow = "";
    }
  }, [open]);

  return (
    <SideNav
      className="sm:side-nav_mobile"
      theme={lightModeOn ? "light" : "dark"}
      isOpen={open}
      onMouseEnter={() => {
        !isMobile && setOpen(true);
      }}
      onMouseLeave={() => {
        !isMobile && setOpen(false);
      }}
    >
      <SideNavHeader
        LogoWrapper={({ children }) => {
          if (open) {
            return <Link to={AppRoutes.HOME}>{children}</Link>;
          }
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
              }}
            >
              {children}
            </div>
          );
        }}
      />
      <Divider marginBottom />
      <SearchBar
        theme={lightModeOn ? "light" : "dark"}
        placeholder={"Search Summoner or Champion"}
        isMasthead
        onSearchComplete={() => setOpen(false)}
      />
      <SideNavLinksContainer>
        <SideNavLink
          className="download-button"
          label="Download Now"
          path={"/app?utm_source=uggsidenav"}
          iconSvg={<SvgMiscDownloadFill />}
          WrapperLink={GlobalWrapperLink}
        />
        {isLoggedIn && riotUserName && riotTagLine && regionId && (
          <SideNavLink
            label="Home"
            path={getProfileOverviewUrl(regionId, riotUserName, riotTagLine)}
            iconSvg={<PentagonHouse />}
            WrapperLink={GlobalWrapperLink}
            onClick={onMobileClick}
            active={[
              getProfileOverviewUrl(regionId, riotUserName, riotTagLine),
              getProfileChampionStatsUrl(regionId, riotUserName, riotTagLine),
              getProfileLiveGameUrl(regionId, riotUserName, riotTagLine),
            ].some((path) => path.match(pathname))}
          >
            {[
              { label: "Overview", path: getProfileOverviewUrl(regionId, riotUserName, riotTagLine) },
              { label: "Champion Stats", path: getProfileChampionStatsUrl(regionId, riotUserName, riotTagLine) },
              { label: "Live Game", path: getProfileLiveGameUrl(regionId, riotUserName, riotTagLine) },
            ].map((dropdown, index) => (
              <SideNavDropdownLink
                key={index}
                label={dropdown.label}
                path={dropdown.path}
                onClick={onMobileClick}
                WrapperLink={GlobalWrapperLink}
                active={(path) => !!pathname.match(path)}
              />
            ))}
          </SideNavLink>
        )}
        <SideNavLink
          label="Tier List"
          path={`${tierListLocation.pathname}?${tierListLocation.search}`}
          iconSvg={<PaperWriting />}
          WrapperLink={GlobalWrapperLink}
          onClick={onMobileClick}
          active={[
            AppRoutes.DEFAULT_TIER_LIST,
            AppRoutes.TOP_TIER_LIST,
            AppRoutes.JUNGLE_TIER_LIST,
            AppRoutes.MID_TIER_LIST,
            AppRoutes.ADC_TIER_LIST,
            AppRoutes.SUPPORT_TIER_LIST,
            AppRoutes.PICK_URF_TIER_LIST,
            AppRoutes.ARURF_TIER_LIST,
            AppRoutes.ARAM_TIER_LIST,
            AppRoutes.NEXUS_BLITZ_TIER_LIST,
            AppRoutes.ONE_FOR_ALL_TIER_LIST,
            AppRoutes.DUO_TIER_LIST,
            AppRoutes.COMBAT_TIER_LIST,
            AppRoutes.OBJECTIVES_TIER_LIST,
            AppRoutes.ARENA_TIER_LIST,
            AppRoutes.ARENA_DUO_TIER_LIST,
          ].some((path) => pathname.match(path))}
        >
          {[
            { label: "Tier List", path: AppRoutes.DEFAULT_TIER_LIST },
            liveSpecialModes.includes(QueueTypeS.ARAM) && { label: "ARAM Tier List", path: AppRoutes.ARAM_TIER_LIST },
            liveSpecialModes.includes(QueueTypeS.PICK_URF) && { label: "URF Tier List", path: AppRoutes.PICK_URF_TIER_LIST },
            liveSpecialModes.includes(QueueTypeS.ARURF) && { label: "ARURF Tier List", path: AppRoutes.ARURF_TIER_LIST },
            liveSpecialModes.includes(QueueTypeS.NEXUS_BLITZ) && {
              label: "Nexus Blitz Tier List",
              path: AppRoutes.NEXUS_BLITZ_TIER_LIST,
            },
            liveSpecialModes.includes(QueueTypeS.ONE_FOR_ALL) && {
              label: "One For All Tier List",
              path: AppRoutes.ONE_FOR_ALL_TIER_LIST,
            },
            liveSpecialModes.includes(QueueTypeS.ARENA) && { label: "Arena Tier List", path: AppRoutes.ARENA_TIER_LIST },
            liveSpecialModes.includes(QueueTypeS.ARENA_DUO) && { label: "Arena Duos", path: AppRoutes.ARENA_DUO_TIER_LIST },
            { label: "Duo Tier List", path: AppRoutes.DUO_TIER_LIST },
            { label: "Combat", path: AppRoutes.COMBAT_TIER_LIST },
            { label: "Objectives", path: AppRoutes.OBJECTIVES_TIER_LIST },
          ].map((dropdown, index) => {
            if (!dropdown) return null;
            return (
              <SideNavDropdownLink
                key={index}
                label={dropdown.label}
                path={dropdown.path}
                onClick={onMobileClick}
                WrapperLink={GlobalWrapperLink}
                active={(path) => !!pathname.match(path)}
              />
            );
          })}
        </SideNavLink>
        <SideNavLink
          label="Champions"
          path={AppRoutes.CHAMPION_HOME}
          iconSvg={<Skullhead />}
          onClick={onMobileClick}
          WrapperLink={GlobalWrapperLink}
          active={[
            AppRoutes.CHAMPION_HOME,
            AppRoutes.CHAMPION_PROFILE_BUILD,
            AppRoutes.CHAMPION_PROFILE_BUILD_ARAM,
            AppRoutes.CHAMPION_PROFILE_BUILD_URF,
            AppRoutes.CHAMPION_PROFILE_BUILD_PICK_URF,
            AppRoutes.CHAMPION_PROFILE_BUILD_ARURF,
            AppRoutes.CHAMPION_PROFILE_BUILD_NEXUS_BLITZ,
            AppRoutes.CHAMPION_PROFILE_BUILD_ONE_FOR_ALL,
          ].some((path) => pathname.match(path))}
        />
        <SideNavLink
          label="Multisearch"
          path={AppRoutes.MULTISEARCH}
          iconSvg={<PaperSearch />}
          onClick={onMobileClick}
          WrapperLink={GlobalWrapperLink}
          active={(path) => !!pathname.match(path)}
        />
        <SideNavLink
          label="Live Games"
          path={AppRoutes.LIVE_GAMES}
          iconSvg={<Camera />}
          onClick={onMobileClick}
          WrapperLink={GlobalWrapperLink}
          active={(path) => !!pathname.match(path)}
        />
        <SideNavLink
          label="Leaderboards"
          path={AppRoutes.LEADERBOARD}
          iconSvg={<Trophy />}
          onClick={onMobileClick}
          WrapperLink={GlobalWrapperLink}
          active={(path) => !!pathname.match(path)}
        />
        <SideNavLink
          label="Items"
          path={AppRoutes.ITEMS}
          iconSvg={<Sword />}
          onClick={onMobileClick}
          WrapperLink={GlobalWrapperLink}
          active={(path) => !!pathname.match(path)}
        />
        <SideNavLink
          label="Probuild Stats"
          path={"https://probuildstats.com"}
          iconSvg={<ProBuildStatsLogo />}
          WrapperLink={AnchorWrapperLink}
          onClick={onMobileClick}
        />
      </SideNavLinksContainer>
      <Footer>
        {!isLoggedIn ? (
          <Link to={AppRoutes.LOGIN} onClick={onMobileClick}>
            <Guest clickable />
          </Link>
        ) : (
          !loadingUserState &&
          regionId &&
          riotUserName &&
          riotTagLine && (
            <Link to={getProfileOverviewUrl(regionId, riotUserName, riotTagLine)} onClick={onMobileClick}>
              <Member
                memberIcon={iconId ? getProfileIconImg(iconId) : "https://static.bigbrain.gg/assets/ugg/icons/profile-icon.svg"}
                memberLabel={`${riotUserName} #${riotTagLine}`}
                statusLabel="Online"
                statusColor="#0ADE96"
                clickable
              />
            </Link>
          )
        )}
        <FooterMenu>
          <Link onClick={onMobileClick} to={AppRoutes.ABOUT}>
            About
          </Link>
          <Link onClick={onMobileClick} to={AppRoutes.FAQ}>
            FAQ
          </Link>
          <Link onClick={onMobileClick} to={AppRoutes.PRIVACY_POLICY}>
            Privacy Policy
          </Link>
          <Link onClick={onMobileClick} to={AppRoutes.PRIVACY_SHIELD}>
            Privacy Shield
          </Link>
          <Link onClick={onMobileClick} to={AppRoutes.CAREERS}>
            Careers
          </Link>
          <Link onClick={onMobileClick} to={AppRoutes.TOS}>
            Terms of Service
          </Link>
        </FooterMenu>
      </Footer>
    </SideNav>
  );
}
