import React, { Component } from "react";
import { ReactComponent as PriorityNotification } from "svg/notification-exclamation.svg";
import { ReactComponent as NewNotificationIcon } from "svg/new-notification.svg";
import { ReactComonenet as NewFilledNotificationIcon } from "svg/new-filled-notification.svg";
import { ReactComponent as GGBetLogo } from "svg/ggbet/GGBet-Logo.svg";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.markAllAsRead = this.markAllAsRead.bind(this);
  }

  markAllAsRead() {
    this.props.setNotificationSeen(true);
    localStorage.setItem("lastNotificationSeenTime", Date.now());
    this.props.setNewNotifications([]);
  }

  render() {
    const { notificationData, newNotifications, notificationSeen, adToggleNotification } = this.props;

    let announcements = [];
    if (notificationData) {
      announcements = notificationData.announcements;
    }
    if (announcements.length == 0) {
      return (
        <div className="notification-dropdown-container">
          <div className="header-title">
            Notifications
            <span className="mark-all-as-read" onClick={this.markAllAsRead}>
              Mark all as read
            </span>
          </div>
          {adToggleNotification === true ? (
            <div className="gg-bet-promo-notif">
              <div className="ggbet-logo-notif-svg">
                <GGBetLogo></GGBetLogo>
              </div>
              <div className="gg-bet-promo-message-text">
                Receive a <b style={{ color: "#FFFFFF" }}>FREE $5 bet</b> with <b style={{ color: "#FFFFFF" }}>PROMO CODE:</b>
              </div>
              <a href={"https://mercurybest.com/gg/gb/?lp=00&param=ugg_ww-gift&go=sitereg&deeplink=/en/league-of-legends/"}>
                <div className="gg-bet-promo-message-code">
                  <div style={{ width: "100%" }}>UGG</div>
                </div>
              </a>
              <a href={"https://mercurybest.com/gg/gb/?lp=00&param=ugg_ww-gift&go=sitereg&deeplink=/en/league-of-legends/"}>
                <div className="gg-bet-promo-message-button">BET NOW</div>
              </a>
              <div className="ad-indication">Ad</div>
            </div>
          ) : null}
          <div className="no-new-notifications">There are no new notifications</div>
        </div>
      );
    } else {
      return (
        <div className="notification-dropdown-container">
          <div className="header-title">
            Notifications{" "}
            <span className="mark-all-as-read" onClick={this.markAllAsRead}>
              Mark all as read
            </span>
          </div>
          <div>
            {adToggleNotification === true ? (
              <div className="gg-bet-promo-notif">
                <div className="ggbet-logo-notif-svg">
                  <GGBetLogo></GGBetLogo>
                </div>
                <div className="gg-bet-promo-message-text">
                  Receive a <b style={{ color: "#FFFFFF" }}>FREE $5 bet</b> with <b style={{ color: "#FFFFFF" }}>PROMO CODE:</b>
                </div>
                <a href={"https://mercurybest.com/gg/gb/?lp=00&param=ugg_ww-gift&go=sitereg&deeplink=/en/league-of-legends/"}>
                  <div className="gg-bet-promo-message-code">
                    <div style={{ width: "100%" }}>UGG</div>
                  </div>
                </a>
                <a href={"https://mercurybest.com/gg/gb/?lp=00&param=ugg_ww-gift&go=sitereg&deeplink=/en/league-of-legends/"}>
                  <div className="gg-bet-promo-message-button">BET NOW</div>
                </a>
                <div className="ad-indication">Ad</div>
              </div>
            ) : null}
          </div>
          {announcements.map((entry, index) => {
            let priorityNotifPresent = entry.priorityNotificationState;
            let newNotifPresent = !notificationSeen;

            const bodyText = priorityNotifPresent ? "priority-title-body-text" : null;

            const className =
              priorityNotifPresent || !(!newNotifications || newNotifications.indexOf(index) === -1)
                ? "text-container-new"
                : "text-container";

            return (
              <div key={index} className="notification-entry-container">
                <div className={className}>
                  <div className="icon-align">
                    {priorityNotifPresent && <PriorityNotification className="priority-notification-icon" />}
                    {newNotifPresent && !priorityNotifPresent && <NewNotificationIcon className="new-notification-icon" />}
                    <div className="body-text">
                      <span className={bodyText}>{entry.title}:</span>
                      &nbsp;
                      {entry.description}
                    </div>
                  </div>
                  <div className="date-text">{entry.dateMade}</div>
                  {announcements.length - 1 == index ? "" : <div className="notification-seperator"></div>}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }
}

export default NotificationDropdown;
