import { useState } from "react";
import gql from "graphql-tag";
import { useApolloClient, useQuery, QueryHookOptions, QueryOptions } from "@apollo/client";
import { replacePlusFromURLPath } from "@ugg/shared/utils/url-query-params";
import { INTERNAL_CURRENT_SEASON } from "@ugg/shared/utils/season-helpers";
import { OVERVIEW_DEFAULT } from "@ugg/shared/query-params/filter-options-profile/overview";
import { RankScore } from "./profile-ranks";
import { MatchSummary } from "./match-summaries";

type SummonerProfileInitParams = Partial<{
  season: string;
}>;

export function getDefaultValsForSummonerProfile(
  regionId: string,
  riotUserName: string,
  riotTagLine: string,
  options: SummonerProfileInitParams = {},
) {
  const params = { ...OVERVIEW_DEFAULT, ...(options || {}) };

  const apiParams = {
    regionId: regionId?.toLowerCase(),
    riotUserName: riotUserName ? decodeURIComponent(replacePlusFromURLPath(riotUserName.toLowerCase())) : "",
    riotTagLine: riotTagLine ? decodeURIComponent(replacePlusFromURLPath(riotTagLine.toLowerCase())) : "",
    seasonId: parseInt(params.season || String(INTERNAL_CURRENT_SEASON)),
  };

  return { apiParams, params };
}

export function useSummonerProfileInitSimple(
  regionId: string,
  riotUserName: string,
  riotTagLine: string,
  options?: SummonerProfileInitParams,
  queryParams?: QueryHookOptions,
) {
  const { apiParams } = getDefaultValsForSummonerProfile(regionId, riotUserName, riotTagLine, options);

  return useQuery<SummonerProfileInitSimpleData>(GET_SUMMONER_PROFILE_SIMPLE, {
    ...(queryParams || {}),
    variables: {
      regionId: apiParams.regionId,
      riotUserName: apiParams.riotUserName,
      riotTagLine: apiParams.riotTagLine,
      seasonId: apiParams.seasonId,
    },
  });
}

type ManualFetchState<T> = {
  loading: boolean;
  data: T | null;
  error: Error | null;
};

export function useManualSummonerProfileInitSimple(
  regionId: string,
  riotUserName: string,
  riotTagLine: string,
  options?: Record<string, any>,
  queryParams?: QueryOptions,
) {
  const client = useApolloClient();
  const [fetchState, setFetchState] = useState<ManualFetchState<SummonerProfileInitSimpleData>>({
    loading: false,
    data: null,
    error: null,
  });
  const { apiParams } = getDefaultValsForSummonerProfile(regionId, riotUserName, riotTagLine, options);

  const getSummoner = () => {
    setFetchState({ loading: true, data: null, error: null });
    client
      .query<SummonerProfileInitSimpleData>({
        query: GET_SUMMONER_PROFILE_SIMPLE,
        variables: apiParams,
        ...(queryParams || {}),
      })
      .then((response) => {
        setFetchState({ loading: false, data: response.data, error: null });
      })
      .catch((error) => {
        setFetchState({ loading: false, data: null, error });
      });
  };

  return [getSummoner, fetchState] as const;
}

export function useClientSummonerProfileInitSimple() {
  const client = useApolloClient();

  return (
    regionId: string,
    riotUserName: string,
    riotTagLine: string,
    options?: Record<string, any>,
    queryParams?: QueryOptions,
  ) => {
    const { apiParams } = getDefaultValsForSummonerProfile(regionId, riotUserName, riotTagLine, options);
    return client.query<SummonerProfileInitSimpleData>({
      query: GET_SUMMONER_PROFILE_SIMPLE,
      variables: apiParams,
      ...(queryParams || {}),
    });
  };
}

export const PlayerInfo = `
  accountIdV3
  accountIdV4
  exodiaUuid
  iconId
  puuidV4
  regionId
  summonerIdV3
  summonerIdV4
  summonerLevel
  riotUserName
  riotTagLine
`;

export interface PlayerProfileSuggestions {
  regionId: string;
  puuid: string;
  riotUserName: string;
  riotTagLine: string;
  riotId: string;
  tier: string;
  rank: string;
  lp: number;
  rankScore: number;
  iconId: number;
  summonerLevel: number;
}

export interface PlayerInfoInterface {
  accountIdV3: number;
  accountIdV4: string;
  exodiaUuid: string;
  iconId: number;
  puuidV4: string;
  regionId: string;
  summonerIdV3: number;
  summonerIdV4: string;
  summonerLevel: number;
  riotUserName: string;
  riotTagLine: string;
}

interface CustomizationData {
  headerBg: string;
  pinnedMatchId: string;
  twitchLink: string;
  twitchName: string;
  twitterLink: string;
  twitterName: string;
  youtubeLink: string;
  youtubeName: string;
  pinnedMatchData: MatchSummary;
}

export interface SummonerProfileInitSimple {
  lastModified: number;
  playerInfo: PlayerInfoInterface;
  memberStatus: string;
  customizationData: CustomizationData;
}
interface SummonerProfileInitSimpleData {
  fetchProfileRanks: {
    rankScores: RankScore[];
  };
  profileInitSimple: SummonerProfileInitSimple;
}

export const GET_SUMMONER_PROFILE_SIMPLE = gql`
  query getSummonerProfile(
    $regionId: String!
    $seasonId: Int!
    $riotUserName: String!
    $riotTagLine: String!
  ) {
    fetchProfileRanks(
      riotUserName: $riotUserName,
      riotTagLine: $riotTagLine,
      regionId: $regionId,
      seasonId: $seasonId
    ) {
      rankScores {
        lastUpdatedAt
        losses
        lp
        promoProgress
        queueType
        rank
        role
        seasonId
        tier
        wins
      }
    }
    profileInitSimple(
      regionId: $regionId,
      riotUserName: $riotUserName,
      riotTagLine: $riotTagLine
    ) {
      lastModified
      memberStatus
      playerInfo {
        ${PlayerInfo}
      }
      customizationData {
        headerBg
      }
    }
  }
`;
