import StackTrace from "stacktrace-js";
import StackFrame from "stackframe";
import StackTraceGPS from "stacktrace-gps";

// Remove whitespace for easier parsing
export function getCleanComponentStack(componentStack: string) {
  return (componentStack || "")
    .trim()
    .split("\n")
    .map((el) => el.trim());
}

// Create new detailed stack using stacktrace-gps
export function getBetterComponentStack(componentStack: string[]): Promise<StackFrame[]> {
  const regexp = /at (?<functionName>[^\s]+)( \((?<fileName>.+):(?<lineNumber>\d+):(?<columnNumber>\d+)\))?$/;
  const stackFrames = componentStack.map((el) => {
    const found = el.match(regexp);
    const { groups } = found || {};
    const { functionName, fileName, lineNumber, columnNumber } = groups || {};

    if (!fileName) {
      return {
        functionName,
        fileName: "",
        lineNumber: -1,
        columnNumber: -1,
      } as StackFrame;
    }

    return new StackFrame({
      functionName,
      fileName,
      lineNumber: parseInt(lineNumber),
      columnNumber: parseInt(columnNumber),
    });
  });

  const gps = new StackTraceGPS({});
  const callback = (newStackFrame: StackFrame) => {
    return Promise.resolve(newStackFrame);
  };

  const promises = stackFrames.map((el) => {
    if (!el.fileName) return Promise.resolve(el);
    return gps
      .pinpoint(el)
      .then(callback, (gpsError) => {
        console.log(gpsError);
        return Promise.resolve(el);
      })
      .catch((error) => {
        return Promise.resolve(el);
      });
  });

  return Promise.all(promises);
}

export function formatBetterComponentStack(componentStack: StackFrame[]) {
  return componentStack.reduce((acc, curr) => {
    const { functionName, fileName, lineNumber, columnNumber } = curr;
    let newFrame = `at ${functionName}`;
    if (fileName) {
      newFrame += ` (${fileName}:${lineNumber}:${columnNumber})`;
    }

    return `${acc}    \n    ${newFrame}`;
  }, "");
}
