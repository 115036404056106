import React, { MouseEventHandler } from "react";
import classNames from "classnames";
import manifest from "@ugitgud/legos/assets/theme-manifest.json";
import { twMerge } from "tailwind-merge";
import { IconSizes } from "@ugitgud/legos/ui/shared";

export type IconName = keyof typeof manifest.icons;

export type IconProps = {
  className?: string;
  icon: React.ReactSVGElement | IconName | JSX.Element | React.ReactNode;
  label?: string;
  size?: IconSizes;
  mono?: boolean;
  wide?: boolean;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

export function isIconName(name: any): name is IconName {
  if (typeof name !== "string") {
    return false;
  }
  return typeof (manifest.icons as Record<string, string>)[name] !== "undefined";
}

//todo(jsimms): move to ui/media
export const Icon: React.FC<IconProps> = ({ icon, mono: fill, wide, className, size = "sm", onClick, label }) => {
  const isNamedIcon = typeof icon === "string";
  return (
    <span
      role="img"
      aria-label={label}
      className={twMerge(
        classNames("flex-shrink-0 icon", `icon-${size.toString()}`, className, {
          "icon-mono": fill,
          wide,
          [`icon-${icon}`]: isNamedIcon,
        }),
      )}
      onClick={onClick}
    >
      {!isNamedIcon && icon}
    </span>
  );
};
