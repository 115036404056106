import { STATIC_LOL_URL } from "@outplayed/riot-assets";
import { useJSONFetcher } from "@outplayed/json-fetcher";

export function useUGGApiVersions({ ssr, skip, apiKey, patch } = {}) {
  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  const url = `${STATIC_LOL_URL}/riot_patch_update/${envDir}/ugg/ugg-api-versions.json`;
  const fetchState = useJSONFetcher(url, { ssr, skip });

  if (fetchState.data && apiKey) {
    let latestPatch = null;
    if (!patch) {
      latestPatch = Object.keys(fetchState.data).sort((a, b) => {
        const aSplit = a.split("_");
        const bSplit = b.split("_");

        for (let i = 0; i < Math.min(aSplit.length, bSplit.length); i++) {
          const _a = parseInt(aSplit[i]) || 0;
          const _b = parseInt(bSplit[i]) || 0;
          if (_a === _b) continue;
          else return _a > _b ? -1 : 1;
        }
        return 0;
      })[0];
    }

    return { ...fetchState, data: fetchState.data[patch || latestPatch][apiKey] };
  }

  return fetchState;
}
